import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { htmlParser } from "../../../utils";
import { Tooltip, Button } from "antd";
import { ExcelIcon } from "../../../assets/icons";

function InterviewExcelExporter({ candidates, newInputs }) {
  const [CandidatesArray, setCandidatesArray] = useState([]);
  const [uniqueKeys, setUniqueKeys] = useState(false);

  //custom funcion to get all the text from te html quill

  function getAllTextFromTags(html, result = []) {
    let htmlProps = html?.props?.children;
    if (!!htmlProps) {
      if (Array.isArray(htmlProps)) {
        htmlProps?.forEach((prop) => getAllTextFromTags(prop, result));
      } else if (typeof htmlProps === "string") {
        result.push(htmlProps);
      } else {
        getAllTextFromTags(htmlProps, result);
      }
    } else {
      if (typeof html === "string") {
        result.push(html);
      }
    }

    return result;
  }
  //custom funcion to get all the text from te html quill

  const getTextFromTags = (value) => {
    const parseRes = htmlParser(value);
    let text = "";

    if (Array.isArray(parseRes)) {
      for (const res of parseRes) {
        let r = getAllTextFromTags(res).join(", ");

        text += r;
      }
    } else {
      text = getAllTextFromTags(parseRes).toString();
    }
    return text;
  };

  useEffect(() => {
    //we format the values of the array of candidates
    const test = [];
    candidates.forEach((candidate) => {
      let newCandidate = {};
      for (const [key, value] of Object.entries(candidate)) {
        let values = newInputs?.fieldOptions.find(
          (item) => item.formItemName === key
        );
        if (!!values) {
          let firsText = `${!!values ? values.label : key}:`;
          let secondText = ` ${
            !!value
              ? values.type === "datepicker"
                ? moment(value).format("DD/MM/YYYY HH:mm")
                : values.type === "quill" || values.type === "customquill"
                ? getTextFromTags(value)
                : value
              : "Nuk Ka"
          }`;
          newCandidate[firsText] = secondText;
        } else {
          if (
            key !== "googleDriveFolderId" &&
            key !== "applicantId" &&
            key !== "googleDriveFiles" &&
            key !== "userId" &&
            key != "createdAt" &&
            key != "keylog" &&
            key != "applicantReApplied" &&
            key !== "applicantEventURI"
          ) {
            let firsText = `${!!values ? values.label : key}:`;

            let secondText = ` ${
              !!value
                ? key === "applicantStartDate"
                  ? moment(value).format("DD/MM/YYYY")
                  : key === "applicantInterviewDate"
                  ? moment(value).format("DD/MM/YYYY HH:mm")
                  : key === "applicantNotes"
                  ? getTextFromTags(value)
                  : key === "applicantRoles"
                  ? !!Array.isArray(value)
                    ? value?.length > 0
                      ? value?.join(", ")
                      : "Nuk Ka"
                    : value
                  : value
                : "Nuk Ka"
            }`;

            newCandidate[firsText] = secondText;
          }
        }
      }
      test.push(newCandidate);
    });
    setCandidatesArray(test);
  }, [candidates]);

  //we create a set of unique keys depending on the users information
  useEffect(() => {
    let test = new Set();
    CandidatesArray.forEach((candidate) => {
      let values = Object.keys(candidate);
      values?.forEach((item) => test.add(item));
    });
    setUniqueKeys(test);
  }, [CandidatesArray]);

  return (
    <>
      {candidates.length !== 0 && (
        <>
          <ReactHtmlTableToExcel
            id="intervistat-table-xls-button"
            className="exportKandidateBtn"
            table="tableIntervistat"
            filename={
              candidates?.length > 1
                ? "Interviews_Table"
                : `${candidates[0]?.applicantFirstName}_${candidates[0]?.applicantLastName}_Table`
            }
            sheet={"Intervistat"}
            buttonText={
              <Tooltip
                placement="top"
                overlayClassName="global-icon-tooltip"
                title={
                  candidates?.length > 1
                    ? "Eksporto në eksel"
                    : `Eksporto "${candidates[0]?.applicantFirstName} ${candidates[0]?.applicantLastName}" në eksel`
                }
              >
                <Button
                  icon={<ExcelIcon width={18} height={20} fill="#fff" />}
                  className="exportKandidateBtn"
                  style={{ backgroundColor: "#1da193" }}
                />
              </Tooltip>
            }
          />
          {uniqueKeys && (
            <table style={{ display: "none" }} id="tableIntervistat">
              <thead>
                <tr>
                  {[...uniqueKeys]?.map((item) => {
                    return (
                      <th>
                        {item
                          .replace(/applicant/gi, "")
                          .replace(/application/gi, "")}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {CandidatesArray?.map((item) => {
                  return (
                    <tr>
                      {[...uniqueKeys]?.map((key) => {
                        return <td>{item[key]}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
}

export default InterviewExcelExporter;
