import React, { useState, useEffect } from "react";
import { Menu, message, Popconfirm } from "antd";
import "./sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import { useAuth } from "../../authentication/authHelpers";
import { FlexIconLogo, LogoKryesore, UserSidebar } from "../../assets/icons";
import { useNavigate, Outlet } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Tooltip from "antd/es/tooltip";

function Sidebar({ data, collapsed }) {
  const [k, setK] = useState([]);
  const [sData, setSData] = useState([]);
  const { users } = useSelector((state) => state.usersList);

  const [pagatFocus, setPagatFocus] = useState(null);
  const loc = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  const handlePassword = () => {
    Swal.fire({
      title: "Vendos kodin personal!!",
      input: "password",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      cancelButtonText: "Anulo",
      confirmButtonText: "Konfirmo",
      cancelButtonColor: "#EA3943",
      confirmButtonColor: "#1DA193",
      reverseButtons: true,
      showLoaderOnConfirm: true,
      showClass: {
        popup: "",
      },
      hideClass: {
        popup: "", // disable popup fade-out animation
      },
      // preConfirm: async (login) => {
      // 	if (login === "1234") {
      // 		navigate("/pagat");
      // 	} else {
      // 		message.error("Kodi i vendosur eshte gabim!");
      // 	}
      // },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value === users?.pinNumber) {
          // Swal.fire({
          // 	title: `SAKTE!`,
          // });
          navigate("/pagat");
        } else {
          message.error("Kodi i vendosur eshte gabim!");
          handlePassword();
        }
      }
    });
  };

  // if refresh page to still be focused last item was clicked
  useEffect(() => {
    let pathname = loc.pathname;
    if (pathname.includes("specifikat"))
      pathname = pathname.split("/").slice(0, -1).join("/");

    let idx = SidebarData.findIndex((el) => el.to.includes(pathname));
    if (idx !== -1) setK([`${SidebarData[idx]?.key}`]);
  }, [loc]);

  useEffect(() => {
    if (data.length > 0) {
      setSData(data);
    }
  }, [data]);
  useEffect(() => {
    if (loc?.pathname === "/pagat" || loc?.pathname === "/pagat/llogaritja") {
      setPagatFocus("pagat");
    } else {
      setPagatFocus(null);
    }
  }, [loc]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 840px)",
  });

  return (
    <div className="side-bar">
      <Link to="/kryefaqja">
        <div className="sidebar-logo">
          {collapsed ? <FlexIconLogo /> : <LogoKryesore />}
          {/* <ChristmasFlexLogo /> */}
        </div>
      </Link>
      {sData?.length > 0 && (
        <Menu
          mode="inline"
          selectedKeys={k}
          defaultSelectedKeys={[k]}
          overlayClassName="main-sidebar"
          className="main-sidebar"
        >
          {/* <h1>{sData?.email}</h1> */}
          {/* fetch the sidebar data and check if have userAccess */}
          {data[0]?.accessConfig?.sidebarConfig[0]
            ?.filter((a) => a.auth !== false)
            .map((el, idx) => {
              let Icon =
                SidebarData[SidebarData.findIndex((s) => s.to === el.to)]?.src;
              if (el?.to === "/pagat") {
                return (
                  <Popconfirm
                    className="sigurt"
                    placement="right"
                    title="A jeni te sigurt?"
                    onConfirm={() => handlePassword()}
                    key={el?.id}
                  >
                    {isDesktopOrLaptop ? (
                      <Menu.Item
                        key={el?.id}
                        className={pagatFocus === "pagat" ? "Focus-Show" : ""}
                        icon={
                          <Icon width={20} height={18} fill="red" color="red" />
                        }
                      >
                        {el?.title}
                      </Menu.Item>
                    ) : (
                      <Menu.Item
                        className={pagatFocus === "pagat" ? "Focus-Show" : ""}
                        key={el?.id}
                        icon={<Icon width={22} height={20} />}
                      >
                        {el?.title}
                      </Menu.Item>
                    )}
                  </Popconfirm>
                );
              }
              return (
                <Menu.Item
                  key={el?.id}
                  icon={
                    <span className="iconWrapper">
                      <Icon width={18} height={16} />
                    </span>
                  }
                  title={el?.title}
                  style={{
                    display: "flex",
                    flexDirection: `${!!collapsed ? "column" : "row"}`,
                  }}
                >
                  <Link to={el?.to} key={el?.id} rel="noopener noreferrer">
                    {!collapsed && el?.title}
                  </Link>
                </Menu.Item>
              );
            })}{" "}
          {/* <img
						src="https://i.ibb.co/dQYXHpr/CITYPNG-COM-Sparkle-Lighted-Christmas-Deer-Illustration-PNG-2000x2000.png"
						width="250px"
					/> */}
        </Menu>
      )}{" "}
      {/* <div
				style={{
					marginTop: "200px",
				}}
			>
				<img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/25839/critters.svg" width="250px" />{" "}
				<br />
				<img
					src="https://i.ibb.co/dQYXHpr/CITYPNG-COM-Sparkle-Lighted-Christmas-Deer-Illustration-PNG-2000x2000.png"
					width="250px"
				/>
			</div> */}
    </div>
  );
}

export default Sidebar;
