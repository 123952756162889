export const albanianDay = (albanianDay) => {
  switch (albanianDay) {
    case "Monday":
      return "E hënë";
    case "Tuesday":
      return "E martë";
    case "Wednesday":
      return "E merkurë";
    case "Thursday":
      return "E enjte";
    case "Friday":
      return "E premte";
    case "Saturday":
      return "E shtunë";
    case "Sunday":
      return "E dielë";
  }
};
