import React, { useContext } from "react";
import { Card, Row, Col } from "antd";
import { EditIcon } from "../../../../../assets/icons";
import "../../components/DataCard.scss";
import PunonjesitContext from "../../../store/PunonjesitContext";

function ContactInfoCard({ handleClick }) {
  const { employee } = useContext(PunonjesitContext);

  return (
    <div className="details-view-employes" style={{ width: "100%" }}>
      <Card
        className="info-card"
        title="Informacione kontakti"
        extra={
          <div className="icon-button">
            <button
              id="edit"
              disabled={employee?.employeeStatus === "Aktiv" ? false : true}
              onClick={handleClick}
            >
              <EditIcon className="pen-icon" />
            </button>
          </div>
        }
      >
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Numri i emergjences:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeEmergencyContact}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Numri i celularit:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeePhoneNumber}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Email i punes:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeEmailWork}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Email personal:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeePersonalEmail}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Adresa:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeAddress}
              </div>
            </Col>
          </div>
        </Row>
      </Card>
    </div>
  );
}
export default ContactInfoCard;
