import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button, Input, Tooltip, DatePicker, message } from "antd";
import moment from "moment-timezone";
import { IconExcel, IconKerko, IconNgargo } from "../../assets/icons";
import AgGridComponent from "../AG-grid/AgGridComponent";
import DetailsModal from "./DetailsModal";
import "./raportet.scss";
import HtmlExelTabel from "../Konfigurimet/dynamic components/HtmlExelTabel";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import { useMediaQuery } from "react-responsive";
import MobileRaportet from "./MobileRaportet";
import { getReportsClockings } from "../Reports/generate/GeneratePage";
import PunonjesitContext from "../punonjesit/store/PunonjesitContext";
const { RangePicker } = DatePicker;

function Raportet() {
  const { clockings } = useSelector((state) => state.clockings);
  const { employee } = useContext(PunonjesitContext);
  const allLeaves = clockings
    ?.filter?.(
      (rqs) =>
        rqs?.dayType === "HolidayRequest" &&
        rqs?.employeeId === employee?.employeeId
    )
    ?.sort((a, b) => moment(b?.clockInDate) - moment(a?.clockInDate));

  const [specificData, setSpecificData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [filteredData, setFilteredData] = useState({
    active: false,
    data: [],
    timeInterval: [],
  });
  const [visible, setVisible] = useState(false);
  const getColor = (reqStatus) => {
    switch (reqStatus) {
      case "Aprovuar":
        return "#1DA193";
      case "Refuzuar":
        return "#EA3943";
      case "Pending":
        return "#FCC94A";
    }
  };

  const excludeWeekends = (startDate, endDate) => {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = new Date(curDate).getDay();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
      if (!isWeekend) {
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000;
    }
    return count;
  };

  const columnDefs = [
    {
      headerName: "Nr.",
      field: "fieldId",
      valueGetter: "node.rowIndex + 1",
      flex: 1,
    },
    {
      headerName: "Lloji i lejes",
      field: "dayType",
      filter: "agTextColumnFilter",
      flex: 3,
      cellRendererFramework: () => {
        return <>Pushime</>;
      },
    },
    {
      headerName: "Statusi i lejes",
      field: "dayType",
      filter: "agTextColumnFilter",
      flex: 3,
      cellRendererFramework: (params) => (
        <div
          className="status-column"
          style={{ backgroundColor: getColor("Aprovuar") }}>
          Aprovuar
        </div>
      ),
    },
    {
      headerName: "Data e lejes",
      field: "clockInDate",
      filter: "agTextColumnFilter",
      flex: 3,
      valueFormatter: function (params) {
        try {
          return moment(params?.value).format("DD/MM/YYYY");
        } catch (err) {
          console.error("employeeWorkStartDate--err");
        }
      },
    },
    // {
    //   headerName: "Data e mbarimit",
    //   field: "requestPeriod",
    //   filter: "agTextColumnFilter",
    //   flex: 3,
    //   valueFormatter: function (params) {
    //     try {
    //       return moment(params.value.slice(-1)[0]).format("DD/MM/YYYY");
    //     } catch (err) {
    //       console.error("employeeWorkStartDate--err");
    //     }
    //   },
    // },
    // {
    //   headerName: "Detajet e lejes",
    //   field: "",
    //   filter: "agTextColumnFilter",
    //   flex: 3,
    //   cellRendererFramework: (params) => (
    //     <div>
    //       <Button
    //         className="request-details-btn"
    //         onClick={() => {
    //           handleClickOpen();
    //           setSpecificData(params.data);
    //         }}
    //       >
    //         Detaje
    //       </Button>
    //     </div>
    //   ),
    // },
    // {
    //   headerName: "Dite pa fundjave",
    //   field: "requestPeriod",
    //   filter: "agTextColumnFilter",
    //   flex: 3,
    //   cellRendererFramework: (params) => (
    //     <div
    //       style={{ display: "flex", justifyContent: "center", marginRight: 80 }}
    //     >
    //       <span>{`${excludeWeekends(
    //         new Date(moment(params.value[0]).format("YYYY/MM/DD")),
    //         new Date(moment(params.value.slice(-1)[0]).format("YYYY/MM/DD"))
    //       )}`}</span>
    //     </div>
    //   ),
    // },
  ];
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onFilteredDate = async (e) => {
    console.log("onFilteredDate", e);
    if (e?.[0] && e?.[1]) {
      setFilteredData((prev) => ({ ...prev, active: true, timeInterval: e }));
      message.loading("Duke ngarkuar te dhenat...", 0);
      await getReportsClockings(e, employee?.employeeId, "HolidayRequest")
        .then((r) => {
          let toFilter = r
            ?.filter((el) => el?.employeeId === employee?.employeeId)
            .filter(
              (rqs) =>
                rqs?.dayType === "HolidayRequest" || rqs?.dayType === "Sick"
            );
          setFilteredData((prev) => ({ ...prev, data: toFilter }));
        })
        .then(() => {
          message.destroy();
          message.success("Te dhenat u ngarkuan me sukses!", 2);
        })
        .catch((err) => {
          message.destroy();
          message.error("Dicka shkoi keq, ju lutem provoni perseri!", 2);
        });
    } else {
      setFilteredData((prev) => ({ ...prev, active: false, timeInterval: [] }));
    }
  };

  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setQuickFilter(null);
  };

  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };
  const handleClickOpen = () => {
    setVisible(true);
  };

  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let rowDataGrid = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowDataGrid?.push(node?.data);
  });

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  return (
    <>
      {isMobileView ? (
        <div style={{ height: "100%" }}>
          <div className="user-requests-aggrid">
            <div className="ag-grid-punojsit">
              <div className="ag-grid-header">
                <Row style={{ width: "100%" }}>
                  <Col
                    flex={4}
                    style={{ display: "flex", gap: 20 }}
                    className="responsive-col-grid-pagesa">
                    <div className="header-search">
                      <div className="icon-search-header">
                        <IconKerko width={15} height={15} />
                      </div>
                      <Input
                        placeholder="Kërko një rekord"
                        onChange={onFilterTextChange}
                        style={{ width: 180 }}
                      />
                      <Button className="pastro-btn" onClick={clearFilters}>
                        Pastro
                      </Button>
                    </div>
                    <div className="header-icons">
                      <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="Lejet-employees-view"
                        filename="lejet-punojsit"
                        sheet="lejet-punojsit"
                        buttonText={
                          <Tooltip
                            placement="top"
                            overlayClassName="global-icon-tooltip"
                            title={"Eksporto në eksel"}>
                            <IconExcel />
                          </Tooltip>
                        }
                      />
                      <ExportToPdf
                        id={"#Lejet-employees-view"}
                        tabelName={"lejet-punojsit"}
                      />
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={"Printo tabelën"}>
                        <IconNgargo onClick={handlePrint} />
                      </Tooltip>
                    </div>
                    <div>
                      <RangePicker
                        bordered={true}
                        className="range-pick-report"
                        allowClear={true}
                        onCalendarChange={onFilteredDate}
                        renderExtraFooter={() =>
                          "Intervali maksimal i te dhenave per kete report eshte 24 muaj."
                        }
                        // disabledDate={(params) =>
                        //   disabledDate(params, filteredData?.timeInterval)
                        // }
                        value={
                          filteredData?.active
                            ? filteredData?.timeInterval
                            : null
                        }
                        // onChange={(e) => {
                        //   onTimeChange(e);
                        //   setShortcut("range");
                        // }}
                        format={"DD/MM/YYYY"}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="ag-theme-alpine"
                style={{ height: 760, width: "100%" }}>
                <AgGridComponent
                  rowData={
                    filteredData?.active
                      ? filteredData?.data?.sort(
                          (a, b) => b?.clockInDate - a?.clockInDate
                        )
                      : allLeaves?.sort(
                          (a, b) => b?.clockInDate - a?.clockInDate
                        )
                  }
                  rowSelection="single"
                  onGridReady={onGridReady}
                  paginationPageSize={15}
                  columnDefs={columnDefs}
                />
                {/* <HtmlExelTabel
                  tableData={rowDataGrid?.map(
                    ({ requestType, requestStatus, requestPeriod }) => ({
                      requestType,
                      requestStatus,
                      requestPeriod: moment(requestPeriod[0]).format(
                        "DD/MM/YYYY"
                      ),
                      requestPeriodEnd: moment(
                        requestPeriod.slice(-1)[0]
                      ).format("DD/MM/YYYY"),
                    })
                  )}
                  header={[
                    "Lloji i lejes",
                    "Statusi i lejes",
                    "Data e fillimit",
                    "Data e mbarimit",
                  ]}
                  id="Lejet-employees-view"
                  componentRef={componentRef}
                /> */}
              </div>
            </div>
          </div>
          <DetailsModal
            {...{
              visible,
              setVisible,
              allLeaves,
              specificData,
            }}
          />
        </div>
      ) : (
        <MobileRaportet rowDataGrid={rowDataGrid} />
      )}
    </>
  );
}

export default Raportet;
function disabledDate(current, ledjo) {
  // Disable dates beyond a 3-month range
  return current && !!ledjo?.[0]
    ? current < moment().subtract(3, "months") ||
        current > moment().add(3, "months")
    : false;
}
