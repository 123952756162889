import React from "react";
import "./systemEmployeeStatus.scss";
import ColorCard from "../../dynamic components/ColorCard";

function SistemiStatustetPunojsve() {
  const id = "ea2e2537-0b6a-4292-aca8-62eafecbfad1";
  const cardTitle = "Statuset e punonjësve";
  const styleCard = {
    height: 555,
    width: "100%",
  };

  return (
    <>
      <ColorCard id={id} cardTitle={cardTitle} styleCard={styleCard} />
    </>
  );
}

export default SistemiStatustetPunojsve;
