import { useSelector } from "react-redux";

/**
 * This hook gets programFields from redux and transforms them into an object
 *
 * @returns Object Formatted programFields object {[fieldName]: fieldOptions}
 */
export const useProgramFields = (key = "fieldId") => {
  const { programFields = [] } = useSelector((state) => state.programFields);

  const programFieldsObj = programFields.reduce(
    (acc, { [key]: keyToUse, fieldOptions }) => ({
      ...acc,
      [keyToUse]: fieldOptions,
    }),
    {}
  );

  return programFieldsObj;
};
