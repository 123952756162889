const NotifReducer = (state, action) => {
	switch (action.type) {
		case "START":
			return {
				user: null,
				isFetching: true,
				error: false,
			};
		// case "UNREAD":
		//   return {
		//     user: "flex@flex.al",
		//     isFetching: false,
		//     error: false,
		//     notificationsArr: action.payload,
		//   };
		case "LOAD_NOTIFS":
			return {
				notificationsArr: action.payload
					.filter((el) => el?.notificationMarkedDeleted === false)
					.sort((a, b) => b.createdAt - a.createdAt),
				isFetching: false,
			};
		case "ADD_NOTIF":
			return {
				notificationsArr: [...state.notificationsArr, action.payload],
			};
		case "PROCESS_NOTIFS":
			return {
				notificationsArr: [...state.notificationsArr],
			};
		case "MARK_READ":
			return {
				notificationsArr: state.notificationsArr.map((n) =>
					n.notificationId === action.payload ? { ...n, notificationMarkedRed: true } : n
				),
			};
		case "MARK_UNREAD":
			return {
				notificationsArr: state.notificationsArr.map((n) =>
					n.notificationId === action.payload ? { ...n, notificationMarkedRed: false } : n
				),
			};
		case "TOGGLE_READ":
			return {
				notificationsArr: state.notificationsArr.map((n) =>
					n.notificationId === action.payload
						? { ...n, notificationMarkedRed: !n.notificationMarkedRed }
						: n
				),
			};
		case "HIDE_NOTIF":
			return {
				notificationsArr: state.notificationsArr.map((n) =>
					n.notificationId === action.payload
						? { ...n, notificationMarkedHidden: !n.notificationMarkedHidden }
						: n
				),
			};
		case "DELETE_NOTIF":
			return {
				notificationsArr: state.notificationsArr.filter((n) => n.notificationId !== action.payload),
			};
		case "MULTI_DELETE":
			return {
				notificationsArr: action.payload,
				isFetching: false,
			}
		// case "ADD_NOTIF":
		//   return {
		//     notificationsArr: state.notificationsArr.map(
		//       (n) => n.notificationId !== action.payload
		//     ),
		//   };
		default:
			throw new Error("Something went wrong with Notification processes.");
	}
};
export default NotifReducer;
