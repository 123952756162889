import React from "react";
import moment from "moment";
import { forwardRef } from "react";

const DokumentacionePrint = forwardRef(({ tableData, componentRef }) => {
  return (
    <div
      className="Dokumentacione-print"
      style={{ visibility: "collapse" }}
      ref={componentRef}
    >
      <table id="table-to-xls">
        <thead>
          <tr>
            <th>Punonjësi</th>
            <th>Lloji i Dokumentacionit</th>
            <th>Statusi</th>
            <th>Skedarët e Ngarkuar</th>
            <th>Data e Skadencës</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map?.((el, index) => (
            <tr key={index}>
              <td style={{ width: "120px" }}>{el?.name} </td>
              <td style={{ width: "150px" }}>{el?.docType}</td>
              <td>{el?.docStatus}</td>
              <td>
                <a
                  href={`${
                    !!el?.googleDriveUploads
                      ? el?.googleDriveUploads?.[0]?.downloadUrl
                      : ""
                  }`}
                  target={"_blank"}
                >
                  {!!el?.googleDriveUploads
                    ? el?.googleDriveUploads[0]?.name
                    : ""}
                </a>
              </td>
              <td>
                {!!el?.expirationDate
                  ? moment(el?.expirationDate).format("DD/MM/YYYY")
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
export default DokumentacionePrint;
