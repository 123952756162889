import { Col } from "antd";
import React from "react";
import Cardkonfig from "../../dynamic components/Card";
import InformacioneKontakti from "./InformacioneKontakti";

function CardComponentInfoContatct({ handleChangeDetajet }) {
  return (
    <Cardkonfig
      contentChild={
        <InformacioneKontakti handleChangeDetajet={handleChangeDetajet} />
      }
      width="100%"
      height={"calc(100% - 35px)"}
    // title="Informacione kontakti"
    />
  );
}

export default CardComponentInfoContatct;
