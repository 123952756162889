import isEqual from "lodash/isEqual";
import { isArrayEqual } from "../utils/isArrayEqual";

/**
 *
 * @param {*} originalObject The original object.
 * @param {*} updatedObject The updated object.
 * @returns the updated keys.
 */

export const getObjectChanges = (originalObject = {}, updatedObject = {}) =>
  Object.keys(updatedObject).reduce(
    (acc, currKey) =>
      !!Object.keys(originalObject).includes(currKey) &&
      (Array.isArray(originalObject[currKey])
        ? isArrayEqual(originalObject[currKey], updatedObject[currKey])
        : isEqual(originalObject[currKey], updatedObject[currKey]))
        ? acc
        : { ...acc, [currKey]: updatedObject[currKey] },
    {}
  );
