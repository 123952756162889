import React, { useState, useEffect, useMemo } from "react";
import { Modal, TimePicker, message, Button, Checkbox } from "antd";
// import {
//   editShift,
//   getShift,
// } from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
// import swal from "sweetalert";
import "./NewShiftModal.scss";
import { ModalXIcon, WarningIcon } from "../../../../assets/icons";
// import TextArea from "antd/lib/input/TextArea";
import { DatePicker, Select, Dropdown, Menu } from "antd";
import moment from "moment-timezone";
import {
  getDisabledAfterCustomHours,
  getDisabledAfterCustomMinutes,
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
} from "../../../dashboard/utils/disabledTimeFilters";
import { BreedingRhombusSpinner } from "react-epic-spinners";
import { createNewShiftByAdmin } from "../utils";
import { useAuth } from "../../../../authentication/authHelpers";
import CrashShift from "../crashShiftModal/CrashShift";
import SuccessComponent from "../../../commonComponents/SuccessComponent/SuccessComponent";
import { RichTextEditor } from "../../../commonComponents";
import { useDispatch, useSelector } from "react-redux";
import { requestMultipleTables } from "../../../../helpers/API/RequestFactory";
import { clockings } from "../../../../store/actions";
import MultiDate from "react-multi-date-picker";
import useReactIpLocation from "react-ip-details";

import DatePanel from "react-multi-date-picker/plugins/date_panel";
import axios from "axios";

const { Option } = Select;

function NewShiftModal({
  selectedEmployee,
  selfType,
  newShiftVisib,
  setNewShiftVisib,
  onRefresh,
  employees,
}) {
  const [newShift, setNewShift] = useState({
    clockInDate: null,
    clockOutDate: null,
    note: "",
    employeeId: null,
  });

  const [dateMoment, setDateMoment] = useState(moment());
  const auth = useAuth();
  const [saving, setSaving] = useState(false);
  const [crashError, setCrashError] = useState(false);
  const [crashData, setCrashData] = useState([]);
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [dayType, setDayType] = useState("Regular");
  const { programFields } = useSelector((state) => state.programFields);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const [calendarMode, setCalendarMode] = useState("single");
  const [multiDays, setMultiDays] = useState([]);
  const { ipResponse } = useReactIpLocation();
  const [clockInWarning, setClockInWarning] = useState();
  const [ip, setIp] = useState("");

  const festat = useMemo(() => {
    if (!!programFields) {
      let temp =
        programFields.find((el) => el?.fieldName === "Festat Zyrtare")
          ?.fieldOptions || [];
      if (temp.length > 0) {
        if (
          temp?.filter(
            (el) =>
              moment(el?.effectiveDate).format("DD/MM/YYYY") ===
              moment(dateMoment).format("DD/MM/YYYY")
          )?.length > 0
        ) {
          setClockInWarning("Kjo ditë është festë zyrtare");
          setDayType("NationalHoliday");
        } else {
          setClockInWarning(false);
          setDayType("Regular");
        }
      }
    }
  }, [dateMoment]);

  const dispatch = useDispatch();
  const reload = async () => {
    const getData = async () => {
      await requestMultipleTables(["clocking"]).then((res) => {
        dispatch(clockings(res[0]));
      });
    };
    await getData();
  };

  const onSave = async (type) => {
    if (
      newShift.clockInDate &&
      newShift.employeeId &&
      newShift.note !== null &&
      dayType !== null
    ) {
      setSaving(true);

      let date1 = moment(dateMoment);
      let clockIn = moment(newShift.clockInDate);
      let date2 = moment(dateMoment);
      let clockOut = moment(newShift.clockOutDate);
      date1.set({
        hour: clockIn.get("hour"),
        minute: clockIn.get("minute"),
        second: clockIn.get("second"),
      });
      date2.set({
        hour: clockOut.get("hour"),
        minute: clockOut.get("minute"),
        second: clockOut.get("second"),
      });

      const toSend = {
        clockInDate: moment(date1).valueOf(),
        clockOutDate:
          newShift.clockOutDate !== null ? moment(date2).valueOf() : null,
        employeeId: newShift.employeeId,
        clockingCategory: "manualAdmin",
        dayType: dayType,
        clockInNote: "",
        clockOutNote: "",
        // employeeFirstName: "",
        // employeeFirstName: "",
        editedClockIn: false,
        editedClockOut: false,
        editedByAdmin: true,
        forgotClockOut: false,
        approved: false,
        clockingLogs: [
          {
            createdAt: moment().valueOf(),
            note: newShift.note,
            newShiftByAdmin: true,
            username: auth?.user?.username,
            ipResponse:
              {
                ...ipResponse,
                IPv4: ip,
              } || null,
          },
        ],
      };

      await createNewShiftByAdmin(toSend, newShift.employeeId, employeeRequests)
        .then(() => {
          if (type === "reset") {
            setSuccessVisibility(true);
          } else if (type === "continue") {
            message.success("Turni u shtua me sukses!");
            setNewShift((prev) => ({
              ...prev,
              clockInDate: null,
              clockOutDate: null,
              note: "",
              // employeeId: null,
            }));
            onRefresh();
          }
          // No need for reload because it needs to be approved! (For Employee View -> Wages!)
          // reload()
        })
        .catch((errArr) => {
          setCrashError(true);
          setCrashData(errArr);
        });
      setSaving(false);
    } else {
      message.error(
        "Gabim ne plotesimin e te dhenave! Ju lutem plotesoni te gjitha fushat!"
      );
    }
  };

  const handleReset = () => {
    setNewShift({
      clockInDate: null,
      clockOutDate: null,
      note: "",
      employeeId: null,
    });
    setDateMoment(moment());
    setNewShiftVisib(false);
    onRefresh();
  };

  const onEditClockIn = (e) => {
    const date = moment(dateMoment);
    const time = moment(e);
    date.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
      second: time.get("second"),
    });
    if (e !== null && newShift.clockOutDate !== null) {
      if (moment(date).valueOf() < moment(newShift.clockOutDate).valueOf()) {
        setNewShift((prev) => ({ ...prev, clockInDate: date }));
      } else {
        message.error("Fillimi i turnit nuk mund te jete me vone se mbarimi.");
      }
    } else {
      setNewShift((prev) => ({ ...prev, clockInDate: date }));
    }
  };

  const onEditClockOut = (e) => {
    const date2 = moment(dateMoment);
    const time2 = moment(e);
    date2.set({
      hour: time2.get("hour"),
      minute: time2.get("minute"),
      second: time2.get("second"),
    });
    const tempClockIn = moment(dateMoment);

    if (newShift?.clockInDate !== null) {
      tempClockIn.set({
        hour: newShift?.clockInDate.get("hour"),
        minute: newShift?.clockInDate.get("minute"),
        second: newShift?.clockInDate.get("second"),
      });
    }

    if (e !== null && newShift.clockInDate !== null) {
      // changed clockIn
      if (moment(date2).valueOf() > moment(tempClockIn).valueOf()) {
        setNewShift((prev) => ({ ...prev, clockOutDate: date2 }));
      } else {
        message.error(
          "Mbarimi i turnit nuk mund te jete me shpejt se fillimi."
        );
        // setNewShift((prev) => ({ ...prev, clockOutDate: date2 }));
      }
    } else {
      setNewShift((prev) => ({ ...prev, clockInDate: date2 }));
    }
  };

  const onCalendarPick = (e) => {
    if (e !== null && e !== undefined) {
      setDateMoment(e);
      setNewShift((prev) => ({
        ...prev,
        clockInDate: null,
        clockOutDate: null,
      }));
    }
  };

  const onSelectEmployee = (e) => {
    if (e !== null && e !== undefined) {
      setNewShift((prev) => ({ ...prev, employeeId: e }));
    }
  };

  const onClockinCateg = (value) => {
    setDayType(value);
  };

  const handleMultiShift = () => {
    console.log("new shift", newShift);
    console.log("multi", multiDays);
    const { clockInDate, clockOutDate, employeeId, note } = newShift;

    if (!!clockInDate && !!clockOutDate && !!employeeId) {
      setSaving(true);

      const multipleSend = multiDays?.map((day) => {
        const temp = day.format("DD/MM/YYYY");
        let date1 = moment(temp, "DD/MM/YYYY");
        let clockIn = moment(newShift.clockInDate);
        let date2 = moment(temp, "DD/MM/YYYY");
        let clockOut = moment(newShift.clockOutDate);
        date1.set({
          hour: clockIn.get("hour"),
          minute: clockIn.get("minute"),
          second: clockIn.get("second"),
        });
        date2.set({
          hour: clockOut.get("hour"),
          minute: clockOut.get("minute"),
          second: clockOut.get("second"),
        });
        const toSend = {
          clockInDate: moment(date1).valueOf(),
          clockOutDate:
            newShift.clockOutDate !== null ? moment(date2).valueOf() : null,
          employeeId: newShift.employeeId,
          clockingCategory: "manualAdmin",
          dayType: dayType,
          clockInNote: "",
          clockOutNote: "",
          editedClockIn: false,
          editedClockOut: false,
          editedByAdmin: true,
          forgotClockOut: false,
          approved: false,
          clockingLogs: [
            {
              createdAt: moment().valueOf(),
              note: newShift.note,
              newShiftByAdmin: true,
              username: auth?.user?.username,
            },
          ],
        };
        return toSend;
      });
      let toSendCount = 0;
      multipleSend?.forEach(async (sendInitial) => {
        setTimeout(async () => {
          let type = "continue";
          await createNewShiftByAdmin(
            sendInitial,
            newShift.employeeId,
            employeeRequests
          )
            .then(() => {
              toSendCount++;
              if (type === "reset") {
                setSuccessVisibility(true);
              } else if (type === "continue") {
                message.success("Turni u shtua me sukses!");
                setNewShift((prev) => ({
                  ...prev,
                  clockInDate: null,
                  clockOutDate: null,
                  note: "",
                  // employeeId: null,
                }));
                // onRefresh();
              }
              // No need for reload because it needs to be approved! (For Employee View -> Wages!)
              // reload()
            })
            .catch((errArr) => {
              setCrashError(true);
              setCrashData(errArr);
            })
            .finally(() => {
              setSaving(false);
              onRefresh();
            });
        }, 1000);
      });
      console.log("multipleSend", multipleSend.length, toSendCount);
      if (toSendCount === multipleSend.length) {
        message.success("Turnet u shtuan me sukses!");
      }
    } else {
      message.error("Ju duhet te plotesoni te gjitha fushat perkatese!");
    }
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <span onClick={() => onSave("reset")}>Shto Turnin dhe mbyll</span>
          ),
        },
        {
          label: (
            <span onClick={() => onSave("continue")}>
              Shto turnin dhe vazhdo{" "}
            </span>
          ),
        },
      ]}
    />
  );

  const onShortcut = (type) => {
    const punchIn = moment(dateMoment);
    const punchOut = moment(dateMoment);

    if (type === "afternoon") {
      punchIn.set({
        hour: "13",
        minute: "30",
        second: "00",
      });
      punchOut.set({
        hour: "21",
        minute: "30",
        second: "00",
      });
      setNewShift((prev) => ({
        ...prev,
        clockInDate: punchIn,
        clockOutDate: punchOut,
      }));
    } else if (type === "afternoon-start") {
      punchIn.set({
        hour: "13",
        minute: "30",
        second: "00",
      });
      setNewShift((prev) => ({
        ...prev,
        clockInDate: punchIn,
        clockOutDate: null,
      }));
    } else if (type === "morning") {
      punchIn.set({
        hour: "10",
        minute: "00",
        second: "00",
      });
      punchOut.set({
        hour: "18",
        minute: "30",
        second: "00",
      });
      setNewShift((prev) => ({
        ...prev,
        clockInDate: punchIn,
        clockOutDate: punchOut,
      }));
    } else if (type === "morning-start") {
      punchIn.set({
        hour: "10",
        minute: "00",
        second: "00",
      });
      setNewShift((prev) => ({
        ...prev,
        clockInDate: punchIn,
        clockOutDate: null,
      }));
    }
  };

  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res?.data);
    setIp(res?.data?.ip);
  };

  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    if (selfType === true && selectedEmployee !== null) {
      // console.log("selected", selectedEmployee);
      setNewShift((prev) => ({
        ...prev,
        employeeId: selectedEmployee?.employeeId,
      }));
    }
  }, [selfType, selectedEmployee]);

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}

      <Modal
        destroyOnClose={true}
        width={1118}
        className="new-shift-container"
        title="Shto Turn Të Ri"
        open={newShiftVisib}
        onOk={() => setNewShiftVisib(false)}
        onCancel={() => setNewShiftVisib(false)}
        closeIcon={<ModalXIcon />}
        centered={true}
        footer={[
          <button className="close-btn" onClick={() => setNewShiftVisib(false)}>
            Mbyll Faqen
          </button>,
          calendarMode === "single" ? (
            <Dropdown disabled={saving} overlay={menu} placement="bottom">
              <Button className="start-btn">Shto Turnin</Button>
            </Dropdown>
          ) : (
            <Button
              disabled={saving}
              className="start-btn"
              onClick={handleMultiShift}
            >
              Shto Disa Turne!
            </Button>
          ),

          // <button className="start-btn" onClick={onSave}>
          // 	Shto Turnin
          // </button>,
        ]}
      >
        <div className="new-shift-wrapper" style={{ height: 400 }}>
          <div className="warning-title">
            <span className="warn-icon">
              <WarningIcon />
            </span>
            {clockInWarning ? (
              <span style={{ color: "#EA3943" }}>{clockInWarning}</span>
            ) : (
              <span>
                {" "}
                Ju jeni duke shtuar nje turn, siguroni te dhenat perpara se te
                ruani ndryshimet!
              </span>
            )}
            {/* {selectedEmployee?.employeeStatus === "Aktiv" ? (
							<span style={{ color: "#EA3943" }}>
								Ju jeni duke shtuar nje turn, siguroni te dhenat perpara se te ruani ndryshimet!
							</span>
						) : (
							<span style={{ color: "#EA3943" }}>
								Ju nuk mund te shtoni nje turn per nje punonjes te pezulluar!
							</span>
						)} */}
          </div>
          <div className="shortcuts">
            <span
              className="shortcut-btn"
              onClick={() => onShortcut("afternoon")}
            >
              Pasdite: 13:30 - 21:30
            </span>
            <span
              className="shortcut-btn"
              onClick={() => onShortcut("afternoon-start")}
            >
              Pasdite Fillim: 13:30 -{" "}
            </span>
            <span
              className="shortcut-btn"
              onClick={() => onShortcut("morning")}
            >
              Paradite: 10:00 - 18:30
            </span>
            <span
              className="shortcut-btn"
              onClick={() => onShortcut("morning-start")}
            >
              Paradite Fillim: 10:00 -{" "}
            </span>
          </div>
          <div className="to-edit-fields">
            {" "}
            <div className="shift-field-end">
              <label style={{ fontWeight: "600", marginLeft: "-35px" }}>
                <strong style={{ color: "red" }}>*</strong>Punonjësi:
              </label>

              <span
                // onClick={toggleApprove}
                style={{
                  // fontWeight: "600",
                  width: "140px",
                  color: "white",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  //   marginTop: "20px",
                  marginLeft: "-20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
              >
                {selfType ? (
                  <h3
                    style={{
                      color: "#1DA193",
                      font: "normal normal 600 15px/39px Open Sans",
                      marginLeft: "-50px",
                    }}
                  >
                    {selectedEmployee?.employeeFirstName +
                      " " +
                      selectedEmployee?.employeeLastName}
                  </h3>
                ) : (
                  <Select
                    showSearch
                    className="select-employee"
                    placeholder="Zgjidh punonjësin!"
                    optionFilterProp="children"
                    disabled={selfType ? true : false}
                    onChange={onSelectEmployee}
                    style={{ minWidth: 180, maxWidth: 180 }}
                  >
                    {employees
                      ?.filter((e) => e.employeeStatus === "Aktiv")
                      .map((el) => (
                        <Option value={el?.employeeId} key={el?.employeeId}>
                          {el?.employeeFirstName} {el?.employeeLastName}
                        </Option>
                      ))}
                  </Select>
                )}
              </span>
            </div>
            <div className="shift-field-start">
              <label style={{ fontWeight: "600" }}>
                <strong style={{ color: "red" }}>*</strong>Fillimi i Turnit:
              </label>
              <TimePicker
                value={newShift.clockInDate}
                placeholder="Fillimi"
                format="HH:mm"
                onChange={onEditClockIn}
                disabledHours={
                  newShift.clockOutDate !== null
                    ? () => getDisabledAfterCustomHours(newShift.clockOutDate)
                    : false
                }
                disabledMinutes={
                  newShift.clockOutDate !== null
                    ? (val) =>
                        getDisabledAfterCustomMinutes(
                          newShift.clockOutDate,
                          val
                        )
                    : false
                }
                showNow={false}
                allowClear={false}
              />
            </div>
            <div style={{ marginLeft: "20px" }} className="shift-field-end">
              <label style={{ fontWeight: "600" }}>Mbarimi i Turnit: </label>
              <TimePicker
                value={newShift.clockOutDate}
                placeholder="Mbarimi"
                format="HH:mm"
                disabledHours={
                  newShift.clockInDate !== null
                    ? () => getDisabledBeforeCustomHours(newShift.clockInDate)
                    : false
                }
                disabledMinutes={
                  newShift.clockInDate !== null
                    ? (val) =>
                        getDisabledBeforeCustomMinutes(
                          newShift.clockInDate,
                          val
                        )
                    : false
                }
                onChange={onEditClockOut}
                showNow={false}
                allowClear={false}
              />
            </div>
            <div style={{ marginLeft: "20px" }} className="shift-field-end">
              <label
                style={{ fontWeight: "600", marginLeft: "0px" }}
                onClick={() =>
                  setCalendarMode(
                    calendarMode === "single" ? "multiple" : "single"
                  )
                }
              >
                <strong style={{ color: "red" }}>*</strong>
                {calendarMode === "single"
                  ? "Data e Turnit:"
                  : "DATAT E TURNIT:"}
              </label>
              {calendarMode === "single" ? (
                <span
                  // onClick={toggleApprove}
                  style={{
                    // fontWeight: "600",
                    width: "140px",
                    color: "white",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    //   marginTop: "20px",
                    marginLeft: "0px",
                    cursor: "pointer",
                  }}
                >
                  <DatePicker
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    defaultValue={dateMoment}
                    //   value={dateMoment}
                    onChange={onCalendarPick}
                    dropdownClassName="calendar-date"
                    placeholder="Zgjidh Datën"
                    disabledDate={(current) => {
                      return moment() < current;
                    }}
                  />
                </span>
              ) : (
                <MultiDate
                  weekStartDayIndex={1}
                  format="MMMM DD YYYY"
                  sort
                  multiple
                  value={multiDays}
                  onChange={(e) => {
                    setMultiDays(e);
                  }}
                  plugins={[<DatePanel />]}
                />
              )}
            </div>
            <div className="shift-field-end" style={{ marginLeft: "20px" }}>
              <label style={{ fontWeight: "600" }}>
                <strong style={{ color: "red" }}>*</strong>Lloji i turnit:{" "}
              </label>

              <Select
                className="daytype-select"
                showSearch
                value={dayType}
                onChange={onClockinCateg}
                placeholder="Zgjidh nje opsion"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {programFields
                  ?.find((el) => el.fieldName === "ClockIn Types")
                  ?.fieldOptions?.map((el, idx) => (
                    <Option key={`shiftType-${idx}`} value={el?.name}>
                      {el?.description}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="shift-field-end">
              <span
                className="multiple-pick"
                onClick={() =>
                  setCalendarMode(
                    calendarMode === "single" ? "multiple" : "single"
                  )
                }
              >
                {calendarMode === "single" ? "Disa Turne?" : "Nje Turn?"}
              </span>
            </div>
          </div>
          <div className="to-note">
            <label style={{ fontWeight: "600" }}>
              {/* <strong style={{ color: "red" }}>*</strong> */}
              Shenimi:
            </label>
            {/* <TextArea
              value={newShift.note}
              autoSize={{ minRows: 4, maxRows: 4 }}
              onChange={(e) =>
                setNewShift((prev) => ({ ...prev, note: e.target.value }))
              }
              className="to-note-textarea"
            /> */}
            <RichTextEditor
              className={"to-note-textarea"}
              value={newShift.note}
              onChange={(e) => {
                setNewShift((prev) => ({ ...prev, note: e }));
                // console.log(e);
              }}
            />
          </div>
          <div className="hours-generation">
            <span style={{ fontWeight: "600" }}>
              Oret totale:{" "}
              {moment(newShift.clockOutDate)
                .diff(moment(newShift.clockInDate), "hours", true)
                .toFixed(2) > 0
                ? moment(newShift.clockOutDate)
                    .diff(moment(newShift.clockInDate), "hours", true)
                    .toFixed(2)
                : 0}{" "}
              ore
            </span>
            <div className="animated-hours">
              <div
                className="calculated-hours"
                style={{
                  width: `${
                    moment(newShift.clockOutDate)
                      .diff(moment(newShift.clockInDate), "hours", true)
                      .toFixed(2) > 0 &&
                    moment(newShift.clockOutDate)
                      .diff(moment(newShift.clockInDate), "hours", true)
                      .toFixed(2) <= 8
                      ? (moment(newShift.clockOutDate)
                          .diff(moment(newShift.clockInDate), "hours", true)
                          .toFixed(2) /
                          8) *
                        100
                      : moment(newShift.clockOutDate)
                          .diff(moment(newShift.clockInDate), "hours", true)
                          .toFixed(2) > 8
                      ? 100
                      : 0
                  }%`,
                }}
              ></div>
            </div>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              {saving && <BreedingRhombusSpinner size={40} color="#1DA193" />}
            </div>
          </div>{" "}
        </div>
        <CrashShift
          type={"new"}
          crashData={crashData}
          visible={crashError}
          setCrashError={setCrashError}
        />
        <SuccessComponent
          visibility={successVisibility}
          setSuccessVisibility={setSuccessVisibility}
          onOk={handleReset}
          text="Turni u shtua me sukses!"
        />
      </Modal>
    </>
  );
}

export default NewShiftModal;
