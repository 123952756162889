import React from "react";
import PayStubTable from "./PayStubTable";

function Timesheet({ timesheetTable }) {
  return (
    <>
      {Object.keys(timesheetTable).length !== 0 && (
        <div className="timesheet-table">
          <br />
          <span style={{ display: "flex", justifyContent: "center" }}>
            {!!timesheetTable?.rowData && <strong>TABELA E ORARIT</strong>}
          </span>
          <br />
          <PayStubTable
            columnsDef={timesheetTable?.columnsDef || []}
            rowData={timesheetTable?.rowData}
          />
        </div>
      )}
    </>
  );
}

export default Timesheet;
