import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, Row, Col, Checkbox, Menu, Alert } from "antd";
import { PaymentInfo } from "./PaymentInfo";
import { EditIcon } from "../../../../assets/icons";
import "./Payment.scss";
import NameCard from "../components/NameCard";
import { paymentData } from "./paymentData";
import PaymentGrid from "./PaymentGrid";
import { API } from "aws-amplify";
import LoaderComponent from "../../../commonComponents/LoadableComp/LoadableComp";
import moment from "moment-timezone";
import {
  BluredCard,
  ComponentCard,
} from "../../../dashboard/AdminDashboard/Draggable/ComponentCard";
import PunonjesitContext from "../../store/PunonjesitContext";
export const EmployeePayment = () => {
  const [showPaymentInfo, setShowPaymentInfo] = useState(true);
  const [changes, setChanges] = useState([]);
  const [shifts, setShifts] = useState(null);

  const { employee } = useContext(PunonjesitContext);

  const displayShifts = useMemo(() => {
    if (!!shifts && !!employee) {
      let temp = [];
      let empShifts = employee?.employeeShiftId || [];
      if (empShifts.length > 0) {
        empShifts?.map((shift) => {
          let name = shifts.find((el) => el?.shiftId === shift);
          if (!!name) {
            temp.push(name);
          }
        });
        if (temp.length > 0) {
          return (
            <div className="style-width-input" style={{ width: "200%" }}>
              {" "}
              {temp?.map((t, i) => (
                <>
                  {i === temp.length - 1 ? (
                    <span>{t?.shiftName}</span>
                  ) : (
                    <span>{t?.shiftName + ", "}</span>
                  )}
                </>
              ))}
            </div>
          );
        } else if (temp.length === 0) {
          return (
            <strong style={{ color: "rgb(234, 57, 67)" }}>
              Nuk ka te dhena
            </strong>
          );
        }
      }
      return (
        <strong style={{ color: "rgb(234, 57, 67)" }}>Nuk ka te dhena</strong>
      );
    }
  }, [shifts, employee]);
  const handleClick = () => {
    setShowPaymentInfo(false);
  };
  const handleHidePayment = () => {
    setShowPaymentInfo(true);
  };
  const getShifts = async () => {
    API.get("shifts", "/shifts").then((res) => setShifts(res));
  };

  // get the data when the page renders
  useEffect(() => {
    getShifts();
  }, []);

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  });

  /**
   * * @EneaXharau - Fixed loading visuals and made styling dynamic to not affect other components
   */
  return (
    <div style={!!shifts ? {} : { height: "100vh" }}>
      <LoaderComponent loading={!!shifts ? false : true}>
        <div className="pay">
          {showPaymentInfo === true ? (
            <Card
              className="pay-info-card"
              title="Informacioni i pagesës"
              extra={
                <div className="icon-button">
                  <button id="edit" onClick={handleClick}>
                    <EditIcon className="pen-icon" />
                  </button>
                </div>
              }
            >
              <ComponentCard
                blurDuration={30}
                height={712}
                width={533}
                blur={true}
                content={
                  <>
                    <div className="styleHead">
                      {paymentData?.typeOfPayment?.map(
                        ({
                          icon,
                          amountBrut,
                          amountNet,
                          showErrorTextBruto,
                          showErrorTextNeto,
                          type,
                        }) => {
                          return (
                            <div className="containersContent">
                              <span
                                style={{
                                  marginTop: "14px",
                                }}
                              >
                                {icon}
                              </span>
                              <div>
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    font: "normal normal 800 16px/22px Open Sans",
                                  }}
                                >
                                  {!!employee?.employeeGrossMonthlySalary &&
                                    (amountBrut !== 112
                                      ? null
                                      : "ALL" +
                                        " " +
                                        currencyFormatter.format(
                                          employee?.employeeGrossMonthlySalary
                                        ))}
                                  <p
                                    style={{
                                      color: "rgb(234, 57, 67)",
                                      position: "relative",
                                      bottom: "5px",
                                    }}
                                  >
                                    {!employee?.employeeGrossMonthlySalary
                                      ? showErrorTextBruto
                                      : null}
                                  </p>
                                </span>
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    font: "normal normal 800 16px/22px Open Sans",
                                    position: "relative",
                                    bottom: "9px",
                                  }}
                                >
                                  {!!employee?.employeeMonthlyNetSalary &&
                                    (amountNet !== 142
                                      ? null
                                      : "ALL" +
                                        " " +
                                        currencyFormatter.format(
                                          employee?.employeeMonthlyNetSalary
                                        ))}
                                  <p
                                    style={{
                                      color: "rgb(234, 57, 67)",
                                      position: "relative",
                                      bottom: "5px",
                                    }}
                                  >
                                    {!employee?.employeeMonthlyNetSalary
                                      ? showErrorTextNeto
                                      : null}
                                  </p>
                                </span>
                              </div>
                              <div>
                                <span
                                  style={
                                    type === "Shuma Bruto"
                                      ? {
                                          fontSize: "18px",
                                          fontWeight: "600",
                                          font: "normal normal 600 14px/22px Open Sans",
                                          position: "relative",
                                          bottom: "26px",
                                        }
                                      : {
                                          fontSize: "18px",
                                          fontWeight: "600",
                                          font: "normal normal 600 14px/22px Open Sans",
                                          position: "relative",
                                          bottom: "35px",
                                        }
                                  }
                                >
                                  {type}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="secondStyle">
                      {paymentData?.payroll?.map(
                        ({
                          icon,
                          amountSalaryPerHour,
                          type,
                          typeWage,
                          showErrorTextPagesaPerOre,
                          showErrorTextLlojiPages,
                        }) => {
                          return (
                            <div className="containersContent">
                              <span
                                style={{
                                  marginTop: "14px",
                                }}
                              >
                                {icon}
                              </span>
                              <div>
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    font: "normal normal 800 16px/22px Open Sans",
                                    position: "relative",
                                    top: "3px",
                                  }}
                                >
                                  {!!employee?.employeeSalaryPerHour &&
                                    (amountSalaryPerHour !== 5
                                      ? null
                                      : "ALL" +
                                        " " +
                                        currencyFormatter.format(
                                          employee?.employeeSalaryPerHour
                                        ))}
                                  <p
                                    style={{
                                      color: "rgb(234, 57, 67)",
                                      position: "relative",
                                      bottom: "5px",
                                    }}
                                  >
                                    {!employee?.employeeSalaryPerHour
                                      ? showErrorTextPagesaPerOre
                                      : null}
                                  </p>
                                </span>
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    font: "normal normal 800 16px/22px Open Sans",
                                    position: "relative",
                                    bottom: "7px",
                                  }}
                                >
                                  {!!employee?.employeePayrollType &&
                                  typeWage !== null
                                    ? null
                                    : employee?.employeePayrollType}
                                  <p
                                    style={{
                                      color: "rgb(234, 57, 67)",
                                      position: "relative",
                                      bottom: "5px",
                                    }}
                                  >
                                    {!employee?.employeePayrollType
                                      ? showErrorTextLlojiPages
                                      : null}
                                  </p>
                                </span>
                              </div>
                              <span
                                style={
                                  type === "Pagesa per ore"
                                    ? {
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        font: "normal normal 600 14px/22px Open Sans",
                                        position: "relative",
                                        bottom: "24px",
                                      }
                                    : {
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        font: "normal normal 600 14px/22px Open Sans",
                                        position: "relative",
                                        bottom: "32px",
                                      }
                                }
                              >
                                {type}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="thirdStyle">
                      {paymentData?.hoursRate?.map(
                        ({
                          icon,
                          typeRate,
                          type,
                          shiftType,
                          showErrorTextFrekeuncaPages,
                        }) => {
                          return (
                            <div className="containersContent">
                              <span
                                style={{
                                  marginTop: "14px",
                                }}
                              >
                                {icon}
                              </span>
                              <div>
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    font: "normal normal 800 16px/22px Open Sans",
                                  }}
                                >
                                  {!!employee?.employeePayrollFrequency &&
                                  typeRate !== "typeRate"
                                    ? null
                                    : employee?.employeePayrollFrequency}
                                  <p
                                    style={{
                                      color: "rgb(234, 57, 67)",
                                    }}
                                  >
                                    {!employee?.employeePayrollFrequency
                                      ? showErrorTextFrekeuncaPages
                                      : null}
                                  </p>
                                </span>

                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    font: "normal normal 800 16px/22px Open Sans",
                                    position: "relative",
                                    bottom: "15px",
                                  }}
                                >
                                  {shiftType !== "shiftType"
                                    ? null
                                    : displayShifts}
                                </span>
                              </div>
                              <span
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  font: "normal normal 600 14px/22px Open Sans",
                                  position: "relative",
                                  bottom: "25px",
                                }}
                              >
                                {type}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </>
                }
              />
            </Card>
          ) : (
            <PaymentInfo
              {...{
                handleHidePayment,
                changes,
                setChanges,
                shifts,
              }}
            />
          )}
          <PaymentGrid employee={employee} />
        </div>
      </LoaderComponent>
    </div>
  );
};
