import { Tooltip } from "antd";
import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { IconPdf } from "../../../assets/icons";
import { FilePdfFilled } from "@ant-design/icons";

function ExportToPdf({ id, tabelName }) {
  //export to Pdf function give by props the id of Html tabel and the fille name
  const exportPdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: id });
    doc.save(`${tabelName}.pdf`);
  };

  return (
    <div className="icona-pdf-grid" style={{ zIndex: 10 }}>
      <Tooltip
        placement="top"
        overlayClassName="global-icon-tooltip"
        title={"Eksporto në pdf"}
        style={{ zIndex: 10 }}
      >
        <FilePdfFilled
          onClick={() => exportPdf()}
          width={22}
          style={{ zIndex: 10 }}
        />
        {/* <IconPdf width={22} height={22} onClick={() => exportPdf()} /> */}
      </Tooltip>
    </div>
  );
}

export default ExportToPdf;
