import React, { useState, useEffect } from "react";
import SpinningRacket from "../SpinningRacket/SpinningRacket";
import { Modal } from "antd";
import "./SuccessComponent.scss";
function SuccessComponent({
  visibility,
  onOk,
  onCancel,
  setSuccessVisibility,
  text,
}) {
  const [visible, setVisible] = useState(false);
  const handleSave = () => {
    onOk();
    setSuccessVisibility(false);
  };
  useEffect(() => {
    setVisible(visibility);
  }, [visibility]);
  return (
    <div>
      <Modal
        className="success-modal"
        maskClosable={true}
        centered={true}
        visible={visible}
        onCancel={() => setVisible(false)}
        destroyOnClose={true}
      >
        <SpinningRacket />
        <div className="success-modal-text">
          <span>{text}</span>
        </div>
        <button className="okay-btn" onClick={handleSave}>
          Ne rregull
        </button>
      </Modal>
    </div>
  );
}
export default SuccessComponent;
