import Card from "../../../components/Card";
import ChartSummary from "../../../components/charts/Chart";
import DashboardChart from "../../../components/charts/DashboardChart";
import ClockIn from "../../../components/clockInOut/ClockIn";
import HolidayWidget from "../../../components/holidayWidget/HolidayWidget";
import RequestForm from "../../../components/requestPermission/RequestForm";
import UserRequest from "../../../components/requestPermission/UserRequest";
import WeeklyMonthly from "../../../components/summary/WeeklyMonthly";
import MoreInformationCard from "../../../UserDashboard/MoreInformationCard";
import UserWageHistory from "../../../UserDashboard/UserWageHistory";
import WorkBeforeShift from "../../../UserDashboard/OvertimeShift/WorkBeforeShift";
import ComponentCard from "../ComponentCard";
import WeatherCard from "../../../components/weatherCard/WeatherCard";
import EmployeOfMonth from "../../../components/EmployeOfMonth/EmployeOfMonth";
import EmployeOfWeek from "../../../components/EmployeOfMonth/EmployeOfWeek";
import Countdown from "../../../components/Countdown/Countdown";
import ProblemsCard from "../../AdminCards/Problems/ProblemsCard";

const defaultData = {
  x: 0,
  y: 0,
  h: 1,
  w: 1,
  minW: 1,
  minH: 1,
  static: false,
};

export const userDashboardCards = [
  // {
  // 	id: 11,
  // 	name: "Countdown",
  // 	emri: "Countdown",
  // 	src: ComponentCard,
  // 	content: Countdown,
  // 	parent: true,
  // 	data: {},
  // 	hidden: false,
  // 	blur: false,
  // },
  {
    i: "0",
    data: {
      name: "ClockIn",
      emri: "Veprime Turni",
      src: ComponentCard,
      content: ClockIn,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
  },
  {
    i: "1",
    data: {
      name: "WeeklyMonthly",
      emri: "Orët Javore/Mujore",
      src: ComponentCard,
      content: WeeklyMonthly,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 1,
    y: 0,
  },
  {
    i: "3",
    data: {
      name: "RequestCard",
      emri: "Kërkesë Leje",
      src: ComponentCard,
      content: UserRequest,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 2,
    y: 0,
  },
  {
    i: "4",
    data: {
      name: "Chart",
      emri: "Grafikë",
      src: ComponentCard,
      content: DashboardChart,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 0,
    y: 1,
  },
  {
    i: "5",
    data: {
      name: "moreInformations",
      emri: "Pushimi i ditës",
      src: ComponentCard,
      content: MoreInformationCard,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 1,
    y: 1,
  },
  {
    i: "6",
    data: {
      name: "workBeforeShift",
      emri: "Kërkesë për orë shtesë",
      src: ComponentCard,
      content: WorkBeforeShift,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 2,
    y: 1,
  },
  {
    i: "7",
    data: {
      name: "Holidays",
      emri: "Festat",
      src: ComponentCard,
      content: HolidayWidget,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 0,
    y: 2,
  },
  {
    i: "8",
    data: {
      name: "Moti",
      emri: "Moti",
      src: ComponentCard,
      content: WeatherCard,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 2,
    y: 2,
  },
  // {
  // 	id: 9,
  // 	name: "Punonjësi i Muajit",
  // 	emri: "Punonjësi i Muajit",
  // 	src: ComponentCard,
  // 	content: EmployeOfMonth,
  // 	parent: true,
  // 	data: {},
  // 	hidden: true,
  //
  // 	blur: false,
  // },
  // {
  // 	id: 10,
  // 	name: "Punonjësi i Javës",
  // 	emri: "Punonjësi i Javës",
  // 	src: ComponentCard,
  // 	content: EmployeOfWeek,
  // 	parent: true,
  // 	data: {},
  // 	hidden: true,
  // 	blur: false,
  // },
  {
    i: "29",
    data: {
      name: "Problematikat",
      emri: `Problematikat`,
      src: ComponentCard,
      content: ProblemsCard,
      parent: false,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    minH: 2,
    h: 2,

    x: 0,
    y: 3,
  },

  // {
  // 	id: 5,
  // 	name: "wageHistory",
  // 	emri: "Statistika Pagat",
  // 	src: UserWageHistory,
  // 	content: "",
  // 	parent: false,
  // 	data: {},
  // 	hidden: false,
  // 	blur: false,
  // },

  // {
  // 	id: 5,
  // 	name: "wageHistory",
  // 	emri: "Statistika Pagat",
  // 	src: UserWageHistory,
  // 	content: "",
  // 	parent: false,
  // 	data: {},
  // 	hidden: false,
  // 	blur: false,
  // },
];

export const getUserSaved = () => {
  const ls = JSON.parse(localStorage.getItem("draggableOrderUser"));
  if (ls !== null) {
    let temporary = [];
    if (ls.length === userDashboardCards.length) {
      ls.forEach((el, idx) => {
        let found = userDashboardCards.findIndex((l, x) => l.i === el.i);
        if (found !== -1) {
          temporary.push({
            ...userDashboardCards[found],
            data: {
              ...userDashboardCards[found].data,
              hidden: el?.data?.hidden,
              blur: el?.data?.blur,
            },
            x: ls[found].x,
            y: ls[found].y,
            h: ls[found].h,
            w: ls[found].w,
            minW: ls[found].minW,
            minH: ls[found].minH,
          });
        }
      });
      return temporary;
    } else {
      localStorage.removeItem("draggableOrderUser");
      localStorage.setItem(
        "draggableOrderUser",
        JSON.stringify(userDashboardCards)
      );
      return userDashboardCards;
    } // return userDashboardCards;
  } else if (ls === null) {
    localStorage.setItem(
      "draggableOrderUser",
      JSON.stringify(userDashboardCards)
    );
    return userDashboardCards;
  }
};
