import React, { useState, useEffect } from "react";
import { Modal, Row } from "antd";
import moment from "moment-timezone";
import "./InnerModal.scss";
import { IconaMbyll } from "../../../../../assets/icons";
import { htmlParser } from "../../../../../utils";

const InnerModal = ({
  isModalVisible,
  setIsModalVisible,
  editedShift,
  clockins,
}) => {
  const [shift, setShift] = useState(null);
  const [hasChange, setHasChange] = useState(false);
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    // console.log("edited shift", editedShift);
    if (editedShift !== null && !!clockins) {
      let t = clockins.filter(
        (el) => el.clockingId === editedShift[0]?.clockingId
      );
      let temp = false;
      if (editedShift[1] === "clockIn") {
        setHasChange(editedShift[0]?.editedClockIn || false);
      } else {
        setHasChange(editedShift[0]?.editedClockOut || false);
      }
      // console.log(t);
      setShift(t);
    }
  }, [editedShift, clockins]);

  console.log("edited shift", editedShift);

  return (
    <>
      {shift !== null ? (
        <Modal
          className="inner-modal"
          title={`${
            editedShift[1] === "clockIn"
              ? "Fillimi i turnit"
              : "Mbarimi i turnit"
          } ${moment(editedShift[0]?.createdAt).format("DD/MM/YYYY")}`}
          onOk={handleOk}
          // centered={true}
          onCancel={handleCancel}
          open={isModalVisible}
          footer={null}
          closeIcon={
            <span style={{ fontSize: "17px", width: "17px", height: "17px" }}>
              <IconaMbyll />
            </span>
          }
        >
          <>
            {hasChange && (
              <>
                {" "}
                <Row className="employee-name">
                  {editedShift[0]?.editedByAdmin
                    ? "Admini/Supervisor"
                    : "Punonjesi"}{" "}
                  ka ndryshuar orarin e
                  {editedShift[1] === "clockIn"
                    ? " fillimit te turnit nga"
                    : " mbarimit te turnit nga"}
                </Row>
                <Row className="shift-time">
                  {editedShift[1] === "clockIn" ? (
                    <>
                      {" "}
                      <span id="red">
                        {moment(editedShift[0]?.createdAt).format("HH:mm")}
                      </span>{" "}
                      {"-"}
                      <span id="green">
                        {" "}
                        {moment(editedShift[0]?.clockInDate).format("HH:mm")}
                      </span>
                    </>
                  ) : (
                    <>
                      {" "}
                      <span id="red">
                        {moment(editedShift[0]?.finishedAt).format("HH:mm")}
                      </span>{" "}
                      {"-"}
                      <span id="green">
                        {" "}
                        {moment(editedShift[0]?.clockOutDate).format("HH:mm")}
                      </span>
                    </>
                  )}
                </Row>
              </>
            )}
            <div className="note-container">
              <Row className="note-title">Shënim </Row>
              <Row className="note">
                {editedShift[1] === "clockOut"
                  ? htmlParser(shift[0]?.clockOutNote)
                  : htmlParser(shift[0]?.clockInNote)}
              </Row>
            </div>
          </>

          {/* </Row> */}
        </Modal>
      ) : null}
    </>
  );
};

export default InnerModal;
