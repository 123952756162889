export const apiRoutes = {
  deductions: "deductions",
  earnings: "earnings",
  notifications: "notifications",
  notes: "notes",
  programFields: "programFields",
  documentation: "documentation",
  filterTables: "filterTables",
  docConfiguration: "docConfiguration",
  userGroups: "userGroups",
  users: "users",
  clocking: "clocking",
  shifts: "shifts",
  departments: "departments",
  jobs: "jobs",
  jobHistory: "jobHistory",
  employees: "employees",
  interviews: "interviews",
};
