import React, { useEffect, useState } from "react";
import "./User.scss";
import moment from "moment-timezone";
import {
  AccessRoleIcon,
  CalendarIcon,
  DepartmentIcon,
  DesignerIcon,
  EmailIcon,
  FirstNameIcon,
  LastNameIcon,
  PhoneIcon,
} from "./assets/icons";
import SessionTable from "./SessionTable";
import {
  disableUser,
  enableUser,
  forceResetUser,
  forceResetUserDisable,
} from "./helpers/apiHelper";
import { API } from "aws-amplify";
import { Button, message, Popconfirm } from "antd";
// import { osName, osVersion, browserName, browserVersion, deviceType } from "react-device-detect";
import { updateArrayElement } from "../../../../../helpers/utils/updateArrayElement";
function LoadedUser({ renderUser }) {
  const [user, setUser] = useState(renderUser);
  const handleDisable = async () => {
    await disableUser(renderUser?.identityId).then(() => {
      setUser((prev) => ({ ...prev, disabled: true }));
    });
  };
  const handleEnable = async () => {
    await enableUser(renderUser?.identityId).then(() => {
      setUser((prev) => ({ ...prev, disabled: false }));
    });
  };

  const handleReset = async () => {
    await forceResetUser(renderUser?.identityId).then(() => {
      setUser((prev) => ({ ...prev, forceReset: true }));
    });
  };

  const handleResetDisable = async () => {
    await forceResetUserDisable(renderUser?.identityId).then(() => {
      setUser((prev) => ({ ...prev, forceReset: false }));
    });
  };

  const handleDelete = async () => {
    await disableUser(renderUser?.identityId).then(() => {
      setUser((prev) => ({ ...prev, disabled: true }));
    });
  };

  const onLogOff = async (sessionId) => {
    if (!!sessionId) {
      const updatedActiveSessions = updateArrayElement(
        user?.activeSessions,
        { ...user?.activeSessions, sessionId: sessionId, logout: true },
        "sessionId"
      );
      await API.put("users", `/users/${renderUser?.identityId}`, {
        body: { activeSessions: updatedActiveSessions },
      }).then((res) => {
        message.success("Veprimi u ruajt me sukses!");
        setUser((prev) => ({ ...prev, activeSessions: updatedActiveSessions }));
      });
    }
  };
  const onAllSessionsEnd = async (id) => {
    let sessions = user?.activeSessions;
    if (Array.isArray(sessions)) {
      if (sessions.length > 0) {
        const updatedActiveSessions = sessions?.map((session) => ({
          ...session,
          logout: true,
        }));
        await API.put("users", `/users/${id}`, {
          body: { activeSessions: updatedActiveSessions },
        }).then((res) => {
          message.success("Veprimi u ruajt me sukses!");
          setUser((prev) => ({
            ...prev,
            activeSessions: updatedActiveSessions,
          }));
        });
      }
    } else {
      message.error("Ndodhi nje gabim!");
    }
  };

  const onDeleteAllSessions = async () => {
    await API.put("users", `/users/${user?.identityId}`, {
      body: {
        activeSessions: [],
      },
    }).then(() => {
      message.success("Sesionet u fshine me sukses!");
      setUser((prev) => ({ ...prev, activeSessions: [] }));
    });
  };

  useEffect(() => {
    setUser(renderUser);
  }, [renderUser]);

  return (
    <div className="container-user-employee">
      <div className="loaded-user-list">
        <div className="loaded-user-first-row">
          <span>
            <FirstNameIcon />
            Emri: {user?.given_name}
          </span>
          <span>
            {" "}
            <LastNameIcon /> Mbiemri: {user?.family_name}
          </span>
          <span>
            {" "}
            <EmailIcon /> Email: {user?.email}
          </span>{" "}
          <span>
            {" "}
            <PhoneIcon /> Nr i tel: {user?.phone_number}
          </span>
        </div>
        <div className="loaded-user-first-row">
          <span>
            {" "}
            <DepartmentIcon /> Departamenti: {user?.department}
          </span>
          <span>
            {" "}
            <DesignerIcon /> Roli: {user?.departmentRole}
          </span>
          <span>
            {" "}
            <AccessRoleIcon /> Roli Aksesues: {user?.userRole}
          </span>{" "}
          <span>
            {" "}
            <CalendarIcon /> Krijuar:{" "}
            {moment(user?.createdAt).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
      </div>
      <div className="action-btns">
        <Popconfirm
          title="A jeni te sigurt?"
          okText="Po."
          cancelText="Jo, anullo!"
          disabled={user?.activeSessions?.length > 0 ? false : true}
          onConfirm={() => onAllSessionsEnd(user?.identityId)}>
          <Button
            // disabled={user?.activeSessions?.length > 0 ? false : true}
            className="dil-btn"
            // onClick={() => onAllSessionsEnd(user?.identityId)}
          >
            Largo
          </Button>
        </Popconfirm>
        {!user?.forceReset ? (
          <Popconfirm
            title="A jeni te sigurt?"
            okText="Po."
            cancelText="Jo, anullo!"
            onConfirm={handleReset}>
            <span className="change-password-btn">Kerko Fjalekalim te ri</span>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="A jeni te sigurt?"
            okText="Po."
            cancelText="Jo, anullo!"
            onConfirm={handleResetDisable}>
            <span
              className="change-password-btn"
              style={{ background: "#1DA193" }}
              // onClick={handleResetDisable}
            >
              Hiq Kerko Fjalekalim te ri
            </span>
          </Popconfirm>
        )}
        {!user?.disabled ? (
          <Popconfirm
            title="A jeni te sigurt?"
            okText="Po."
            cancelText="Jo, anullo!"
            onConfirm={handleDisable}>
            <span className="disable-btn">Pezullo</span>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="A jeni te sigurt?"
            okText="Po."
            cancelText="Jo, anullo!"
            onConfirm={handleEnable}>
            <span
              className="disable-btn"
              style={{ backgroundColor: "#7AC14D" }}
              // onClick={handleEnable}
            >
              Aktivizo
            </span>
          </Popconfirm>
        )}
        <button
          disabled
          className="delete-btn2"
          onClick={handleDelete}
          style={{ cursor: "not-allowed" }}>
          Fshij perdoruesin
        </button>{" "}
        <Popconfirm
          title="A jeni te sigurt?"
          okText="Po."
          cancelText="Jo, anullo!"
          onConfirm={onDeleteAllSessions}>
          <span className="delete-btn">Fshij sesionet</span>
        </Popconfirm>
      </div>
      <div className="table-sessions">
        <div className="table-sessions-header">
          <SessionTable
            sessions={user?.activeSessions}
            id={renderUser?.identityId}
            onLogOff={onLogOff}
          />
        </div>
      </div>
    </div>
  );
}

export default LoadedUser;
