export const createUserDriveFolders = async ({
  hotCredentials,
  employee,
  driveRequest,
  defaultPermissions,
}) => {
  let googleDriveFolderIds = {};

  const parentFolderValue = hotCredentials?.find(
    (el) => el?.credentialId === "9"
  )?.credentialValue;
  const folderName = `${employee.employeeFirstName} ${employee.employeeLastName}
     - ${Date.now()}`;

  await driveRequest
    .getFolderIdOrCreate({
      name: folderName,
      parent: parentFolderValue,
      defaultPermissions,
    })
    .then(async (userFolderId) => {
      googleDriveFolderIds = {
        ...googleDriveFolderIds,
        userFolderId,
      };
      await driveRequest
        .getFolderIdOrCreate({
          name: "Dokumentacione",
          parent: userFolderId,
          defaultPermissions,
        })
        .then((docFolderId) => {
          googleDriveFolderIds = {
            ...googleDriveFolderIds,
            docFolderId,
          };
        })
        .catch((err) => console.log("err: ", err));
    })
    .catch((err) => console.log("err: ", err));

  return googleDriveFolderIds;
};
