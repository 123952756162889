import React, { useMemo } from "react";
import PayStubTable from "../../PaySub/payStubTable/PayStubTable";
import { prepareMultis } from "./prepare";
import "./MultiGenerate.scss";
import { Alert } from "antd";
function MultiGenerateTable({ savedPaySttubs, componentRef, toGenerate }) {
	const multiTableData = useMemo(() => {
		if (!!savedPaySttubs) {
			return prepareMultis(savedPaySttubs, toGenerate);
		}
	}, [savedPaySttubs, toGenerate]);
	console.log("multiTableData", { savedPaySttubs, toGenerate });

	return (
		<div className="multi-generate-table" ref={componentRef}>
			{" "}
			{savedPaySttubs.length > 0 ? (
				<PayStubTable columnsDef={multiTableData?.columnsDef} rowData={multiTableData?.rowData} />
			) : (
				<>
					<Alert
						showIcon
						type="warning"
						message="Nuk ka te dhena per punonjesit dhe periudhen e zgjedhur. Kthehuni mbrapa dhe ndryshoni opsionet e paraqitura!"
					/>
				</>
			)}
		</div>
	);
}

export default MultiGenerateTable;
