export const columnDefs = [
  {
    headerName: "Zgjidh",
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    headerName: "Punonjesi",
    field: "employeeFirstName",
    cellRendererFramework: (params) => {
      const { employeeFirstName = "", employeeLastName = "" } =
        params?.data || {};
      return (
        <>
          {employeeFirstName} {employeeLastName}
        </>
      );
    },
  },
  {
    headerName: "Roli",
    field: "employeeRoleName",
  },
  {
    headerName: "Skuadra",
    field: "employeeTeam",
  },
];
