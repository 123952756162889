import { createContext, useState, useContext, useEffect } from "react";
import { Layout, message } from "antd";
import Hader from "../components/header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { API, Auth } from "aws-amplify";
import LoaderComponent from "../components/commonComponents/LoadableComp/LoadableComp";
import PageNotFound from "../components/commonComponents/PageNotFound";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "../components/Auth/Login";
// import { Auth } from "aws-amplify";
import KonfigurimetMenu from "../components/Konfigurimet/KonfigurimetMenu/KonfigurimetMenu";
import AutumnLeaves from "../components/commonComponents/LoadableComp/Autumn/AutumnLeaves";
export const AuthContext = createContext(null);
const { Header, Sider, Content, Footer } = Layout;
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userAccess, setUserAccess] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const initializeAccess = async () => {
    await API.get("users", "/users")
      .then((res) => {
        setUserAccess([res]);
        setUserRole(res?.userRole);
      })
      .catch((err) => console.log("Something went wrong || users", err));
  };
  const initializeEmployee = async (userSub) => {
    await API.get("employees", "/employees").then((res) => {
      let empl = res.find((el) => el.userSub === userSub);
      if (!!empl && !!userSub) {
        setEmployeeId(empl?.employeeId || null);
        setEmployee(empl || null);
      } else {
        setEmployeeId(null);
        setEmployee(null);
      }
    });
  };

  const signin = async (newUser, callback) => {
    setUser(newUser);
    await initializeEmployee(newUser?.attributes?.sub);
    await initializeAccess(newUser?.attributes?.sub);
    callback();
  };
  const signout = (callback) => {
    setUser(null);
    setUserAccess([]);
    setEmployeeId(null);
    callback();
  };
  // useEffect(() => {
  //   if (user !== null && user.attributes.sub !== null) {
  //     initializeAccess(user?.attributes?.sub);
  //   }
  // }, [user]);

  const value = {
    user,
    userAccess,
    employeeId,
    signin,
    signout,
    userRole,
    employee,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
  return useContext(AuthContext);
};
/**
 * @returns instance of auth status
 */
export const AuthStatus = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  useEffect(() => {
    if (auth.userAccess.length > 0) {
      setData(auth.userAccess);
      setLoading(false);
    }
  }, [auth]);

  const specifikatRoute = {
    auth: true,
    exact: false,
    path: "punonjesit/specifikat/:employeeId",
    title: "Specifikat Punonjesit",
    write: true,
  };

  if (loading === false && data.length > 0) {
    //&& location.pathname.startsWith("/konfigurimet") === false
    //&& auth.userAccess[0].userRole !== "Admin"
    if (location.pathname !== null) {
      let filter =
        data[0].accessConfig?.routeConfig.concat([specifikatRoute])?.filter((el) => {
          return (
            (el.path === location.pathname || el.path.includes("punonjesit/specifikat/")) &&
            el?.auth !== false
          );
        }) || [];
      if (filter.length === 0) {
        return <PageNotFound />;
      }
    }
  }
  if (!auth.user) {
    return <Login />;
  }

  return (
    <LoaderComponent loading={loading}>
      {/* <AutumnLeaves /> */}

      <div>
        <Layout>
          <Sider
            style={{ position: "sticky", zIndex: 1, height: "100%" }}
            width="270px"
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            breakpoint={"xl"}
          >
            {location.pathname.startsWith("/konfigurimet") === true ? (
              <KonfigurimetMenu data={data} />
            ) : (
              <Sidebar data={data} collapsed={collapsed} />
            )}
          </Sider>
          <Layout>
            <Header style={{ position: "sticky", zIndex: 1, width: "100%" }}>
              <Hader data={data} />
            </Header>
            <Content style={{ overflow: "auto", width: "100%" }}>{children}</Content>
          </Layout>
        </Layout>
      </div>
    </LoaderComponent>
  );
};
