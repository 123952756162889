import React, { useState } from "react";
import "../../../dashboard/components/Card.scss";
import "./AdminCard.scss";
export const GeneralCard = ({ icon, number, text, onClick }) => {
  const classNames = [
    "total-small-card",
    text === "Punonjës me mungesë të planifikuar" && "abstance-small-card",
    text === "Punonjës me kohë të plotë" && "fulltime-small-card",
    text === "Punonjës me kohë të pjesshme" && "parttime-small-card",
  ]
    .filter((e) => e)
    .join(" ");

  return (
    <div
      className={classNames}
      style={{ width: "100%", height: "calc(100%)", cursor: 'pointer' }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className="div-content">
        {/* <div className="icon-div">Icon</div> */}
        <div className="icon-cont">
          {icon}
          <div className="number">{number}</div>
          <div className="text-div">{text}</div>
        </div>
      </div>
    </div>
  );
};
