import { Button, Modal, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import QuillEditor from "./Components/QuillEditor";
import { InfoIcon, DownloadIcon } from "./Image";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import QuillRenderer from "./Components/QuillRenderer";
import { InterviewsContext } from "../../data";
import { fileIcons } from "../../../Documentation/View/documentationViewData";
import { useDispatch, useSelector } from "react-redux";
import { candidates as candidatesReducer } from "../../../../store/actions";
import { ModalXIcon } from "../../../../assets/icons";

const InformacioneShtese = ({ uploadedFiles }) => {
  const { candidates } = useSelector((state) => state.candidates);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [applicantNotes, setApplicantNotes] = useState();

  const {
    kandidatiSelektuar,
    setKandidatiSelektuar,
    defaultSwalProperties,

    setIntervistat,
    intervistat,
  } = useContext(InterviewsContext);

  useEffect(() => {
    setApplicantNotes(
      kandidatiSelektuar.applicantNotes && kandidatiSelektuar.applicantNotes
    );
  }, [kandidatiSelektuar]);

  function updateCandidatet(data, newCandidad) {
    return data?.map((el) =>
      el?.applicantId === kandidatiSelektuar?.applicantId
        ? {
            ...newCandidad,
            applicantId: kandidatiSelektuar?.applicantId,
          }
        : el
    );
  }

  //logic for Submiting the applicant interview Notes

  const handleSubmit = async (e) => {
    const temporaryArray = {
      ...kandidatiSelektuar,
      applicantNotes: applicantNotes,
    };

    const { applicantId, userId, ...rest } = temporaryArray;

    Swal.fire({
      title: "Ju lutemi prisni...",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await API.put(
      "interviews",
      `/interviews/${kandidatiSelektuar?.applicantId}`,
      {
        body: rest,
      }
    )
      .then(() => {
        setKandidatiSelektuar({ ...temporaryArray });
        dispatch(
          candidatesReducer(updateCandidatet(candidates, temporaryArray))
        );
        setIntervistat(updateCandidatet(intervistat, temporaryArray));
        setShowModal(false);
        Swal.mixin({
          ...defaultSwalProperties,
        }).fire({
          icon: "success",
          title: "Kandidati u ndryshua!",
        });
      })
      .catch((err) => {
        message.error("err: ", err);
        console.log("err: ", err);
      });
  };
  //logic for downloadin a file from google drive
  const handleDownload = (file) => {
    window.location.href = file.downloadUrl;
  };

  let applicantFullName =
    kandidatiSelektuar.applicantFirstName +
    " " +
    kandidatiSelektuar.applicantLastName;

  let textFormat =
    applicantFullName.length > 35
      ? applicantFullName.slice(0, 35) + "..."
      : applicantFullName;

  return (
    <div className="info-shtese-container">
      <div className="first-content-container">
        <div className="content-header">
          <p>Shënime gjatë proçesit</p>
          <Button
            className="ndryshoniBtn"
            onClick={() => setShowModal(!showModal)}
          >
            Ndryshoni
          </Button>
        </div>
        <div className="content-container">
          <div className="first-container">
            <QuillRenderer value={kandidatiSelektuar?.applicantNotes || ""} />
          </div>
          <div className="second-container">
            <div className="second-container-header">
              <img src={InfoIcon} alt="Info Icon" />
              <p>
                Këto janë dokumente/foto të ndryshme për{" "}
                <span>{textFormat}</span>.
              </p>
            </div>
            <div className="second-content-container">
              {uploadedFiles &&
                uploadedFiles.map((files, index) => {
                  const fileExtension = files.name.split(".").pop();

                  return (
                    <div
                      className="upload-container"
                      key={index}
                      onClick={() => handleDownload(files)}
                    >
                      <div className="text-image">
                        <div className="uploadedFileIcon">
                          {fileIcons({ type: fileExtension }) ||
                            fileIcons({ type: "image" })}
                        </div>
                        <p>
                          {files.name.length > 23
                            ? `${files.name.slice(0, 23)}...`
                            : files.name}
                        </p>
                      </div>
                      <div className="delete-button">
                        <img src={DownloadIcon} width={12} height={14} />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {
        <Modal
          open={showModal}
          centered
          destroyOnClose={true}
          className="shenime-modal"
          onCancel={() => setShowModal(!showModal)}
          title={`Shënime gjatë proçesit (${textFormat})`}
          closeIcon={<ModalXIcon />}
          footer={[
            <div key="footer-buttons" className="flex-between">
              <Button
                type="primary"
                shape="rounded"
                danger
                onClick={() => setShowModal(!showModal)}
              >
                Mbyllni faqen
              </Button>

              <Button
                className="ant-btn-confirm"
                htmlType="submit"
                type="primary"
                shape="rounded"
                onClick={handleSubmit}
              >
                Ruani ndryshimet
              </Button>
            </div>,
          ]}
        >
          <div className="shenime-modal-container">
            <div className="first-modal-content-container">
              <QuillEditor
                value={applicantNotes}
                placeholder="Shkruani shënime gjatë proçesit të aplikantit..."
                classNameStyle="quill-box"
                onChange={setApplicantNotes}
              />
            </div>
          </div>
        </Modal>
      }
    </div>
  );
};

export default InformacioneShtese;
