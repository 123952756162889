import { albanianTranslations, getTranslation } from "./translations";

export const REPORT_TYPES = {
	//Employees
	employeeId: "string",
	employeeFirstName: "string",
	employeeLastName: "string",
	employeeGender: "string",
	employeeDayOfBirth: "date",
	employeeSupervisor: "string",
	employeeEmailWork: "string",
	// employeeEmailPersonal: "string",
	employeePhoneWork: "string",
	employeePhonePersonal: "string",
	employeePhoneNumber: "string",
	employeePersonalEmail: "string",
	employeeAddress: "string",
	employeeCity: "string",
	employeeCountry: "string",
	employeeZipCode: "string",
	employeeDepartmentName: "string",
	employeePosition: "string",
	employeeStartDate: "date",
	employeeHireDate: "date",
	employeeEndDate: "date",
	employeeStatus: "string",
	employeeReasonforTermination: "string",
	employeeTypeOfEmployment: "string",
	employeeTypeOfContract: "string",
	employeeTypeOfContractDescription: "string",
	employeeTypeOfContractStartDate: "date",
	employeeTypeOfContractEndDate: "date",
	employeeTypeOfContractSalary: "string",
	employeeSalaryPerHour: "currency",
	employeeMonthlyNetSalary: "currency",
	employeeGrossMonthlySalary: "currency",
	// Clockings
	clockInDate: "date",
	clockOutDate: "date",
	clockDate: "date",
	approved: "boolean",
	clockInNote: "string",
	clockOutNote: "string",
	duration: "number",
	employeeFullName: "string",
	clockInPayment: "currency",
	clockInRate: "currency",
	before19: "number",
	between19and22: "number",
	after22: "number",
	employee: "string",
	breakTime: "string",
	breakTimeVal: "number",
	employeeTeam: "string",
	// Employee Requests
	requestType: "string",
	requestStart: "date",
	requestEnd: "date",
	requestStatus: "string",
	createdAt: "date",
	requestDescription: "string",
	// prepayments
	additions: "currency",
	deductions: "currency",
	timeframe: "date",
	total: "currency",
	//bankForwarding
	idNumber: "string",
	totalBruto: "currency",
	totalNeto: "currency",
	totalIns: "currency",
	totalEmployeeerIns: "currency",
	bankTotal: "currency",
	total: "currency",
	// Detailed Taxes
	healthIns: "currency",
	socialIns: "currency",
	progressiveTax: "currency",
	healthInsEmployeer: "currency",
	insuranceEmployeer: "currency",
	// Trendi i punesimeve largimeve "suspensions"
	employeeLatestStatusUpdate: "string",
	regularDays: "number",
	sickDays: "number",
	holidayRequestDays: "number",
	nationalHolidayDays: "number",
	nationalHolidayWorkedDays: "number",
	maternityDays: "number",
	missingDays: "number",
};

export const getReportTypes = (key) => {
	let temp = null;
	Object.entries(albanianTranslations).forEach(([key1, value]) => {
		if (value === key) {
			temp = key1;
		}
	});
	if (Object.keys(REPORT_TYPES).includes(temp)) {
		return REPORT_TYPES[temp];
	}
	return temp || "string";
};
