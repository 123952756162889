import React, { useMemo, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useAuth } from "../../../authentication/authHelpers";
import { useActivity } from "../../../hooks/useActivity";
import PagatGrid from "../PagatGrid";
import EmployeeWageView from "./employee/EmployeeWageView";
import { useNavigate } from "react-router";
import Wages from "./Wages";
import { API } from "aws-amplify";
import {
  ADMIN_USER,
  assignAccessRights,
} from "../../../constantsFactory/accessRights";
import { message } from "antd";
import { REGULAR_USER } from "../../../constantsFactory/regularUser";

function MainWage() {
  const { userRole } = useAuth();
  const activity = useActivity(30000); // 1s=1000ms
  const navigate = useNavigate();
  const auth = useAuth();

  const onTest = async (e) => {
    // await API.get("clockingLogs", "/clockingLogs").then((res) => console.log(res));
    // await assignAccessRights("Employee", "us-east-1:bde7d083-b2c4-41f2-a4d6-7c497103e2d7");
    // await API.put("employees", "/employees/d00f0fb2-5f7c-4834-819a-7e922a2a62e0", {
    // 	body: {
    // 		accountStatus: null,
    // 	},
    // }).then(() => message.success("SUCCESS"));
    // await API.get("userGroups", "/userGroups").then((res) => console.log(res));
    // await API.put("userGroups", "/userGroups/748396e7-aec1-4160-9ba9-b0c0cc6a5edc", {
    // 	body: {
    // 		...REGULAR_USER.accessConfig,
    // 	},
    // }).then(() => message.success("U shtua me sukses"));
    // let res = getRes(e);
    // console.log("result", res);
  };

  useMemo(() => {
    if (activity === false) {
      let timerInterval;
      Swal.fire({
        title: `KUJDES!`,
        html: "Ju do te largoheni ne <b></b> sekonda!",

        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Qendro!",
        denyButtonText: `Me largo!`,
        confirmButtonColor: "#1DA193",
        reverseButtons: true,
        timer: 15000,
        timerProgressBar: true,
        didOpen: () => {
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = parseInt(Swal.getTimerLeft() / 1000);
            if (Swal.getTimerLeft() / 1000 < 1) {
              navigate("/kryefaqja");
              clearInterval(timerInterval);
              Swal.close();
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire("Veprimi u ruajt me sukses!!", "", "success");
        } else if (result.isDenied) {
          navigate("/kryefaqja");

          Swal.fire("Ju u larguat!", "", "info");
        }
      });
    }
  }, [activity]);

  return (
    <>
      {/* <button onClick={() => onTest("LVIII")}>TEST </button> */}
      {userRole === "Admin" || userRole === "Super Admin" ? (
        <PagatGrid />
      ) : (
        <EmployeeWageView />
      )}
    </>
  );
}

export default MainWage;
