import React from "react";
import MainTabs from "./MainTabs/MainTabs";
import "./Intervistat.scss";
import LoaderComponent from "../commonComponents/LoadableComp/LoadableComp";
import { useSelector } from "react-redux";

const Intervistat = () => {
  return (
    <LoaderComponent>
      <div className="intervistatMainPage">
        <MainTabs />
      </div>
    </LoaderComponent>
  );
};

export default Intervistat;
