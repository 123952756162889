import React from "react";
import DepartmentetCard from "./DepartmentCard/DepartmentCard";

const Departamentet = () => {
  return (
    <>
      <DepartmentetCard />
    </>
  );
};
export default Departamentet;
