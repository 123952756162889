import React from "react";
import "./ProblemStatus.scss";

function ProblemStatus({ status }) {
  return (
    <div
      className="problemStatus"
      style={
        status === "Open"
          ? { backgroundColor: "#FCC94A", color: "#1D3445" }
          : { backgroundColor: "#1DA193", color: "#FBFBFB" }
      }
    >
      {status === "Open" ? "Në pritje" : "Zgjidhur"}
    </div>
  );
}

export default ProblemStatus;
