import CardComponent from "../../../../Konfigurimet/KompaniaSettings/KompaniaPozicionet/CardComponent";
import { iconPickerDep } from "../../../../Konfigurimet/KompaniaSettings/KompaniaPozicionet/iconPickerDep";
import TotalEmployeeNumber from "../../TotalEmployeeNumber";
import ComponentCard from "../ComponentCard";

const defaultData = {

  x: 0,
  y: 0,
  h: 1,
  w: 1,
  minW: 1,
  minH: 1,
  static: false

}
export const saved = [
  {
    i: 0,
    data: {

      name: "TotalEmployee",
      emri: "Punonjësit total",
      src: ComponentCard,
      content: TotalEmployeeNumber,
      parent: true,
      // data: cardData[0],
      hidden: false,
    },
    ...defaultData
  },
];

const proccessMap = (dynamicMap) => {
  let a = 0;
  let b = 0;

  return dynamicMap.map((el, index) => {
    if (a === 4) {
      a = 0;
      b++;
    }
    const result = {
      i: `${index}`,
      data: {
        name: el.departmentName,
        emri: (
          <div style={{ display: "flex", color: "#1D3445", alignItems: "center" }}>
            {" "}
            {iconPickerDep(el.departmentName)} &nbsp; {el.departmentName}
          </div>
        ),
        src: CardComponent,
        parent: false,
        data: el,
        hidden: false,
      },
      ...defaultData,
      x: a,
      y: b
    };

    a++;

    return result;
  });
};

export const getSettingsSaved = (dynamicMap) => {
  let test = proccessMap(dynamicMap);
  const ls = JSON.parse(localStorage.getItem("draggableSetingsPozicionet"));
  if (ls !== null) {
    let temporary = [];
    ls.forEach((el, idx) => {
      let found = test.findIndex((l, x) => l.i === el.i);
      if (found !== -1) {
        temporary.push({
          ...test[found],
          data: { ...test[found].data, hidden: el.data.hidden },
          x: ls[found].x,
          y: ls[found].y,
          h: ls[found].h,
          w: ls[found].w,
          minW: ls[found].minW,
          minH: ls[found].minH,
        })
      }
    });
    return temporary;
  }
  else if (ls === null) {
    localStorage.setItem("draggableSetingsPozicionet", JSON.stringify(proccessMap(dynamicMap)));
    return proccessMap(dynamicMap);
  }
};

const swap = (theArray, indexA, indexB) => {
  var temp = theArray[indexA];
  theArray[indexA] = theArray[indexB];
  theArray[indexB] = temp;
};
