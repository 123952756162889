export const FormGridStatusRenderer = ({ value, statuses }) => {
  try {
    const { color } = statuses.find(({ status }) =>
      value.toUpperCase().includes(status.toUpperCase())
    );

    return (
      `<div class="statusCard" style="color: #fff` +
      `;background:` +
      color +
      ` ;">` +
      value +
      `</div>`
    );
  } catch (e) {}
};
