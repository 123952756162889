import { ArrowLeftOutlined } from "@ant-design/icons";
import "./SquircleBackButton.scss";

const SquircleBackButton = ({ onClick, className = "" }) => (
  <div {...{ onClick, className: `squircleBackButton ${className}`.trim() }}>
    <ArrowLeftOutlined />
  </div>
);

export default SquircleBackButton;
