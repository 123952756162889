import moment from "moment-timezone";
import { prepareMultis } from "../../../Pagat/wages/multiGenerate/prepare";
import { getTranslation } from "../../generate/translations";

export const suspensions = (data, filter, columns, manualColumns) => {
  let result = {
    columnsDef: [],
    allColumns: [],
    rowData: [],
    selectedColumns: [],
    allColumns: [],
  };
  if (!!data && !!filter) {
    result.selectedColumns = manualColumns || columns.columnDefs;
    result.allColumns = columns.allAppliedColumnDefs;

    const preFilter = data
      .filter((f) => {
        if (f.employeeStatus === "Aktiv") {
          if (
            moment(f?.employeeHireDate).isSameOrAfter(
              filter?.timeInterval[0]
            ) &&
            moment(f?.employeeHireDate).isSameOrBefore(filter?.timeInterval[1])
          ) {
            return true;
          }
        } else if (f?.employeeStatus === "Shkarkuar") {
          if (
            moment(f?.employeeTerminationDate).isSameOrAfter(
              filter?.timeInterval[0]
            ) &&
            moment(f?.employeeTerminationDate).isSameOrBefore(
              filter?.timeInterval[1]
            )
          ) {
            return true;
          }
        } else if (f?.employeeStatus === "Larguar") {
          if (
            moment(f?.employeeSuspensionDate).isSameOrAfter(
              filter?.timeInterval[0]
            ) &&
            moment(f?.employeeSuspensionDate).isSameOrBefore(
              filter?.timeInterval[1]
            )
          ) {
            return true;
          }
        }
      })
      .map((el) => {
        return {
          ...el,
          employeeSuspensionDate: moment(el.employeeSuspensionDate).format(
            "DD/MM/YYYY"
          ),
          employeeTerminationDate: moment(el.employeeTerminationDate).format(
            "DD/MM/YYYY"
          ),
          employeeHireDate: moment(el.employeeHireDate).format("DD/MM/YYYY"),
          employeeLatestStatusUpdate: getLatest(el?.employeeStatus, el),
        };
      });

    if (!!manualColumns && Array.isArray(manualColumns)) {
      manualColumns.forEach((e) => {
        result.columnsDef.push(getTranslation(e));
      });
      preFilter.forEach((emplRecord) => {
        let toAdd = [];
        manualColumns.forEach((c) => toAdd.push(emplRecord[c]));
        result.rowData.push(toAdd);
      });
    } else {
      columns?.columnDefs.forEach((e) => {
        result.columnsDef.push(getTranslation(e));
      });
      preFilter.forEach((emplRecord) => {
        let toAdd = [];
        columns?.columnDefs?.forEach((c) => toAdd.push(emplRecord[c]));
        result.rowData.push(toAdd);
      });
    }
  }
  return result;
};

// iterate through object keys and values
function iterate(obj) {
  let result = [];
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result.push(obj[key]);
    }
  }
  return result;
}

// const getTranslation = (key) => {
// 	if (albanianTranslations.hasOwnProperty(key)) {
// 		return albanianTranslations[key];
// 	}
// 	return "";
// };

function getLatest(status, empl) {
  if (status === "Aktiv") {
    return moment(empl.employeeHireDate).format("DD/MM/YYYY");
  } else if (status === "Shkarkuar") {
    return moment(empl.employeeTerminationDate).format("DD/MM/YYYY");
  } else if (status === "Larguar") {
    return moment(empl.employeeSuspensionDate).format("DD/MM/YYYY");
  }
}
