import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
import "./KanbanConfirmModal.scss";
function KanbanConfirmModal({ handleCancel, handleOk, visible, questions }) {
  const [value, setValue] = useState(null);

  //Modal to select the reason for the disqualification
  return (
    <Modal
      centered
      className="kanbanConfirmModal"
      onCancel={handleCancel}
      destroyOnClose
      open={visible}
      width={500}
      footer={[
        <div className="footerContainer">
          <Button className="decline" onClick={handleCancel}>
            Anulloni
          </Button>

          <Button
            className="send-request"
            disabled={!value}
            onClick={() => handleOk(value)}
          >
            Konfirmoni
          </Button>
        </div>,
      ]}
    >
      <div className="kanbanConfirmModalBody">
        <h1>Zgjidhni Arsyen e Skualifikimit</h1>
        {questions?.map((item) => {
          return (
            <Button
              className="disqualificationButton"
              onClick={() => setValue(item)}
            >
              {item}
            </Button>
          );
        })}
      </div>
    </Modal>
  );
}

export default KanbanConfirmModal;
