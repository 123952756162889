import moment from "moment-timezone";
import React from "react";
import MobileHtmlTabel from "../../../utils/MobileComponents/MobileHtmlTabel";
import PagatGridHeader from "./PagatGridHeader";

function MobilePagatGrid({ rowData }) {
	return (
		<div className="base-mobile-div">
			<div className="header-paga" style={{ marginBottom: 15 }}>
				<PagatGridHeader rowData={rowData} />
			</div>
			<MobileHtmlTabel
				header={["Nr", "Muaji", "Shuma neto", "Shuma bruto"]}
				tableData={rowData?.map(({ nr, muaji, neto, bruto }) => ({
					nr,
					muaji: moment(muaji).format("DD/MM/YYYY"),
					neto,
					bruto,
				}))}
				isLink={true}
			/>
		</div>
	);
}

export default MobilePagatGrid;
