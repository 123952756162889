import React from "react";
import moment from "moment-timezone";
import { Button, Modal, TimePicker, Select, Col, Row, Input } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { htmlParser } from "../../../../utils/htmlParser";
function PaisjetOraritFilter({
  DepartamentFilter,
  endduplicateCheck,
  duplicateCheck,
  setFilterModal,
  filetModal,
  clearFilters,
  gridApi,
  shiftDescriptionduplicateCheck,
  shiftNameduplicateCheck,
}) {
  // amtd format
  const format = "HH:mm";
  const { Option } = Select;

  const CloseModal = () => {
    clearFilters();
    setFilterModal(false);
  };

  const titulliFilter = (e) => {
    var FilterComponent = gridApi.getFilterInstance("shiftName");
    FilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };

  const descriptionFilter = (e) => {
    var FilterComponent = gridApi.getFilterInstance("shiftDescription");
    FilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };

  const startFilter = (e) => {
    var FilterComponent = gridApi.getFilterInstance("shiftStartTime");
    FilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };

  const endFilter = (e) => {
    var FilterComponent = gridApi.getFilterInstance("shiftEndTime");
    FilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };

  return (
    <Modal
      className="perdoruesit-filter"
      title="Kërkim i Avancuar"
      visible={filetModal}
      onOk={() => setFilterModal(false)}
      onCancel={() => setFilterModal(false)}
      destroyOnClose={true}
      footer={[
        <Button
          style={{
            background: "#EA3943",
            borderRadius: 5,
            color: "#fff",
            border: 0,
          }}
          className="anullo-btn"
          onClick={() => CloseModal()}>
          Anulo
        </Button>,
        <Button
          style={{
            background: "#1DA193",
            borderRadius: 5,
            color: "#fff",
            border: 0,
            width: 100,
          }}
          className="kerko-btn">
          Kërko
        </Button>,
      ]}>
      <Row gutter={16}>
        <Col span={6}>
          <h3>Titulli Orarit:</h3>
          <Select
            showSearch
            bordered={false}
            className="filter-dropdown-global"
            style={{
              background: "#F5F5F7",
              border: 0,
              borderRadius: 5,
              width: "100%",
            }}
            suffixIcon={<CaretDownFilled />}
            placeholder="Titulli i orarit"
            onChange={titulliFilter}>
            {shiftNameduplicateCheck?.map((Data) =>
              Data === "" ? (
                Data
              ) : (
                <Option key={Data} value={Data}>
                  {Data}
                </Option>
              )
            )}
          </Select>
        </Col>
        <Col span={6}>
          <h3>Përshkrimi Orarit:</h3>
          <Select
            showSearch
            bordered={false}
            className="filter-dropdown-global"
            style={{
              background: "#F5F5F7",
              border: 0,
              borderRadius: 5,
              width: "100%",
            }}
            suffixIcon={<CaretDownFilled />}
            placeholder="Përshkrimin i orarit "
            onChange={descriptionFilter}>
            {shiftDescriptionduplicateCheck?.map((Data) =>
              Data === "" ? (
                Data
              ) : (
                <Option key={Data} value={Data}>
                  {htmlParser(Data)}
                </Option>
              )
            )}
          </Select>
        </Col>
        <Col span={6}>
          <h3>Orari Fillimit:</h3>
          <Select
            showSearch
            bordered={false}
            className="filter-dropdown-global"
            style={{
              background: "#F5F5F7",
              border: 0,
              borderRadius: 5,
              width: "100%",
            }}
            suffixIcon={<CaretDownFilled />}
            placeholder="Orarin i fillimit"
            onChange={startFilter}>
            {duplicateCheck?.map((Data) =>
              Data === "" ? (
                Data
              ) : (
                <Option key={Data} value={Data}>
                  {moment(Data).format("HH:mm")}
                </Option>
              )
            )}
          </Select>
        </Col>
        <Col span={6}>
          <h3>Orari Mbarimit:</h3>
          <Select
            showSearch
            bordered={false}
            className="filter-dropdown-global"
            style={{
              background: "#F5F5F7",
              border: 0,
              borderRadius: 5,
              width: "99%",
            }}
            suffixIcon={<CaretDownFilled />}
            placeholder="Orarin i mbarimit"
            onChange={endFilter}>
            {endduplicateCheck?.map((Data) =>
              Data === "" ? (
                Data
              ) : (
                <Option key={Data} value={Data}>
                  {moment(Data).format("HH:mm")}
                </Option>
              )
            )}
          </Select>
        </Col>
      </Row>
    </Modal>
  );
}

export default PaisjetOraritFilter;
