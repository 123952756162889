import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../authentication/authHelpers";
import ChartSummary from "./Chart";
import { API } from "aws-amplify";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";
function DashboardChart() {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { clockings } = useSelector((state) => state.clockings);

  useEffect(() => {
    if (auth.employeeId !== null && !!clockings) {
      setData(clockings || []);
    }
    setLoading(false);
  }, [auth, clockings]);
  console.log({ data });

  return (
    <div className="Grafike" style={{ height: "100%", width: "100%" }}>
      {!loading ? (
        <ChartSummary
          dataSrc={data}
          dataTitle={"Oret"}
          employeeChart={auth?.employeeId}
        />
      ) : (
        <div>
          <Skeleton />
        </div>
      )}
    </div>
  );
}

export default DashboardChart;
