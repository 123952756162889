import { useState, useEffect, useMemo, useLayoutEffect } from "react";
import "./dashboard.scss";
import { API } from "aws-amplify";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { useAuth } from "../../authentication/authHelpers";
import DragContainer from "./AdminDashboard/Draggable/DragContainer";
import { assignAccessRights } from "../../constantsFactory/accessRights";
import { message } from "antd";
import {
	notificationAdminToOnlyOneUser,
	notificationUserToAdmin,
} from "../../utils/sendNotification";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { automateFactory } from "../../automation/automation";
function UserDashboard({ role }) {
	// const [loading, setLoading] = useState(true);
	const userData = useAuth();
	const [notifs, setNotifs] = useState(null);

	// const onLoad = (loady) => {
	//   setLoading(loady);
	// };

	// useLayoutEffect(() => {
	// 	if (!!userData?.employeeId) {
	// 		const startWork = moment();
	// 		startWork.set({
	// 			hour: "13",
	// 			minute: "30",
	// 			second: "00",
	// 		});
	// 		if (moment().diff(startWork, "seconds", true) > 0) {
	// 			// 1 hour = 3600000 milliseconds
	// 			automateFactory("clockInReminder", userData);
	// 			setInterval(() => {
	// 				automateFactory("clockInReminder", userData);
	// 			}, 3600000);
	// 		}
	// 	}
	// }, [userData]);
	// const sendDontForget = async () => {
	// 	let notifs;
	// 	let isRightTime = moment().format("HH:mm") > moment("20:50pm", "HH:mm:ss").format("HH:mm:ss");

	// 	if (isRightTime) {
	// 		await API.get("notifications", "/notifications").then(
	// 			(res) =>
	// 				(notifs = res.some(
	// 					(el) =>
	// 						moment().format("DD/MM/YYYY") === moment(el.createdAt).format("DD/MM/YYYY") &&
	// 						el?.notificationBody === "Before you leave, don't forget to clock out!" &&
	// 						el?.userDetails.hasOwnProperty(auth?.userAccess[0]?.identityId)
	// 				))
	// 		);
	// 		console.log("notifs:", notifs);

	// 		if (!notifs) {
	// 			let data = {
	// 				notificationTitle: "Don't forget to clock out!",
	// 				createdAt: moment().format("DD/MM/YYYY"),
	// 				notificationCategory: "Info",
	// 				notificationPriority: false,
	// 				notificationBody: `Before you leave, don't forget to clock out!`,
	// 				isAnnouncement: false,
	// 				notificationUrl: "/kryefaqja",
	// 			};
	// 			await notificationAdminToOnlyOneUser(data, {
	// 				identityId: auth?.userAccess[0]?.identityId,
	// 			});
	// 		}
	// 	}
	// 	// message.success("U nis");
	// };

	// const sendDontForgetToclockIn = async () => {
	// 	let notifs;
	// 	let isRightTime = moment().format("HH:mm") > moment("13:25pm", "HH:mm:ss").format("HH:mm:ss");

	// 	if (isRightTime) {
	// 		await API.get("notifications", "/notifications").then(
	// 			(res) =>
	// 				(notifs = res.some(
	// 					(el) =>
	// 						moment().format("DD/MM/YYYY") === moment(el.createdAt).format("DD/MM/YYYY") &&
	// 						el?.notificationBody === "Before starting to work, don't forget to clock in!" &&
	// 						el?.userDetails.hasOwnProperty(auth?.userAccess[0]?.identityId)
	// 				))
	// 		);

	// 		if (notifs === false) {
	// 			let data = {
	// 				notificationTitle: "Don't forget to clock in!",
	// 				createdAt: moment().format("DD/MM/YYYY"),
	// 				notificationCategory: "Info",
	// 				notificationPriority: false,
	// 				notificationBody: `Before starting to work, don't forget to clock in!`,
	// 				isAnnouncement: false,
	// 				notificationUrl: "/kryefaqja",
	// 			};
	// 			notificationAdminToOnlyOneUser(data, {
	// 				identityId: auth?.userAccess[0]?.identityId,
	// 			});
	// 		}
	// 	}
	// 	// message.success("U nis");
	// };

	return (
		<LoadableComp loading={false}>
			{" "}
			<DragContainer view={role} />
		</LoadableComp>
	);
}

export default UserDashboard;
