import { useEffect, useReducer, useState } from "react";
import { apiRoutes } from "../../apiRoutes";
import { fetchData } from "../../fetchData";
import { views } from "./documentationConfigurationData";
import {
  DocumentationCategoriesView,
  CategoryView,
  DocumentationPreview,
} from "./View";
import { DocumentationContext } from "./context";
import { docConfigReducer } from "./reducer";
import { actions } from "./actions";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";

const DocumentationConfiguration = () => {
  const [categories, dispatchCategories] = useReducer(docConfigReducer, []);
  const [selectedCategoryName, setSelectedCategoryName] = useState({});
  const [selectedDocumentationName, setSelectedDocumentationName] = useState();
  const [activeView, setActiveView] = useState(views.CATEGORIES_VIEW);

  const [loading, setLoading] = useState(true);

  const selectedCategory = categories.find(
    ({ categoryName }) => categoryName === selectedCategoryName
  );

  const viewsContainer = {
    DocumentationCategoriesView,
    CategoryView,
    DocumentationPreview,
  };

  const ViewToRender = viewsContainer[activeView];

  const curryDispatch = (fn) => fn(dispatchCategories);

  useEffect(() => {
    fetchData(apiRoutes.docConfiguration).then((r) => {
      setLoading(false);
      dispatchCategories({
        type: actions.SET_CATEGORIES,
        payload: r,
      });
    });
  }, []);

  /**
   * * @EneaXharau - Added a div between LoadableComp
   * * also added styling to better center Loader
   */
  return (
    <DocumentationContext.Provider
      value={{
        categories,
        curryDispatch,
        selectedCategory,
        selectedCategoryName,
        setSelectedCategoryName,
        setActiveView,
        selectedDocumentationName,
        setSelectedDocumentationName,
      }}
    >
      <div style={{ height: "100%", width: "100%" }}>
        <LoadableComp loading={loading}>
          <ViewToRender />
        </LoadableComp>
      </div>
    </DocumentationContext.Provider>
  );
};

export default DocumentationConfiguration;
