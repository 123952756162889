import utf8 from "utf8";
import { encodedUtf8ToByteArray } from "./utils";
import {
  URL_FILES,
  CONTENT_TYPE_JSON,
  URL_UPLOAD,
  MIME_FOLDER,
  _CREATE_HEADERS,
  _CREATE_QUERY_PARAMS,
} from "./driveStatic";
import { blobToBase64 } from "./utils/blobToBase64";

const driveApi = ({ accessToken, boundary = "" }) => {
  const params = { boundary: boundary || "foo_bar_baz" };

  const uploadFile = (media, mediaType, metadata, isBase64) => {
    const ddb = `--${params.boundary}`;
    const ending = `\n${ddb}--`;

    let body =
      `\n${ddb}\n` +
      `Content-Type: ${CONTENT_TYPE_JSON}\n\n` +
      `${JSON.stringify(metadata)}\n\n${ddb}\n` +
      (isBase64 ? "Content-Transfer-Encoding: base64\n" : "") +
      `Content-Type: ${mediaType}\n\n`;

    if (typeof media === "string") {
      body += `${media}${ending}`;
    } else {
      body = new Uint8Array(
        encodedUtf8ToByteArray(utf8.encode(body))
          .concat(media)
          .concat(encodedUtf8ToByteArray(utf8.encode(ending)))
      );
    }

    return fetch(`${URL_UPLOAD}?uploadType=multipart`, {
      method: "POST",
      headers: _CREATE_HEADERS(
        accessToken,
        `multipart/related; boundary=${params.boundary}`,
        body.length
      ),
      body,
    });
  };

  const deleteDriveItem = (fileId) => {
    return fetch(`${URL_FILES}/${fileId}`, {
      method: "DELETE",
      headers: _CREATE_HEADERS(accessToken),
    });
  };

  const list = (queryParams) => {
    if (queryParams)
      return fetch(`${URL_FILES}${_CREATE_QUERY_PARAMS(queryParams)}`, {
        headers: _CREATE_HEADERS(accessToken),
      });
  };

  const updatePermission = async (fileId, role = "writer", permissionId) => {
    if (fileId && permissionId)
      return await fetch(`${URL_FILES}/${fileId}/permissions/${permissionId}`, {
        method: "PATCH",
        headers: _CREATE_HEADERS(accessToken, "application/json"),
        body: JSON.stringify({
          role,
        }),
      });
  };

  const deletePermission = async (fileId, permissionId) => {
    if (fileId && permissionId)
      return await fetch(`${URL_FILES}/${fileId}/permissions/${permissionId}`, {
        method: "DELETE",
        headers: _CREATE_HEADERS(accessToken, "application/json"),
      });
  };

  /**
   *
   * @param {String} fileId id of file to change the permission
   */
  const share = async ({
    fileId,
    role = "writer",
    type = "anyone",
    emailAddress = "",
    dontSendEmail = false,
  }) => {
    if (fileId)
      if (dontSendEmail) {
        return await fetch(
          `${URL_FILES}/${fileId}/permissions?sendNotificationEmail=false`,
          {
            method: "POST",
            headers: _CREATE_HEADERS(accessToken, "application/json"),
            body: JSON.stringify({
              role,
              type,
              emailAddress,
              transferOwnership: "false",
              withLink: true,
            }),
          }
        );
      } else {
        return await fetch(`${URL_FILES}/${fileId}/permissions`, {
          method: "POST",
          headers: _CREATE_HEADERS(accessToken, "application/json"),
          body: JSON.stringify(
            emailAddress === ""
              ? {
                  role,
                  type,
                  transferOwnership: "false",
                  withLink: true,
                }
              : {
                  role,
                  type,
                  emailAddress,
                  transferOwnership: "false",
                  withLink: true,
                }
          ),
        });
      }
  };

  const getFilesByFolderId = async (folderId) =>
    await list({
      q: `'${folderId}' in parents`,
      fields:
        "files(id,name,mimeType,size,kind,permissions,webViewLink,thumbnailLink)",
    });

  const permissionList = async (fileId) => {
    if (fileId)
      return await fetch(`${URL_FILES}/${fileId}?fields=permissions`, {
        method: "GET",
        headers: _CREATE_HEADERS(accessToken, "application/json"),
      });
  };

  const getDriveItemId = async ({
    name,
    parent,
    mimeType,
    trashed = false,
  }) => {
    const queryParams = { name, trashed };

    if (mimeType) {
      queryParams.mimeType = mimeType;
    }

    let result = await list({
      q:
        _CREATE_QUERY_PARAMS(queryParams, "", " and ", true) +
        ` and '${parent}' in parents`,
    });

    if (!result.ok) {
      throw result;
    }

    const file = (await result.json()).files[0];

    return file ? file.id : file;
  };

  const getFolderIdOrCreate = async ({
    name,
    parent,
    defaultPermissions = [],
  }) => {
    const mimeType = MIME_FOLDER;
    const formattedName =
      name?.replace(/[`~!@#$%^&*()_|+\=?.<>\{\}\[\]\\\/]/gim, "-") ||
      name ||
      "";
    let id = await getDriveItemId({ name: formattedName, parent, mimeType });

    if (!id) {
      const body = JSON.stringify({
        name: formattedName,
        parents: [parent],
        mimeType,
      });

      let result = await fetch(URL_FILES, {
        method: "POST",
        headers: _CREATE_HEADERS(accessToken, CONTENT_TYPE_JSON, body.length),
        body,
      });

      if (!result.ok) {
        throw result;
      }

      id = (await result.json()).id;
      defaultPermissions?.forEach(async (el) => {
        await share({
          fileId: id,
          type: "user",
          role: el?.permissionRole,
          emailAddress: el?.permissionEmail,
          dontSendEmail: true,
        })
          .then((res) => {
            if (!res.ok) {
              throw result;
            }
          })
          .catch((err) => console.log("err: ", err));
      });
    }
    return id;
  };

  const createFolders = async ({
    parent,
    folderNames = [],
    defaultPermissions = [],
  }) => {
    const folders = await Promise.all(
      folderNames.map(async (folderName, index) => ({
        folderName,
        folderId: await getFolderIdOrCreate({
          name: folderName,
          parent,
          defaultPermissions: defaultPermissions[index],
        }),
      }))
    );

    return folders?.reduce(
      (acc, { folderName, folderId }) => ({ ...acc, [folderName]: folderId }),
      {}
    );
  };

  const getDriveItem = (fileId, queryParams) => {
    const parameters = _CREATE_QUERY_PARAMS(queryParams);

    return fetch(`${URL_FILES}/${fileId}${parameters}`, {
      headers: _CREATE_HEADERS(accessToken),
    });
  };

  const updateDriveItem = (fileId, queryParams) => {
    let resource = queryParams.resource;
    delete queryParams.resource;

    let config = {
      headers: _CREATE_HEADERS(accessToken, "application/json"),
      method: "PATCH",
    };

    if (resource) {
      config.body = JSON.stringify(resource);
    }
    return fetch(
      `${URL_FILES}/${fileId}${_CREATE_QUERY_PARAMS(queryParams)}`,
      config
    );
  };

  const getImageSrc = async (fileId) => {
    let src, blob;

    await getDriveItem(fileId, { alt: "media" })
      .then(async (r) => {
        if (r.ok) {
          const data = await r.blob();
          blob = URL.createObjectURL(data);
          await blobToBase64(data).then((res) => (src = res));
        }
        src = undefined;
        blob = undefined;
      })
      .catch((e) => console.error("ERROR", e));

    return { src, blob };
  };

  return {
    list,
    share,
    uploadFile,
    getImageSrc,
    getDriveItem,
    createFolders,
    permissionList,
    getDriveItemId,
    updateDriveItem,
    deleteDriveItem,
    updatePermission,
    deletePermission,
    getFilesByFolderId,
    getFolderIdOrCreate,
  };
};

export default driveApi;
