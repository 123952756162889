import types from "../types/types";
const initialState = {
  programFields: undefined,
};

const programFieldsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PROGRAM_FIELDS:
      return {
        ...state,
        programFields: payload,
      };
    default:
      return state;
  }
};

export default programFieldsReducer;
