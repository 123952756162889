import moment from "moment-timezone";
export const getAppliedShifts = (shiftsArr) => {
	if (Array.isArray(shiftsArr)) {
		let sorted =
			shiftsArr.sort((a, b) => {
				let tempStart = moment(moment(a?.shiftStartTime).format("HH:mm"), "HH:mm").valueOf();
				let tempEnd = moment(moment(b?.shiftStartTime).format("HH:mm"), "HH:mm").valueOf();
				return tempStart - tempEnd;
			}) || [];

		let shiftsText = "";
		sorted.forEach((shift, idx) => {
			shiftsText += `${moment(shift?.shiftStartTime).format("HH:mm")} - ${moment(
				shift?.shiftEndTime
			).format("HH:mm")} ${idx !== sorted?.length - 1 ? "|" : ""} `;
		});
		return shiftsText;
	}
	return "Aktualisht nuk ka turn të konfiguruar për ju";
};
