export const albanianMonth = (albanianMonth) => {
  switch (albanianMonth) {
    case "January":
      return "Janar";
    case "February":
      return "Shkurt";
    case "March":
      return "Mars";
    case "April":
      return "Prill";
    case "May":
      return "Maj";
    case "July":
      return "Qershor";
    case "June":
      return "Korrik";
    case "August":
      return "Gusht";
    case "September":
      return "Shtator";
    case "October":
      return "Tetor";
    case "November":
      return "Nëntor";
    case "December":
      return "Dhjetor";
  }
};
