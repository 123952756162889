import { useEffect, useState } from "react";
import { toSentenceCase } from "../utils";
import { message } from "antd";

const useDrivePermissions = ({ form, folderId, driveRequest }) => {
  const [permissions, setPermissions] = useState([]);
  const [spinning, setSpinning] = useState(true);
  const [permissionSpin, setPermissionSpin] = useState({
    email: "",
    spin: false,
  });

  const getPermissionsList = (permissions) => {
    let permissionsList = [];
    permissions.forEach((el) => {
      permissionsList.push({
        id: el?.id,
        role: el?.role,
        photo: el?.photoLink,
        name: el?.displayName,
        email: el?.emailAddress,
      });
    });
    return permissionsList;
  };
  //region HANDLE SUBMIT
  const handleSubmit = async (getKeylogs = () => {}) => {
    form
      .validateFields()
      .then((res) => {
        const { permissionEmail, permissionRole } = res;
        let currentPermission = null;
        let keylogParams = [];

        if (!!permissionEmail && !!permissionRole) {
          let doesEmailExist = false;
          let doesEmailHaveThisRole = false;
          permissions?.forEach((el) => {
            if (el?.email === permissionEmail) {
              doesEmailExist = true;
              currentPermission = el;
            }
            if (el?.role === permissionRole && !!doesEmailExist)
              doesEmailHaveThisRole = true;
          });
          if (!!doesEmailExist && !!doesEmailHaveThisRole) {
            message.info(
              `Ky email e ka aktualisht autorizimin '${toSentenceCase(
                permissionRole
              )}' për këtë dokument!`
            );
          } else if (!!doesEmailExist && !doesEmailHaveThisRole) {
            setPermissionSpin({ email: permissionEmail, spin: true });
            updatePermission({ permissionRole, currentPermission });
            keylogParams = [
              {
                field: "Roli i autorizimit",
                oldValue: `${permissionEmail} - ${toSentenceCase(
                  currentPermission?.role
                )}`,
                newValue: `${permissionEmail} - ${toSentenceCase(
                  permissionRole
                )}`,
              },
            ];
          } else if (!doesEmailExist && !doesEmailHaveThisRole) {
            addPermission({ permissionRole, permissionEmail });
            keylogParams = [
              {
                field: "Email i autorizimit",
                newValue: permissionEmail,
              },
              {
                field: "Roli i autorizimit",
                newValue: toSentenceCase(permissionRole),
              },
            ];
          }
          if (getKeylogs && typeof getKeylogs === "function") {
            getKeylogs(keylogParams);
          }
        }
      })
      .catch((err) => console.log("err:", err));
  };
  //region >add()
  const addPermission = async ({
    permissionRole,
    permissionEmail,
    specificFolderId = undefined,
  }) => {
    const toSend = {
      fileId: specificFolderId || folderId,
      type: "user",
      role: permissionRole,
      emailAddress: permissionEmail,
      dontSendEmail: true,
    };
    if (!!toSend?.fileId) {
      await driveRequest
        .share(toSend)
        .then((res) => res.json())
        .then((res) => {
          if (res?.id) {
            driveRequest
              .permissionList(specificFolderId || folderId)
              .then((res) => res.json())
              .then(({ permissions }) => {
                form.resetFields();
                setPermissions(getPermissionsList(permissions));
              })
              .catch((err) => console.log("err:", err))
              .finally(() => message.success("Autorizimi u shtua me sukses!"));
          }
          if (res?.error?.errors[0]?.reason === "invalidSharingRequest") {
            form.resetFields();
            message.error(
              `'${permissionEmail}' nuk përkon me asnjë Google account!`
            );
          }
          if (res?.error?.errors[0]?.reason === "notFound") {
            form.resetFields();
            message.error("Ky dokument nuk u gjet në Google Drive!");
          }
        })
        .catch((err) => console.log("err:", err));
    } else {
      message.error({
        content: "Nuk u lidh dot me Google Drive!",
        key: "noFolderId",
      });
    }
  };
  //region >update()
  const updatePermission = async ({ permissionRole, currentPermission }) => {
    await driveRequest
      .updatePermission(folderId, permissionRole, currentPermission?.id)
      .then((res) => res.json())
      .then((res) => {
        form.resetFields();
        if (res?.id) {
          setPermissions((prev) =>
            prev?.map((el) =>
              el.id === currentPermission?.id
                ? {
                    ...el,
                    role: permissionRole,
                  }
                : el
            )
          );
          setPermissionSpin((prev) => ({ ...prev, spin: false }));
          message.success("Autorizimi u përditësua me sukses!");
        } else if (res?.error) {
          setPermissionSpin((prev) => ({ ...prev, spin: false }));
          message.error(res?.error.message);
        }
      })
      .catch((err) => console.log(err));
  };
  //region HANDLE REMOVE
  const handleRemove = async ({
    permissionId,
    permissionEmail,
    getKeylogs = () => {},
  }) => {
    await driveRequest
      .deletePermission(folderId, permissionId)
      .then((res) => {
        if (!!res.ok) {
          const keylogParams = [
            {
              field: "Email i autorizimit",
              oldValue: permissionEmail,
              newValue: "",
            },
          ];
          if (getKeylogs && typeof getKeylogs === "function") {
            getKeylogs(keylogParams);
          }
          setPermissions((prev) => prev.filter((el) => el.id !== permissionId));
          message.success("Autorizimi u fshi me sukses!");
        } else {
          res.json().then((res) => message.error(res.error.message));
        }
      })
      .catch((err) => {
        message.error("Ndodhi një gabim!");
        console.log("err:", err);
      });
  };
  //region GET PERMISSIONS
  const getPermissions = async () => {
    let permissionsList = [];
    await driveRequest
      .permissionList(folderId)
      .then((res) => res.json())
      .then(({ permissions }) => {
        if (!permissions) {
          message.error(
            "Ky dokument nuk ekziston dhe mund të jetë fshirë nga Google Drive!"
          );
          setSpinning(false);
        } else {
          permissionsList.push(
            getPermissionsList(permissions)
            // getPermissionsList(permissions.filter((el) => el.role !== "owner"))
          );
        }
        setPermissions(permissionsList.flat());
        setSpinning(false);
      });
  };

  useEffect(() => {
    if (!!folderId) {
      getPermissions();
    }
    message.error("Nuk u lidh dot me Google Drive!");
    setSpinning(false);
  }, [folderId]);

  return {
    spinning,
    permissions,
    handleRemove,
    handleSubmit,
    addPermission,
    permissionSpin,
    updatePermission,
  };
};

export default useDrivePermissions;
