import React, { useState } from "react";
import { Switch, Row, Col, message, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";

function KonfigurimetAcessRights({ savedData, setSaveData }) {
  const navigate = useNavigate();

  const [btndisable, setBtnDisable] = useState(null);
  const onChange = (path, val) => {
    // console.log(path);
    // console.log(val);
    let result;
    let temp;
    savedData?.accessConfig?.settingsConfig.map((sc) => {
      let sctemp = sc.submenu?.find((el) => el?.to === path);
      if (!!sctemp) {
        temp = sctemp;
        result = sc;
      }
    });
    if (!!temp) {
      temp.auth = val;
      // console.log("Prev", temp);
      let tempData = savedData;
      tempData.accessConfig.settingsConfig?.forEach((element, idx) => {
        if (element?.SubmenuKey === result?.SubmenuKey) {
          tempData.accessConfig.settingsConfig[idx] = result;
        }
        return element;
      });
      setSaveData(tempData);
    }
    setBtnDisable(val);
  };

  const onParentChange = (path, val) => {
    // console.log(path);
    // console.log(val);
    let prev = savedData?.accessConfig?.settingsConfig.find(
      (parentEl) => parentEl.to === path
    );
    // console.log("Parent", prev);
    if (!!prev) {
      prev.auth = val;
      let tempData = savedData;
      tempData.accessConfig.settingsConfig?.forEach((element, idx) => {
        if (element?.SubmenuKey === prev?.SubmenuKey) {
          tempData.accessConfig.settingsConfig[idx] = prev;
        }
        return element;
      });
      setSaveData(tempData);
    }
  };
  const changeRights = async () => {
    await API.put("users", `/users/${savedData?.identityId}`, {
      body: {
        accessConfig: {
          ...savedData.accessConfig,
        },
      },
    })
      .then((success) => {
        message.success("Aksesi u dha me sukses");
        // console.log(success);
        // console.log("test13", savedData);
        navigate("/konfigurimet/siguria/rolet/siguris", { state: savedData });
      })
      .catch((err) => {
        message.error("Ndodhi nje gabim!");
        // console.log("Error Instance", err);
      });
    // console.log("forma", savedData);
  };
  return (
    <div className="konfigurimet-detjat">
      <Row
        style={{ display: "flex", height: 400 }}
        className="konfigurimet-details-card-row"
      >
        {savedData?.accessConfig?.settingsConfig
          ?.sort((a, b) => b.SubmenuKey - a.SubmenuKey)
          .map((sub) =>
            sub?.submenu?.map((el) => (
              <Col
                className="gutter-row"
                span={6}
                style={{ display: "flex", height: 40 }}
              >
                <div className="body-kryefaqja" style={{ display: "flex" }}>
                  <h3 style={{ fontSize: 13, marginTop: 7 }}>{el.title}</h3>
                  <div className="switch-div" style={{ display: "flex" }}>
                    <Switch
                      defaultChecked={el?.auth}
                      onChange={(val) => onChange(el?.to, val)}
                    />
                  </div>
                </div>
              </Col>
            ))
          )}
        {savedData?.accessConfig?.settingsConfig
          ?.filter((el) => el.parent === false || el.parent === true)
          .map((el) => (
            <Col
              className="gutter-row"
              span={6}
              style={{ display: "flex", height: 40 }}
            >
              <div className="body-kryefaqja" style={{ display: "flex" }}>
                <h3 style={{ fontSize: 13, marginTop: 7 }}>{el.title}</h3>
                <div className="switch-div" style={{ display: "flex" }}>
                  <Switch
                    defaultChecked={el?.auth}
                    onChange={(val) => onParentChange(el?.to, val, el?.parent)}
                  />
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <div className="konfigurimet-detjat-fotter">
        <Button
          disabled={btndisable !== null ? false : true}
          className="ruaj-ndrryshimet-btn"
          onClick={() => changeRights()}
        >
          Ruaj ndryshimet
        </Button>
      </div>
    </div>
  );
}
export default KonfigurimetAcessRights;
