import React from "react";
import { Card } from "antd";
import "./dynamic_elements.scss";

function Cardkonfig({ width, height, title, contentChild, key }) {
  return (
    <div className="card" key={key}>
      <div
        key={key}
        title={title}
        bordered={false}
        style={{
          width: width,
          height: height,
          padding: 12,
        }}
      >
        {contentChild}
      </div>
    </div>
  );
}

export default Cardkonfig;
