import React from "react";
import { Drawer, List, Typography, Divider } from "antd";
import {
  HiddenEye,
  ModalXIcon,
  VisibleIcon,
} from "../../../../../assets/icons";
import "./HiddenDrawer.scss";
import { InfoIcon } from "../../../components/notifications/notificationList/filterHeader/src";
function HiddenDrawer({
  setHiddenDrawer,
  hiddenDrawerVisib,
  drawerContent,
  onUnhide,
}) {
  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];
  return (
    <div>
      {" "}
      <Drawer
        title="Kartat e fshehura"
        placement="right"
        onClose={() => setHiddenDrawer(false)}
        open={hiddenDrawerVisib}
        closeIcon={<ModalXIcon />}
        className={"hidden-cards-drawer"}
        width={550}
      >
        <div>
          <List
            size="small"
            locale={{ emptyText: "Nuk ka karta te fshehura" }}
            header={
              <h4>
                <InfoIcon /> Klikoni butonat në cepin e djathtë për të rishfaqur
                kartat e fshehura....
              </h4>
            }
            bordered
            dataSource={
              drawerContent !== null
                ? drawerContent?.filter((el) => el?.data?.hidden === true)
                : null
            }
            renderItem={(item) => {
              return (
                <List.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "#F1F1F5",
                      padding: "5px 10px",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "19.07px",
                      borderRadius: "5px",
                    }}
                  >
                    {item?.data?.emri}{" "}
                    <span
                      style={{
                        background: "#2E5D9E",
                        height: "32px",
                        width: "32px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => onUnhide(item?.i)}
                    >
                      <VisibleIcon height={16} width={16} />
                    </span>
                  </div>
                </List.Item>
              );
            }}
          />
        </div>
      </Drawer>
    </div>
  );
}

export default HiddenDrawer;
