import { Alert } from "antd";

export const warnifyTax = (payStubObj) => {
	const bruto = payStubObj?.bruto?.totalBruto;
	if (bruto > 0 && bruto < 35000) {
		return (
			<Alert
				message="Fitimet e taksueshme perfshihen ne nivelin e 1 te taksueshem!"
				type="success"
				showIcon
			/>
		);
	} else if (bruto > 35000 && bruto < 40000) {
		return (
			<Alert
				message="Kujdes! Ju jeni afer nivelit tjeter te taksueshem! (mbi 40,000)"
				type="info"
				showIcon
			/>
		);
	} else if (bruto >= 40001 && bruto < 45000) {
		return <Alert message="Niveli i 2 i taksueshem! (40,001 - 45,000)" type="warning" showIcon />;
	} else if (bruto >= 45000 && bruto < 50000) {
		return (
			<Alert
				message="Kujdes! Ju jeni afer nivelit tjeter te taksueshem! (mbi 50,000)"
				type="error"
				showIcon
			/>
		);
	} else if (bruto > 50000) {
		return <Alert message="Niveli me i larte i taksimit! (mbi 50,000 ALL)" type="error" showIcon />;
	}
};
