import React from "react";
import KalkulatoriPages from "./KalkulatoriPages";

const PagatEksportetListes = () => {
  return (
    <>
      <div>
        <KalkulatoriPages />
      </div>
    </>
  );
};

export default PagatEksportetListes;
