import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input, Form, Select } from "antd";
import { API } from "aws-amplify";

const NewDepartmentForm = ({ getUsersList, context }) => {
  const { departments } = useSelector((state) => state.departments);
  const { Option } = Select;
  const { users } = useSelector((state) => state.usersList);
  const [allUsers, setAllUsers] = useState([]);

  function handleChange(value) {
    // console.log(`selected ${value}`);
  }

  //* gets employees from database and shows in department form

  useEffect(() => {
    if (users) {
      setAllUsers(users.allUsers.Items);
      getUsersList(users.allUsers.Items);
    }
  }, [users]);

  return (
    <div className="departmentForm">
      <Form.Item
        className="newDepartmentName"
        name="departmentName"
        colon
        label="Emri i departamentit"
        rules={[
          {
            required: true,
            message: "Emri departamentit është i detyrueshëm",
          },
          {
            validator: (_, value) =>
              !!value &&
              //* checks if the department exists and if yes return true
              departments.some(
                ({ departmentName: depName = "" }) =>
                  depName.toLowerCase() === value.toLowerCase()
              )
                ? Promise.reject(new Error("Ky departament ekziston tashmë"))
                : Promise.resolve(),
          },
        ]}
      >
        <Input
          className="input-title"
          placeholder="Shkruaj titullin"
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item
        className="profit"
        name="departmentManagerId"
        colon
        label="Supervizori"
        rules={[
          {
            required: true,
            message: "Supervizori është i detyrueshëm",
          },
        ]}
      >
        <Select
          defaultValue={context?.departmentManagerName}
          value={context?.departmentManagerName}
          className="select-data"
          placeholder="Selekto"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          allowClear
          style={{
            background: "#f1f7fb",
            width: 270,
            borderRadius: 5,
          }}
        >
          {allUsers?.map((user) => (
            //* map supervisor name and surname
            <Option value={user.identityId}>
              {user.given_name + " " + user.family_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className="profit"
        name="roles"
        colon
        label="Rolet"
        rules={[
          {
            required: true,
            message: "Rolet janë të detyrueshëm",
          },
        ]}
      >
        <Select
          className="select-data"
          placeholder="Selekto"
          mode="tags"
          onChange={handleChange}
          style={{
            background: "#f1f7fb",
            width: 270,
            borderRadius: 5,
          }}
        />
      </Form.Item>
    </div>
  );
};

export default NewDepartmentForm;
