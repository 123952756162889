import React, { createContext, useState, useEffect, useRef } from "react";
// import Approvals from "./approvals/Approvals";
// import { API } from "aws-amplify";
import moment from "moment-timezone";
import { IconExcel, IconNgargo } from "../../assets/icons";
import { Tooltip } from "antd";
import AgGridComponent from "../AG-grid/AgGridComponent";
// import ShiftModal from "../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
import { getMonthsOfYear } from "../punonjesit/EmployeeView/Payment/utils/calculations";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  calculateMonthAllPayment,
  getMonthlyAmounts,
  // calculateWagePerHours,
} from "./wages/utils/calculations";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import HtmlExelTabel from "../Konfigurimet/dynamic components/HtmlExelTabel";
import PagatGridHeader from "./wages/PagatGridHeader";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import { useMediaQuery } from "react-responsive";
import MobilePagatGrid from "./wages/MobilePagatGrid";
const PagatContext = createContext(null);

function PagatGrid() {
  // const { Option } = Select;
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const { clockings } = useSelector((state) => state.clockings);
  const { employees } = useSelector((state) => state.employeesList);
  const { wages } = useSelector((state) => state.wages);
  let navigate = useNavigate();
  // columns displayed in the grid
  const columnDefs = [
    {
      headerName: "Nr",
      field: "nr",
      checkboxSelection: true,
      suppressSizeToFit: true,
      maxWidth: 100,
    },
    {
      headerName: "Muaji",
      field: "muaji",
      cellRendererFramework: ({ data }) => (
        <div style={{ cursor: "pointer" }}>
          {moment(data.muaji).format("MMMM YYYY") !== "Invalid date" ? (
            <div variant="outlined" onClickCapture={() => handleMonthModal(data)}>
              <span style={{ color: "#1DA193", fontWeight: 600 }}>
                {moment(data.muaji).format("MMMM YYYY")}
              </span>
            </div>
          ) : (
            <span>{data.muaji}</span>
          )}
        </div>
      ),
    },
    {
      headerName: "Shuma Bruto",
      field: "bruto",
    },
    {
      headerName: "Shuma Neto",
      field: "neto",
    },

    {
      headerName: "Taksat e punedhenesit",
      field: "taxes",
    },
    {
      headerName: "Kostoja totale",
      field: "totalCost",
    },
  ];
  // calculateMonthAllPayment(employees, clockings, { data: { muaji: moment() } });

  // make the grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onBtExport = () => {
    gridApi.exportDataAsExcel();
  };

  const handleCellClick = (params) => {
    // console.log("Click", params);
  };

  const handleMonthModal = (data) => {
    return navigate("/pagat/llogaritja", { state: { data } });
  };

  useEffect(() => {
    if (!!wages) {
      let temp = [...getMonthsOfYear()] || [];
      if (temp.length > 0) {
        let appliedWages = wages.filter((el) => el?.wageStatus === "Finalized");
        temp.forEach((el) => {
          el.neto = getMonthlyAmounts("neto", appliedWages, el.muaji) || 0;
          el.bruto = getMonthlyAmounts("bruto", appliedWages, el.muaji) || 0;
          el.taxes = getMonthlyAmounts("taxes", appliedWages, el.muaji) || 0;
          el.totalCost = getMonthlyAmounts("totalCost", appliedWages, el.muaji) || 0;
        });
      }
      setRowData([...temp]);
    }
  }, [wages]);

  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  return (
    <>
      {isMobileView ? (
        <div style={{ marginTop: 20 }}>
          <div style={{ margin: 25 }}>
            <PagatGridHeader rowData={rowData} />
          </div>

          <div className="payment-grid">
            <div className="punonjesit">
              <div className="ag-grid-punojsit">
                <div className="ag-grid-header">
                  {
                    // <div className="header-icons">
                    // 	<IconFilter />
                    // 	<Tooltip
                    // 		placement="top"
                    // 		overlayClassName="global-icon-tooltip"
                    // 		title={"Importo ne eksel"}
                    // 	>
                    // 		<IconExcel onClick={() => onBtExport()} />
                    // 	</Tooltip>
                    // 	<Tooltip
                    // 		placement="top"
                    // 		overlayClassName="global-icon-tooltip"
                    // 		title={"Fshi një rekord"}
                    // 	>
                    // 		<DeleteIcon />
                    // 	</Tooltip>
                    // </div>
                  }

                  <div className="header-icons">
                    <ReactHtmlTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button"
                      table="Pagat-home-page"
                      filename="pagat"
                      sheet="pagat"
                      buttonText={
                        <Tooltip
                          placement="top"
                          overlayClassName="global-icon-tooltip"
                          title={"Eksporto në eksel"}
                        >
                          <IconExcel />
                        </Tooltip>
                      }
                    />{" "}
                    <ExportToPdf id={"#Pagat-home-page"} tabelName={"pagat"} />
                    <Tooltip
                      placement="top"
                      overlayClassName="global-icon-tooltip"
                      title={"Printo tabelën"}
                    >
                      <IconNgargo onClick={handlePrint} />
                    </Tooltip>
                  </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: 610, width: "100%" }}>
                  <AgGridComponent
                    rowData={rowData}
                    rowSelection="single"
                    onGridReady={onGridReady}
                    paginationPageSize={12}
                    columnDefs={columnDefs}
                    //   onRowValueChanged={onRowValueChanged}
                    // onSelectionChanged={handleSelectionChanged}
                    onCellClicked={handleCellClick}
                    onCell
                    serverSideStoreType={"full"}
                    enableCellChangeFlash={true}
                    rowModelType={"serverSide"}
                  />
                </div>
              </div>
              <HtmlExelTabel
                tableData={rowData?.map(({ muaji, neto, bruto }) => ({
                  muaji: moment(muaji).format("MMMM - YYYY"),
                  neto,
                  bruto,
                }))}
                header={["Muaji", "Shuma neto", "Shuma bruto"]}
                id="Pagat-home-page"
                componentRef={componentRef}
              />
            </div>
          </div>
        </div>
      ) : (
        <MobilePagatGrid rowData={rowData} />
      )}
    </>
  );
}

export default PagatGrid;
