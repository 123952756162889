import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PayStubTable from "../../../Pagat/PaySub/payStubTable/PayStubTable";
import "./ReportContent.scss";
import { useReactToPrint } from "react-to-print";
import moment from "moment-timezone";
import { Alert, Button, Card, Input, message, Skeleton, Tooltip } from "antd";
import ClockIn from "../../../dashboard/components/clockInOut/ClockIn";
import ReportTable from "./ReportTable";
import "./ReportTable.scss";
import { API } from "aws-amplify";
import { useLocation } from "react-router";
import { useAuth } from "../../../../authentication/authHelpers";
import HtmlExelTabel from "../../../Konfigurimet/dynamic components/HtmlExelTabel";
import {
  ReportContext,
  ReportProvider,
  useReportsData,
} from "../reportContext/reportHelpers";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { IconExcel } from "../../../../assets/icons";
import ExportToPdf from "../../../Konfigurimet/dynamic components/ExportToPdf";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { exportToCSV } from "./export";
const { TextArea } = Input;

function ReportContent({
  contentData,
  infos,
  page,
  secondLevelFilter,
  activeFilter,
  fetching,
}) {
  const { state } = useLocation();
  const { userAccess } = useAuth();
  const [loading, setLoading] = useState(true);
  const { reportData } = useReportsData();
  const [loadData, setLoadData] = useState(false);

  const [content, setContent] = useState({
    note: {
      enable: false,
      content: "",
      focus: false,
    },
    title: { text: "", enable: false },
    subTitle: { text: "", enable: false },
  });
  const componentRef = useRef("payStub-print");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: null,
    pageStyle: {
      background: "red",
    },
  });
  const excelRef = useRef("Print-Punojsit");
  const handleExcel = useReactToPrint({
    content: () => excelRef.current,
  });

  const handleUpdate = async () => {
    if (state && !!userAccess[0]) {
      const previousReports = await API.get("reportsLogs", `/reportsLogs`).then(
        (res) => res.filter((el) => el?.reportItem === state)
      );
      // console.log("PreviousReports", previousReports);
      if (previousReports.length > 0) {
        const previousReport = previousReports[0];
        if (previousReport.reportItem === state) {
          const updatedReport = await API.put(
            "reportsLogs",
            `/reportsLogs/${previousReport.reportId}`,
            {
              body: {
                createdBy: {
                  name: userAccess[0].given_name,
                  lastName: userAccess[0].family_name,
                  userSub: userAccess[0].userSub,
                  time: moment().valueOf(),
                },
              },
            }
          )
            .then(() => message.success("Report updated!"))
            .catch((err) => console.log("error update", err));
        }
      } else {
        await API.post("reportsLogs", "/reportsLogs", {
          body: {
            reportItem: state,
            createdBy: {
              name: userAccess[0].given_name,
              lastName: userAccess[0].family_name,
              userSub: userAccess[0].userSub,
              time: moment().valueOf(),
            },
          },
        }).then((res) => message.success("Report created!"));
      }
    }
  };

  const onPrint = () => {
    setContent((prev) => ({
      ...prev,
      title: { ...prev.title, enable: true },
      subTitle: { ...prev.subTitle, enable: true },
    }));
    setTimeout(() => {
      handlePrint();
      handleUpdate();
    }, 1000);
  };

  const excelData = useMemo(() => {
    let temp = reportData || [];

    if (
      temp?.length > 0 &&
      !!content?.note?.content &&
      content?.note?.content !== ""
    ) {
      let newElements = [];
      let newElement = {};
      Object.keys(temp[0]).forEach((colName) => {
        newElement[colName] = "";
      });
      newElements.push(newElement);
      newElements.push(newElement);
      newElements.push({ Note: "Shenimi" });
      newElements.push({ Note: content?.note?.content?.toString() });

      return [...reportData, ...newElements];
    } else {
      return reportData;
    }
  }, [reportData, content]);

  useLayoutEffect(() => {
    console.log("Active", activeFilter);
    if (!!page && !!infos) {
      let desired = infos[page] || null;
      if (!!desired) {
        setContent((prev) => ({
          ...prev,
          title: { ...prev.title, text: desired?.title || "" },
          subTitle: {
            ...prev.subTitle,
            text:
              desired?.subTitle +
                ` (${
                  activeFilter?.timeInterval[0]
                    ? moment(activeFilter?.timeInterval[0])?.format(
                        "DD/MM/YYYY"
                      )
                    : ""
                } - ${
                  activeFilter?.timeInterval[1]
                    ? moment(activeFilter?.timeInterval[1])?.format(
                        "DD/MM/YYYY"
                      )
                    : "NUK KENI ZGJEDHUR INTERVAL - "
                })` || "",
          },
          note: { ...prev.note, text: desired?.note || "" },
        }));
      }
    }
  }, [page, infos, activeFilter]);

  useEffect(() => {
    if (!!contentData) {
      if (loading === true) {
        setLoading(false);
      } else {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
    return () => {
      setLoading(true);
    };
  }, [contentData]);
  console.log("Content Data:", contentData);
  return (
    <div className="report-wrapper">
      <Card
        className="card-report-header"
        title={`${content?.title?.text} / ${content?.subTitle?.text}`}
        bordered={false}
        style={{
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 20,
          width: "100%",
          height: 700,
        }}
      >
        <div className="report" ref={componentRef}>
          <div className="report-tools">
            <div className="leftDiv">
              <Button
                className="write-note"
                onClick={() =>
                  setContent((prev) => ({
                    ...prev,
                    note: {
                      ...prev.note,
                      enable: !prev.note.enable,
                      focus: false,
                    },
                  }))
                }
              >
                {content?.note?.content !== ""
                  ? "Ndrysho shënimin"
                  : "Shkruaj shënim"}
              </Button>
              <Button
                className="Fshij-shenim"
                onClick={() =>
                  setContent((prev) => ({
                    ...prev,
                    note: {
                      ...prev.note,
                      enable: !prev.note.enable,
                      focus: false,
                      content: "",
                    },
                  }))
                }
              >
                Fshij shënimin
              </Button>
              {/* <span>Shkruaj titull</span>
						<span>Shkruaj Nentitull</span> */}
              <Button
                style={{ width: 140, marginLeft: 320 }}
                className="write-note"
                onClick={() =>
                  setContent((prev) => ({
                    ...prev,
                    title: { ...prev.title, enable: false },
                  }))
                }
              >
                Ndrysho titullin
              </Button>
              <Button
                className="write-note"
                onClick={() =>
                  setContent((prev) => ({
                    ...prev,
                    subTitle: { ...prev.subTitle, enable: false },
                  }))
                }
                style={{ width: 150 }}
              >
                Ndrysho nëntitullin
              </Button>
            </div>
            <div className="rightDiv">
              <Button className="print" onClick={onPrint}>
                Printo raportin
              </Button>

              {state === "approvals" || state === "supervisorApprovals" ? (
                <>
                  <Button
                    className="print"
                    onClick={() =>
                      exportToCSV(
                        reportData,
                        "Listë_Prezencat_",
                        activeFilter?.timeInterval,
                        content?.note?.content
                      )
                    }
                    style={{ background: "#009FB7", marginLeft: "20px" }}
                  >
                    Excel
                  </Button>
                  <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="Aprovimet-homePage"
                    filename={
                      !!infos && page ? `${infos[page].title}` : "FileExcelHR"
                    }
                    sheet={
                      !!infos && page ? `${infos[page].title}` : "FileExcelHR"
                    }
                    buttonText={
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={"Eksporto në Excel"}
                      >
                        <Button
                          className="print"
                          style={{ background: "#009FB7" }}
                          onClick={() => {
                            setLoadData((prev) => !prev);
                          }}
                        >
                          Excel Merged
                        </Button>
                      </Tooltip>
                    }
                  />
                </>
              ) : (
                <ReactHtmlTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="Aprovimet-homePage"
                  filename={
                    !!infos && page ? `${infos[page].title}` : "FileExcelHR"
                  }
                  sheet={
                    !!infos && page ? `${infos[page].title}` : "FileExcelHR"
                  }
                  buttonText={
                    <Tooltip
                      placement="top"
                      overlayClassName="global-icon-tooltip"
                      title={"Eksporto në Excel"}
                    >
                      <Button
                        className="print"
                        style={{ background: "#009FB7" }}
                        onClick={() => {
                          setLoadData((prev) => !prev);
                        }}
                      >
                        Excel
                      </Button>
                    </Tooltip>
                  }
                />
              )}
              {/* <ExportToPdf id={"#Aprovimet-homePage"} tabelName={"aprovimet"} /> */}
            </div>
          </div>
          {!loading ? (
            <div className="report-body">
              <div className="report-title">
                {" "}
                <div className="input-holder">
                  {!content.title.enable && (
                    <Input
                      autoFocus={true}
                      style={{
                        width: 600,
                        background: "#F5F5F7",
                        borderRadius: 5,
                        border: 0,
                      }}
                      value={content.title.text}
                      onBlur={(e) =>
                        setContent((prev) => ({
                          ...prev,
                          title: { ...prev.title, enable: true },
                        }))
                      }
                      placeholder="Sheno titullin..."
                      onChange={(e) =>
                        setContent((prev) => ({
                          ...prev,
                          title: { ...prev.title, text: e.target.value },
                        }))
                      }
                    />
                  )}
                  {content?.title?.enable && (
                    <span
                      onClick={() =>
                        setContent((prev) => ({
                          ...prev,
                          title: { ...prev.title, enable: false },
                        }))
                      }
                    >
                      <h1>{content.title.text}</h1>
                    </span>
                  )}
                </div>
                <div className="input-holder">
                  {!content.subTitle.enable && (
                    <Input
                      autoFocus={true}
                      value={content.subTitle.text}
                      style={{
                        width: 400,
                        background: "#F5F5F7",
                        borderRadius: 5,
                        border: 0,
                      }}
                      onBlur={() =>
                        setContent((prev) => ({
                          ...prev,
                          subTitle: { ...prev.subTitle, enable: true },
                        }))
                      }
                      placeholder="Sheno nentitullin..."
                      onChange={(e) =>
                        setContent((prev) => ({
                          ...prev,
                          subTitle: { ...prev.subTitle, text: e.target.value },
                        }))
                      }
                    />
                  )}
                  {content?.subTitle?.enable && (
                    <span
                      onClick={() =>
                        setContent((prev) => ({
                          ...prev,
                          subTitle: { ...prev.subTitle, enable: false },
                        }))
                      }
                    >
                      <h5>{content.subTitle.text}</h5>
                    </span>
                  )}
                </div>
              </div>
              <div>
                <ReportTable
                  columnsDef={contentData?.columnsDef || []}
                  rowData={
                    !!secondLevelFilter
                      ? secondLevelFilter.rowData || []
                      : contentData.rowData
                  }
                />
                <div className="page-number"></div>

                {contentData?.rowData?.length === 0 && (
                  <div>
                    <br />
                    <Alert
                      type="warning"
                      showIcon
                      description={
                        <span>
                          Per raportin e perzgjedhur, nuk ka asnje te dhene!
                          Ndryshoni filtrat dhe gjeneroni serish!
                        </span>
                      }
                    />
                  </div>
                )}
              </div>
              <div className="report-notes">
                {" "}
                <br />
                {content.note.enable && !content.note.focus && (
                  <TextArea
                    autoFocus={true}
                    onBlur={() =>
                      setContent((prev) => ({
                        ...prev,
                        note: { ...prev.note, focus: true },
                      }))
                    }
                    // status={(e) => console.log(e)}
                    value={content.note.content}
                    onChange={(e) =>
                      setContent((prev) => ({
                        ...prev,
                        note: { ...prev.note, content: e.target.value },
                      }))
                    }
                    placeholder="Shkruaj nje shenim"
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  />
                )}
                {content.note.enable && content.note.focus && (
                  <span
                    onClick={() =>
                      setContent((prev) => ({
                        ...prev,
                        note: { ...prev.note, focus: false },
                      }))
                    }
                  >
                    {content.note.content}
                  </span>
                )}
              </div>{" "}
              <div className="reports-footer">
                {" "}
                <span style={{ fontStyle: "italic" }}>
                  Koha e gjenerimit: {moment().format("DD/MM/YYYY HH:mm:ss")}
                </span>
              </div>
            </div>
          ) : (
            <div className="report-body">
              {" "}
              <Skeleton /> <Skeleton /> <Skeleton /> <Skeleton /> <Skeleton />{" "}
              <Skeleton /> <Skeleton />{" "}
            </div>
          )}
        </div>{" "}
        <HtmlExelTabel
          tableData={excelData}
          loadData={loadData}
          header={!!reportData?.length > 0 ? Object.keys(reportData[0]) : []}
          // header={[
          // 	"Statusi",
          // 	"Emri",
          // 	"Mbiemri",
          // 	"Departamenti",
          // 	"Data",
          // 	"Fillimi i turnit",
          // 	"Mbarimi i turnit",
          // 	"Orët totale",
          // ]}
          id="Aprovimet-homePage"
          componentRef={excelRef}
        />
      </Card>
    </div>
  );
}

export default ReportContent;

const getExcelData = (reportData) => {
  console.log("report data", reportData);
  return reportData;
};
