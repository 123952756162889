export const sortObjectKeys = (obj, order) => {
  const sortedKeys = Object.getOwnPropertyNames(obj).sort((a, b) =>
    order === "asc" ? a.localeCompare(b) : b.localeCompare(a)
  );
  let sortedObject = {};
  sortedKeys.map((key) => {
    sortedObject[key] = obj[key];
  });
  return sortedObject;
};
