import React, { useEffect, useState, useRef } from "react";
import {
  // IconExcel,
  IconKerko,
  // IconVeprime,
  IconNgargo,
  IconPunonjesit,
  // DeleteIcon,
  IconExcel,
} from "../../../../assets/icons";
import { CaretDownFilled } from "@ant-design/icons";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { API, Auth } from "aws-amplify";
import { Input, Button, Tooltip, Select, Form, message, Row, Col } from "antd";
import moment from "moment-timezone";
import { useReactToPrint } from "react-to-print";
import "./siguria_perdoruesit.scss";
import SiguriaPerdoruesitModal from "./SiguriaPerdoruesitModal";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import SiguriaPerdoruesitFilterModal from "./SiguriaPerdoruesitFilterModal";
import PritTablePerdoruesit from "./PritTablePerdoruesit";
import { useNavigate } from "react-router-dom";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import ExportToPdf from "../../dynamic components/ExportToPdf";
import { useMediaQuery } from "react-responsive";
import MobileSiguriaPerdoruesit from "./MobileSiguriaPerdoruesit";
import { useSelector } from "react-redux";

function SiguriaPerdoruesit() {
  const navigate = useNavigate();
  // columnDefs for the agGrid
  const columnDefs = [
    {
      headerName: "Nr.",
      field: "identityId",
      // checkboxSelection: true,
      flex: 1.2,
      valueGetter: "node.rowIndex + 1",
    },
    {
      headerName: "Emri",
      field: "given_name",
      filter: "agTextColumnFilter",
      flex: 2,
      cellRendererFramework: function (params) {
        let keyData = params.data.given_name;
        return (
          <div
            className="navigate-row"
            onClick={() =>
              navigate("/konfigurimet/siguria/rolet/siguris", {
                state: params.data,
              })
            }
          >
            {keyData}
          </div>
        );
      },
    },
    {
      headerName: "Mbiemri",
      field: "family_name",
      filter: "agTextColumnFilter",
      flex: 2,
      cellRendererFramework: function (params) {
        let keyData = params.data.family_name;
        return (
          <div
            className="navigate-row"
            onClick={() =>
              navigate("/konfigurimet/siguria/rolet/siguris", {
                state: params.data,
              })
            }
          >
            {keyData}
          </div>
        );
      },
    },
    {
      headerName: "Gjinia",
      field: "gender",
      filter: "agTextColumnFilter",
      flex: 2,
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter",
      flex: 2,
    },
    {
      headerName: "Lloji i userit",
      field: "userRole",
      filter: "agTextColumnFilter",
      flex: 2,
    },
    {
      headerName: "Departamenti",
      field: "department",
      filter: "agTextColumnFilter",
      flex: 3,
    },
    {
      headerName: "Pozicioni",
      field: "departmentRole",
      filter: "agTextColumnFilter",
      flex: 2,
    },
    {
      headerName: "Nr.Tel",
      field: "phone_number",
      filter: "agTextColumnFilter",
      flex: 2,
    },
    {
      headerName: "Krijuar",
      field: "createdAt",
      filter: "agTextColumnFilter",
      flex: 2,
      cellRenderer: (params) => {
        try {
          return moment(params.value).format("DD/MM/YYYY");
        } catch (err) {
          console.error("createdAt--err");
        }
      },
    },
  ];

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [user, setUser] = useState();
  const [selected, setSelected] = useState(null);
  const [agGridSearchInput, setAgGridSearchInput] = useState(null);
  const { Option } = Select;
  const { users } = useSelector((state) => state.usersList);

  // console.log(tableData);

  // making ag grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  // get data when page is renderd
  useEffect(() => {
    if (!!users) {
      setTableData(users?.allUsers.Items || []);
    }
  }, []);

  // state for email and verification code
  const [konfig, setKonfig] = useState({
    email: "",
    verifyCode: "",
  });

  // change the state of email and verification code
  const HandleChange = (objField, e) => {
    const value = objField;
    setKonfig((prev) => ({ ...prev, [value]: e }));
  };

  // change the state of data with the new value
  const onChange = (objField, e) => {
    const value = objField;
    setData((prev) => ({ ...prev, [value]: e }));
  };

  // state to  add new data to grid
  const [data, setData] = useState({
    // identityId: uuidv4(),
    given_name: "",
    family_name: "",
    gender: "",
    email: "",
    department: "",
    userRole: "",
    departmentRole: "",
    userSub: "",
    phone_number: "",
    // createdAt: moment(),
  });

  // close the modal and clean the form
  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setUser(null);
  };

  // ag grid global search
  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
    setAgGridSearchInput(e.target.value);
  };

  // clear filter ag grid
  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setQuickFilter(null);
    setSelected(null);
    setAgGridSearchInput(null);
  };

  // delete items from grid
  // const deleteItems = () => {
  //   const selectData = gridApi.getSelectedNodes();
  //   let selectedData = selectData.map((node) => node.data.identityId);
  //   let selecData = selectData.map((node) => node.data);
  //   gridApi.applyTransaction({
  //     remove: selecData,
  //   });
  //   API.del("users", `/users/${selectedData}`).then(() => {
  //     setTableData((prev) =>
  //       prev.filter((item) => item.identityId !== data.identityId)
  //     );
  //   });
  // };

  // submit the form
  const formSubmit = async () => {
    await Auth.signUp({
      username: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      attributes: {
        phone_number:
          "+" +
          form
            .getFieldValue("phone_number")
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", ""),
        family_name: form.getFieldValue("family_name"),
        given_name: form.getFieldValue("given_name"),
        gender: form.getFieldValue("gender"),
        "custom:department": form.getFieldValue("department"),
        "custom:userRole": form.getFieldValue("userRole"),
        "custom:role": form.getFieldValue("departmentRole"),
      },
    })
      .then((res) => {
        // console.log({ res });
        setData((prev) => ({ ...prev, sub: res.userSub }));
        setUser(res);
      })
      .catch((err) => console.log(err));
  };

  /*** confirm user with email pascode  */
  const userConfig = () => {
    Auth.confirmSignUp(form.getFieldValue("email"), form.getFieldValue("verifyCode")).then(
      (res) => {
        if (res === "SUCCESS") {
          message.success("Krijimi i nje useri u bë me sukses").then((r) => onClose());
        }
      }
    );
  };

  // function handleMenuClick(e) {
  //   console.log("click", e);
  // }

  // dropdown search for department
  const departmentFilter = (e) => {
    var departmentFilterComponent = gridApi.getFilterInstance("department");
    departmentFilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
    setSelected(e);
  };

  // check for duplicate employe department name
  const duplicateCheck = [];

  tableData &&
    tableData
      ?.map((data) => {
        if (duplicateCheck.includes(data.department)) return null;
        duplicateCheck.push(data.department);
        return data;
      })
      ?.filter((e) => e);

  //print fille
  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  return (
    <LoadableComp loading={!!tableData ? false : true}>
      {isMobileView ? (
        <div className="perdoruesit">
          <div className="ag-grid-punojsit">
            <div className="ag-grid-header">
              <Row className="harder-row" style={{ width: "100%" }}>
                <Col flex={4} style={{ display: "flex" }}>
                  <div className="header-search">
                    <div className="icon-search-header">
                      <IconKerko width={15} height={15} />
                    </div>
                    <Input
                      placeholder="Kërko një rekord"
                      onChange={onFilterTextChange}
                      value={agGridSearchInput}
                      style={{ width: 180 }}
                    />
                    <Button className="pastro-btn" onClick={clearFilters}>
                      Pastro
                    </Button>
                  </div>
                  <div className="punojsit-div">
                    <span className="icon-punojsit">
                      <IconPunonjesit width={15} />
                    </span>
                    <Select
                      className="punojsit-dropdown"
                      onChange={departmentFilter}
                      value={selected}
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Të gjithë"
                      style={{ width: 220 }}
                    >
                      {duplicateCheck.map((Data) => (
                        <Option key={Data} value={Data}>
                          {Data}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="header-icons">
                    <SiguriaPerdoruesitFilterModal
                      tableData={tableData}
                      duplicateCheck={duplicateCheck}
                      gridApi={gridApi}
                      clearFilters={clearFilters}
                    />

                    <ReactHtmlTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button"
                      table="table-to-perdoruesit"
                      filename="perdoruesit"
                      sheet="perdoruest"
                      buttonText={
                        <Tooltip
                          placement="top"
                          overlayClassName="global-icon-tooltip"
                          title={"Eksporto në eksel"}
                        >
                          <IconExcel />
                        </Tooltip>
                      }
                    />
                    <ExportToPdf id={"#table-to-perdoruesit"} tabelName={"perdoruesit"} />
                    <Tooltip
                      placement="top"
                      overlayClassName="global-icon-tooltip"
                      title={"Printo tabelën"}
                    >
                      <IconNgargo onClick={handlePrint} />
                    </Tooltip>

                    {/* <Tooltip
                    placement="top"
                    overlayClassName="global-icon-tooltip"
                    title={"Fshi një rekord"}
                    >
                    <DeleteIcon
                    onClick={deleteItems}
                    style={{ marginLeft: 10 }}
                    />
                  </Tooltip> */}
                  </div>
                </Col>
                <Col
                  flex={1}
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    marginRight: 20,
                  }}
                >
                  <Button className="perdorues-te-ri-btn" onClick={() => setVisible(true)}>
                    Krijo përdorues
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="ag-theme-alpine" style={{ height: 771, width: "100%" }}>
              <AgGridComponent
                rowData={tableData}
                rowSelection="single"
                onGridReady={onGridReady}
                paginationPageSize={16}
                columnDefs={columnDefs}
                serverSideStoreType={"full"}
                // enableCellChangeFlash={true}
                rowModelType={"serverSide"}
              />
            </div>
          </div>
          {/* <ExportToExel /> */}
          {/* table to print component */}
          <PritTablePerdoruesit tableData={rowData} componentRef={componentRef} />
        </div>
      ) : (
        <MobileSiguriaPerdoruesit tableData={tableData} setVisible={setVisible} />
      )}
      <SiguriaPerdoruesitModal
        formSubmit={formSubmit}
        userConfig={userConfig}
        user={user}
        onClose={onClose}
        form={form}
        onChange={onChange}
        HandleChange={HandleChange}
        visible={visible}
        setVisible={setVisible}
        tableData={tableData}
        setData={setData}
      />
    </LoadableComp>
  );
}

export default SiguriaPerdoruesit;
