import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../../authentication/authHelpers";
import UserWageHistory from "../../../dashboard/UserDashboard/UserWageHistory";
import PaymentGrid from "../../../punonjesit/EmployeeView/Payment/PaymentGrid";
import UserActionCards from "../../../punonjesit/EmployeeView/Personal/User/UserActionCards";
import WageGrid from "./WageGrid";
import LoadableComp from "./../../../../components/commonComponents/LoadableComp/LoadableComp";
import WageInfo from "./WageInfo";
import { useSelector } from "react-redux";
import {
  BluredCard,
  ComponentCard,
} from "../../../dashboard/AdminDashboard/Draggable/ComponentCard";
import { useMediaQuery } from "react-responsive";
import EmployeeWagesMobileView from "./EmployeeWagesMobileView";

function EmployeeWageView() {
  const { employeeId } = useAuth();
  const [employeesData, setEmployeesData] = useState(null);
  const { employees } = useSelector((state) => state.employeesList);

  useEffect(() => {
    if (!!employeeId && employees) {
      let found = employees.find((el) => el?.employeeId === employeeId);
      if (!!found) {
        // console.log("Found", found);
        setEmployeesData(found);
      }
    }
  }, [employeeId, employees]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 821px)",
  });

  return (
    <LoadableComp loading={employeeId === null ? true : false}>
      {isDesktopOrLaptop ? (
        <div className="pay">
          {!!employeeId && (
            <Row
              className="paymet-row-grid"
              gutter={16}
              style={{ width: "100%", paddingTop: 20 }}
            >
              <Col
                span={6}
                style={{ paddingRight: 0, width: "100%", height: "425px" }}
              >
                <div
                  className="position-item"
                  style={{
                    width: 385,
                    marginLeft: 20,
                    height: 425,
                    marginTop: 0,
                  }}
                >
                  {/* {<EmployeeWageCard />} */}
                  <WageInfo
                    showPaymentInfo={true}
                    employeesData={employeesData}
                  />
                  {/* <WageInfo showPaymentInfo={true} employeesData={employeesData} /> */}
                  <UserActionCards
                    contentChild={
                      <ComponentCard
                        blurDuration={30}
                        height={425}
                        width={"100%"}
                        blur={true}
                        content={<UserWageHistory employee={employeeId} />}
                      />
                    }
                    headerName={"Historiku Pagat"}
                  />
                </div>
              </Col>

              <Col
                style={{
                  marginLeft: 30,
                  width: 1200,
                  paddingTop: 0,
                  marginBottom: 20,
                }}
                className="col-for-grid"
              >
                {/* <PaymentGrid style={{ width: "100%", paddingTop: 0 }} /> */}
                <WageGrid employee={employeeId} />
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <EmployeeWagesMobileView
          employeeId={employeeId}
          employeesData={employeesData}
        />
      )}
    </LoadableComp>
  );
}

export default EmployeeWageView;
