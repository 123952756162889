import types from "../types/types";
const initialState = {
  employeeRequests: undefined,
};

const employeeRequestsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_EMPLOYEE_REQUESTS:
      return {
        ...state,
        employeeRequests: payload,
      };
    default:
      return state;
  }
};

export default employeeRequestsReducer;
