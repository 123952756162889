import React, { useState } from "react";
import { Row, Col, Button, Modal, Select, Tooltip } from "antd";
import { IconFilter } from "../../../../assets/icons";
import { CaretDownFilled } from "@ant-design/icons";
import { htmlParser } from "../../../../utils";

function FilterPaisjetOrarit({
  gridApi,
  tableData,
  duplicateCheck,
  formuladuplicateCheck,
  descriptionduplicateCheck,
  clearFilters,
}) {
  const { Option } = Select;
  const [visibleFilter, setVisibleFilter] = useState(false);
  function closeFilter() {
    clearFilters();
    setVisibleFilter(false);
  }
  const firstFilter = (e) => {
    var emriFilterComponent = gridApi.getFilterInstance("name");
    emriFilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };

  const secondFilter = (e) => {
    var mbiemriFilterComponent = gridApi.getFilterInstance("description");
    mbiemriFilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };
  const thirdFilter = (e) => {
    var departamentiFilterComponent = gridApi.getFilterInstance("formula");
    departamentiFilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };

  return (
    <div>
      <Tooltip
        placement="top"
        overlayClassName="global-icon-tooltip"
        title={"Filter i avancuar"}
        style={{ opacity: 1.0 }}
      >
        <IconFilter
          className="header-icons"
          onClick={() => setVisibleFilter(true)}
        />{" "}
      </Tooltip>
      <Modal
        className="global-filter-modal"
        title="Kërkim i Avancuar"
        destroyOnClose
        open={visibleFilter}
        onOk={() => setVisibleFilter(false)}
        onCancel={() => setVisibleFilter(false)}
        // destroyOnClose={true}
        footer={[
          <Button onClick={closeFilter} className="charge-close-btn">
            Mbyll
          </Button>,
          <Button
            onClick={() => setVisibleFilter(false)}
            className="charge-search-btn"
          >
            Kërko
          </Button>,
        ]}
      >
        <Row gutter={10} style={{ width: "100%" }}>
          <Col className="coln-2" span={8}>
            <h3>Emri i politikes:</h3>
            <Select
              showSearch
              bordered={false}
              className="filter-dropdown-global"
              style={{
                background: "#F5F5F7",
                border: 0,
                borderRadius: 5,
                width: "100%",
              }}
              suffixIcon={<CaretDownFilled />}
              placeholder="Emri"
              onChange={firstFilter}
            >
              {duplicateCheck?.map((Data) =>
                Data === "" ? (
                  Data
                ) : (
                  <Option key={Data} value={Data}>
                    {Data}
                  </Option>
                )
              )}
            </Select>
          </Col>

          <Col className="coln-1" span={8}>
            <h3>Përshkrimi i politikes:</h3>
            <Select
              showSearch
              bordered={false}
              className="filter-dropdown-global"
              style={{
                background: "#F5F5F7",
                border: 0,
                borderRadius: 5,
                width: "100%",
              }}
              suffixIcon={<CaretDownFilled />}
              placeholder="Përshkrimin"
              onChange={secondFilter}
            >
              {descriptionduplicateCheck?.map((Data) =>
                Data === "" ? (
                  Data
                ) : (
                  <Option key={Data} value={Data}>
                    {htmlParser(Data)}
                  </Option>
                )
              )}
            </Select>
          </Col>

          <Col className="coln-3" span={8}>
            <h3>Formula:</h3>
            <Select
              showSearch
              bordered={false}
              className="filter-dropdown-global"
              style={{
                background: "#F5F5F7",
                border: 0,
                borderRadius: 5,
                width: "100%",
              }}
              suffixIcon={<CaretDownFilled />}
              placeholder="Formula"
              onChange={thirdFilter}
            >
              {formuladuplicateCheck?.map((Data) =>
                Data === "" ? (
                  Data
                ) : (
                  <Option key={Data} value={Data}>
                    {Data}
                  </Option>
                )
              )}
            </Select>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default FilterPaisjetOrarit;
