import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";
import { Button, Input, Form, message, Skeleton } from "antd";
import { RichTextEditor } from "../../../commonComponents";
import { IconI } from "../../../../assets/icons";
import { useAuth } from "../../../../authentication/authHelpers";

function LlojKerkesave({ fields, tableData, setTableData, setFields, data, setData }) {
  const [requests, setRequests] = useState([]);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  let auth = useAuth();

  const username = {
    userId: auth.userAccess[0].userSub,
    employeeFirstName: auth.userAccess[0].given_name,
    employeeLastname: auth.userAccess[0].family_name,
  };
  const showMessage = () => {
    message.success("Lloji i kerkeses u shtua!");
  };
  const handleSave = () => {
    let finalResult = {
      statusId: uuidv4(),
      statusDescription: form.getFieldValue("statusDescription"),
      statusTitle: form.getFieldValue("statusTitle"),
      statusName: fields,
      statusCreator:
        auth?.userAccess[0]?.given_name +
        " " +
        auth?.userAccess[0]?.family_name,
      statusCreationDate: moment().valueOf(),
    };

    let logs = data.fieldOptions.find(
      (item) => item?.name === "Logs"
    );
    let newLogsValue = {
      id: uuidv4(),
      activity: "U shtua",
      author: username,
      changeDate: Date.now(),
      currentData: finalResult,
    };
    if (logs) {
      logs?.value?.push(newLogsValue);
    } else {
      logs = {
        id: uuidv4(),
        name: "Logs",
        value: [newLogsValue],
      };
    }
    setLoading(true);
    API.put(
      "programFields",
      "/programFields/e480aa5f-a671-4781-a8bb-89876006cc90",
      {
        body: {
          fieldOptions: [...requests.fieldOptions, finalResult, logs],
        },
      }
    )
      .then(() => {
        // if (form.getFieldsError().map)
        setLoading(false);
        message.success("Kerkesa u dergua me sukses!", 10);
        setData({ ...data, fieldOptions: [...tableData, finalResult, logs,] })
        setTableData([...tableData, finalResult]);
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
        message.error("Ndodhi nje gabim!");
      });
    let field = [...requests.fieldOptions, finalResult];
    setRequests((prev) => ({ ...prev, fieldOptions: field, ...fields }));
    setFields([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fieldsData = await API.get(
        "programFields",
        "/programFields/e480aa5f-a671-4781-a8bb-89876006cc90"
      );
      setRequests(fieldsData);

      // setData(fieldsData.fieldOptions);
    };
    fetchData();
  }, []);

  return (
    <div className="llojet-kerkesave">
      {!loading ? (
        <Form autocomplete="off" onFinish={handleSave} form={form}>
          <h3>Shkruaj llojin e kërkesës:</h3>
          <div className="quill-div">
            <Form.Item
              name="statusTitle"
              rules={[
                {
                  // pattern: /^[a-zA-Z]+$/,
                  required: true,
                  message: "Kjo fushe nuk mund te jete bosh!",
                },
              ]}
            >
              <Input
                style={{
                  background: "#F5F5F7",
                  border: 0,
                  borderRadius: 5,
                  width: 214,
                }}
                placeholder="Shkruaj llojin e kërkesës"
              />
            </Form.Item>
            <span className="llojet-input-icon">
              <IconI width={13} height={13} />
            </span>
          </div>

          <div className="label-quill-div">
            <h3>Përshkrimi i llojit të kërkesës:</h3>
            <div className="quill-div">
              <Form.Item
                name="statusDescription"
                rules={[
                  {
                    required: true,
                    message: "Kjo fushe nuk mund te jete bosh!",
                  },
                ]}
              >
                <RichTextEditor className="lloj-kerkesash-quill" />
              </Form.Item>
              <span className="llojet-input-icon">
                <IconI width={13} height={13} />
              </span>
            </div>
          </div>
          <Form.Item>
            <Button
              className="shto-llojn-btn"
              type="primary"
              htmlType="submit"
              onClick={showMessage}
            >
              Shto llojin e kërkesës
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Skeleton />
      )}
    </div>
  );
}

export default LlojKerkesave;
