import React, { useMemo, useState } from "react";
import "./HtmlMobileTabel.scss";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "antd";
import { XIcon } from "../../assets/icons";

function MobileHtmlTabel({ tableData, header, isLink, isHeader }) {
  // html tabel given the data dinamicly body its tabelData and header array with string , and unique id , componentRef its userd to print the tabel
  // removeId it is the field of id that i hide form table
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const location = useLocation();
  const path = location?.pathname;
  const link = path.split("/")[1];
  const navigate = useNavigate();

  const getLinkForMobileTable = (path, id) => {
    switch (link) {
      case "punonjesit":
        return `/punonjesit/specifikat`;
      case "konfigurimet":
        return `/konfigurimet/siguria/rolet/siguris`;
      case "pagat":
        return `/pagat/llogaritja`;
      default:
        return `${path}/${id}`;
    }
  };

  const filter = useMemo(() => {
    let data = tableData?.filter?.(
      ({ statusHeaderKey }) => statusHeaderKey === status
    );
    setSearchTerm(data);
  }, [status]);

  const filterdStatus = tableData?.map?.(
    ({ statusHeaderKey }) => statusHeaderKey
  );

  const Status = filterdStatus?.filter?.(
    (type, index) => filterdStatus?.indexOf(type) === index
  );

  // console.log(filtered);

  const onSearchCard = (e) => {
    let substring = e?.target?.value;
    let filteredData = tableData?.filter?.((entry) =>
      Object?.values(entry)?.some(
        (val) => typeof val === "string" && val?.toLowerCase()?.match(substring)
      )
    );
    setInputValue(substring);
    setSearchTerm(filteredData);
    // setStatus(" ");
    substring !== "" ? setFilterActive(true) : setFilterActive(false);
  };

  const clearData = () => {
    setStatus("");
    setFilterActive(false);
    setInputValue("");
  };

  return (
    <>
      {!!isHeader ? (
        <div className="main-header-div">
          <div className="search-div">
            {" "}
            <Input
              placeholder="Kërko një rekord"
              value={inputValue}
              bordered={false}
              onChange={onSearchCard}
              style={{ width: "100%", background: "#f1f7fb", borderRadius: 5 }}
            />
            <div onClick={() => clearData()} className="clear-btn">
              <XIcon />
            </div>
          </div>
          {Status?.map?.((el) => (
            <div
              className={status === el ? "item-header-active" : "item-header"}
              onClick={() => setStatus(el)}
              key={el}
            >
              {el}
            </div>
          ))}
        </div>
      ) : null}

      {tableData?.length > 0 ? (
        <div className="Background-mobile-tabel">
          <Table>
            <Thead>
              <Tr>
                {header?.map((el) => (
                  <Th>{el}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {status !== "" || filterActive === true
                ? searchTerm?.map?.((el) =>
                    !!isLink ? (
                      <div
                        onClick={() =>
                          navigate(getLinkForMobileTable(), {
                            state: link === "pagat" ? { data: el } : el,
                          })
                        }
                      >
                        <Tr key={el.id}>
                          {Object.entries(el)?.map(([key, value]) => {
                            if (
                              key !== "employeeId" &&
                              key !== "accessConfig" &&
                              key !== "identityId"
                            ) {
                              return <Td key={el.linkId}>{value}</Td>;
                            }
                          })}
                        </Tr>
                      </div>
                    ) : (
                      <Tr key={el.id}>
                        {Object.entries(el)?.map(([key, value]) => {
                          if (key !== "linkId") {
                            return <Td key={el.linkId}>{value}</Td>;
                          }
                        })}
                      </Tr>
                    )
                  )
                : tableData?.map?.((el) =>
                    !!isLink ? (
                      <div
                        onClick={() =>
                          navigate(getLinkForMobileTable(), {
                            state: link === "pagat" ? { data: el } : el,
                          })
                        }
                      >
                        <Tr key={el.id}>
                          {Object.entries(el)?.map(([key, value]) => {
                            if (
                              key !== "employeeId" &&
                              key !== "accessConfig" &&
                              key !== "identityId"
                            ) {
                              return <Td key={el.linkId}>{value}</Td>;
                            }
                          })}
                        </Tr>
                      </div>
                    ) : (
                      <Tr key={el.id}>
                        {Object.entries(el)?.map(([key, value]) => {
                          if (key !== "linkId") {
                            return <Td key={el.linkId}>{value}</Td>;
                          }
                        })}
                      </Tr>
                    )
                  )}
            </Tbody>
          </Table>
        </div>
      ) : (
        <div className="Background-mobile-tabel-noData">
          <b>There is no data</b>
        </div>
      )}
    </>
  );
}

export default MobileHtmlTabel;
