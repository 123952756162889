import React from "react";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";

const QuillEditor = ({
  value,
  placeholder,
  classNameStyle,
  onChange,
  readOnly,
}) => {
  const handleChange = (value) => {
    if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
      value = "";
    }

    const santitizedValue = DOMPurify.sanitize(value);
    onChange(santitizedValue);
  };

  return (
    <ReactQuill
      value={value || ""}
      theme="snow"
      readOnly={readOnly}
      placeholder={placeholder}
      className={classNameStyle}
      onChange={handleChange}
      modules={{
        toolbar: false,
      }}
    />
  );
};

export default QuillEditor;
