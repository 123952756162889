import "./NotificationList.scss";
import { useState, useEffect, useMemo } from "react";
// import DummyNotifs from "./dummyNotifs.json";
import SingleNotif from "../singleNotif/SingleNotif";
import FilterHeader from "./filterHeader/FilterHeader";
import { useContext } from "react";
import { NotifContext } from "../context/notifContext";
import {
  activeFiltering,
  getNotifsCount,
  particulateNotifCategory,
} from "./filters";
import {
  Checkbox,
  Input,
  message,
  Pagination,
  Popconfirm,
  Skeleton,
} from "antd";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../authentication/authHelpers";
import { useSelector } from "react-redux";
import { UnfoldIcon } from "../../../../../assets/icons";
import { requestAllPages } from "../../../../../helpers/API/RequestFactory";

function NotificationList({
  notifsType,
  activeFilter,
  processActiveFilter,
  activeKey,
  countNotifs,
  handleCancel,
}) {
  const { dispatch, isFetching, notificationsArr } = useContext(NotifContext);
  const [notifs, setNotifs] = useState(notificationsArr);
  const [pagination, setPagination] = useState({ head: 0, tail: 20 });
  const [multiCheck, setMultiCheck] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [filterActive, setFilterActive] = useState(false);
  let auth = useAuth();
  const [tempRef, setTempRef] = useState(null);
  const [readUnread, setReadUnread] = useState(false);

  async function loadNotifications() {
    await API.get("notifications", "/notifications").then(
      (notifs) =>
        dispatch({
          type: "LOAD_NOTIFS",
          payload: notifs.map((el) => {
            let test = [];
            Object.entries(el?.userDetails).forEach((ent) => {
              if (ent[0] === auth?.userAccess[0]?.identityId) {
                test.push({ identityId: ent[0], ...ent[1] });
              }
            });
            if (test.length > 0) return { ...el, ...test[0] };
          }),
        }),
      (err) => console.error("error loading notifs", err)
    );
  }

  // Marks Notification as Read
  async function markRead(notifID, notifContent) {
    dispatch({ type: "MARK_READ", payload: notifID });
    let userD = notifContent.userDetails;
    userD[auth?.userAccess[0].identityId].notificationMarkedRed = true;
    await API.put("notifications", `/notifications/${notifID}`, {
      body: {
        userDetails: {
          ...userD,
        },
      },
    }).then((res) => console.log("marked read", res));
  }

  // Marks Notification as Read
  async function markUnRead(notifID, notifContent) {
    dispatch({ type: "MARK_UNREAD", payload: notifID });
    let userD = notifContent.userDetails;
    userD[auth?.userAccess[0].identityId].notificationMarkedRed = false;
    await API.put("notifications", `/notifications/${notifID}`, {
      body: {
        userDetails: {
          ...userD,
        },
      },
    }).then((res) => console.log("marked read", res));
  }

  // Marks Notification as Read/Unread toggle
  async function toggleRead(notifID, markedRead, notifContent) {
    dispatch({ type: "TOGGLE_READ", payload: notifID });
    let userD = notifContent.userDetails;
    userD[auth?.userAccess[0].identityId].notificationMarkedRed = !markedRead;
    await API.put("notifications", `/notifications/${notifID}`, {
      body: {
        userDetails: {
          ...userD,
        },
      },
    }).then((res) => console.log("marked read", res));
  }
  //Marks Notification as Hidden
  async function hideNotif(notifID, markedHidden, notifContent) {
    dispatch({ type: "HIDE_NOTIF", payload: notifID });
    let userD = notifContent.userDetails;
    userD[auth?.userAccess[0].identityId].notificationMarkedHidden =
      !markedHidden;
    await API.put("notifications", `/notifications/${notifID}`, {
      body: {
        userDetails: {
          ...userD,
        },
      },
    }).then((res) => console.log("marked read", res));
  }
  // Marks the specified Notification as deleted
  async function deleteNotif(notifID, notifContent) {
    dispatch({ type: "DELETE_NOTIF", payload: notifID });
    let userD = notifContent.userDetails;
    userD[auth?.userAccess[0].identityId].notificationMarkedDeleted = true;
    await API.put("notifications", `/notifications/${notifID}`, {
      body: {
        userDetails: {
          ...userD,
        },
      },
    }).then(
      // () => console.log("delete notif"),
      () => message.success("Njoftimi u fshi me sukses!")
      // () => message.error("Ndodhi nje problem")
    );
  }

  const multiHide = async () => {
    multiCheck?.forEach(async (check) => {
      await hideNotif(
        check?.notificationId,
        check?.notificationMarkedHidden,
        check
      );
      // message.success("Success ");
    });
    setMultiCheck([]);
  };

  const multiDelete = async () => {
    await Promise.all(
      multiCheck?.map(async (check) => {
        let userD = check?.userDetails;
        userD[auth?.userAccess[0].identityId].notificationMarkedDeleted = true;
        await API.put(
          "notifications",
          `/notifications/${check?.notificationId}`,
          {
            body: {
              userDetails: {
                ...userD,
              },
            },
          }
        );
      })
    )
      .then(() => {
        const filterIds = multiCheck?.map((el) => el?.notificationId);
        const remainingNotifs = notifs?.filter(
          (el) => !filterIds?.includes(el?.notificationId)
        );
        setNotifs(remainingNotifs);
        dispatch({ type: "MULTI_DELETE", payload: remainingNotifs });
        message.success("Njoftimet u fshinë me sukses!");
      })
      .catch((err) => console.log(err));
    setMultiCheck([]);
  };

  const multiMarkRead = async () => {
    multiCheck?.forEach(async (check) => {
      await markRead(check?.notificationId, check);
      // message.success("Success ");
    });
    setMultiCheck([]);
  };

  const multiMarkUnread = async () => {
    multiCheck?.forEach(async (check) => {
      await markUnRead(check?.notificationId, check);
    });
    setMultiCheck([]);
  };

  const onPagination = () => {
    let tempLen = notifs.length;
    if (tempLen > pagination.tail) {
      if (tempLen - pagination.tail > 20) {
        setPagination((prev) => ({ ...prev, tail: pagination.tail + 20 }));
      } else {
        setPagination((prev) => ({ ...prev, tail: notifs.length }));
      }
    }
  };

  const onAddCheck = (e, n) => {
    if (e) {
      let toTemp = new Set([...multiCheck, { ...n }]);
      setMultiCheck([...toTemp]);
    } else if (!e) {
      let temp = multiCheck.filter(
        (el) => el?.notificationId !== n?.notificationId
      );
      setMultiCheck(temp);
    }
  };

  const selectAll = () => {
    setMultiCheck(notifs?.slice(pagination.head, pagination.tail));
  };

  const getRef = (ref) => {
    setTempRef(ref);
  };

  const onSearchCard = (e) => {
    let substring = e.target.value;
    if (substring !== "" && !!substring) {
      let filteredData = notifs?.filter(
        (item) =>
          item.notificationTitle.toLowerCase().includes(substring.toLowerCase())
        //   item.notificationTitle.toLowerCase().includes(substring.toLowerCase())
      );
      setSearchTerm(filteredData);
      setFilterActive(true);
    } else {
      setSearchTerm(null);
      setFilterActive(false);
    }
  };

  // Filtering of Notifications
  useEffect(() => {
    let particulate = particulateNotifCategory(notifsType, notificationsArr); //First Filter Category
    let finalfiltered = activeFiltering(activeFilter, particulate); //Active Second Filtering
    if (!!particulate & !!finalfiltered) {
      setNotifs(finalfiltered);
    }
  }, [notificationsArr, activeFilter, dispatch]);

  // Get notification count for each category
  useEffect(() => {
    if (!!notifs) {
      let notifsCountObj = getNotifsCount(notificationsArr);
      countNotifs(notifsCountObj);
    }
  }, [notificationsArr]);

  // Load Notifications from the Database & call Context API
  useEffect(() => {
    loadNotifications();
  }, [auth]);

  return (
    <>
      {isFetching ? (
        <div className="loading-notifications-skeleton">
          <Skeleton active />
          <br />
          <Skeleton active />
        </div>
      ) : (
        <>
          {" "}
          <div className="notifs-list-container">
            {" "}
            <div className="notification-headr-container">
              <div className="notif-search-headr">
                <Input
                  onChange={onSearchCard}
                  className="kerko"
                  placeholder="Kërko..."
                />
              </div>
              <FilterHeader {...{ activeFilter, processActiveFilter }} />
            </div>
            {multiCheck.length > 0 && (
              <>
                <div className="selection-options-notifs">
                  {" "}
                  {multiCheck?.some(
                    (el) => el?.notificationMarkedRed === false
                  ) && (
                    <Popconfirm
                      title="A jeni te sigurt qe deshironi ti shenoni si te lexuara?"
                      onConfirm={multiMarkRead}
                      okText="Po, sheno."
                      cancelText="Jo, anullo."
                    >
                      <button className="selection-btn">
                        Sheno si te lexuar
                      </button>{" "}
                    </Popconfirm>
                  )}
                  {multiCheck?.some(
                    (el) => el?.notificationMarkedRed === true
                  ) && (
                    <Popconfirm
                      title="A jeni te sigurt qe deshironi ti shenoni si te palexuara?"
                      onConfirm={multiMarkUnread}
                      okText="Po, sheno."
                      cancelText="Jo, anullo."
                    >
                      <button className="selection-btn">
                        Sheno si te palexuar
                      </button>{" "}
                    </Popconfirm>
                  )}
                  <Popconfirm
                    title="A jeni te sigurt qe deshironi ti fshehni nga lista kryesore?"
                    onConfirm={multiHide}
                    okText="Po, fshehi."
                    cancelText="Jo, anullo."
                  >
                    {" "}
                    <button className="selection-btn">Fsheh/Shfaq</button>{" "}
                  </Popconfirm>
                  <button className="selection-btn" onClick={selectAll}>
                    Selekto te gjitha
                  </button>{" "}
                  <button
                    className="selection-btn"
                    onClick={() => setMultiCheck([])}
                  >
                    Hiq Selektimin
                  </button>
                  <Popconfirm
                    title="A jeni te sigurt qe deshironi ti fshini?"
                    onConfirm={multiDelete}
                    okText="Po, fshij."
                    cancelText="Jo, anullo."
                  >
                    {" "}
                    <button className="selection-btn" style={{ color: "red" }}>
                      Fshini Njoftim{multiCheck?.length > 1 ? "et" : "in"}
                    </button>{" "}
                  </Popconfirm>
                </div>
                <br />
              </>
            )}
            <ul className="notifs-list">
              {" "}
              {filterActive === false
                ? notifs
                    ?.slice(pagination.head, pagination.tail)
                    .map((n, idx) => (
                      <li key={idx} className="list-item">
                        <>
                          <div
                            className={`marker ${
                              !!multiCheck.find(
                                (el) => el?.notificationId === n.notificationId
                              )
                                ? "checked"
                                : ""
                            }`}
                          >
                            <Checkbox
                              checked={
                                !!multiCheck.find(
                                  (el) =>
                                    el?.notificationId === n.notificationId
                                )
                                  ? true
                                  : false
                              }
                              key={`${idx} item`}
                              onChange={(e) => onAddCheck(e.target.checked, n)}
                            />
                          </div>
                          <SingleNotif
                            // onClick={() => navigate(`${n.notificationUrl}`)}
                            handleCancel={handleCancel}
                            key={idx}
                            content={n}
                            {...{
                              deleteNotif,
                              markRead,
                              hideNotif,
                              toggleRead,
                            }}
                          />
                        </>
                      </li>
                    ))
                : searchTerm.map((n, idx) => (
                    <li key={idx} className="list-item">
                      <>
                        <div
                          className={`marker ${
                            !!multiCheck.find(
                              (el) => el?.notificationId === n.notificationId
                            )
                              ? "checked"
                              : ""
                          }`}
                        >
                          <Checkbox
                            checked={
                              !!multiCheck.find(
                                (el) => el?.notificationId === n.notificationId
                              )
                                ? true
                                : false
                            }
                            key={`${idx} item`}
                            onChange={(e) => onAddCheck(e.target.checked, n)}
                          />
                        </div>
                        <SingleNotif
                          // onClick={() => navigate(`${n.notificationUrl}`)}
                          handleCancel={handleCancel}
                          key={idx}
                          content={n}
                          {...{ deleteNotif, markRead, hideNotif, toggleRead }}
                        />
                      </>
                    </li>
                  ))}
            </ul>
            {searchTerm === null ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                {pagination.tail < notifs.length && (
                  <span onClick={onPagination} style={{ cursor: "pointer" }}>
                    <UnfoldIcon />
                  </span>
                )}
              </div>
            ) : null}
            {notifs.length === 0 && isFetching === false ? (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  fontSize: "19px",
                }}
              >
                Nuk ka njoftime për të shfaqur!
              </p>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}
export default NotificationList;
