import React, { useContext, useState } from "react";
import "./Kategorite.scss";
import { EyeFilled, EyeOutlined } from "@ant-design/icons";
import { Button, Tag, message } from "antd";
import QuillRenderer from "./Components/QuillRenderer";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { InterviewsContext } from "../../data";
import { useDispatch } from "react-redux";
import { candidates as candidatesReducer } from "../../../../store/actions";
import { useAuth } from "../../../../authentication/authHelpers";
import moment from "moment";
import AddTagModal from "./AddTagModal";

const Pergjithshme = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const {
    kandidatiSelektuar,
    setKandidatiSelektuar,
    setIntervistat,
    defaultSwalProperties,
  } = useContext(InterviewsContext);

  const [visible, setVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [applicantTagNames, setApplicantTagNames] = useState(
    kandidatiSelektuar?.applicantTag || []
  );

  const handleUpdate = async (tagName = "", e) => {
    if (!!tagName) e.preventDefault();
    const updatedTags = applicantTagNames.filter((items) => items !== tagName);

    const { applicantId, userId, ...rest } = kandidatiSelektuar;

    const tempData = {
      ...rest,
      applicantTag: updatedTags,
      keylog: [
        ...(kandidatiSelektuar?.keylog || []),
        {
          activity: "Ndryshuar",
          author:
            auth.userAccess[0]?.given_name +
            " " +
            auth.userAccess[0]?.family_name,
          changeDate: moment().valueOf(),
          field: "Taget",
          oldValue: kandidatiSelektuar?.applicantTag,
          newValue: updatedTags,
        },
      ],
    };

    Swal.fire({
      title: "Ju lutemi prisni...",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await API.put(
      "interviews",
      `/interviews/${kandidatiSelektuar?.applicantId}`,
      {
        body: tempData,
      }
    )
      .then(() => {
        let newBody = {
          ...tempData,
          applicantId,
          userId,
        };
        dispatch(candidatesReducer(newBody));
        setIntervistat((prev) => [...prev, newBody]);
        setKandidatiSelektuar({ ...newBody });
        Swal.mixin({
          ...defaultSwalProperties,
        }).fire({
          icon: "success",
          title: "Kandidati u ndryshua!",
        });
      })
      .catch((err) => {
        console.log("Pergjithshme page error: ", { err });
        message.error("Ndodhi nje gabim");
      });
  };

  return (
    <div className="pergjithshme-container">
      <div className="content-container">
        <div className="first-section">
          <p>Përmbledhje</p>
        </div>
        <div className="divider" />
        <div className="tags-section">
          <div className="tag-container">
            {kandidatiSelektuar.applicantTag &&
              kandidatiSelektuar.applicantTag.map((listItems, index) => (
                <Tag
                  key={index}
                  closable
                  onClose={(e) => handleUpdate(listItems, e)}
                >
                  #{listItems}
                </Tag>
              ))}
          </div>
          <Button className="add-tag-btn" onClick={() => setVisible(!visible)}>
            Shtoni një tag
          </Button>
        </div>
        <div className="divider" />
        <div className="personal-information">
          <p>
            <span>Datëlindja:</span>{" "}
            {kandidatiSelektuar.applicantBirthday &&
              dayjs(kandidatiSelektuar.applicantBirthday)?.format("DD/MM/YYYY")}
          </p>
          <p>
            <span>Nr. Personal:</span>{" "}
            {kandidatiSelektuar.applicantPersonalNumber}
          </p>
          <p>
            <span>Nr. Celulari:</span> {kandidatiSelektuar.applicantPhoneNumber}
          </p>
          <p>
            <span>E-mail:</span> {kandidatiSelektuar.applicantEmail}
          </p>
        </div>
        <div className="divider" />
        <div className="applicant-work-information">
          <p>
            <span>Adresa e banimit:</span> {kandidatiSelektuar.applicantAddress}
          </p>
          <p>
            <span>Team-i:</span> {kandidatiSelektuar.applicantTeam}
          </p>
          <p>
            <span>Data e mundshme e fillimit:</span>{" "}
            {!!kandidatiSelektuar.applicantStartDate
              ? dayjs(kandidatiSelektuar.applicantStartDate).format(
                  "DD/MM/YYYY"
                )
              : "S'ka datë"}
          </p>
          <p className="password-show">
            <span>Norma e pagës:</span>{" "}
            {showPassword ? kandidatiSelektuar.applicantPayment : "***********"}
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? <EyeFilled /> : <EyeOutlined />}
            </button>
          </p>
        </div>
        <div className="divider" />
        <div className="show-applicant-info">
          <div className="first-container">
            <p className="title">Informacione të tjera</p>
            <div className="university-container">
              <p>
                <span>Niveli i diplomës:</span>{" "}
                {kandidatiSelektuar.applicantDiplomaLevel}
              </p>
              <p>
                <span>Universiteti:</span>{" "}
                {kandidatiSelektuar.applicantUniversity}
              </p>
              <p>
                <span>Dega:</span> {kandidatiSelektuar.applicantUniversityField}
              </p>
            </div>
            <p className="quill-title">Trajnime dhe kualifikime të tjera:</p>
            <QuillRenderer value={kandidatiSelektuar.applicantTraining} />
          </div>
          <div className="divider"></div>
          <div className="second-container">
            <span>Referenca të mëparshme:</span>
            <QuillRenderer value={kandidatiSelektuar.applicantReference} />
          </div>
        </div>
      </div>
      <AddTagModal
        {...{
          visible,
          setVisible,
          kandidatiSelektuar,
          handleUpdate,
          applicantTagNames,
          setApplicantTagNames,
        }}
      />
    </div>
  );
};

export default Pergjithshme;
