import moment from "moment-timezone";
export const getTimeFilterObj = (dateInstance, dateType) => {
	let timeFilterObj = { startTime: "", endTime: "", type: "" };
	try {
		if (dateType === "weekly") {
			return (timeFilterObj = {
				startTime: moment(dateInstance).startOf("week").valueOf(),
				endTime: moment(dateInstance).endOf("week").valueOf(),
				type: dateType,
			});
		}
		if (dateType === "monthly") {
			return (timeFilterObj = {
				startTime: moment(dateInstance).startOf("month").valueOf(),
				endTime: moment(dateInstance).endOf("month").valueOf(),
				type: dateType,
			});
		}
	} catch (err) {
		console.error("An error occured during calculation", err);
		return timeFilterObj;
	}
};

export const calculateEmployeeHours = (timeFilterObj, employeeData) => {
	// total hours is calculated in decimal hours
	// console.log("timeFilter", timeFilterObj);
	let result = { totalHours: 0, extraHours: 0, missingHours: 0 };
	if (timeFilterObj.startTime !== "" && timeFilterObj.endTime !== "") {
		try {
			employeeData
				.filter(
					(e) => e.clockInDate > timeFilterObj.startTime && e.clockInDate < timeFilterObj.endTime
				)
				.map(
					(empl) =>
						(result = {
							...result,
							totalHours:
								result.totalHours +
								moment(empl?.clockOutDate).diff(moment(empl?.clockInDate), "hours", true),
						})
				);
			result = furtherCalculations(timeFilterObj.type, result);
		} catch (err) {
			console.error("An error occured during calculation", err);
		}
		return result;
	}
	return result;
};

const furtherCalculations = (filterType, result) => {
	let calculated = result;
	if (filterType === "weekly") {
		return (calculated = {
			...calculated,
			extraHours: calculated.totalHours > 40 ? calculated.totalHours - 40 : 0,
			missingHours: calculated.totalHours < 40 ? 40 - calculated.totalHours : 0,
		});
	}
	if (filterType === "monthly") {
		return (calculated = {
			...calculated,
			extraHours: calculated.totalHours > 1600 ? calculated.totalHours - 1600 : 0,
			missingHours: calculated.totalHours < 1600 ? 1600 - calculated.totalHours : 0,
		});
	}
	return result;
};
