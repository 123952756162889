import { Close, Tick } from "../../assets/index";
import "./FieldOption.scss";

const FieldOption = ({ children, className = "", added = false, onClick }) => {
  return (
    <div className={`docFieldOption ${className}`.trim()}>
      <div className="fieldOptionLabel">{children}</div>
      <span
        className={`fieldOptionButton ${!!added ? "added" : "notAdded"}`}
        onClick={onClick}
      >
        {!added ? <Tick /> : <Close className="closeIcon" />}
      </span>
    </div>
  );
};

export default FieldOption;
