import { Popconfirm } from "antd";
import { useState, useEffect } from "react";
import { Flex } from "../../../assets/icons";
import { useGeneralInformationsContext } from "./context";
import "./Popover.scss";

const CancelAddEmployeeModalPopover = ({
  visible,
  setVisible,
  setModalVisible,
  modalVisible,
  overlayClassName = "employee-create-confirm",
  title,
  form,
  setCurrentStep,
}) => {
  const [popOverVisible, setPopoverVisible] = useState(false);
  const { setGetName } = useGeneralInformationsContext();

  useEffect(() => {
    setPopoverVisible(visible);
  }, [visible]);

  return (
    <>
      {popOverVisible && modalVisible && (
        <Popconfirm
          overlayClassName={overlayClassName}
          okText="Po"
          cancelText="Jo"
          title={title}
          onConfirm={() => {
            setVisible(false);
            setPopoverVisible(false);
            setModalVisible(false);
            form.resetFields();
            setGetName("");
            setCurrentStep(0);
          }}
          open={popOverVisible}
          onCancel={() => {
            setVisible(false);
          }}
          icon={<Flex />}
        ></Popconfirm>
      )}
    </>
  );
};

export default CancelAddEmployeeModalPopover;
