import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import LoadableComp from "../../../../../../components/commonComponents/LoadableComp/LoadableComp";
import "./MultipleFilePrevieModal.scss";
import NormalSizedModal from "../../../../../commonComponents/NormalSizedModal/NormalSizedModal";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";
import { openInNewTab } from "../../../../../Documentation/utils/openInNewTab";
import { GDriveIcon } from "../../../../../Documentation/View/assets";
import { Button, message } from "antd";

const MultipleFilePrevieModal = ({
  visible,
  setVisible,
  file = {},
  setCurrentFile = () => {},
  uploadedFiles,
  driveRequest,
}) => {
  const { src, mimeType, url } = file;
  const getFileId = (array, object) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === object.id) {
        return i;
      }
    }
  };

  const handleClickPrevious = async () => {
    const index = getFileId(uploadedFiles, file);
    message.loading({
      content: "Loading file...",
      key: "loadingFile",
      duration: 0,
    });

    try {
      if (index === 0) {
        await driveRequest
          .getImageSrc(uploadedFiles[uploadedFiles.length - 1].id)
          .then((file) => {
            setCurrentFile({
              ...file,
              url: uploadedFiles[uploadedFiles.length - 1].url,
              mimeType: uploadedFiles[uploadedFiles.length - 1].mimeType,
              id: uploadedFiles[uploadedFiles.length - 1].id,
            });
          });
      } else {
        await driveRequest
          .getImageSrc(uploadedFiles[index - 1].id)
          .then((file) => {
            setCurrentFile({
              ...file,
              url: uploadedFiles[index - 1].url,
              mimeType: uploadedFiles[index - 1].mimeType,
              id: uploadedFiles[index - 1].id,
            });
          });
      }
      message.destroy("loadingFile");
    } catch (err) {
      message.error({
        content: "Something went wrong while getting the file",
        key: "loadingFile",
      });
      console.log("Error getting files: ", err);
    }
  };

  const handleClickNext = async () => {
    message.loading({
      content: "Loading file...",
      key: "loadingFile",
      duration: 0,
    });
    const index = getFileId(uploadedFiles, file);
    try {
      if (index + 1 === uploadedFiles.length) {
        await driveRequest.getImageSrc(uploadedFiles[0].id).then((file) => {
          setCurrentFile({
            ...file,
            url: uploadedFiles[0].url,
            mimeType: uploadedFiles[0].mimeType,
            id: uploadedFiles[0].id,
          });
        });
      } else {
        await driveRequest
          .getImageSrc(uploadedFiles[index + 1].id)
          .then((file) => {
            setCurrentFile({
              ...file,
              url: uploadedFiles[index + 1].url,
              mimeType: uploadedFiles[index + 1].mimeType,
              id: uploadedFiles[index + 1].id,
            });
          });
      }
      message.destroy("loadingFile");
    } catch (err) {
      message.error({
        content: "Something went wrong while getting the file",
        key: "loadingFile",
      });
    }
  };

  return (
    <NormalSizedModal
      {...{
        visible,
        setVisible,
        className: "filePreviewModal",
        destroyOnClose: true,
        centered: true,
        maskClosable: true,
      }}
    >
      <div className="bodyContent">
        <CloseOutlined
          className="closeIcon"
          onClick={() => setVisible(false)}
        />
        <LoadableComp loading={!src}>
          {uploadedFiles?.length !== 1 && (
            <div className="previousNextContainer">
              <Button
                icon={<LeftOutlined />}
                size="large"
                onClick={() => handleClickPrevious()}
              />
              <Button
                icon={<RightOutlined />}
                size="large"
                onClick={() => handleClickNext()}
              />
            </div>
          )}
          {mimeType === "application/pdf" ? (
            <iframe
              title="pdfPreview"
              id="previewIFrame"
              className="displayedFileFrame"
              {...{ src }}
            />
          ) : mimeType?.replace(/\/.*$/, "") === "image" ? (
            <img alt="..." className="displayedImage" {...{ src }} />
          ) : (
            <div>
              Ky Format nuk është i supportuar, klikoni iconën e Google Drive...{" "}
            </div>
          )}
          {!!url && (
            <MondayButton
              {...{
                className: "gDriveButton",
                Icon: <GDriveIcon />,
                onClick: () => openInNewTab(url),
              }}
            >
              Hapni në Drive
            </MondayButton>
          )}
        </LoadableComp>
      </div>
    </NormalSizedModal>
  );
};

export default MultipleFilePrevieModal;
