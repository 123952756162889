import { message } from "antd";
import { useAuth } from "../../../authentication/authHelpers";
import { postAnnouncement, editAnnouncement } from "../utils/methods";
import { useSelector } from "react-redux";
import { notificationNoPostToDepartment } from "../../../utils/sendNotification";
import { Select, Input, Modal, DatePicker } from "antd";
import { ModalXIcon } from "../../../assets/icons";
import { NewIcon } from "../../DocumentationConfiguration/assets";
import moment from "moment";
import { RichTextEditor } from "../../commonComponents";
import "./NewAnnouncement.scss";

const NewAnnouncement = ({
  mode,
  data,
  setData,
  gridAPI,
  onReset,
  visible,
  setVisible,
  retrievedData,
}) => {
  const auth = useAuth();
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { departments } = useSelector((state) => state.departments);
  const { users } = useSelector((state) => state.usersList);

  const getUserName = () => {
    const { user = {} } = auth;
    const name =
      user?.attributes?.given_name + " " + user?.attributes?.family_name;
    return name;
  };

  const onSubmit = async () => {
    const {
      announcementTitle,
      announcementBody,
      announcementUrl,
      announcementCategory,
      announcementTarget,
      announcementTeam,
      announcementId = "",
      endAnnouncementDate,
    } = data;
    const createdBy = getUserName();
    const userResponses = {};
    const newAnnouncement = {
      announcementTitle,
      announcementBody,
      announcementUrl,
      userResponses,
      createdBy,
      announcementCategory,
      announcementTarget,
      announcementTeam,
      endAnnouncementDate,
    };
    if (mode.modeType === "new") {
      await postAnnouncement(newAnnouncement)
        .then(async (r) => {
          console.log("returned", r);
          // apply transaction post
          gridAPI.applyTransaction({ add: [r], addIndex: 0 });
          message.success("Announcement created successfully");
          const announcementTeamTarget =
            activeEmployees?.filter((a) =>
              announcementTeam?.teams?.includes(a?.employeeTeam)
            ) || [];
          let usersToPost = users?.allUsers?.Items?.filter((e) => {
            return (
              announcementTarget?.departments?.includes(e?.department) &&
              announcementTeamTarget?.some((a) => a?.userSub === e?.userSub)
            );
          });
          notificationNoPostToDepartment(
            {
              notificationTitle: announcementTitle,
              content: "Ju keni nje lajmerim te ri",
            },
            usersToPost
          );
          // sendNotification({
          // 	headings: { en: announcementTitle },
          // 	contents: { en: "Ju keni nje lajmerim te ri" },
          // 	url: announcementUrl || "https://portal.flex.al/kryefaqja",
          // 	// included_segments: ["s"]
          // 	// data: {
          // 	//   ids: [
          // 	//     { user: auth.userAccess[0].email, read: false, removed: false },
          // 	//   ],
          // 	// },
          // });
          setVisible(false);
          onReset();
          // success;
        })
        .catch((e) => {
          // error
          message.error("Error creating announcement");
        });
    } else {
      let editableData = {
        announcementTitle,
        announcementBody,
      };
      await editAnnouncement(announcementId, editableData).then((r) => {
        // apply transaction post
        retrievedData();
        message.success("Announcement edited successfully!");
        setVisible(false);
        onReset();
        // success;
      });
    }
  };

  const onCleanUp = () => {
    setVisible(false);
    onReset();
  };
  const onChange = (e, val) => {
    if (e === "announcementTarget") {
      setData({ ...data, [e]: { departments: val } });
    } else if (e === "announcementTeam") {
      setData({ ...data, [e]: { teams: val } });
    } else {
      setData({ ...data, [e]: val });
    }
  };
  return visible ? (
    <Modal
      open={visible}
      onCancel={onCleanUp}
      width={"1600px"}
      destroyOnClose={true}
      wrapClassName="new-announcemenet-modal"
      title={mode.modeType === "new" ? "Krijo Lajmërim" : "Ndrysho Lajmërim"}
      closeIcon={<ModalXIcon />}
      footer={
        <div className="new-announcemenet-footer">
          <button onClick={onCleanUp} className="custom-modal-btn_cancel">
            Anullo
          </button>
          <button
            className="custom-modal-btn_confirm"
            disabled={
              data?.announcementBody === "" ||
              data?.announcementTitle === "" ||
              !data?.announcementTarget?.departments?.length ||
              !data?.announcementTeam?.teams?.length ||
              !data?.endAnnouncementDate
            }
            Icon={<NewIcon />}
            onClick={onSubmit}
          >
            {mode.modeType === "new"
              ? "Aktivizo Lajmërimin"
              : "Ndrysho Lajmërimin"}
          </button>
        </div>
      }
    >
      <div className="new-announcemenet-content">
        <div className="new-announcemenet-content__item">
          <h3>Titulli</h3>
          <Input
            value={data?.announcementTitle}
            placeholder="Shkruaj titullin"
            onChange={(e) => {
              onChange("announcementTitle", e.target.value);
            }}
          />
        </div>
        <div className="new-announcemenet-content__item">
          <br />
          <h3>Përmbajtja</h3>
          <RichTextEditor
            value={data?.announcementBody}
            className={"modal-quill-edit"}
            onChange={(e) => {
              onChange("announcementBody", e);
            }}
          />
        </div>
        <br />
        <div className="new-announcemenet-merged-items">
          <div className="new-announcemenet-content__item">
            <h3>Kategoria</h3>
            <Select
              style={{ width: 300 }}
              value={data?.announcementCategory}
              disabled={mode.modeType !== "new"}
              placeholder="Zgjidh kategorine"
              onChange={(e) => {
                onChange("announcementCategory", e);
              }}
            >
              <Select.Option value="importantAnnouncement">
                Lajmërim
              </Select.Option>
              <Select.Option value="needAction">
                Lajmërim që ka nevojë për përgjigje
              </Select.Option>
              <Select.Option value="otherWish">Urim</Select.Option>
            </Select>
          </div>
          <div className="new-announcemenet-content__item2">
            <h3>Url</h3>
            <Input
              style={{ width: 220 }}
              placeholder="vendos linkun e lajmërimit"
              disabled={mode.modeType !== "new"}
              value={data?.announcementUrl}
              onChange={(e) => {
                onChange("announcementUrl", e.target.value);
              }}
            />
          </div>
          <div className="new-announcemenet-content__item">
            <h3>Departamenti</h3>
            <Select
              style={{ width: 300 }}
              mode="multiple"
              disabled={mode.modeType !== "new"}
              value={data?.announcementTarget?.departments || []}
              placeholder="Zgjidh Departamentet"
              onChange={(e) => {
                onChange("announcementTarget", e);
              }}
            >
              {departments?.map((item) => {
                return (
                  <Select.Option
                    key={item.departmentId}
                    value={item.departmentName}
                  >
                    {item.departmentName}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div className="new-announcemenet-content__item">
            <h3>Kompania</h3>
            <Select
              style={{ width: 300 }}
              mode="multiple"
              disabled={mode.modeType !== "new"}
              value={data?.announcementTeam?.teams || []}
              placeholder="Zgjidh kompanite"
              onChange={(e) => {
                onChange("announcementTeam", e);
              }}
            >
              <Select.Option value="Flex Tirana">Flex Tirana</Select.Option>
              <Select.Option value="Flex Prishtina">
                Flex Prishtina
              </Select.Option>
            </Select>
          </div>
          <div className="new-announcemenet-content__item">
            <h3>Data perfundimtare</h3>
            <DatePicker
              format={"DD/MM/YYYY"}
              disabledDate={(e) => {
                let disabled = false;
                if (e?.valueOf() < moment().startOf("day").valueOf())
                  disabled = true;
                return disabled;
              }}
              style={{
                width: 300,
                borderRadius: 5,
                backgroundColor: "#f5f5f7",
                border: "none",
              }}
              value={
                !!data?.endAnnouncementDate
                  ? moment(data?.endAnnouncementDate)
                  : null
              }
              onChange={(e) => {
                onChange(
                  "endAnnouncementDate",
                  !!e ? moment(e).valueOf() : null
                );
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default NewAnnouncement;
