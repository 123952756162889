import React, { useContext } from "react";
import { Card, Row, Col } from "antd";
import { EditIcon } from "../../../../../assets/icons";
import "../../components/DataCard.scss";
import moment from "moment-timezone";
import PunonjesitContext from "../../../store/PunonjesitContext";

function DataCardInfoPersonal({ handleClick }) {
  const { employee } = useContext(PunonjesitContext);

  return (
    <div className="details-view-employes" style={{ width: "100%" }}>
      <Card
        className="info-card"
        title="Informacione personale"
        extra={
          <div className="icon-button">
            <button
              id="edit"
              disabled={employee?.employeeStatus === "Aktiv" ? false : true}
              onClick={handleClick}
            >
              <EditIcon className="pen-icon" />
            </button>
          </div>
        }
      >
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Emri:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeFirstName}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Mbiemri:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeLastName}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Atesia:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeFathersName}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Numri personal:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeePersonalNumber}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Gjinia:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeGender}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Ditëlindja:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {!!employee?.employeeDayOfBirth
                  ? moment(employee?.employeeDayOfBirth)
                      .format("DD/MM/YYYY")
                      .valueOf()
                  : ""}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Orari i pushimit:</div>
            </Col>
            <Col span={13}>
              <div
                style={{
                  display: "flex",
                }}
              >
                {employee?.breakTime?.length > 0 ? (
                  <span className="label-show-details">{`${employee?.breakTime[0]} - ${employee?.breakTime[1]}`}</span>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 10 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Pushim i paguar:</div>
            </Col>
            <Col span={13}>
              <div
                style={{
                  display: "flex",
                }}
              >
                <span className="label-show-details">
                  {!!employee?.isBreakTimePaid ? "Paguar" : "I Pa paguar"}
                </span>
                {/* {!!employeesData?.isBreakTimePaid ? "Paguar" : "I Pa paguar"} */}
              </div>
            </Col>
          </div>
        </Row>
      </Card>
    </div>
  );
}
export default DataCardInfoPersonal;
