import moment from "moment-timezone";
import React from "react";
import MobileHtmlTabel from "../../utils/MobileComponents/MobileHtmlTabel";

function MobileRaportet({ rowDataGrid }) {
	return (
		<MobileHtmlTabel
			tableData={rowDataGrid?.map(({ requestType, requestStatus, requestPeriod }) => ({
				statusHeaderKey: requestType,
				requestStatus,
				requestPeriod: moment(requestPeriod[0]).format("DD/MM/YYYY"),
				requestPeriodEnd: moment(requestPeriod.slice(-1)[0]).format("DD/MM/YYYY"),
			}))}
			header={["Lloji i lejes", "Statusi i lejes", "Data e fillimit", "Data e mbarimit"]}
			isLink={false}
			isHeader={true}
		/>
	);
}

export default MobileRaportet;
