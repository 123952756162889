import { Switch } from "antd";
import React, { useMemo, useState } from "react";
import { MiniChart } from "react-ts-tradingview-widgets";
import { SwitchIcon } from "../../../../../assets/icons";
import "./Currency.scss";

function CurrencyWidget() {
  const [switchy, setSwitch] = useState(true);
  const AppliedChart = useMemo(
    () => (
      <div style={{ pointerEvents: "none" }}>
        <MiniChart
          {...{
            symbol: switchy ? "FX_IDC:USDALL" : "FX_IDC:ALLUSD",
            width: "100%",
            height: "190",
            locale: "en",
            dateRange: "12M",
            colorTheme: "light",
            trendLineColor: "rgba(41, 98, 255, 1)",
            underLineColor: "rgba(41, 98, 255, 0.3)",
            underLineBottomColor: "rgba(41, 98, 255, 0)",
            isTransparent: true,
            autosize: false,
            largeChartUrl: "",
            copyrightStyles: {
              parent: { display: "none" },
              link: { display: "none" },
              span: { display: "none" },
            },
          }}
        />
      </div>
    ),
    [switchy]
  );
  return (
    <div
      className="currency-widget"
      style={{ paddingTop: "10%", height: "270px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 10,
        }}
      >
        <span
          style={{
            cursor: "pointer",
            width: 40,
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => setSwitch(!switchy)}
        >
          <SwitchIcon />
        </span>
      </div>
      {AppliedChart}
    </div>
  );
}

export default React.memo(CurrencyWidget);
