import { useEffect } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { GPicker } from "../../../../GPicker";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
// import "./ProblemsDocUploader.scss";

const DATE_FORMAT = "DD/MM/YYYY hh:mm a";

/**
 *
 * @param {Array} uploadedFiles Array of the uploaded files.
 * @param {Function} onDelete On Delete button click callback function.
 * @param {Function} onPickerSuccess On file upload callback function.
 * @param {Function} accessToken API access token.
 * @param {Function} parentFolderId kandidatiSelektuar's folder Id.
 */

const AddContactDocUploader = ({
  onPickerSuccess = () => {},
  disabled = false,
  parentFolderId,
}) => {
  const { accessToken } = useSelector((state) => state.accessToken);

  return (
    <>
      <div className="uploadSection">
        <GPicker
          token={accessToken}
          onAuthenticate={() => {}}
          createPicker={(google, oauthToken) => {
            const uploadView = new google.picker.DocsUploadView()
              .setIncludeFolders(true)
              .setParent(parentFolderId);

            const picker = new window.google.picker.PickerBuilder()
              .enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
              .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
              .enableFeature(google.picker.Feature.MINE_ONLY)
              .enableFeature(google.picker.Feature.NAV_HIDDEN)
              .addView(uploadView)
              .setOAuthToken(oauthToken)
              .setDeveloperKey("AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo")
              .setCallback(async (data) => {
                if (
                  data.action === google.picker.Action.PICKED &&
                  !!data.docs.length
                ) {
                  onPickerSuccess({
                    uploadedFiles: data.docs,
                  });
                }
              });
            picker.build().setVisible(true);
          }}
        >
          <MondayButton
            {...{
              className: "uploaderContactButton",
              disabled: disabled || !parentFolderId || !accessToken,
              hasIcon: false,
            }}
          >
            Ngarkoni dokumente
          </MondayButton>
        </GPicker>
      </div>
    </>
  );
};

export default AddContactDocUploader;
