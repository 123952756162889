import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Form, message, Skeleton } from "antd";
import { Auth } from "aws-amplify";
import SiguriaPerdoruesitModal from "../../../../Konfigurimet/SiguriaSettings/SiguriaPerdoruesit/SiguriaPerdoruesitModal";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./User.scss";
import LoadedUser from "./LoadedUser";
import { NoUser, WarningRed } from "../../../../../assets/icons";
import MergeEmployeeUser from "./MergeEmployeeUser";

function User({ employee }) {
  const [renderUser, setRenderUser] = useState(null);
  const { users } = useSelector((state) => state.usersList);
  const { employees } = useSelector((state) => state.employeesList);
  const [allUsers, setAllUsers] = useState(null);
  const [form] = Form.useForm();
  const { employeeId } = useParams();
  const [selectedEmpl, setSelectedEmpl] = useState({});
  const [user, setUser] = useState();
  const [visible, setVisible] = useState(false);
  const [mergeVisib, setMergeVisib] = useState(false);
  const [uSub, setUsub] = useState(false);
  const [konfig, setKonfig] = useState({
    email: "",
    verifyCode: "",
  });
  const [data, setData] = useState({
    given_name: "",
    family_name: "",
    gender: "",
    email: "",
    department: "",
    userRole: "",
    departmentRole: "",
    userSub: "",
    phone_number: "",
    forceReset: true,
    // createdAt: moment(),
  });

  // get employeeData using params
  useEffect(() => {
    employees.filter((el) =>
      el.employeeId === employeeId ? setSelectedEmpl(el) : null
    );
  }, [employeeId, employees]);
  const navigate = useNavigate();
  const handleNewUser = () => {
    setVisible(true);
  };

  const addItems = async (addIndex) => {
    await API.put("employees", `/employees/${selectedEmpl.employeeId}`, {
      body: {
        userSub: uSub,
        accountStatus: "created",
      },
    }).then((r) => {
      navigate(`/punonjesit/specifikat/${selectedEmpl?.employeeId}`, {
        state: { ...selectedEmpl, userSub: false, accountStatus: "created" },
      });
      setRenderUser(null);
      setRenderUser(false);
    });
    form.resetFields();
    setVisible(false);
  };
  const formSubmit = async (res) => {
    // console.log(res);
    await Auth.signUp({
      username: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      attributes: {
        phone_number:
          "+" +
          form
            .getFieldValue("phone_number")
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", ""),
        family_name: form.getFieldValue("family_name"),
        given_name: form.getFieldValue("given_name"),
        gender: form.getFieldValue("gender"),
        "custom:department": form.getFieldValue("department"),
        "custom:userRole": form.getFieldValue("userRole"),
        "custom:role": form.getFieldValue("departmentRole"),
      },
    }).then((res) => {
      console.log("Rezultati Sign Up", res);
      setUsub(res?.userSub);
      setData((prev) => ({
        ...prev,
        // sub: res.userSub,
        given_name: form.getFieldValue("given_name"),
        family_name: form.getFieldValue("family_name"),
        gender: form.getFieldValue("gender"),
        email: form.getFieldValue("email"),
        department: form.getFieldValue("department"),
        userRole: form.getFieldValue("userRole"),
        departmentRole: form.getFieldValue("departmentRole"),
        phone_number: form.getFieldValue("phone_number"),
      }));
      setUser(res);
    });
  };
  const userConfig = () => {
    Auth.confirmSignUp(
      form.getFieldValue("email"),
      form.getFieldValue("verifyCode")
    ).then((res) => {
      if (res === "SUCCESS") {
        addItems();
        message
          .success("Krijimi i nje useri u bë me sukses")
          .then((r) => onClose());
      }
    });
  };

  const onChange = (objField, e) => {
    const value = objField;
    setData((prev) => ({ ...prev, [value]: e }));
  };

  const HandleChange = (objField, e) => {
    const value = objField;
    setKonfig((prev) => ({ ...prev, [value]: e }));
  };

  const onClose = () => {
    // form.resetFields();
    setVisible(false);
    // setUser(null);
  };

  useEffect(() => {
    if (!!users) {
      setAllUsers(users?.allUsers?.Items);
    }
  }, [users]);

  useEffect(() => {
    if (!!employee && allUsers) {
      let result;
      if (!!employee?.userSub) {
        result = allUsers.find((el) => el.userSub === employee?.userSub);
      }
      if (!!result) {
        setRenderUser(result);
      } else {
        setRenderUser(false);
      }
    }
  }, [employee, allUsers]);
  useEffect(() => {
    if (!!selectedEmpl) {
      form.setFieldsValue({
        given_name: selectedEmpl?.employeeFirstName,
        family_name: selectedEmpl?.employeeLastName,
        gender: selectedEmpl?.employeeGender,
        email: selectedEmpl?.employeeEmailWork,
        department: selectedEmpl?.employeeDepartmentName,
        phone_number: selectedEmpl?.employeePhoneNumber,
        userRole: "",
        departmentRole: selectedEmpl?.employeeRoleName,
      });
    }
  }, [selectedEmpl]);
  useEffect(() => {
    // const getUser = async () => {
    //   await API.get("users", "/users").then((res) => console.log(res));
    // };
    // getUser();
    // console.log("Department", form.getFieldValue("department"));
    // console.log("Location", selectedEmpl);
  }, [selectedEmpl, form, data]);

  return (
    <div>
      {renderUser === null ? (
        <div className="user-card-skeleton-on-load">
          <Skeleton />
        </div>
      ) : (
        <div
          className="loaded-user-merge-card"
          style={
            {
              // display: "flex",
              // justifyContent: "center",
              // flexDirection: "column",
              // paddingLeft: "40px",
              // paddingTop: "20px",
              // height: "90%",
              // overflowY: "scroll",
            }
          }
        >
          {renderUser !== false ? (
            <>
              <LoadedUser {...{ renderUser }} />
            </>
          ) : (
            <div className="no-user-merge">
              {
                selectedEmpl?.accountStatus !== "created" &&
                selectedEmpl?.accountStatus !== "registered" ? (
                  <>
                    {" "}
                    <span>
                      <WarningRed />
                    </span>
                    <span className="no-user-merge__account">
                      Nuk ka llogari!
                    </span>
                    <span className="no-user-merge__employee">
                      Punonjësi i zgjedhur nuk ka një llogari për të aksesuar
                      sistemin.
                    </span>
                    <button className="add-user" onClick={handleNewUser}>
                      Krijo Përdorues
                    </button>{" "}
                    <button
                      className="add-user"
                      style={{ width: "auto" }}
                      onClick={() => setMergeVisib(true)}
                    >
                      Lidh Userin me Punonjesin
                    </button>
                  </>
                ) : selectedEmpl?.accountStatus === "created" ? (
                  <>
                    <span>
                      <NoUser />
                    </span>
                    <span className="no-user-merge__account">
                      Llogaria është krijuar me sukses!
                    </span>
                    <span className="no-user-merge__employee">
                      Pritet që përdoruesi të logohet në sistem për të
                      aktivizuar llogarinë.
                    </span>
                  </>
                ) : null
                // <button onClick={onClick}>CLICK</button>
              }
            </div>
          )}{" "}
          <SiguriaPerdoruesitModal
            formSubmit={formSubmit}
            userConfig={userConfig}
            user={user}
            onClose={onClose}
            form={form}
            onChange={onChange}
            HandleChange={HandleChange}
            visible={visible}
            setVisible={setVisible}
            setData={setData}
            from={"employee"}
          />{" "}
          <MergeEmployeeUser
            visible={mergeVisib}
            setVisible={setMergeVisib}
            selected={selectedEmpl}
          />
        </div>
      )}
    </div>
  );
}
export default User;
