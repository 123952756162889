import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Button, Modal, Select, Tooltip, DatePicker } from "antd";
import { IconFilter, WarningIcon } from "../../../assets/icons/index";
import { CaretDownFilled } from "@ant-design/icons";
import "./FilterAgGrid.scss";
import { ClearIcon, IconCalendar, NextIcon } from "../../dashboard/assets";
import moment from "moment";
import { onDateFilter } from "../../Documentation/utils/onFilter";

const FilterAgGrid = ({
  gridApi,
  clearFilters,
  tableData,
  filterKeys,
  filterActive,
  replaceable,
}) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [options, setOptions] = useState(null);
  const [visibleFilter, setVisibleFilter] = useState(false);

  const setFilterModel = ({ e, key, type, filterType, dateFrom, dateTo }) => {
    const filterComponent = gridApi.getFilterInstance(key);
    const filterObj = {
      filterType,
      dateFrom,
      dateTo,
      type,
    };
    if (filterType !== "date") filterObj.filter = `${e}`;
    filterComponent.setModel(filterObj);
  };

  const generalFilter = (e) => {
    tableData?.forEach((el) => {
      for (const { key } of filterKeys) {
        if (!!replaceable && !!replaceable[key]?.length) {
          for (const replace of replaceable[key]) {
            for (const [replaceKey, value] of Object.entries(replace)) {
              if (value === e) {
                setFilterModel({ e, key, type: "contains" });
                break;
              }
            }
          }
          if (el?.[key] === e) {
            setFilterModel({ e, key, type: "contains" });
          }
        } else {
          if (el?.[key] === e) {
            setFilterModel({ e, key, type: "contains" });
          }
        }
        if (Array.isArray(e)) {
          setFilterModel({
            key,
            filterType: "date",
            type: "inRange",
            dateFrom: moment(e[0]).format("YYYY-MM-DD"),
            dateTo: moment(e[1]).format("YYYY-MM-DD"),
          });
        }
      }
    });
    gridApi.onFilterChanged();
  };

  function closeFilter() {
    clearFilters();
    setVisibleFilter(false);
  }

  useEffect(() => {
    let options = {};
    filterKeys?.forEach(({ key, type }) => {
      if (type === "string") {
        options[key] = [];
        tableData?.forEach((el) => {
          if (!!replaceable && !!replaceable[key]?.length) {
            replaceable[key]?.forEach((replace) => {
              for (const [replaceKey, value] of Object.entries(replace)) {
                if (!!value && options[key]?.indexOf(value) === -1)
                  options[key].push(value);
              }
            });
          } else {
            if (!!el?.[key] && options[key]?.indexOf(el?.[key]) === -1)
              options[key].push(el?.[key]);
          }
        });
      }
    });
    setOptions(options);
  }, []);

  return (
    <div style={{ zIndex: 5 }}>
      <Tooltip
        placement="top"
        overlayClassName="global-icon-tooltip"
        title={"Filter i avancuar"}
        style={{ opacity: 1.0 }}
      >
        <span style={{ zIndex: 5 }}>
          <IconFilter
            style={{ zIndex: 5 }}
            className="header-icons"
            onClick={() => setVisibleFilter(true)}
          />{" "}
        </span>
      </Tooltip>
      <Modal
        className="global-filter-modal"
        title={
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <span>Kërkim i Avancuar</span>
            {typeof filterActive == "boolean" && !filterActive && (
              <Tooltip title={"Po kërkon vetëm punonjësit aktivë!"}>
                <WarningIcon fill="#fff" width={14} height={14} />
              </Tooltip>
            )}
          </div>
        }
        destroyOnClose
        open={visibleFilter}
        onOk={() => setVisibleFilter(false)}
        onCancel={() => setVisibleFilter(false)}
        // destroyOnClose={true}
        footer={[
          <Button onClick={closeFilter} className="charge-close-btn">
            Mbyll
          </Button>,
          <Button
            onClick={() => setVisibleFilter(false)}
            className="charge-search-btn"
          >
            Kërko
          </Button>,
        ]}
      >
        <Row gutter={10} style={{ width: "100%" }}>
          {filterKeys?.map(({ key, label, type }) => (
            <Col className="coln-2" span={6}>
              <h3>{label}</h3>
              {type === "string" ? (
                <Select
                  showSearch
                  bordered={false}
                  className="filter-dropdown"
                  suffixIcon={<CaretDownFilled />}
                  placeholder={label}
                  onChange={generalFilter}
                >
                  {options?.[key]?.map((el) => (
                    <Option key={el} value={el}>
                      {el}
                    </Option>
                  ))}
                </Select>
              ) : type === "date-range" ? (
                <RangePicker
                  onChange={generalFilter}
                  format={"DD/MM/YYYY"}
                  clearIcon={<ClearIcon />}
                  suffixIcon={<IconCalendar />}
                  separator={<NextIcon />}
                />
              ) : null}
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
};

export default FilterAgGrid;
