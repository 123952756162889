import { useState } from "react";
import filterData from "./src/filterData";

function FilterHeader({ activeFilter, processActiveFilter }) {
  const [headerFilter, setHeaderFilter] = useState(activeFilter);
  return (
    <div className="notifs-extra-filters">
      {/* <span className="filter-title">Filtro sipas:</span> */}
      {filterData.map((el, idx) => (
        <span
          className="filter-btn"
          key={idx}
          style={
            {
              fontFamily: "Open Sans",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "19.07px",
              color: "#2E5D9E",
            }

            // el.id === activeFilter
            //   ? activeStyle(el.color2)
            //   : inactiveStyle(el.color)
          }
          onClick={() => processActiveFilter(el.id)}
        >
          {<el.Icon fill={el.id === activeFilter ? el.color2 : el.color} />}
          {el.title}
        </span>
      ))}
    </div>
  );
}

export default FilterHeader;

const activeStyle = (customColor) => {
  return {
    backgroundColor: customColor,
    color: " white",
    // border: "0.5px solid gray",
  };
};

const inactiveStyle = (customColor) => {
  return {
    backgroundColor: customColor,
    color: "white",
    // opacity: "50%",
  };
};
