import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./ClockOut.scss";
import "../AdminCards/ClockInStats/ClockInStats.scss";
import { iconPicker } from "../AdminCards/ClockInStats/iconPicker";
import { Col, Row } from "antd";
import { HollowDotsSpinner } from "react-epic-spinners";

const ClockOuts = () => {
  const { eligibleEmployeesClockIn } = useSelector(
    (state) => state.employeesList
  );
  const { clockings } = useSelector((state) => state.clockings);
  const [missedClockOutUsers, setMissedClockOutUsers] = useState(null);

  // * @LedioPilua - Changed the validation to fix issues & added dependencies
  useEffect(() => {
    if (!!clockings && eligibleEmployeesClockIn?.length > 0) {
      const missedClockOuts = clockings?.filter(
        (el) => el?.clockOutDate === null
      );
      const filteredUsers = eligibleEmployeesClockIn?.filter((el) =>
        missedClockOuts?.find((clk) => el?.employeeId === clk?.employeeId)
      );
      setMissedClockOutUsers(filteredUsers);
    }
  }, [clockings, eligibleEmployeesClockIn]);

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <>
      {!!missedClockOutUsers ? (
        <div className="delayed-clockouts-container">
          {missedClockOutUsers?.map((dco) => {
            return (
              <div className="dep-icon" key={dco?.employeeId}>
                <div
                  key={dco?.employeeId}
                  className="dep-icon-text"
                  style={{ width: "40%" }}
                >

                  {dco?.employeeFirstName + " " + dco?.employeeLastName}
                </div >

                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start", alignItems: 'center', gap: "10px", }} >
                  {iconPicker(dco?.employeeDepartmentName)}
                  <div
                    className="dep-icon-text"
                  >
                    {dco.employeeDepartmentName}
                  </div>
                </div>

              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}>
          <HollowDotsSpinner color="#1DA193" size={24} />
        </div>
      )}
    </>
  );
};
export default ClockOuts;
