import React, { useEffect, useMemo, useState } from "react";
import { Modal, Row, Col, Spin, Alert, Button, Tooltip } from "antd";
import moment from "moment-timezone";
import "./ActivityModal.scss";
import _ from "lodash";
import { array } from "prop-types";
import { ModalXIcon } from "../../../../../assets/icons";
import { PdfWhiteIcon } from "../../../../Documentation/View/assets";
import KeylogsExcelExporter from "../../../../Intervistat/MainTabs/KeylogsExcelExporter";
import { formatChanges } from "./formatChanges";
import exportPdf from "./exportPDF";

function ActivityModal({
  keylog,
  isModalVisible,
  setIsModalVisible,
  view,
  name = "",
}) {
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const filterKeylog = useMemo(() => {
    if (view === "effectiveDate") {
      return keylog?.filter?.(
        (el) => el?.oldValue !== el?.newValue && el?.field === "Data Efektive"
      );
    } else {
      return keylog?.filter?.((el) => el?.oldValue !== el?.newValue);
    }
  }, [keylog]);

  return (
    <Modal
      className="activity-modal"
      title="Ndryshimet që janë bërë"
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null}
      centered
      closeIcon={<ModalXIcon/>}
    >
      {filterKeylog?.length > 0 && (
        <div className="changes-container">
          {!!name?.length ? (
            <div className="exportButtons">
              <Tooltip
                placement="top"
                overlayClassName="global-icon-tooltip"
                title={"Eksporto në pdf"}
                className="exportKandidateBtn"
              >
                <Button
                  icon={<PdfWhiteIcon height={20} width={18} />}
                  className="exportKandidateBtn"
                  style={{ backgroundColor: "#ea3943" }}
                  onClick={() => exportPdf({ filterKeylog, name })}
                />
              </Tooltip>
              <KeylogsExcelExporter
                keylogs={filterKeylog}
                headers={tableHeaders}
                filename={`${name}${" "}Keylogs`}
              />
            </div>
          ) : null}
          <Row key={'header-row'} className="table-header">
            {tableHeaders?.map((h, idx) => (
              <Col span={4} key={idx}>{h?.value}</Col>
            ))}
          </Row>
          <div className="table-body">
            {filterKeylog?.map((data, i) => {
              return (
                <div
                  style={{
                    backgroundColor: `${i % 2 === 0 ? "#F5F5F7" : "#fff"}`,
                  }}
                >
                  <Row className="data-rows" key={'data-row'} gutter={6}>
                    <Col span={4}>{data?.activity}</Col>
                    <Col span={4}>
                      {moment(data?.changeDate).format("DD/MM/YYYY H:mm")}
                    </Col>
                    <Col span={4}>{data?.author}</Col>
                    <Col span={4}>{data?.field}</Col>
                    <Col span={4}>{formatChanges(data?.oldValue)}</Col>
                    <Col span={4}>{formatChanges(data?.newValue)}</Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!filterKeylog?.length && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert
            message={"Nuk ka ndryshime për këtë rekord"}
            type="info"
            showIcon
          />
        </div>
      )}
    </Modal>
  );
}
export default ActivityModal;

export const tableHeaders = [
  { key: "activity", value: "Aktiviteti" },
  { key: "changeDate", value: "Ndodhur më" },
  { key: "author", value: "Ndryshuar nga" },
  { key: "field", value: "Fusha" },
  { key: "oldValue", value: "Vlera e vjetër" },
  { key: "newValue", value: "Vlera e re" },
];
