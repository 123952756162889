import React, { useState, useEffect } from "react";
import { Button, Select, Form, Input, Checkbox, message } from "antd";
import { DeleteIcon, XIcon } from "../../../../assets/icons";
import { CaretDownOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";
import { useZbritjetContext } from "./ZbritjetContext";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import { useAuth } from "../../../../authentication/authHelpers";
import { v4 as uuidv4 } from "uuid";

function ZbritjetEditCard({ Data, id, handleHideEditView, socketHandler }) {
	const { Option } = Select;
	const [form] = Form.useForm();
	const { userAccess } = useAuth();

	const username = {
		userId: userAccess[0].userSub,
		employeeFirstName: userAccess[0].given_name,
		employeeLastname: userAccess[0].family_name,
	};
	//get the data with context
	const { setTableData, setToBeEdited } = useZbritjetContext();

	// state with the value
	const [saveData, setSavedData] = useState({
		...Data,
	});

	// change the vlaue of the state
	const handleChange = (objField, e) => {
		let test = objField;
		setSavedData((prev) => ({ ...prev, [test]: e }));
	};
	//function to compare 2 object and get the changes
	function compareObjects(previousObject, currentObject) {
		const keys1 = Object.keys(previousObject);
		const keys2 = Object.keys(currentObject);
		let changesArray = [];

		const commonKeys = keys1.filter((key) => keys2.includes(key));
		if (commonKeys.length === 0) {
		}
		commonKeys.forEach((key) => {
			if (typeof currentObject[key] === "object") {

			} else {
				if (previousObject[key] !== currentObject[key]) {
					let changedData = {
						id: uuidv4(),
						activity: "Ndryshuar",
						author: username,
						changeDate: Date.now(),
						field: key,
						previousData: previousObject[key],
						currentData: currentObject[key],
					};
					changesArray.push(changedData);
				}
			}
		});
		if (changesArray !== 0) {
			return changesArray;
		} else {
			return false;
		}
	}

	console.log(saveData);

	// update function
	const updateItems = async () => {
		const { deductionId, userId, ...rest } = saveData;
		let logs = compareObjects(Data, saveData)
		await API.put("deductions", `/deductions/${Data.deductionId}`, {
			body: { ...rest, deductionLogs: !!Data?.deductionLogs ? [...saveData.deductionLogs, logs] : [logs] },
		}).then(() => {
			/**
			 * * @EneaXharau - Added socket emitter
			 * * .send() takes -> { request: String, body: any} -> stringified
			 */
			// if (socketHandler.readyState === socketHandler.OPEN) {
			// 	socketHandler.send(JSON.stringify({ request: "loss_updated", body: "A loss was updated" }));
			// }
			setTableData((prev) =>
				prev?.map((item) => (item.deductionId === saveData.deductionId ? { ...saveData, deductionLogs: [...saveData.deductionLogs, logs] } : item))
			);

			setToBeEdited(null);
			successUpdate();
		});
		// console.log(saveData);
	};

	// update message after updating one card
	const successUpdate = () => {
		message.success("Te dhenat u ndryshuan me sukses !", 10);
		window.localStorage.removeItem("draggableSetingsZbritjet");
	};

	//delete card
	const deleteItems = async () => {
		await API.del("deductions", `/deductions/${Data.deductionId}`).then(() => {
			/**
			 * * @EneaXharau - Added socket emitter
			 * * .send() takes -> { request: String, body: any} -> stringified
			 */
			// if (socketHandler.readyState === socketHandler.OPEN) socketHandler.send(JSON.stringify({ request: "loss_removed", body: "A loss was removed" }));
			setTableData((prev) => prev.filter((item) => item.deductionId !== saveData.deductionId));
			success();
		});
	};

	//show   Delete message  after deleting one card
	const success = () => {
		window.localStorage.removeItem("draggableSetingsZbritjet");
		message.success("Te dhenat u fshin me sukses !", 10);
	};

	// its for the form to get initail value from server
	useEffect(() => {
		form.setFieldsValue({
			deductionAmount: Data.deductionAmount,
			// deductionFormula: Data.deductionFormula,
			deductionName: Data.deductionName,
			// deductionRate: Data.deductionRate,
			// deductionType: Data.deductionType,
			deductionRule: Data.deductionRule,
			excludeFromTotalHours: Data.excludeFromTotalHours,
		});
	}, [Data, form]);

	return (
		<div className="fitimet-edit-card">
			<Form name="modal-zbritjet" onFinish={updateItems} form={form}>
				<div className="input-edit-festat" style={{ width: "100%" }}>
					<h3>Titulli:</h3>
					<Form.Item
						name="deductionName"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruaj Titullin !",
							},
						]}
					>
						<Input
							type="text"
							autoComplete="off"
							onChange={(e) => handleChange("deductionName", e.target.value)}
							placeholder="Shkruaj Titullin"
							style={{ width: 222 }}
						/>
					</Form.Item>
				</div>
				{/* <div className="edit-festat-dropdown" style={{ width: "100%" }}>
          <h3 style={{ width: "100%" }}>Lloji i zbritjes:</h3>
          <Form.Item
            name="deductionType"
            rules={[
              {
                required: true,
                message: "Selekte nje opsion !",
              },
            ]}
          >
            <Select
              placeholder="Selekto"
              onChange={(val) => handleChange("deductionType", val)}
              style={{ width: 222 }}
              bordered={false}
              dropdownClassName="festat-edit-dropdown"
              suffixIcon={<CaretDownOutlined />}
            >
              <Option value="Software Developer">Software Developer</Option>
              <Option value="React Developer">React Developer</Option>
              <Option value="Node Developer">Node Developer</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="edit-festat-dropdown" style={{ width: "100%" }}>
          <h3 style={{ width: "100%" }}>Rregulli i llogaritjes:</h3>
          <Form.Item
            name="deductionFormula"
            rules={[
              {
                required: true,
                message: "Selekte nje opsion !",
              },
            ]}
          >
            <Select
              placeholder="Selekto"
              onChange={(val) => handleChange("deductionFormula", val)}
              style={{ width: 222 }}
              bordered={false}
              dropdownClassName="festat-edit-dropdown"
              suffixIcon={<CaretDownOutlined />}
            >
              <Option value="Software Developer">Software Developer</Option>
              <Option value="React Developer">React Developer</Option>
              <Option value="Node Developer">Node Developer</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="input-edit-festat" style={{ width: "100%" }}>
          <h3>Raporti:</h3>
          <Form.Item
            name="deductionRate"
            rules={[
              {
                required: true,
                message: "Ju lutem shkruaj Raportin !",
              },
            ]}
          >
            <Input
              type="number"
              onChange={(e) => handleChange("deductionRate", e.target.value)}
              autoComplete="off"
              placeholder="0.00"
              style={{ width: 222 }}
            />
          </Form.Item>
        </div> */}
				<div className="input-edit-festat" style={{ width: "100%" }}>
					<h3>Rregulli:</h3>
					<Form.Item
						name="deductionRule"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruaj Faktorin e raportit !",
							},
						]}
					>
						<Input
							type="number"
							onChange={(e) => handleChange("deductionRule", e.target.value)}
							autoComplete="off"
							placeholder="0.00"
							style={{ width: 222 }}
						/>
					</Form.Item>
				</div>
				<div className="input-edit-festat" style={{ width: "100%" }}>
					<h3>Vlera:</h3>
					<Form.Item
						name="deductionAmount"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruaj Vlerën !",
							},
						]}
					>
						<Input
							type="number"
							onChange={(e) => handleChange("deductionAmount", e.target.value)}
							autoComplete="off"
							placeholder="0.00"
							style={{ width: 222 }}
						/>
					</Form.Item>
				</div>
				<Form.Item
					name="excludeFromTotalHours"
					valuePropName="checked"
				// rules={[
				//   {
				//     validator: async (_, checked) => {
				//       if (!checked) {
				//         return Promise.reject(
				//           new Error("Ju lutem Zgjidh Opsionin !")
				//         );
				//       }
				//     },
				//   },
				// ]}
				>
					<Checkbox
						onChange={(e) => handleChange("excludeFromTotalHours", e.target.checked)}
						className="edit-festat-checkbox"
						style={{ width: "100%" }}
					>
						<h3 style={{ width: 222 }}>Përjashtoje në shfaqjen e orëve totale</h3>
					</Checkbox>
				</Form.Item>
				<Form.Item>
					<div className="footer-edit-card">
						<div className="icon-goBack" onClick={() => handleHideEditView(id)}>
							<XIcon />
						</div>
						<GlobalPopupKonfirm
							Button={
								<div
									className="icon-del"
								//  onClick={deleteItems}
								>
									<DeleteIcon width={18} height={18} />
								</div>
							}
							title={"Jeni të sigurt që do të fshini këtë rekord?"}
							onConfirm={deleteItems}
							okText={"Po"}
							cancelText={"Jo"}
						/>
						<Button htmlType="submit" className="ruaj-foter-card">
							Ruaj Ndryshimet
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
}
export default ZbritjetEditCard;
