import { useState, useEffect } from "react";
import "./SpinningRacket.scss";
import { RacketIcon, DoneIcon } from "../../../assets/icons";
function SpinningRacket() {
  const [enable, setEnabled] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setEnabled(true);
    }, 600);
  }, []);
  return (
    <div className="racket-component">
      <div className="middle">
        <div className="racket-circle-outline">
          {!enable ? (
            <RacketIcon className="racket-success" />
          ) : (
            <DoneIcon className="tick-success" />
          )}
        </div>
      </div>
    </div>
  );
}

export default SpinningRacket;
