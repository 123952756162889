import { Button, Card, Form, Input, Select } from "antd";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { CloseIcon, TickIcon } from "../../assets/icons";
import {
	CivilEngineeringIcon,
	FinanceIcon,
	FlexDepartmentIcon,
	HRIcon,
	InternalStaffIcon,
	SalesManagementIcon,
	SchedulingIcon,
	SoftwareDevelopmentIcon,
} from "../assets";
import "./Departmentcard.scss";

const EditDepartment = ({ hideEditView, department, refreshRequests, socketHandler }) => {
	const { Option } = Select;
	const [form] = Form.useForm();
	const { users } = useSelector((state) => state.usersList);
	const [dataValue, setDataValue] = useState(null);

	//! saves changes that you make at EditDepartment
	const handleSave = async () => {
		const departmentManagerId = form.getFieldValue("departmentManagerId");
		const departmentName = form.getFieldValue("departmentName");
		const roles = form.getFieldValue("roles");

		await API.put("departments", `/departments/${department.departmentId}`, {
			body: {
				departmentName: departmentName,
				departmentManagerId: departmentManagerId,
				departmentManagerName: dataValue,
				departmentRoles: roles.map((r) => {
					return { name: r, id: v4() };
				}),
			},
		}).then(() => {
			/**
			 * * @EneaXharau - Added socket emitter
			 * * .send() takes -> { request: String, body: any} -> stringified
			 */
			// if (socketHandler.readyState === socketHandler.OPEN) {
			// 	socketHandler.send(
			// 		JSON.stringify({
			// 			request: "department_change",
			// 			body: "A department was changed.",
			// 		})
			// 	);
			// }
			refreshRequests();
			hideEditView(department.departmentId);
		});
	};

	//! gets users and shows them when you want to edit supervisor for a specific department
	function handleChange(value) {
		let data = users?.allUsers?.Items?.find((val) => val.identityId === value);
		setDataValue(`${data?.given_name} ${data?.family_name}`);
	}

	// * @EneaXharau -  Added departments dependency to re-display values
	useEffect(() => {
		if (!!department) {
			let data = department.departmentManagerName;
			setDataValue(data);
		}
	}, [department]);

	//* setsFields of departments at EditDepartment
	useEffect(() => {
		form.setFieldsValue({
			departmentName: department.departmentName,
			departmentManagerId: department.departmentManagerId,
			departmentManagerName: department.departmentManagerName,
			roles: department.departmentRoles?.map((el) => el.name),
		});
	}, [department]);

	const departmentIcons = {
		"Finance & Accounting": FinanceIcon,
		"Architecture & Engineering": CivilEngineeringIcon,
		"IT & Software Development": SoftwareDevelopmentIcon,
		"Human Resources": HRIcon,
		Administration: FlexDepartmentIcon,
		"Project Monitoring & Coordinating": SchedulingIcon,
		"Sales Management": SalesManagementIcon,
		"Internal Staff": InternalStaffIcon,
	};

	return (
		<Form form={form} onFinish={handleSave} name="saveEditingIcon">
			<Card
				key={department.departmentId}
				title={
					<div className="cardsHeader">
						<div className="departmentHeaderIcons">
							<img src={departmentIcons[department.departmentName]} />
							<span className="header">{department.departmentName}</span>
						</div>
						<div className="editCardHeaderIcons">
							<Form.Item>
								<div className="editButtons">
									<CloseIcon
										className="closeEditCardIcon"
										onClick={() => hideEditView(department.departmentId)}
									/>
									<Button className="saveEditCardButton" form="saveEditingIcon" htmlType="submit">
										<TickIcon className="saveEditCardIcon" />
									</Button>
								</div>
							</Form.Item>
						</div>
					</div>
				}
			>
				<div className="editSelect">
					<Form.Item className="editLabel" label="Emri i departamentit" name="departmentName">
						<Input autoComplete="off" className="editDepartmentName" />
					</Form.Item>
					<Form.Item className="editLabel" label="Supervizori" name="departmentManagerId">
						<Select
							className="editSupervisor"
							onChange={handleChange}
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{users?.allUsers?.Items?.map((el) => (
								<Option value={el?.identityId}>{`${el?.given_name} ${el?.family_name}`}</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item className="editLabel" label="Rolet" name="roles">
						<Select mode="tags" className="editRoles"></Select>
					</Form.Item>
				</div>
			</Card>
		</Form>
	);
};
export default EditDepartment;
