import { useState } from "react";
import { Modal, Input, message } from "antd";
import moment from "moment-timezone";
import { API } from "aws-amplify";
import MondayButton from "../../../components/commonComponents/MondayButton/MondayButton";
import { CheckOutlined, SaveFilled } from "@ant-design/icons";
import {
  Check,
  CloseIcon,
  IconaMbyll,
  ModalXIcon,
} from "../../../assets/icons";
import "./EditQOTD.scss";
import { useAuth } from "../../../authentication/authHelpers";
const { TextArea } = Input;

function EditQOTD({ qotd, setQotd }) {
  const [visible, setVisible] = useState(false);
  const [newData, setNewData] = useState({
    quote: qotd?.quote.toString(),
    author: qotd?.author,
  });
  const auth = useAuth();

  const submitNewQOTD = async (quote, author) => {
    await API.put(
      "programFields",
      "/programFields/9d4c8f40-3a6d-11ed-a92c-178f43e8cbef",
      {
        body: {
          fieldOptions: {
            date: moment().format("DD/MM/YYYY"),
            quote: newData?.quote,
            author: newData?.author,
            editedBy:
              auth?.userAccess?.[0]?.given_name +
                " " +
                auth?.userAccess?.[0]?.family_name ?? "",
          },
        },
      }
    ).then((res) => {
      setQotd({ quote: newData?.quote, author: newData?.author });
      message.success("Quote of the day updated successfully");
      setVisible(false);
    });
  };

  const changeHandler = (key, val) => {
    setNewData((prev) => ({ ...prev, [key]: val }));
  };

  return (
    <div>
      {" "}
      {auth?.userRole === "Admin" && (
        <>
          {" "}
          <span
            style={{ color: "grey", cursor: "pointer", marginLeft: "10px" }}
            onClick={() => setVisible(true)}
          >
            edit
          </span>
          <Modal
            title="Change Quote Of The Day"
            open={visible}
            destroyOnClose
            className="edit-qotd-modal"
            onCancel={() => setVisible(false)}
            closeIcon={<ModalXIcon />}
            footer={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <MondayButton
                  className="mondayButtonRed"
                  onClick={() => setVisible(false)}
                  Icon={<IconaMbyll />}
                >
                  Cancel
                </MondayButton>
                <MondayButton
                  onClick={() => submitNewQOTD(qotd)}
                  Icon={<CheckOutlined />}
                  disabled={
                    newData?.quote === qotd?.quote &&
                    newData?.author === qotd?.author
                  }
                >
                  Submit
                </MondayButton>
              </div>
            }
          >
            <div className="edit-qotd-wrapper">
              <div className="input-holder">
                <span>Quote</span>
                <TextArea
                  value={newData?.quote || ""}
                  onChange={(e) => changeHandler("quote", e.target.value)}
                />
              </div>
              <div className="input-holder">
                <span>Author</span>
                <Input
                  value={newData?.author || ""}
                  onChange={(e) => changeHandler("author", e.target.value)}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default EditQOTD;
