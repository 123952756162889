const DOC = "doc";
const DOCX = "docx";
const PDF = "pdf";
const PNG = "png";
const JPG = "jpg";
const EXCEL = "xlsx";
const PP = "pptx";
const WEBP = "webp";

export const generateDocumentViewLink = ({ fileName = "", src }) => {
  const fileType = fileName.split(".")[fileName.split(".").length - 1];
  switch (fileType) {
    case DOC:
      return `data:application/msword;base64,${src.split(",")[1]}`;
    case DOCX:
      return `data:application/msword;base64,${src.split(",")[1]}`;
    case PDF:
      return `data:application/pdf;base64,${src.split(",")[1]}`;
    case PNG:
      return `data:image/png;base64,${src.split(",")[1]}`;
    case JPG:
      return `data:image/jpg;base64,${src.split(",")[1]}`;
    case WEBP:
      return `data:image/png;base64,${src.split(",")[1]}`;
    case EXCEL:
      return `data:application/msexcel;base64,${src.split(",")[1]}`;
    case PP:
      return `data:application/mspowerpoint;base64,${src.split(",")[1]}`;
    default:
      return null;
  }
};
