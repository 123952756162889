import { useSelector } from "react-redux";

/**
 * This hook gets programFields from redux and transforms them into an object
 *
 * @returns Object Formatted programFields object {[fieldName]: fieldOptions}
 */

export const useProgramFields = () => {
  const { programFields = [] } = useSelector((state) => state.programFields);

  const programFieldsObj = programFields.reduce(
    (acc, { fieldName, fieldOptions }) => ({
      ...acc,
      [fieldName]: fieldOptions,
    }),
    {}
  );
  return programFieldsObj;
};
