import moment from "moment";

export const getOnlineEmployees = (dataList, subDataList) => {
  if (!subDataList?.length || !dataList) {
    return [];
  }
  let foundOnline = subDataList?.map((subEl) => {
    let user = dataList?.find((el) => el.userSub === subEl.userSub);
    if (!!user && !!user?.lastLoggedIn) {
      let active =
        moment().diff(moment(user.lastLoggedIn), "minutes", true) > 16
          ? false
          : true;
      return {
        ...subEl,
        isActive: active,
      };
    } else {
      return { ...subEl };
    }
  });
  return foundOnline;
};
