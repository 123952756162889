import { Button, Form, Input, message, Select } from "antd";
import { API } from "aws-amplify";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import ReturnImg from "../../../../utils/ReturnImg";
import "./info_rreth_kompanis.scss";
function EmployOfMonthContent() {
  const { Option } = Select;
  //get active employes from redoux
  const { activeEmployees } = useSelector((state) => state.employeesList);
  // state with selected employe return object
  const [currentEmp, setCurrentEmp] = useState(null);
  // state with data for employe of month , week from program fields return object
  const [emplWeekMonth, setEmplWeekMonth] = useState(null);
  // program fieds data return arrray
  const [tableData, setTableData] = useState(null);
  // input mesage return input mesage value
  const [messageInput, setMessageInput] = useState(null);

  //select the current employe
  const onSelect = (e) => {
    setCurrentEmp(activeEmployees?.find?.((el) => el?.employeeId === e) || {});
  };

  //store the input value  in state
  const onSelectMessage = (e) => {
    setMessageInput(e?.target?.value);
  };

  // find and store in state employe month & week data from program fields
  const EmpMonthWeek = () => {
    const kompaniaFieldOptions = tableData?.find?.(
      (data) => data?.fieldName === "Employees of the Week & Month"
    );
    setEmplWeekMonth(kompaniaFieldOptions?.fieldOptions);
  };

  // get data from api
  const getProgramFields = () => {
    API.get("programFields", "/programFields").then((r) => setTableData(r));
  };

  // show ProgramFields data
  useEffect(() => {
    getProgramFields();
  }, []);

  useEffect(() => {
    if (!!tableData) {
      EmpMonthWeek();
    }
  }, [tableData]);

  // save the data in db
  const handleSaveEmpMonth = async () => {
    let now = moment();
    let startMonth = now.clone().startOf("month");
    let endMonth = now.clone().endOf("month");
    let prev = emplWeekMonth?.previous;
    let initialMessazh = "Jemi krenarë për ty! Urime për punën e bërë mirë!";
    const monthData = {
      start: moment(startMonth).valueOf(),
      end: moment(endMonth).valueOf(),
      userSub: currentEmp?.userSub || "",
      name:
        Object.keys(currentEmp || {})?.length > 0
          ? `${currentEmp?.employeeFirstName} ${currentEmp?.employeeLastName}`
          : "",
      type: "month",
      mesazhPublikMonth: messageInput || initialMessazh || "",
      googleDriveFileId: currentEmp?.googleDriveFileId || "",
    };
    await API.put(
      "programFields",
      "/programFields/4sf-s5d4f-645dfhgrt-9hg89o8775",
      {
        body: {
          fieldOptions: {
            current: emplWeekMonth?.current || {},
            month: monthData,
            previous: [...prev, monthData],
          },
        },
      }
    ).then(() => {
      message.success("Të dhënat u ndryshuan me sukses");
    });
  };

  useEffect(() => {
    if (!!emplWeekMonth) {
      setCurrentEmp(
        activeEmployees?.find?.(
          (el) =>
            `${el?.employeeFirstName} ${el?.employeeLastName}` ===
            emplWeekMonth?.month?.name
        )
      );
    }
  }, [emplWeekMonth]);

  return (
    <div className="emp-month-container">
      <div className="items-emp-month">
        <Form.Item
          style={{ width: "100%" }}
          name="nameMonth"
          label="Punonjësit:"
          rules={[
            {
              required: false,
              message: "Zgjidhni Punonjësin!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch={true}
            bordered={false}
            style={{
              width: "100%",
              height: 32,
              borderRadius: 5,
              background: "#f5f5f7",
              margin: 0,
            }}
            placeholder="Punonjësit"
            onChange={(val) => onSelect(val)}
          >
            {activeEmployees?.map((emp) => (
              <Option value={emp?.employeeId} key={emp?.employeeId}>
                {`${emp?.employeeFirstName} ${emp?.employeeLastName}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: "100%" }}
          name="mesazhPublikMonth"
          label="Mesazh publik:"
          rules={[
            {
              required: false,
              message: "Zgjidhni Punonjësin!",
            },
          ]}
        >
          <Input.TextArea
            rows={6}
            bordered={false}
            style={{
              width: "100%",
              //   height: 32,
              borderRadius: 5,
              background: "#f5f5f7",
              margin: 0,
            }}
            placeholder="Mesazh publik"
            onChange={(val) => onSelectMessage(val)}
          ></Input.TextArea>
        </Form.Item>
        <div className="div-footer">
          <Button className="publikoBtn" onClick={() => handleSaveEmpMonth()}>
            Publiko
          </Button>
        </div>
      </div>
      <div className="img-emp-month">
        {!!currentEmp ? (
          <div className="img-div">
            {
              <ReturnImg
                width={220}
                height={220}
                borderRadius={5}
                googleDriveFileId={currentEmp?.googleDriveFileId}
                defaultImg={
                  <Avatar
                    name={
                      Object.keys(currentEmp || {})?.length > 0 &&
                      currentEmp?.employeeFirstName +
                        " " +
                        currentEmp?.employeeLastName
                    }
                    size="180"
                    round={true}
                  />
                }
              />
            }
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default EmployOfMonthContent;
