import React, { useEffect, useState } from "react";
import { Modal, Select, DatePicker, Form, Button, message, Input } from "antd";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import { RichTextEditor } from "../../../../commonComponents";
import { useDispatch, useSelector } from "react-redux";
import "./AddLeave.scss";
import { RequestChair } from "../../../assets";
import { notificationUserToAdmin } from "../../../../../utils/sendNotification";
import { useAuth } from "../../../../../authentication/authHelpers";
import { employeeRequests as employeeReducer } from "../../../../../store/actions";
import { requestDataWithParams } from "../../../../../helpers/API/RequestFactory";
import { ModalXIcon } from "../../../../../assets/icons";

// * @EneaXharau - Websocket handler and listener
// ----------------------
const AddLeave = ({
  isAddModalVisible,
  setIsAddModalVisible,
  form,
  tableData,
  setIsAddLeaveEmailOpen,
  setEmailObject,
  editData,
  setEditData,
}) => {
  const { employees } = useSelector((state) => state.employeesList);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { programFields } = useSelector((state) => state.programFields);
  const { clockings } = useSelector((state) => state.clockings);
  const { users } = useSelector((state) => state.usersList);
  const Option = Select;
  const dispatch = useDispatch();
  const userDataAuth = useAuth();
  const [chosenStartDate, setChosenDate] = useState("");
  const [chosenEndDate, setChosenEndDate] = useState("");
  const [isDisabled, setIsDisabled] = useState("");
  const [text, setText] = useState(false);
  const [vacFields, setVacFields] = useState([]);
  const [disabled, setDisabled] = useState(0);
  //Fshin te gjitha requests e users
  // const deleteRequests = async () => {
  // 	await requestAllPages("employeeRequests", "requestId")
  // 		.then(async (res) => {
  // 			await res?.map(
  // 				async (request) =>
  // 					await API.del("employeeRequests", `/employeeRequests/${request?.requestId}`)
  // 			);
  // 		})
  // 		.then(() => message.success("Success"));
  // };
  /**
   * * @EneaXharau ->
   * * Filtron kerkesat duke zgjdhur ato qe e kane daten e mbarimit, me te madhe se
   * * data e sotme, pra kerkesat qe nuk kane skaduar dhe kerkesat qe i perkasin vetem user-it qe eshte zgjedhur
   */

  const filteredRequests = (allRequestsData) => {
    let filteredReq = allRequestsData?.filter(
      (req) => req?.requestPeriod?.slice(-1)[0] >= moment().valueOf()
    );
    return filteredReq?.filter(
      (rec) =>
        rec?.userSub === form.getFieldsValue().employeeName?.split(" ")[2]
    );
  };
  const handleOk = () => {
    setIsAddModalVisible(false);
    setEditData(false);
  };
  const handleCancel = () => {
    setEditData(false);
    setIsAddModalVisible(false);
  };
  //Mbyll modalin dhe i ben reset fushave te form
  const handleDecline = () => {
    setEditData(false);

    handleCancel();
    form.resetFields();
  };
  //Kontrollon nqs useri ka krijuar nje kerkese me para qe perplaset me kerkesen e re qe do te krijoje
  //pra nqs dy intervalet e pushimeve kane qofte edhe nje vlere te njejte.
  const checkIfUserHasRequested = (existingPeriod, newPeriod) => {
    const contains = existingPeriod.some((element) => {
      return newPeriod.includes(element);
    });
    return contains;
  };

  /**
   * * @EneaXharau - Async function taking no parameters
   * * it awaits for redux to fetch data from AWS database
   * * then sets in state which also re-renders component to display new info
   */
  const refreshRequests = async () => {
    return await requestDataWithParams(
      "employeeRequests",
      moment().subtract(2, "years").valueOf()
    ).then((res) => dispatch(employeeReducer(res || [])));
  };
  //Shton ne nje array te gjitha datat e segmentit [startDate, endDate]
  var enumerateDaysBetweenDates = function (startDate, endDate) {
    var dates = [];
    var currDate = moment(startDate).startOf("day"); //fillimi i pushimeve
    var lastDate = moment(endDate).startOf("day"); //perfundimi i pushimeve
    dates.push(currDate.valueOf());
    while (currDate.add(1, "day").diff(lastDate) <= 0) {
      dates.push(currDate.clone().valueOf());
    }
    return dates;
  };

  //function to send an email when a leave is created

  const handleSave = () => {
    let startDate = form.getFieldsValue().startDate;
    let endDate = form.getFieldsValue().endDate;
    //Kur leja e zgjedhur eshte ditore, data e perfundimit vendoset
    //automatikisht data e fillimit + 24h dhe behet disable.
    // if (form.getFieldsValue().requestType !== "Dite Personale") {
    //   endDate = form.getFieldsValue().endDate;
    // }
    let empName = form.getFieldsValue().employeeName;
    let timePeriod = enumerateDaysBetweenDates(startDate, endDate);
    let allFilteredRequests = filteredRequests(tableData);
    if (editData) {
      allFilteredRequests = allFilteredRequests.filter(
        (item) => item.requestId !== editData?.requestId
      );
    }
    //checkTimePeriods nje vektor me vlera true ose false, ne varesi nqs
    //periudha kohore e marre nga db ka dite te perbashketa me intervalin e kerkeses se re
    const checkTimePeriods = allFilteredRequests?.map((fr) => {
      return checkIfUserHasRequested(fr.requestPeriod, timePeriod);
    });
    let dates = enumerateDaysBetweenDates(startDate, endDate);
    //objekti newRequest do te shtohet ne db
    let splitEmplName = empName?.split(" ")?.filter(Boolean);
    let newRequest = {
      userSub: splitEmplName[2],
      family_name: splitEmplName[1],
      given_name: splitEmplName[0],
      requestType: form.getFieldsValue().requestType,
      requestPeriod: dates,
      requestDescription: form.getFieldsValue().requestDescription,
      creatorIsAdmin: true,
      employeeId: splitEmplName?.[3],
    };

    const desiredEmpl = employees.find(
      (empl) => empl.userSub === splitEmplName[2]
    );
    //Nese vektori checkTimePeriods permban nje vlere true
    //dmth qe per kete punonjes, ne njeren nga lejet e tij te meparshme ka dite qe i perkasin
    //intervalit te ri, qe ai kerkon te marre leje
    if (!checkTimePeriods.includes(true)) {
      if (
        !!desiredEmpl &&
        clockings.some(
          (el) =>
            el.employeeId === desiredEmpl.employeeId &&
            dates.some(
              (d) =>
                moment(d).format("DD/MM/YYYY") ===
                moment(el.clockInDate).format("DD/MM/YYYY")
            )
        )
      ) {
        message.warning(
          "Ju nuk mund të shtoni një kërkesë në të njëjtën periudhë me clockIn!",
          8
        );
      } else {
        if (editData) {
          setDisabled((prev) => prev + 1);
          API.put(
            "employeeRequests",
            `/employeeRequests/${editData?.requestId}`,
            {
              body: newRequest,
            }
          ).then((r) => {
            /**
             * * @EneaXharau - Added socket emitter
             * * .send() takes -> { request: String, body: any} -> stringified
             */

            // let index = tableData.findIndex(
            //   (data) => data.requestId === editData
            // );
            // setTableData((prev) => {
            //   const updatedData = [...prev];
            //   updatedData[index] = {
            //     ...newRequest,
            //     requestStatus: updatedData[index].requestStatus,
            //     requestId: editData,
            //   };
            //   return updatedData;
            // });
            message.success("Të dhënat u ndryshuan !", 8);
            form.resetFields();
            handleCancel();
            // * @EneaXharau - Added if condition to send only when there are users to send to
            if (!!users && users?.allUsers?.Items?.length > 0) {
              notificationUserToAdmin(
                {
                  notificationTitle: "Kërkesë për leje",
                  createdAt: moment().format("DD/MM/YYYY"),
                  notificationPriority: false,
                  notificationCategory: "Critical",
                  notificationBody: `${
                    userDataAuth?.userAccess[0]?.given_name
                  }  ${
                    userDataAuth?.userAccess[0]?.family_name
                  } bëri kërkesë për leje për datën ${moment(startDate).format(
                    "DD/MM/YYYY"
                  )} deri ${moment(endDate).format(
                    "DD/MM/YYYY"
                  )} për punonjësin ${newRequest.given_name} ${
                    newRequest.family_name
                  }`,
                  isAnnouncement: false,
                  notificationUrl: "/kryefaqja",
                  notificationFirstName:
                    userDataAuth?.userAccess[0]?.given_name,
                  notificationLastName:
                    userDataAuth?.userAccess[0]?.family_name,
                },
                users?.allUsers?.Items
              );
              setEmailObject({
                admin: userDataAuth,
                startDate: startDate,
                endDate: endDate,
                user: newRequest,
              });
              setIsAddLeaveEmailOpen(true);
            }
            refreshRequests();
          });
        } else {
          setDisabled((prev) => prev + 1);
          API.post("employeeRequests", "/employeeRequests", {
            body: newRequest,
          }).then((r) => {
            /**
             * * @EneaXharau - Added socket emitter
             * * .send() takes -> { request: String, body: any} -> stringified
             */
            // setTableData((prev) => [...prev, r]);
            message.success("Të dhënat u ndryshuan !", 8);
            form.resetFields();
            handleCancel();
            // * @EneaXharau - Added if condition to send only when there are users to send to
            if (!!users && users?.allUsers?.Items?.length > 0) {
              notificationUserToAdmin(
                {
                  notificationTitle: "Kërkesë për leje",
                  createdAt: moment().format("DD/MM/YYYY"),
                  notificationPriority: false,
                  notificationCategory: "Critical",
                  notificationBody: `${
                    userDataAuth?.userAccess[0]?.given_name
                  }  ${
                    userDataAuth?.userAccess[0]?.family_name
                  } bëri kërkesë për leje për datën ${moment(startDate).format(
                    "DD/MM/YYYY"
                  )} deri ${moment(endDate).format(
                    "DD/MM/YYYY"
                  )} për punonjësin ${newRequest.given_name} ${
                    newRequest.family_name
                  }`,
                  isAnnouncement: false,
                  notificationUrl: "/kryefaqja",
                  notificationFirstName:
                    userDataAuth?.userAccess[0]?.given_name,
                  notificationLastName:
                    userDataAuth?.userAccess[0]?.family_name,
                },
                users?.allUsers?.Items
              );
              setEmailObject({
                admin: userDataAuth,
                startDate: startDate,
                endDate: endDate,
                user: newRequest,
              });
              setIsAddLeaveEmailOpen(true);
            }
            refreshRequests();
          });
        }
      }
    } else {
      message.warning(
        "Ju nuk mund të shtoni një kërkesë në të njëjtën periudhë kohore me kërkesat e mëparshme!",
        8
      );
    }
  };

  let prgFields = programFields?.find(
    (rf) => rf?.fieldId === "e480aa5f-a671-4781-a8bb-89876006cc90"
  );

  const excludeWeekends = (startDate, endDate) => {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = new Date(curDate).getDay();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
      if (!isWeekend) {
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000;
    }
    return count;
  };

  useEffect(() => {
    if (isAddModalVisible) {
      setDisabled(0);
    }
    if (editData) {
      setChosenDate(editData?.requestPeriod.slice(-1)[0]);
    }
  }, [isAddModalVisible]);

  return (
    <div>
      <Modal
        className="add-new-request"
        title="Krijo një kërkesë për leje"
        open={isAddModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<ModalXIcon />}
        footer={[
          <Button className="decline" onClick={handleDecline}>
            {editData ? "Anullo Ndryshimet" : "Anullo"}
          </Button>,
          <Button
            className="send-request"
            htmlType="submit"
            key="submit"
            form="add-leave"
            disabled={disabled === 1 ? true : false}
          >
            {editData ? "Ndrysho kërkesën" : "Krijo kërkesën"}
          </Button>,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Form
            className="personal-info"
            onFinish={handleSave}
            form={form}
            name="add-leave"
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 16,
            }}
            autoComplete="off"
            autoFocus
          >
            <Form.Item
              label="Emri i punonjesit"
              name="employeeName"
              rules={[
                { required: true, message: "Kjo fushë nuk mund të jetë bosh!" },
              ]}
            >
              <Select
                disabled={!!editData}
                showSearch
                className="select-details"
                placeholder="Selekto emrin e punonjësit"
              >
                {employees
                  ?.filter?.(({ employeeStatus }) => employeeStatus === "Aktiv")
                  ?.map?.((employee) => {
                    return (
                      <Option
                        value={`${employee?.employeeFirstName} ${employee?.employeeLastName} ${employee?.userSub} ${employee?.employeeId}`}
                      >{`${employee?.employeeFirstName} ${employee.employeeLastName}`}</Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Lloji i lejes"
              name="requestType"
              rules={[
                { required: true, message: "Kjo fushë nuk mund të jetë bosh!" },
              ]}
            >
              <Select
                className="select-details"
                placeholder="Selekto llojin e lejes"
              >
                {prgFields?.fieldOptions?.map(({ statusTitle }) => (
                  <Option key={statusTitle} value={statusTitle}>
                    {statusTitle}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Data e fillimit"
              name="startDate"
              rules={[
                { required: true, message: "Kjo fushë nuk mund të jetë bosh!" },
              ]}
            >
              <DatePicker
                format={"DD/MM/YYYY"}
                placeholder="Selekto daten e fillimit"
                className="my-picker"
                disabledDate={(current) => {
                  return moment().add(-1, "days") >= current;
                }}
                onChange={(val) => {
                  setChosenDate(val);
                  form.setFieldValue("endDate", null);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Data e mbarimit"
              name="endDate"
              rules={[
                {
                  required: isDisabled ? false : true,
                  message: "Kjo fushë nuk mund të jetë bosh!",
                },
              ]}
            >
              <DatePicker
                format={"DD/MM/YYYY"}
                placeholder="Selekto daten e mbarimit"
                className="my-picker"
                disabled={isDisabled ? true : false}
                onChange={(val) => setChosenEndDate(val)}
                disabledDate={(current) => {
                  return moment(chosenStartDate) > current;
                }}
              />
            </Form.Item>
            {!!form.getFieldValue("startDate") &&
            !!form.getFieldValue("endDate") ? (
              <Form.Item label="Dite pa fundjave">
                <Input
                  style={{
                    width: 345,
                  }}
                  disabled
                  value={
                    !!form.getFieldValue("startDate") &&
                    !!form.getFieldValue("endDate")
                      ? `${excludeWeekends(
                          form.getFieldValue("startDate"),
                          form.getFieldValue("endDate")
                        )} dite`
                      : ""
                  }
                />
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item
              label="Pershkrimi"
              name="requestDescription"
              rules={[
                { required: true, message: "Kjo fushë nuk mund të jetë bosh!" },
              ]}
            >
              <RichTextEditor className="request-text" />
            </Form.Item>
          </Form>
          <RequestChair className="chair-icon" style={{ marginRight: 67 }} />
        </div>
      </Modal>
    </div>
  );
};
export default AddLeave;
