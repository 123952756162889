import React, { useContext, useEffect, useState } from "react";
import "./ListaEkandidateve.scss";
import { Tabs } from "antd";
import {
  Pergjithshme,
  AvantazhetDisavantazhet,
  Dokumentet,
  InformacioneShtese,
} from "./Kategorite";
import { InterviewsContext } from "../data";
import { useUploadedFiles } from "../../Documentation/hooks/useUploadedFiles";

const KandidatiTabs = () => {
  const {
    kandidatiSelektuar,
    // setKandidatiSelektuar,
    // setIntervistat,
    // intervistat,
  } = useContext(InterviewsContext);

  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [
    uploadedFiles,
    setUploadedFiles,
    formattedDriveFiles,
    onPickerSuccess,
  ] = uploadHandlers;
  const [
    filesToBeDeleted,
    // setFilesToBeDeleted,
    onDelete,
  ] = deleteHandlers;

  //UseEffect to change the uploaded files when the selected candidated change

  useEffect(() => {
    setUploadedFiles(kandidatiSelektuar?.googleDriveFiles);
  }, [kandidatiSelektuar]);

  const tabs = [
    {
      key: "1",
      label: "Te pergjithshme",
      children: <Pergjithshme />,
    },
    {
      key: "2",
      label: "Avantazhet & Disavantazhet",
      children: (
        <AvantazhetDisavantazhet kandidatiSelektuar={kandidatiSelektuar} />
      ),
    },
    {
      key: "3",
      label: "Dokumentet",
      children: (
        <Dokumentet
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          formattedDriveFiles={formattedDriveFiles}
          onPickerSuccess={onPickerSuccess}
          onDelete={onDelete}
          filesToBeDeleted={filesToBeDeleted}
        />
      ),
    },
    {
      key: "4",
      label: "Informacione Shtese",
      children: <InformacioneShtese uploadedFiles={uploadedFiles} />,
    },
  ];

  return (
    <div className="kandidadiTabs">
      <Tabs className="tabs" items={tabs} defaultActiveKey="1" />
    </div>
  );
};

export default KandidatiTabs;
