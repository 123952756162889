import BrutoIcon from "./BrutoIcon.svg";
import NetoIcon from "./NetoIcon.svg";
import PagesPerOreIcon from "./PagesPerOreIcon.svg";
import LlojiPages from "./LlojiPages.svg";
import FrekuencaPages from "./FrekuencaPages.svg";
import Turni from "./TurniPerkatesIcon.svg";
export const paymentData = {
  typeOfPayment: [
    {
      id: 1,
      icon: <img src={BrutoIcon} />,
      amountBrut: 112,
      type: "Shuma Bruto",
      showErrorTextBruto: "Nuk ka te dhena",
    },
    {
      id: 2,
      icon: <img src={NetoIcon} />,
      amountNet: 142,
      type: "Shuma Neto",
      showErrorTextNeto: "Nuk ka te dhena",
    },
  ],
  payroll: [
    {
      id: 3,
      icon: <img src={PagesPerOreIcon} />,
      amountSalaryPerHour: 5,
      type: "Pagesa per ore",
      showErrorTextPagesaPerOre: "Nuk ka te dhena",
    },
    {
      id: 4,
      icon: <img src={LlojiPages} />,
      typeWage: null,
      type: "Lloji i pages",
      showErrorTextLlojiPages: "Nuk ka te dhena",
    },
  ],
  hoursRate: [
    {
      id: 5,
      icon: <img src={FrekuencaPages} />,
      typeRate: "typeRate",
      type: "Frekuenca e pages",
      showErrorTextFrekeuncaPages: "Nuk ka te dhena",
    },
    {
      id: 6,
      icon: <img src={Turni} />,
      shiftType: "shiftType",
      type: "Turni perkates",
    },
  ],
};
