import { Checkbox } from "antd";
import "./TransferLabel.scss";

const TransferLabel = ({
  title,
  transferred,
  checked,
  onChange,
  onClick,
  disabled,
}) => (
  <div
    className={`transferLabelContainer ${
      !!disabled ? "disabledTransferLabel" : ""
    }`.trim()}
  >
    <div
      className={`marker ${!!transferred ? "transferred" : ""} ${
        !!checked ? "checked" : ""
      }`.trim()}
      onClick={() => onChange(!checked)}
    >
      {!!checked ? (
        <Checkbox
          {...{
            checked,
            onChange: ({ target: { checked: eventChecked } }) =>
              onChange(eventChecked),
          }}
        />
      ) : (
        <div className="innerMarker" />
      )}
    </div>
    <div className="titleContainer" {...{ onClick }}>
      {title}
    </div>
  </div>
);

export default TransferLabel;
