import types from "../types/types";

const wages = (wages) => {
	return (dispatch) => {
		dispatch({
			type: types.SET_WAGES,
			payload: wages,
		});
	};
};
export default wages;
