import { Button } from "antd";
import React from "react";
import { htmlParser } from "../../../../utils";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";

function MobilePaisjetOrarit({ setVisible, tableData }) {
  return (
    <div className="base-mobile-with-header">
      <div className="header-div">
        {" "}
        <Button onClick={() => setVisible(true)} className="btn-header">
          Shto një pajisje të re
        </Button>{" "}
      </div>
      {/* <div className="base-mobile-div"> */}
      <MobileHtmlTabel
        header={[
          "Emri i pajisjes",
          "Përshkrimi",
          "IP",
          "Sistemi",
          "Shfletuesi",
        ]}
        tableData={tableData?.map?.(
          ({ name, description, ip, sistemi, shfletuesi }) => ({
            statusHeaderKey: name,
            description: htmlParser(description),
            ip,
            sistemi,
            shfletuesi,
          })
        )}
        isHeader={true}
        isLink={false}
      />
    </div>
  );
}

export default MobilePaisjetOrarit;
