import { createContext, useState, useContext, useEffect } from "react";
export const ReportContext = createContext(null);

export const ReportProvider = ({ children }) => {
	const [reportData, setReportData] = useState([]);
	const [drawerFilter, setDrawerFilter] = useState(null);

	const populateData = (data) => {
		if (Array.isArray(data)) {
			setReportData(data);
		}
	};

	const retrieveData = () => {
		return reportData;
	};

	const changeDrawerFilter = (filter) => {
		setDrawerFilter(filter);
	};

	const driftOff = { reportData, populateData, retrieveData, drawerFilter, changeDrawerFilter };

	return <ReportContext.Provider value={driftOff}>{children}</ReportContext.Provider>;
};

export const useReportsData = () => {
	return useContext(ReportContext);
};
