import { API } from "aws-amplify";

export const apiPost = async (endpoint, body) =>
  await API.post(endpoint, `/${endpoint}`, { body });

export const apiDelete = async (endpoint, id) =>
  await API.del(endpoint, `/${endpoint}/${id}`);

export const apiPut = async (endpoint, id, body) =>
  await API.put(endpoint, `/${endpoint}/${id}`, { body });
