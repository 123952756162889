import types from "../types/types";

const clockings = (clockings) => {
	return (dispatch) => {
		dispatch({
			type: types.SET_CLOCKINGS,
			payload: Array.isArray(clockings) ? clockings : [],
		});
	};
};

export default clockings;
