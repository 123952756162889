import React, { useState } from "react";
import { Col, Modal, DatePicker } from "antd";
import { IconFilter } from "../../../../../assets/icons";
import "./FilteringModal.scss";

const { RangePicker } = DatePicker;

const FilteringModal = ({
  dateFilter,
  clearFilters,
  onWeekFilter,
  gridApi,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <IconFilter className="header-icons" onClick={() => setVisible(true)} />
      <Modal
        className="filter-modal"
        open={visible}
        centered={true}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        destroyOnClose={true}
        footer={null}
      >
        <Col>
          <RangePicker
            className="date-filter"
            format="DD/MM/YYYY"
            onChange={(e) => {
              dateFilter(e, gridApi);
            }}
          />
          {weeks?.map(({ key, label }) => (
            <button className="button" onClick={() => onWeekFilter(key)}>
              {label}
            </button>
          ))}
          <button
            className="button clear-button"
            onClick={() => clearFilters()}
          >
            Pastro Filterin
          </button>
        </Col>
      </Modal>
    </div>
  );
};
export default FilteringModal;

const weeks = [
  { key: "firstWeek", label: "Java e parë" },
  { key: "secondWeek", label: "Java e dytë" },
  { key: "thirdWeek", label: "Java e tretë" },
  { key: "fourthWeek", label: "Java e katërt" },
  { key: "fifthWeek", label: "Java e fundit" },
];
