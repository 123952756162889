import { useEffect, useState } from "react";
import { Button } from "antd";
import dayjs from "dayjs";
import { driveApi } from "../../DriveRequest";
import NormalSizedModal from "../../commonComponents/NormalSizedModal/NormalSizedModal";
import InfoLabel from "../InfoLabel/InfoLabel";
import FilePreviewModal from "../View/components/FilePreviewModal/FilePreviewModal";
import "./UploadedFilesRenderer.scss";

const dateFormat = "DD/MM/YYYY";

const UploadedFilesRenderer = ({
  node: {
    data: { googleDriveUpload = [], amendmentDriveUpload = [] },
  },
  accessToken,
}) => {
  const [visible, setVisible] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState();
  const [currentFile, setCurrentFile] = useState();
  const [files, setFiles] = useState([]);

  const driveRequest = driveApi({ accessToken });

  const arrayToChoose = !!amendmentDriveUpload.length
    ? amendmentDriveUpload
    : googleDriveUpload.length
    ? googleDriveUpload
    : [];

  //! takes uploaded files in google drive by fetching them with driveRequest and shows them at documentation page
  const formatFiles = async (arr = []) => {
    return await Promise.all(
      arr?.map(async ({ name, id, mimeType, url, lastEditedUtc }) => {
        return {
          label: (
            <p
              className="fileName"
              onClick={() => {
                setPreviewModalVisible(true);
                driveRequest
                  .getImageSrc(id)
                  .then((image) => setCurrentFile({ ...image, mimeType, url }));
              }}
            >
              {name}
            </p>
          ),
          id: id,
          name: name,
          value: dayjs(lastEditedUtc).format(dateFormat),
        };
      })
    );
  };

  //* show the number of files uploaded at the type of file
  useEffect(() => {
    if (!!arrayToChoose.length)
      formatFiles(arrayToChoose).then((r) => setFiles(r));
  }, [JSON.stringify(googleDriveUpload), JSON.stringify(amendmentDriveUpload)]);

  return (
    <>
      <Button
        type="primary"
        onClick={() => !!arrayToChoose?.length && setVisible(true)}
      >
        {arrayToChoose?.length}
      </Button>
      <NormalSizedModal
        {...{
          visible,
          setVisible,
          title: "Skedarët e Ngarkuar",
          maskClosable: true,
          centered: true,
          className: "uploadedFilesModal",
        }}
      >
        <div className="filesContainer">
          {files?.map((el, key) => (
            <InfoLabel {...{ ...el, key }} />
          ))}
        </div>
      </NormalSizedModal>

      {!!previewModalVisible && (
        <FilePreviewModal
          {...{
            visible: previewModalVisible,
            setVisible: () => {
              setCurrentFile({});
              setPreviewModalVisible(false);
            },
            file: currentFile,
          }}
        />
      )}
    </>
  );
};

export default UploadedFilesRenderer;
