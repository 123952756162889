import { createContext, useContext } from "react";

export const GeneralInformationsContext = createContext({});
export const GooglePickerFilesContext = createContext({});

export const useGeneralInformationsContext = () =>
  useContext(GeneralInformationsContext);

export const useGooglePickerFilesContext = () =>
  useContext(GooglePickerFilesContext);
