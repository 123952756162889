import React, { useEffect, useState, useContext, useMemo } from "react";
import "./header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuth } from "../../authentication/authHelpers";
import NotificationsModal from "./components/NotificationsModal";
import { useUserSession } from "../../hooks/useUserSession";
import { API } from "aws-amplify";
import { NotifContext } from "../dashboard/components/notifications/context/notifContext";
import RequestsDrawer from "./components/RequestsDrawer";
import { useSelector } from "react-redux";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import moment from "moment-timezone";
import MobileHeader from "./components/MobileHeader";
import KonfigurimetHeader from "./components/KonfigurimetHeader";
import KryefaqjaHeader from "./components/KryefaqjaHeader";
import ProblemsDrawer from "./components/ProblemsDrawer";
import InterviewDrawer from "./components/InterviewDrawer";
import { driveApi } from "../DriveRequest";
import { fetchData } from "../../fetchData";
import { apiRoutes } from "../../apiRoutes";
import dayjs from "dayjs";
import { notificationUserToAdmin } from "../../utils/sendNotification";
import { callGmailAPI } from "../../utils";
import {
  saveNewCandidate,
  updateAutomationControllerField,
} from "./components/saveNewCandidate";

const weatherId = "788ae94b-c7e9-4309-a79e-eacc8b279a62";
const INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID =
  "345dgdf-456457fgjvbmn-96789khjghj";
// get weather data form free api for new yourk and tirana
const TiranaUrl = `https://api.openweathermap.org/data/2.5/weather?q=tirana&units=imperial&appid=adaf1809160dd551dd81d02077f56678`;
const NewYorkUrl = `https://api.openweathermap.org/data/2.5/weather?q=new%20york&units=imperial&appid=adaf1809160dd551dd81d02077f56678`;
const PristinaUrl = `https://api.openweathermap.org/data/2.5/weather?q=prishtina&units=imperial&appid=adaf1809160dd551dd81d02077f56678`;

function Header({ data }) {
  let location = useLocation();
  let title = "";
  let auth = useAuth();
  let navigate = useNavigate();

  const userSession = useUserSession();
  const [notifsActive, setNotifsActive] = useState(false);
  const [requestsActive, setRequestsActive] = useState(false);
  const [problemsActive, setProblemsActive] = useState(false);
  const [interviewActive, setInterviewActive] = useState(false);

  const [count, setCount] = useState([]);
  const [pendingRequest, setPendingRequests] = useState([]);

  // * @EneaXharau - state dataWeather holds an object with keys tirana, newyork which has info from api
  const [dataWeather, setDataWeather] = useState({});
  const [toggle, setToggle] = useState(false);
  const [toggleTirana, setToggleTirana] = useState(false);
  const [togglePrishtina, setTogglePrishtinas] = useState(false);

  // get notifications with context
  const { candidates } = useSelector((state) => state.candidates);
  const { programFields } = useSelector((state) => state.programFields);
  const { notificationsArr, dispatch } = useContext(NotifContext);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { problematikat } = useSelector((state) => state.problematikat);
  const { employees } = useSelector((state) => state.employeesList);
  const { departments } = useSelector((state) => state.departments);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);

  const driveRequest = driveApi({ accessToken });
  const [userPhoto, setUserPhoto] = useState("");

  const [qotd, setQotd] = useState({ quote: "", author: "" });

  const getImageFromDrive = async () => {
    if (!!data[0]?.googleDriveFileId) {
      await driveRequest
        .getImageSrc(data[0]?.googleDriveFileId)
        .then((image) => {
          setUserPhoto(image?.src);
        });
    }
  };

  const handleLogOut = async () => {
    let current = auth?.userAccess || [];
    await userSession.close(current[0]);
    await Auth.signOut();
    auth.signout(() => navigate("/login"));
  };
  if (location.pathname === "/kryefaqja") {
    title = "Mirë se erdhe";
  } else if (location.pathname === "/") {
    title = "Mirë se erdhe";
  }

  //region HEADER DATA
  // load the notifications
  async function loadNotifications() {
    await API.get("notifications", "/notifications").then(
      (notifs) =>
        dispatch({
          type: "LOAD_NOTIFS",
          payload: notifs?.map((el) => {
            let test = [];
            Object.entries(el?.userDetails).forEach((ent) => {
              if (ent[0] === auth?.userAccess[0]?.identityId) {
                test.push({ identityId: ent[0], ...ent[1] });
              }
            });
            if (test.length > 0) return { ...el, ...test[0] };
          }),
          // .filter((u) => u.identityId === auth?.userAccess[0]?.identityId),
        }),
      (err) => console.log("error loading notifs", err)
    );
  }

  /**
   * * @EneaXharau - Async function which fetches programFields/weatherid
   * * to get weather information and set it in state to re-render component
   */
  const fetchWeather = async () => {
    await API.get("programFields", `/programFields/${weatherId}`)
      .then((res) => {
        setDataWeather(res.fieldOptions);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchQotd = async () => {
    await API.get(
      "programFields",
      "/programFields/9d4c8f40-3a6d-11ed-a92c-178f43e8cbef"
    ).then(async (res) => {
      let option = res?.fieldOptions || {};
      if (option.date === moment().format("DD/MM/YYYY")) {
        setQotd({ quote: option?.quote.toString(), author: option?.author });
      } else {
        const res = await fetch("https://api.quotable.io/random");
        const data = await res.json();
        if (!!data?.content) {
          let quote = data?.content || {};
          await API.put(
            "programFields",
            "/programFields/9d4c8f40-3a6d-11ed-a92c-178f43e8cbef",
            {
              body: {
                fieldOptions: {
                  date: moment().format("DD/MM/YYYY"),
                  quote: quote?.toString(),
                  author: data?.author || "",
                },
              },
            }
          ).then((res) => {
            setQotd({ quote: quote?.toString(), author: data?.author || "" });
          });
        }
      }
    });
  };

  /**
   * * @EneaXharau - Async function which fetches WeatherAPI with axios gets the JSON obj
   * * then it sets in state using API.put and then it re-fetches the info from said table
   */
  const updateWeather = async () => {
    let tiranaData = {};
    let newyorkData = {};
    let pristinaData = {};
    await axios.get(TiranaUrl).then((response) => {
      tiranaData = response.data;
    });
    await axios.get(NewYorkUrl).then((response) => {
      newyorkData = response.data;
    });
    await axios.get(PristinaUrl).then((response) => {
      console.log("pristina", response.data);
      pristinaData = response.data;
    });
    API.put("programFields", `/programFields/${weatherId}`, {
      body: {
        fieldOptions: {
          newyork: newyorkData,
          tirana: tiranaData,
          pristina: pristinaData,
        },
      },
    })
      .then(() => {
        fetchWeather();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //region EXP DOCS & CALENDLY
  const { docsLastCheck = undefined, calendlyLastCheck = undefined } =
    useMemo(() => {
      let options = {};
      for (let i = 0; i < programFields?.length; i++) {
        const field = programFields[i];
        if (field?.fieldName === "Automation Controller") {
          options = field.fieldOptions;
        }
      }
      return options;
    }, [programFields]);
  const getExpiringDocs = async () => {
    let docs = [];
    const currentTimestamp = parseInt(dayjs().format("x"));
    await fetchData(apiRoutes.documentation).then((res) => {
      const data = res.filter(
        (el) =>
          parseInt(el?.expirationDate) < currentTimestamp + 604800000 &&
          parseInt(el?.expirationDate) > currentTimestamp
      );
      docs = data;
    });
    return docs;
  };
  const fireExpiringDocsNotifs = async () => {
    (await getExpiringDocs())?.forEach((el) => {
      let currentEmployee = null;
      for (let i = 0; i < employees?.length; i++) {
        if (employees[i]?.employeeId === el?.recordId) {
          currentEmployee = employees[i];
          break;
        }
      }
      if (!!currentEmployee) {
        const notifBody = {
          notificationTitle: "Dokument në prag skadence",
          notificationBody: `Dokumenti "${el?.docType}" për punonjësin ${
            currentEmployee?.employeeFirstName
          } ${currentEmployee?.employeeLastName} skadon ${
            moment(el?.expirationDate).date() === moment().date()
              ? "sot"
              : `në ${moment(el?.expirationDate).format("DD/MM/YYYY")}`
          }!`,
          notificationUrl: "/dokumentacione",
        };
        notificationUserToAdmin(notifBody, employees);
        callGmailAPI("sendEmail", {
          to: "developers@flex.al",
          subject: "Dokument ne prag skadence! ",
          body: `<p>Dokumenti "${el?.docType}" për punonjësin ${
            currentEmployee?.employeeFirstName
          } ${currentEmployee?.employeeLastName} skadon ${
            moment(el?.expirationDate).date() === moment().date()
              ? "sot"
              : `në ${moment(el?.expirationDate).format("DD/MM/YYYY")}`
          }!</p>
            <br />
            <p>Për të parë dokumentin kliko <a href="https://portal.flex.al/dokumentacione">këtu</a></p>
            <p>Best Regards,</p>
            <p>Flex Team</p>`,
          attachments: [],
          category: "error",
          authData: false,
        })
          .then(() => {})
          .catch((err) => console.log(err));
      }
    });
    updateAutomationControllerField("docsLastCheck");
  };

  useEffect(() => {
    try {
      if (!!docsLastCheck) {
        if (moment(docsLastCheck).valueOf() + 86400000 < Date.now()) {
          fireExpiringDocsNotifs();
        }
      } else {
        fireExpiringDocsNotifs();
      }
      //newCandidates
      // console.log((Date.now() - moment(calendlyLastCheck).valueOf()) / 60000);
      if (!!candidates && !!departments && !!programFields?.length) {
        const { fieldOptions: defaultPermissions } = programFields?.find(
          ({ fieldId }) => fieldId === INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID
        );
        if (!!calendlyLastCheck) {
          if (Date.now() - moment(calendlyLastCheck).valueOf() > 3600000) {
            saveNewCandidate({
              candidates,
              departments,
              employees,
              driveRequest,
              hotCredentials,
              defaultPermissions,
            });
          }
        } else {
          saveNewCandidate({
            candidates,
            departments,
            employees,
            driveRequest,
            hotCredentials,
            defaultPermissions,
          });
        }
      }
    } catch (error) {
      console.error("ERROR NGA Header.js", error);
    }
  }, [docsLastCheck, calendlyLastCheck]);

  // calculate from ferenight to celcius
  const fToCelTirana = ((dataWeather?.tirana?.main?.temp - 32) * 5) / 9;
  const fToCel = ((dataWeather?.newyork?.main?.temp - 32) * 5) / 9;
  const fToCelPristina = ((dataWeather?.pristina?.main?.temp - 32) * 5) / 9;

  // * @EneaXharau - limit the fetches done to the api for every 10 minutes
  // * and between 1PM to 10PM
  useEffect(() => {
    try {
      updateWeather();
      fetchWeather();
      fetchQotd();
      getImageFromDrive();
      const time = new Date().getHours();
      if (time >= 13 || time <= 22) {
        const interval = setInterval(() => {
          updateWeather();
        }, 600000);
        return () => clearInterval(interval);
      }
    } catch (error) {
      console.error("ERROR NGA Header.js", error);
    }
  }, []);

  useEffect(() => {
    loadNotifications();
  }, [auth]);

  useEffect(() => {
    setCount(notificationsArr.filter((x) => !x?.notificationMarkedRed));
  }, [notificationsArr]);

  useEffect(() => {
    if (!!employeeRequests) {
      //Vendos numrin e kerkesave qe jane Pending te Badge i kerkesave per leje
      setPendingRequests(
        employeeRequests
          ?.map((el) => ({
            ...el,
            requestStatus:
              el?.requestPeriod?.length > 1
                ? moment().valueOf() - el?.requestPeriod?.slice(-1) > 0
                  ? "Përfunduar"
                  : el.requestStatus
                : moment().valueOf() - el.requestPeriod[0] > 0
                ? "Përfunduar"
                : el.requestStatus,
          }))
          ?.filter?.(
            (pr) =>
              pr?.requestStatus === "Pending" && pr?.userSub !== "undefined"
          )
      );
    }
  }, [employeeRequests]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 460px)",
  });

  return (
    <>
      {isDesktopOrLaptop ? (
        <div className="header-app">
          {location.pathname.startsWith("/konfigurimet") === true ? (
            <KonfigurimetHeader handleLogOut={handleLogOut} />
          ) : (
            <KryefaqjaHeader
              {...{
                qotd,
                location,
                dataWeather,
                fToCelTirana,
                setToggleTirana,
                togglePrishtina,
                fToCelPristina,
                setTogglePrishtinas,
                toggleTirana,
                fToCel,
                setToggle,
                toggle,
                userPhoto,
                auth,
                setRequestsActive,
                requestsActive,
                pendingRequest,
                setNotifsActive,
                notifsActive,
                handleLogOut,
                count,
                setQotd,
                setProblemsActive,
                problemsActive,
                problematikatCount: problematikat?.filter(
                  (x) => x?.ticketStatus === "Open"
                )?.length,
                candidatesCount: candidates.filter(
                  (items) =>
                    moment(items.applicantInterviewDate).week() ===
                    moment().week()
                )?.length,
                interviewActive,
                setInterviewActive,
              }}
            />
          )}
        </div>
      ) : (
        <MobileHeader
          {...{
            data,
            location,
            handleLogOut,
            auth,
            setRequestsActive,
            requestsActive,
            pendingRequest,
            setNotifsActive,
            notifsActive,
            count,
            userPhoto,
            setProblemsActive,
            problemsActive,
            problematikatCount: problematikat?.filter(
              (x) => x?.ticketStatus === "Open"
            )?.length,
            candidatesCount: candidates.filter(
              (items) =>
                moment(items.applicantInterviewDate).week() === moment().week()
            )?.length,
            interviewActive,
            setInterviewActive,
          }}
        />
      )}
      <NotificationsModal
        {...{
          notifsActive,
          setNotifsActive,
        }}
      />
      <RequestsDrawer
        {...{
          requestsActive,
          setRequestsActive,
        }}
      />
      <ProblemsDrawer
        {...{
          problemsActive,
          setProblemsActive,
        }}
      />
      <InterviewDrawer
        {...{
          interviewActive,
          setInterviewActive,
        }}
      />
      {/* <HappyHolidaysModal /> */}
    </>
  );
}
export default Header;
