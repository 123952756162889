import { Col, Divider, Typography, Tag, Button } from "antd";
import React, { useState } from "react";
import QuillRenderer from "./Components/QuillRenderer";
import AvantazhetDisavantazhetModal from "./AvantazhetDisavantazhetModal";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

const AvantazhetDisavantazhet = ({ kandidatiSelektuar }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="avantazhetDisavantazhet-container">
      <div className="content-container">
        <div className="sections">
          <p className="title">Avantazhet</p>

          {Array.isArray(kandidatiSelektuar.applicantAdvantages) ? (
            <div className="tag-container">
              {kandidatiSelektuar &&
                kandidatiSelektuar?.applicantAdvantages?.map(
                  (listItems, index) => <Tag key={index}>#{listItems}</Tag>
                )}
            </div>
          ) : (
            <QuillRenderer value={kandidatiSelektuar.applicantAdvantages} />
          )}
        </div>
        <div className="divider" />
        <div className="sections">
          <p className="title">Disavantazhet</p>
          {Array.isArray(kandidatiSelektuar.applicantDisadvantages) ? (
            <div className="tag-container">
              {kandidatiSelektuar &&
                kandidatiSelektuar?.applicantDisadvantages?.map(
                  (listItems, index) => <Tag key={index}>#{listItems}</Tag>
                )}
            </div>
          ) : (
            <QuillRenderer value={kandidatiSelektuar.applicantDisadvantages} />
          )}
        </div>
      </div>
      <div className="avantazhetDisavantazhet-footer">
        <MondayButton hasIcon={false} onClick={() => setIsVisible(true)}>
          Ndryshoni
        </MondayButton>
      </div>
      {isVisible && (
        <AvantazhetDisavantazhetModal {...{ isVisible, setIsVisible }} />
      )}
    </div>
  );
};

export default AvantazhetDisavantazhet;
