import {
  Absence,
  BrutoIcon,
  FullTime,
  PartTime,
  TotalNumber,
} from "../../../../../assets/icons";
import ClockInStats from "../../AdminCards/ClockInStats/ClockInStats";
// import HorizontalCard from "../../../components/horizontalCard/HorizontalCard";
import Employees from "../../AdminCards/Employees/Employees";
import WorkEvents from "../../AdminCards/WorkEvents/WorkEvents";
import { LeaveRequests } from "../../AdminCards/WorkLeave/LeaveRequests";
import ClockOuts from "../../ClockOutStats/ClockOuts";
import EmployeeMeKoheTePjesshme from "../../EmployeeMeKoheTePjesshme";
import EmployeeMeKoheTePlote from "../../EmployeeMeKoheTePlote";
import Njoftimet from "../../NjoftimetCard/Njoftimet";
import TotalEmployeeNumber from "../../TotalEmployeeNumber";
import ComponentCard from "../ComponentCard";
import moment from "moment-timezone";
import NoClockIn from "../../AdminCards/ClockInStats/NoClockIn";
import LateClockIns from "../../AdminCards/ClockInStats/LateClockIns";
import CurrencyWidget from "../../AdminCards/Currency/CurrencyWidget";
import Active from "../../AdminCards/Active/Active";
import WagesHistory from "../../AdminCards/WagesHistory/WagesHistory";
import ClockingsLogs from "../../AdminCards/ClockingsLogs/ClockingsLogs";
import ClockIn from "../../../components/clockInOut/ClockIn";
import LastThreeMonthEmployes from "../../AdminCards/LastThreeMonthEmployes/LastThreeMonthEmployes";
import WorkShiftAdminCard from "../../NjoftimetCard/WorkShiftAdmin/WorkShiftAdminCard";
import HolidayWidget from "../../../components/holidayWidget/HolidayWidget";
import WeatherCard from "../../../components/weatherCard/WeatherCard";
import EmployeOfWeek from "../../../components/EmployeOfMonth/EmployeOfWeek";
import EmployeOfMonth from "../../../components/EmployeOfMonth/EmployeOfMonth";
import WagesDepartments from "../../AdminCards/WagesDepartments/WagesDepartments";
import WageTotals from "../../AdminCards/Wages/WageTotals";
import Countdown from "../../../components/Countdown/Countdown";
import ProblemsCard from "../../AdminCards/Problems/ProblemsCard";
import InterviewToday from "../../AdminCards/InterviewToday/InterviewToday";
const cardData = [
  {
    icon: <TotalNumber />,
    number: 111,
    text: "Totali i punonjësve",
  },
  {
    icon: <FullTime />,
    number: 100,
    text: "Punonjës me kohë të plotë",
  },
  {
    icon: <PartTime />,
    number: 11,
    text: "Punonjës me kohë të pjesshme",
  },
  {
    icon: <Absence />,
    number: 0,
    text: "Punonjës me mungesë të planifikuar",
  },
  { icon: <BrutoIcon />, number: 0, text: "" },
];
// let date = new Date();
// let Month = date?.toLocaleString("en-us", { month: "long" });
// let longYear = date?.toLocaleString("en-us", { year: "numeric" });


const defaultData = {

  x: 0,
  y: 0,
  h: 1,
  w: 1,
  minW: 1,
  minH: 1,
  static: false

}


export const saved = [
  // {
  // 	i: "29",
  // 	name: "Countdown",
  // 	emri: "Countdown",
  // 	src: ComponentCard,
  // 	content: Countdown,
  // 	parent: true,
  // 	data: {},
  // 	hidden: false,
  // 	blur: false,
  // },
  {
    i: "0",
    data: {
      name: "TotalEmployee",
      emri: "Punonjësit total",
      src: ComponentCard,
      content: TotalEmployeeNumber,
      parent: true,
      // data: cardData[0],
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 0,
    y: 0,
  },
  {
    i: "1",
    data: {
      name: "FullTimeEmployee",
      emri: "Punonjësit me kohë të plotë",
      src: ComponentCard,
      content: EmployeeMeKoheTePlote,
      parent: false,
      // data: cardData[1],
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 1,
    y: 0
  },
  {
    i: "2",
    data: {

      name: "PartTimeEmployee",
      emri: "Punonjësit me kohë të pjesshme",
      src: ComponentCard,
      content: EmployeeMeKoheTePjesshme,
      parent: true,
      // data: cardData[2],
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 2,
    y: 0
  },

  {
    i: "4",
    data: {
      name: "WorkEvents",
      emri: "Eventet e punës",
      src: ComponentCard,
      content: WorkEvents,
      parent: true,
      hidden: false,
      blur: false,
      data: {},
    },
    ...defaultData,
    x: 3,
    y: 0
  },
  {
    i: "5",
    data: {

      name: "EmployeesHours",
      emri: "Orët e punonjësve",
      src: ComponentCard,
      content: Employees,
      parent: false,
      data: {},
      hidden: false,
      blur: true,
    },
    ...defaultData,
    x: 0,
    y: 1
  },
  {
    i: "6",
    data: {

      name: "LeaveRequests",
      emri: "Pushime vjetore",
      src: ComponentCard,
      content: LeaveRequests,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    minH: 2,
    h: 2,
    x: 1,
    y: 1

  },

  // {
  // 	i: "7",
  // data:{

  // 	name: "wagesStatistics",
  // 	emri: "Statistika Pagat (Finalizuar, Neto)",
  // 	src: ComponentCard,
  // 	content: WageStatistic,
  // 	parent: true,
  // 	hidden: false,
  // 	blur: true,
  // },
  //...defaultData
  // },
  // x: 2,
  //   y: 1

  {
    i: "8",
    data: {
      name: "clockInStatistics",
      emri: "Statistika për Clock In",
      src: ComponentCard,
      content: ClockInStats,
      parent: true,
      hidden: false,

    },
    ...defaultData,
    x: 3,
    y: 1
  },
  {
    i: "9",
    data: {
      name: "noClockOuts",
      emri: "Punonjës që nuk kanë bërë clock out",
      src: ComponentCard,
      content: ClockOuts,
      parent: true,
      hidden: false,
    },
    ...defaultData,
    minW: 1,
    w: 1,
    x: 0,
    y: 3
  },
  {
    i: "10",
    data: {

      name: "oreShtese",
      emri: "Orë shtesë",
      src: ComponentCard,
      content: WorkShiftAdminCard,
      parent: true,
      hidden: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 2,
    y: 3
  },
  // {
  //   i: "10",
  // data:{
  //   name: "wagePieChart",
  //   emri: `${moment()
  //     .startOf("month")
  //     .format("DD/MM/YYYY")} - ${moment().format("DD/MM/YYYY")}`,
  //   src: ComponentCard,
  //   content: WagePie,
  //   parent: true,
  //   hidden: false,
  //   blur: false,
  // },
  // ...defaultData,
  // x: 2,
  //   y: 2
  // },

  // {
  //   i: "11",
  // data:{
  //   name: "brutoPreview",
  //   emri: `Parashikimi (${moment()
  //     .startOf("month")
  //     .format("DD/MM/YYYY")} - ${moment().format("DD/MM/YYYY")})`,
  //   src: ComponentCard,
  //   content: WagesInfoCard,
  //   parent: false,
  //   hidden: false,
  //   blur: false,
  // },
  // ...defaultData,
  // x: 3,
  //   y: 2
  // },
  // {
  //   i: "12",
  // data:{
  //   name: "totalPreview",
  //   emri: `Parashikimi (${moment()
  //     .startOf("month")
  //     .format("DD/MM/YYYY")} - ${moment().format("DD/MM/YYYY")})`,
  //   src: ComponentCard,
  //   content: WagesTaxesCard,
  //   parent: false,
  //   hidden: false,
  //   blur: false,
  // },
  // ...defaultData,
  // x: 0,
  //   y: 3
  // },
  // {
  //   i: "13",
  // data:{

  //   name: "netoPreview",
  //   emri: `Parashikimi (${moment()
  //     .startOf("month")
  //     .format("DD/MM/YYYY")} - ${moment().format("DD/MM/YYYY")})`,
  //   src: ComponentCard,
  //   content: WagesNetoCard,
  //   parent: false,
  //   hidden: false,
  //   blur: false,
  // },
  // ...defaultData,
  // x: 1,
  //   y: 3
  // },
  {
    i: "14",
    data: {

      name: "noClockIn",
      emri: "Punonjës që nuk kanë bërë clock in (sot)",
      src: ComponentCard,
      content: NoClockIn,
      parent: false,
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 0,
    y: 2
  },
  {
    i: "15",
    data: {

      name: "lateClockIns",
      emri: "Clock in me vonesë (sot)",
      src: ComponentCard,
      content: LateClockIns,
      parent: false,
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 3,
    y: 2
  },
  {
    i: "16",
    data: {
      name: "exchangeCurrency",
      emri: `Këmbimi Valutor ${moment().format("DD/MM/YYYY")}`,
      src: ComponentCard,
      content: CurrencyWidget,
      parent: false,
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 0,
    y: 4
  },
  {
    i: "17",
    data: {
      name: "activeUsers",
      emri: "Përdoruesit Aktivë",
      src: ComponentCard,
      content: Active,
      parent: false,
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 1,
    y: 4
  },
  {
    i: "18",
    data: {
      name: "wageHistory",
      emri: "Statistika Pagat",
      src: ComponentCard,
      content: WagesHistory,
      parent: false,
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 0,
    y: 5
  },
  {
    i: "19",
    data: {
      name: "clockingsLogs",
      emri: "Historiku i turneve",
      src: ComponentCard,
      content: ClockingsLogs,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 2,
    y: 5
  },
  {
    i: "20",
    data: {
      name: "ClockIn",
      emri: "Veprime Turni",
      src: ComponentCard,
      content: ClockIn,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 3,
    y: 4,
  },
  {
    i: "21",
    data: {
      name: "adminNotifs",
      emri: "Veprime Admin",
      src: ComponentCard,
      width: "100%",
      height: "100%",
      content: Njoftimet,
      parent: false,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 4,
    minH: 2,
    h: 2,
    x: 0,
    y: 6

  },
  {
    i: "22",
    data: {
      name: "adminNotifs",
      emri: "Në punë më pak se 3 muaj",
      src: ComponentCard,
      content: LastThreeMonthEmployes,
      parent: false,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    x: 1,
    y: 5
  },
  {
    i: "23",
    data: {
      name: "Holidays",
      emri: "Festat",
      src: ComponentCard,
      content: HolidayWidget,
      parent: true,
      data: {},

      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 2,
    y: 5
  },
  {
    i: "24",
    data: {
      name: "Moti",
      emri: "Moti",
      src: ComponentCard,
      content: WeatherCard,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 0,
    y: 8


  },
  {
    i: "25",
    data: {
      name: "Punonjësi i Muajit",
      emri: "Punonjësi i Muajit",
      src: ComponentCard,
      content: EmployeOfMonth,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 0,
    y: 9
  },
  {
    i: "26",
    data: {

      name: "Punonjësi i Javës",
      emri: "Punonjësi i Javës",
      src: ComponentCard,
      content: EmployeOfWeek,
      parent: true,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 0,
    y: 10
  },
  {
    i: "27",
    data: {

      name: "Statistika Pagat",
      emri: `Statistika Pagat Departamentet (${moment().format("MMMM YYYY")})`,
      src: ComponentCard,
      content: WagesDepartments,
      parent: true,
      data: {},
      hidden: false,
      blur: true,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 2,
    y: 10
  },
  {
    i: "28",
    data: {

      name: "Statistika Pagat",
      emri: `Statistika Pagat (${moment().format("MMMM YYYY")})`,
      src: ComponentCard,
      content: WageTotals,
      parent: true,
      data: {},
      hidden: false,
      blur: true,
    },
    ...defaultData,
    x: 2,
    y: 4
  },
  {
    i: "29",
    data: {
      name: "Problematikat",
      emri: `Problematikat`,
      src: ComponentCard,
      content: ProblemsCard,
      parent: false,
      data: {},
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    minH: 2,
    h: 2,
    x: 2,
    y: 11
  },
  {
    i: "30",
    data: {
      name: "InterviewToday",
      emri: "Intervistat e sotme",
      src: ComponentCard,
      content: InterviewToday,
      parent: false,
      hidden: false,
      blur: false,
    },
    ...defaultData,
    minW: 2,
    w: 2,
    x: 0,
    y: 11
  },
];
export const getAdminSaved = () => {
  const ls = JSON.parse(localStorage.getItem("draggableOrderAdmin"));
  if (ls !== null) {
    if (ls.length === saved.length) {
      let temporary = [];
      ls.map((el, idx) => {
        let found = saved.findIndex((l, x) => l.i === el.i);
        if (found !== -1) {
          temporary.push({
            ...saved[found],
            data: { ...saved[found].data, hidden: el?.data?.hidden, blur: el?.data?.blur },
            x: el.x,
            y: el.y,
            h: el.h,
            w: el.w,
            minW: el.minW,
            minH: el.minH,
          });
        }
      });
      return temporary;
    }
    return saved;
  } else if (ls === null) {
    localStorage.setItem("draggableOrderAdmin", JSON.stringify(saved));
    return saved;
  }
};
const swap = (theArray, indexA, indexB) => {
  var temp = theArray[indexA];
  theArray[indexA] = theArray[indexB];
  theArray[indexB] = temp;
};
