import React, { useContext, useEffect, useState } from "react";
import "./Permbledhje.scss";
import { Select } from "antd";
import { charts } from "./charts";
import { Chart } from "react-google-charts";
import moment from "moment";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { InterviewsContext } from "../data";
import { useSelector } from "react-redux";
const Permbledhje = (props) => {
  const { candidates } = useSelector((state) => state.candidates);
  const { programFields } = useSelector((state) => state.programFields);
  const { setActiveKey } = useContext(InterviewsContext);

  const newInputs = programFields?.find(
    (el) => el?.fieldName === "Konfigurimi i Kandidateve"
  );

  const [chartData, setChartData] = useState({});
  const { Option } = Select;
  const [activeFilter, setActiveFilter] = useState({});

  useEffect(() => {
    const charts = [
      "Intervista te planifikuara",
      "Aplikimet",
      "Pranuar/Skualifikuar",
      "Team",
      "Platformat",
      "Ditët në provë",
      "Untitled",
    ];
    if (candidates?.length > 0) {
      const updatedChartData = {};
      console.log({ candidates });

      charts?.forEach?.((chart) => {
        updatedChartData[chart] = {
          candidates: candidates?.filter?.((kandidati) => {
            const { applicantInterviewDate = {} } = kandidati;
            const timestamp = dayjs(applicantInterviewDate).valueOf();
            const year = moment(timestamp).year();
            return year === moment().year();
          }),
          monthToFilter: "All",
          year: moment().year(),
        };
      });

      setChartData((prevState) => ({
        ...prevState,
        ...updatedChartData,
      }));
    }
  }, [candidates]);

  useEffect(() => {
    if (Object.keys(activeFilter).length > 0) {
      const updatedChart = {};

      Object.keys(activeFilter)?.forEach?.((key) => {
        const { month, year } = activeFilter?.[key];
        const filteredcandidates = candidates?.filter?.((kandidati) => {
          const { applicantInterviewDate = {} } = kandidati;
          const timestamp = dayjs(applicantInterviewDate).valueOf();
          const monthToFilter = moment(timestamp).month();
          const yearToFilter = moment(timestamp).year();
          return (
            (month === "All" || monthToFilter === Number(month)) &&
            yearToFilter === year
          );
        });
        updatedChart[key] = {
          ...chartData[key],
          candidates: filteredcandidates,
          monthToFilter: month,
          year,
        };
      });
      setChartData((prevState) => ({
        ...prevState,
        ...updatedChart,
      }));
    }
  }, [activeFilter]);

  return (
    <div className="hiringCharts">
      {!!newInputs &&
        newInputs.length !== 0 &&
        charts(chartData, newInputs?.fieldOptions)?.map?.((chart, index) => {
          const {
            topTitle = "",
            selectOptions = [],
            years = [],
            props = {},
          } = chart;
          return (
            <div className="charts" key={index}>
              <>
                <div className="headerChart">
                  <span className="topTitle">{topTitle}</span>
                  <div className="selectContent">
                    <Select
                      className="selectOptions"
                      defaultValue={
                        selectOptions?.[selectOptions?.length - 1]?.name
                      }
                      onChange={(value) => {
                        setActiveFilter({
                          ...activeFilter,
                          [topTitle]: {
                            ...activeFilter[topTitle],
                            month: value,
                            year:
                              activeFilter?.[topTitle]?.year || moment().year(),
                          },
                        });
                      }}
                    >
                      {selectOptions?.map?.((option, index) => {
                        return (
                          <Option
                            key={index}
                            value={
                              typeof option?.value === "string"
                                ? option?.value
                                : moment(option?.value).month()
                            }
                          >
                            {option?.name}
                          </Option>
                        );
                      })}
                    </Select>
                    <Select
                      className="selectYear"
                      defaultValue={years?.[years?.length - 1]}
                      onChange={(value) => {
                        setActiveFilter({
                          ...activeFilter,
                          [topTitle]: {
                            ...activeFilter[topTitle],
                            month: activeFilter?.[topTitle]?.month || "All",
                            year: Number(value),
                          },
                        });
                      }}
                    >
                      {years?.map?.((option, index) => {
                        return (
                          <Option key={index} value={option}>
                            {option}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                {chartData?.[topTitle]?.candidates?.length > 0 ? (
                  <Chart
                    {...{
                      ...props,
                      chartEvents: [
                        {
                          eventName: "select",
                          callback: ({ chartWrapper }) => {
                            setActiveKey("2");
                          },
                        },
                      ],
                    }}
                  />
                ) : (
                  <div>
                    <span>Asnje te dhene!</span>
                  </div>
                )}
              </>
            </div>
          );
        })}
    </div>
  );
};

export default Permbledhje;
