import {
  Alert,
  Button,
  DatePicker,
  message,
  Modal,
  Select,
  TimePicker,
} from "antd";
import React, { useLayoutEffect, useState, useEffect } from "react";
import { useAuth } from "../../../../../authentication/authHelpers";
import { WarningIcon } from "../../notifications/notificationList/filterHeader/src";
import moment from "moment-timezone";
import { RichTextEditor } from "../../../../commonComponents";
import {
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
} from "../../../utils/disabledTimeFilters";
import { useDispatch, useSelector } from "react-redux";
import { BreedingRhombusSpinner } from "react-epic-spinners";
import Forgot from "../forgot/Forgot";
import {
  endDoubleShift,
  endShift,
  getLastOnGoingShift,
} from "../../../helpers/apiCall";
import swal from "sweetalert";
import axios from "axios";
import { getSocketUrl } from "../../../../../utils/websocketConfig";
import { notificationUserToAdminNoDbPost } from "../../../../../utils/sendNotification";
import { debounce } from "lodash";
import { editClocking } from "../../../../../store/actions/addClockings";
import { getAppliedShifts } from "./utils";
import HappyHolidaysModal from "../../../../../assets/holidays/Modals/HappyHolidaysModal";
import { ModalXIcon } from "../../../../../assets/icons";
import useReactIpLocation from "react-ip-details";
const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

const { Option } = Select;

function ClockOutModal({
  clockOutVisible,
  setClockOutVisible,
  onSave,
  lastShift,
  apiData,
  setLoading,
  setLoadingColor,
  loadShiftData,
  resetValues,
  // onClockForgot,
  setNewShift,
  setLastShift,
  shiftConfiguration,
}) {
  const { users } = useSelector((state) => state.usersList);
  const userData = useAuth();
  const [newShift, setNewSh] = useState({ note: "" });
  const { programFields } = useSelector((state) => state.programFields);
  const [graceWarnings, setGraceWarning] = useState({
    enable: false,
    text: "",
  });
  const [showForgot, setShowForgot] = useState(false);
  const [gracePeriod, setGracePeriod] = useState(
    0
    // programFields?.find((el) => el.fieldName === "Politika e oreve jashte orarit")?.fieldOptions?.find((el) => el.name === "Grace Period")?.formula || 0
  );
  const [editedTime, setEditedTime] = useState(null);
  const [saving, setSaving] = useState(false);
  const [holidayModal, setHolidaysModal] = useState(false);
  const dispatch = useDispatch();
  const { ipResponse } = useReactIpLocation({ numberToConvert: 100 });
  const [ip, setIp] = useState("");

  const onChange = (e) => {
    if (moment(e).isBefore(moment(lastShift.clockInDate))) {
      message.error("You can't choose a time before your clock in time!");
      setNewSh((prev) => ({ ...prev, clockOutDate: getAutoSuggest(moment()) }));
    } else if (moment().isBefore(e)) {
      message.error("You can't choose a time in the future!");
      setNewSh((prev) => ({ ...prev, clockOutDate: getAutoSuggest(moment()) }));
      setGraceWarning({
        enable: false,
        text: "",
      });
    } else {
      if (gracePeriod > 0) {
        if (checkGracePeriod(e)) {
          setNewSh((prev) => ({
            ...prev,
            clockOutDate: getAutoSuggest(moment(e)),
          }));
        } else {
          message.error(
            "Aktualisht, nuk lejohet ndryshimi i orës se daljes. Kontaktoni me supervisorin përkates!"
          );
        }
      } else {
        message.info("Ju nuk lejoheni të ndryshoni kohën e mbarimit!");
        setNewSh((prev) => ({
          ...prev,
          clockOutDate: getAutoSuggest(moment()),
        }));
      }
    }
  };

  const getAutoSuggest = (time) => {
    // let time = moment(time);
    let l_moment = moment();
    l_moment.set({
      hour: "21",
      minute: "30",
      second: "00",
    });
    let h_moment = moment();
    h_moment.set({
      hour: "21",
      minute: "45",
      second: "30",
    });
    let autoSuggestion = moment();
    autoSuggestion.set({
      hour: "21",
      minute: "30",
      second: "00",
    });

    let lower_bound = moment(l_moment).valueOf();
    let upper_bound = moment(h_moment).valueOf();

    // Morning Shift
    let l_moment2 = moment();
    l_moment2.set({
      hour: "18",
      minute: "30",
      second: "00",
    });
    let h_moment2 = moment();
    h_moment2.set({
      hour: "18",
      minute: "32",
      second: "30",
    });

    let lower_bound2 = moment(l_moment2).valueOf();
    let upper_bound2 = moment(h_moment2).valueOf();

    if (
      moment(time).valueOf() > lower_bound2 &&
      moment(time).valueOf() < upper_bound2
    ) {
      // setShift((prev) => ({ ...prev, clockOutDate: autoSuggestion }));
      autoSuggestion.set({
        hour: "18",
        minute: "30",
        second: "00",
      });
      return autoSuggestion;
    } else {
      if (
        moment(time).valueOf() > lower_bound &&
        moment(time).valueOf() < upper_bound
      ) {
        autoSuggestion.set({
          hour: "21",
          minute: "30",
          second: "00",
        });
        return autoSuggestion;
      } else {
        return moment(time);
      }
      // return autoSuggestion;
    }
  };

  const checkGracePeriod = (date) => {
    const cond = moment().diff(date, "minutes", true);
    console.log("cond", cond);
    let h_moment = moment();
    h_moment.set({
      hour: "21",
      minute: "30",
      second: "30",
    });
    if (moment().diff(date, "minutes", true) <= gracePeriod) {
      if (moment().diff(date, "minutes", true) < 1) {
        setGraceWarning({
          text: "",
          enable: false,
        });
        return true;
      } else {
        setGraceWarning({
          text: "Nevojitet një shënim për këtë ndryshim!",
          enable: true,
        });
        return true;
      }
    } else {
      setGraceWarning({
        text: "",
        enable: false,
      });
      return false;
    }
  };

  const onClockOutSend = async () => {
    const isGraced = checkGracePeriod(newShift?.clockOutDate);
    const toSend = newShift;
    if (
      moment(newShift?.clockOutDate).diff(
        moment(lastShift?.clockInDate),
        "minutes",
        true
      ) > 2
    ) {
      message.loading("Saving Data");
      if (isGraced) {
        toSend.clockOutDate = getAutoSuggest(newShift?.clockOutDate);
        setNewSh((prev) => ({
          ...prev,
          clockOutDate: toSend.clockOutDate,
          clockingLogs: [
            ...(prev?.clockingLogs || []),
            {
              createdAt: moment().valueOf(),
              note: "Krijuar nga vete punonjesi.",
              newShiftByAdmin: false,
              newShiftByUser: true,
              username: userData?.user?.username,
              ipResponse:
                {
                  ...ipResponse,
                  IPv4: ip,
                } || null,
            },
          ],
        }));
        console.log("setNewSh1", newShift);
        const temp = newShift?.note.replace(/<[^>]+>/g, "");
        const noteLen = temp.replace(/\s/g, "").length;
        const noteReq = graceWarnings.enable && noteLen < 5 ? false : true;
        if (noteReq) {
          setLoadingColor("#EA3943");
          setLoading(true);
          await onClockOut()
            .then(() => setLoading(false))
            .catch((err) => {
              console.error(err);
              setLoading(false);
            });
        } else {
          message.error("Shënimi duhet të ketë të paktën 6 karaktere!");
        }
      } else {
        toSend.clockOutDate = getAutoSuggest(moment());
        setNewSh((prev) => ({
          ...prev,
          clockOutDate: toSend.clockOutDate,
          clockingLogs: [
            ...(prev?.clockingLogs || []),
            {
              createdAt: moment().valueOf(),
              note: "Krijuar nga vete punonjesi.",
              newShiftByAdmin: false,
              newShiftByUser: true,
              username: userData?.user?.username,
              ipResponse:
                {
                  ...ipResponse,
                  IPv4: ip,
                } || null,
            },
            ,
          ],
        }));
        console.log("setNewSh2", newShift);
        setLoadingColor("#EA3943");
        setLoading(true);
        await onClockOut()
          .then(() => setLoading(false))
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      }
    } else {
      message.info("Ju nuk mund te mbyllni nje turn me te vogel se 2 minuta!");
    }
    console.log("final shift on clockout", newShift);
  };

  const onClockOut = async () => {
    if (
      moment(lastShift?.clockInDate).format("DD/MM/YYYY") ===
      moment().format("DD/MM/YYYY")
    ) {
      let clockOut;
      if (editedTime !== null) {
        if (newShift?.note !== "") {
          clockOut = {
            category: "manual",
            employeeId: lastShift.employeeId,
            clockOutNote: newShift?.note || null,
            editedClockOut: true,
            finishedAt: moment().valueOf(),
            approved: false,
          };
          await handleClockOut(clockOut, newShift?.clockOutDate, "single");
        } else {
          message.error("Ju duhet të vendosni një shënim.");
        }
      } else if (editedTime === null) {
        clockOut = {
          category: "automatic",
          editedClockOut: false,
          employeeId: lastShift.employeeId,
          clockOutNote: newShift?.note || null,
          // finishedAt:  moment().valueOf(),
          finishedAt: moment().valueOf(),
          approved: false,
        };
        await handleClockOut(clockOut, newShift?.clockOutDate, "single");
      }
      // console.log("Clock out!", clockOut);
    } else if (
      moment(lastShift?.clockOutDate).format("DD/MM/YYYY") !==
      moment().format("DD/MM/YYYY")
    ) {
      let today = moment();
      today.set({
        hour: "08",
        minute: "00",
        second: "00",
      });
      if (moment().valueOf() < moment(today).valueOf()) {
        let clockOut;
        if (editedTime !== null) {
          if (newShift?.note !== "") {
            clockOut = {
              category: "manual",
              employeeId: lastShift.employeeId,
              clockOutNote: newShift?.note,
              editedClockOut: true,
              finishedAt: moment().valueOf(),
              approved: false,
            };
            await handleClockOut(clockOut, newShift?.clockOutDate, "double");
          } else {
            message.error("Ju duhet të vendosni një shënim.");
          }
        } else if (editedTime === null) {
          clockOut = {
            category: "automatic",
            editedClockOut: false,
            employeeId: lastShift.employeeId,
            clockOutNote: newShift?.note || null,
            finishedAt: moment().valueOf(),
            approved: false,
          };
          await handleClockOut(clockOut, newShift?.clockOutDate, "double");
        }
      } else {
        setShowForgot(true);
      }
    }
  };

  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res?.data?.ip);
  };
  useEffect(() => {
    getIp();
  }, []);

  const handleClockOut = async (shift, val, type) => {
    // let lastShift = await getLastShift();

    if (!!shift?.employeeId) {
      let lastShift = getLastOnGoingShift(apiData);
      lastShift?.clockingLogs?.push({
        createdAt: moment().valueOf(),
        note: "Krijuar nga vete punonjesi.",
        newShiftByAdmin: false,
        newShiftByUser: true,
        username: userData?.user?.username,
        ipResponse:
          {
            ...ipResponse,
            IPv4: ip,
          } || null,
      });
      if (!!lastShift.clockingId) {
        if (type === "single") {
          setSaving(true);
          await endShift(lastShift, shift, val);
          await loadShiftData(lastShift.employeeId);
          setNewSh(false);
          setNewShift(false);
          setSaving(false);
          setLastShift({ ...lastShift, clockOutDate: moment(val).valueOf() });

          swal({
            title: `Turni u përfundua me sukses në orën ${moment(val).format(
              "HH:mm"
            )}!`,
            icon: "success",
            className: "custum-swal",
            // confirmButtonText
          });
          /**
           * * @EneaXharau - Added socket emitter
           * * .send() takes -> { request: String, body: any} -> stringified
           */
          dispatch(
            editClocking({
              ...lastShift,
              clockOutDate: moment(val).valueOf(),
            })
          );
          resetValues();
          // setHolidaysModal(true);
          setLoading(false);
          setClockOutVisible(false);
          if (socketHandler.readyState === socketHandler.OPEN) {
            socketHandler.send(
              JSON.stringify({
                request: "user_clockOut",
                body: { ...lastShift, clockOutDate: moment(val).valueOf() },
                id: lastShift.employeeId,
              })
            );
          }
        } else {
          setSaving(true);
          await endDoubleShift(lastShift, shift, val);
          setNewSh(false);
          setNewShift(false);
          resetValues();
          setSaving(false);

          swal({
            title: `Turni u përfundua me sukses në orën ${moment(val).format(
              "HH:mm"
            )}!`,
            icon: "success",
            className: "custum-swal",
          });
          await loadShiftData(shift.employeeId);
          // setHolidaysModal(true);
          setLoading(false);
          setClockOutVisible(false);
        }
        notificationUserToAdminNoDbPost(
          {
            notificationTitle: "Mbaroi Turni",
            createdAt: moment().format("DD/MM/YYYY"),
            notificationCategory: "Info",
            notificationPriority: false,
            notificationBody: `${userData?.userAccess[0]?.given_name} ${
              userData?.userAccess[0]?.family_name
            } mbaroi turnin ${moment(val).format("HH:mm")} `,
            isAnnouncement: false,
            notificationUrl: "/aprovimet",
            notificationFirstName: userData?.userAccess[0]?.given_name,
            notificationLastName: userData?.userAccess[0]?.family_name,
          },
          users?.allUsers.Items
        );
      }
    }
  };

  const onClockForgot = async (obj) => {
    const { isAfterMidnight } = obj;
    let clockForgot = {
      employeeId: lastShift.employeeId,
      editedClockOut: true,
      forgotClockOut: true,
      clockOutNote: obj.shenimi,
      finishedAt: moment().valueOf(),
      approved: false,
    };

    if (
      moment(obj.fillimi).format("DD/MM/YYYY") !==
      moment(obj.mbarimi).format("DD/MM/YYYY")
    ) {
      // let diff = moment.duration(obj.mbarimi.diff(obj.fillimi)).asDays();
      const date = moment(obj.fillimi);
      const time = moment(obj.mbarimi);
      date.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: "00",
      });
      // console.log("Diferenca", diff);
      // let editedClockOut = moment(obj.mbarimi).subtract(diff, "days").valueOf();
      if (isAfterMidnight) {
        await handleClockOut(clockForgot, time, "double");
      } else {
        await handleClockOut(clockForgot, date, "single");
      }
    }
  };

  useLayoutEffect(() => {
    if (
      moment(lastShift?.clockInDate).format("DD/MM/YYYY") !==
      moment().format("DD/MM/YYYY")
    ) {
      setShowForgot(true);
    }
  }, [lastShift]);

  return (
    <div>
      {" "}
      <Modal
        // destroyOnClose={true}
        width={918}
        className="new-shift"
        title={`Konfirmo Mbarimin e Turnit  (Turni I përcaktuar: ${
          shiftConfiguration?.appliedShifts?.length > 0
            ? getAppliedShifts(shiftConfiguration?.appliedShifts)
            : getAppliedShifts(shiftConfiguration?.applicableShifts)
        })`}
        open={clockOutVisible}
        onOk={() => setClockOutVisible(false)}
        onCancel={() => setClockOutVisible(false)}
        centered={true}
        closeIcon={<ModalXIcon />}
        footer={[
          <Button
            className="close-btn"
            onClick={() => setClockOutVisible(false)}
          >
            Mbyll Faqen
          </Button>,
          <Button className="start-btn" onClick={onClockOutSend}>
            Përfundo Turnin
          </Button>,
        ]}
      >
        {!!lastShift?.clockInDate && (
          <div className="new-shift-wrapper">
            <div className="warning-title">
              <span className="warn-icon" style={{ fill: "red" }}>
                <WarningIcon />
              </span>

              <span style={{ color: "#EA3943" }}>
                Përshëndetje,{" "}
                <strong>
                  {userData?.userAccess[0]?.given_name +
                    " " +
                    userData?.userAccess[0]?.family_name}
                </strong>
                . Ju jeni duke përfunduar punën, siguroni të dhënat përpara se
                të largoheni!
              </span>
            </div>{" "}
            {gracePeriod > 0 && (
              <div>
                <br />
                <Alert
                  message={`Ju mund të ndryshoni orarin e mbarimit duke lënë një shënim të vlefshëm! `}
                  type="info"
                  showIcon
                />
              </div>
            )}
            <div className="to-edit-fields">
              {" "}
              <div className="shift-field-start" style={{ marginLeft: "30px" }}>
                <label style={{ fontWeight: "600" }}>
                  <strong style={{ color: "red" }}>*</strong>Fillimi i Turnit:
                </label>
                <TimePicker
                  defaultValue={
                    !!lastShift?.clockInDate
                      ? moment(lastShift?.clockInDate)
                      : null
                  }
                  placeholder="Fillimi"
                  // inputReadOnly
                  format="HH:mm"
                  // onChange={onEditClockIn}
                  disabled={true}
                  // disabledHours={() => getDisabledAllHours()}
                  // disabledMinutes={(val) => getDisabledButGracePeriodMins(newShift.clockOutDate, val)}
                  // disabledHours={() => getDisabledAfterCustomHours()}
                  // disabledMinutes={(val) => getDisabledAfterMinutes(val)}
                  // showNow={false}
                  // allowClear={false}
                />
              </div>
              <div style={{ marginLeft: "20px" }} className="shift-field-end">
                <label style={{ fontWeight: "600" }}>Mbarimi i Turnit: </label>
                <TimePicker
                  value={
                    !!newShift?.clockOutDate
                      ? getAutoSuggest(moment(newShift?.clockOutDate))
                      : getAutoSuggest(moment())
                  }
                  placeholder="Mbarimi"
                  // disabled={true}
                  format="HH:mm"
                  disabledHours={
                    lastShift.clockInDate !== null
                      ? () =>
                          getDisabledBeforeCustomHours(lastShift.clockInDate)
                      : false
                  }
                  disabledMinutes={
                    lastShift.clockInDate !== null
                      ? (val) =>
                          getDisabledBeforeCustomMinutes(
                            lastShift.clockInDate,
                            val
                          )
                      : false
                  }
                  // onChange={onEditClockOut}
                  showNow={false}
                  allowClear={false}
                  onChange={onChange}
                />
              </div>
              <div style={{ marginLeft: "20px" }} className="shift-field-end">
                {/* <Alert message="Warning" type="warnings" showIcon /> */}
                <label style={{ fontWeight: "600", marginLeft: "0px" }}>
                  <strong style={{ color: "red" }}>*</strong>Data e Turnit:
                </label>

                <span
                  style={{
                    width: "140px",
                    color: "white",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    marginLeft: "0px",
                    cursor: "pointer",
                  }}
                >
                  <DatePicker
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    disabled={true}
                    defaultValue={
                      !!lastShift?.clockInDate
                        ? moment(lastShift?.clockInDate)
                        : null
                    }
                    //   value={dateMoment}
                    // onChange={onCalendarPick}
                    dropdownClassName="calendar-date"
                    placeholder="Zgjidh Datën"
                    // disabledDate={(current) => {
                    // 	return moment() < current;
                    // }}
                  />
                </span>
              </div>
              <div className="shift-field-end" style={{ marginLeft: "20px" }}>
                <label style={{ fontWeight: "600" }}>
                  <strong style={{ color: "red" }}>*</strong>Lloji i turnit:{" "}
                </label>

                <Select
                  className="daytype-select"
                  disabled
                  showSearch
                  value={lastShift?.dayType}
                  placeholder="Zgjidh nje opsion"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {programFields
                    ?.find((el) => el.fieldName === "ClockIn Types")
                    ?.fieldOptions?.map((el, idx) => (
                      <Option key={`shiftType-${idx}`} value={el?.name}>
                        {el?.description}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
            <div className="to-note">
              {graceWarnings.enable && (
                <>
                  <Alert
                    message={graceWarnings?.text}
                    type="warning"
                    showIcon
                  />
                  <br />
                </>
              )}
              <label style={{ fontWeight: "600" }}>Shënimi:</label>

              <RichTextEditor
                className={"to-note-textarea"}
                value={newShift.note}
                onChange={(e) => setNewSh((prev) => ({ ...prev, note: e }))}
              />
            </div>
            <div className="hours-generation">
              <span style={{ fontWeight: "600" }}>
                Orët totale:{" "}
                {moment(newShift.clockOutDate)
                  .diff(moment(lastShift?.clockInDate), "hours", true)
                  .toFixed(2) > 0
                  ? moment(getAutoSuggest(moment(newShift?.clockOutDate)))
                      .diff(moment(lastShift.clockInDate), "hours", true)
                      .toFixed(2)
                  : 0}{" "}
                orë
              </span>
              <div className="animated-hours">
                <div
                  className="calculated-hours"
                  style={{
                    width: `${
                      moment(newShift.clockOutDate)
                        .diff(moment(lastShift.clockInDate), "hours", true)
                        .toFixed(2) > 0 &&
                      moment(newShift.clockOutDate)
                        .diff(moment(lastShift.clockInDate), "hours", true)
                        .toFixed(2) <= 8
                        ? (moment(newShift.clockOutDate)
                            .diff(moment(lastShift.clockInDate), "hours", true)
                            .toFixed(2) /
                            8) *
                          100
                        : moment(newShift.clockOutDate)
                            .diff(moment(lastShift.clockInDate), "hours", true)
                            .toFixed(2) > 8
                        ? 100
                        : 0
                    }%`,
                  }}
                ></div>
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                {saving && <BreedingRhombusSpinner size={40} color="#1DA193" />}
              </div>
            </div>{" "}
          </div>
        )}
        {showForgot && (
          <Forgot
            handleForgot={(val) => setShowForgot(val)}
            showForgot={showForgot}
            {...{ userData, shift: lastShift, apiData, onClockForgot }}
          />
        )}
      </Modal>{" "}
    </div>
  );
}

export default ClockOutModal;
