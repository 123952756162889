import types from "../types/types";

const initialState = {
    candidates: []
};
function candidatesReducer(state = initialState, action) {
    const { type, payload } = action;

    if (type === types.SET_CANDIDATES) {
        if (Array.isArray(payload)) {
            return {
                candidates: mergeCandidates(state.candidates, payload)
            }
        } else {
            const existingIndex = state.candidates.findIndex(({ applicantId }) => applicantId === payload?.applicantId);
            if (existingIndex > -1) {
                let tmp = [...state.candidates];
                tmp[existingIndex] = {
                    ...tmp[existingIndex],
                    ...payload
                }

                return {
                    candidates: tmp
                }
            } else {
                return {
                    candidates: [
                        ...state.candidates,
                        payload
                    ]
                }
            }
        }
    } else if (type === types.REMOVE_CANDIDATES) {
        if (Array.isArray(payload)) {
            const idsToRemove = payload.map(({ applicantId }) => applicantId);
            return {
                candidates: state.candidates.filter(({ applicantId }) => !idsToRemove.includes(applicantId))
            };
        } else {
            return {
                candidates: state.candidates.filter(({ applicantId }) => applicantId !== payload.applicantId)
            };
        }
    } else {
        return state
    }
}

function mergeCandidates(initial, newEntries) {
    const initialDictionary = initial.reduce((acc, val) => ({ ...acc, [val?.applicantId]: { ...val } }), {});
    const finalState = [];

    for (const entry of newEntries) {
        const existingEntry = initialDictionary?.[entry?.applicantId];
        if (existingEntry) {
            finalState.push({
                ...existingEntry,
                ...entry
            })
        } else {
            finalState.push(entry)
        }
    }

    return finalState;
}

export default candidatesReducer;
