import React, { useEffect, useState } from "react";
import { IconI } from "../../../../assets/icons";
import { Form, Input, Row, Col, DatePicker } from "antd";
import { useMediaQuery } from "react-responsive";

function DetajetKompaniaCard({ handleChange }) {
  // this is a function for changing the view of component when the screen is less then 1681px\
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1681px)",
  });
  const { TextArea } = Input;
  return (
    <>
      {isDesktopOrLaptop ? (
        <div className="detajet-kompania-card" style={{ height: 333 }}>
          <Row style={{ width: "100%", marginTop: 3 }}>
            <Col flex="1 1 200px">
              <div className="input" style={{ width: "95%" }}>
                <h3 style={{ width: "20%" }}>
                  <span className="star-color">*&nbsp;</span>NUIS:
                </h3>
                <Form.Item
                  name="nusi"
                  style={{ width: "100%", margin: 0, padding: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj NUIS !",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Shkruaj numrin unik i identifikimit të subjektit"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange("nusi", e.target.value)}
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 32 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
            <Col flex="0 1 300px">
              <div className="input" style={{ width: "100%" }}>
                <h3 style={{ width: "36%" }}>
                  <span className="star-color">*&nbsp;</span>Shteti:
                </h3>
                <Form.Item
                  name="shteti"
                  style={{ width: "100%", margin: 0, padding: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj shtetin !",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Shkruaj shtetin"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange("shteti", e.target.value)}
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 35 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", paddingTop: 5 }}>
            <Col flex="1 1 200px">
              <div className="input" style={{ width: "95%" }}>
                <h3 style={{ width: "18%" }}>
                  <span className="star-color">*&nbsp;</span>Emri:
                </h3>
                <Form.Item
                  style={{ width: "100%", margin: 0, padding: 0 }}
                  name="emriSubjektit"
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj emrin !",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Shkruaj emrin"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleChange("emriSubjektit", e.target.value)
                    }
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 32 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
            <Col flex="0 1 300px">
              <div className="input" style={{ width: "100%" }}>
                <h3 style={{ width: "23%" }}>
                  <span className="star-color">*&nbsp;</span>Qyteti:
                </h3>
                <Form.Item
                  name="qyteti"
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj qytetin !",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Shkruaj qytetin"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange("qyteti", e.target.value)}
                  />
                </Form.Item>
                <span className="card-input-icon">
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
          </Row>
          <Row
            className="responsive-row"
            style={{ width: "100%", position: "relative", bottom: 15 }}
          >
            <Col flex="1 1 200px">
              <div className="input" style={{ width: "95%" }}>
                <h3 className="data-label" style={{ width: 320 }}>
                  <span className="star-color">*&nbsp;</span>Data e
                  regjistrimit:
                </h3>
                <Form.Item
                  name="dataRegjistrimit"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Selekto nje opsion !",
                    },
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    placeholder="Data e regjistrimit"
                    className="responsive-date-picker"
                    style={{
                      width: "100%",
                      background: "#F5F5F7",
                      borderRadius: 5,
                      border: 0,
                    }}
                    onChange={(val) => handleChange("dataRegjistrimit", val)}
                  />
                </Form.Item>
                <span style={{ width: 47 }} className="card-input-icon">
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
            <Col flex="0 1 300px">
              <div className="input" style={{ width: "100%" }}>
                <h3 style={{ width: 150 }}>
                  <span className="star-color">*&nbsp;</span>Kodi postar:
                </h3>
                <Form.Item
                  name="kodiPostar"
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj kodin postar!",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Shkruaj kodin postar"
                    type="number"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange("kodiPostar", e.target.value)}
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 32 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
          </Row>
          <div
            className="input"
            style={{ width: "84.5%", position: "relative", bottom: 20 }}
          >
            <h3 style={{ width: 250 }}>
              <span className="star-color">*&nbsp;</span>Email i kompanisë:
            </h3>
            <Form.Item
              name="subjektEmail"
              style={{ width: "100%", paddingTop: 5 }}
              rules={[
                {
                  // required: true,
                  message: "Shkruaj emailin e kompanisë!",
                },
              ]}
            >
              <Input
                onChange={(e) => handleChange("subjektEmail", e.target.value)}
                placeholder="Emaili kompanisë"
                style={{ width: "99%" }}
              />
            </Form.Item>
            <span className="card-input-icon" style={{ width: 32 }}>
              <IconI width={13} height={13} />
            </span>
          </div>
          <div
            className="text-area"
            style={{
              width: "82%",
              marginLeft: 20,
              position: "relative",
              bottom: 40,
            }}
          >
            <h3>
              <span className="star-color">*&nbsp;</span>Adresa:
            </h3>
            <Form.Item
              name="adresa"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Ju lutem Shkruaj Adresën !",
                },
              ]}
            >
              <TextArea
                placeholder="Shkruaj adresen "
                onChange={(e) => handleChange("adresa", e.target.value)}
                rows={3}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <span className="card-input-icon" style={{ width: 27 }}>
              <IconI width={13} height={13} />
            </span>
          </div>
        </div>
      ) : (
        <div
          className="detajet-kompania-card-responsive"
          style={{ height: "100%", width: "100%" }}
        >
          <Row className="res-kompania-row">
            <Col span={12} style={{ display: "block" }}>
              <div className="input" style={{ width: "100%" }}>
                <h3 style={{ width: 50 }}>
                  <span className="star-color">*&nbsp;</span>NUSI:
                </h3>
                <Form.Item
                  name="nusi"
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj NUSI !",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Shkruaj numrin unik i identifikimit të subjektit"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange("nusi", e.target.value)}
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 25 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
            <Col span={12} style={{ display: "block" }}>
              <div className="input" style={{ width: "100%" }}>
                <h3 style={{ width: 60 }}>
                  <span className="star-color">*&nbsp;</span>Shteti:
                </h3>
                <Form.Item
                  name="shteti"
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj shtetin !",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Shkruaj shtetin"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange("shteti", e.target.value)}
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 25 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
          </Row>

          <Row className="res-kompania-row">
            <Col span={12} style={{ display: "block" }}>
              <div className="input" style={{ width: "100%" }}>
                <h3 style={{ width: 50 }}>
                  <span className="star-color">*&nbsp;</span>Emri:
                </h3>
                <Form.Item
                  name="emriSubjektit"
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj emrin !",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Shkruaj emrin"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleChange("emriSubjektit", e.target.value)
                    }
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 25 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
            <Col span={12} style={{ display: "block" }}>
              <div className="input" style={{ width: "100%" }}>
                <h3 style={{ width: 60 }}>
                  <span className="star-color">*&nbsp;</span>Qyteti:
                </h3>
                <Form.Item
                  name="qyteti"
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj qytetin !",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Shkruaj qytetin"
                    style={{ width: "100%" }}
                    onChange={(e) => handleChange("qyteti", e.target.value)}
                  />
                </Form.Item>
                <span className="card-input-icon">
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className="input" style={{ width: "100%" }}>
                <h3 style={{ width: 200 }} className="kodi-postar-label">
                  <span className="star-color">*&nbsp;</span>Kodi postar:
                </h3>
                <Form.Item
                  name="kodiPostar"
                  rules={[
                    {
                      required: true,
                      message: "Shkruaj kodin postar!",
                    },
                  ]}
                >
                  <Input
                    className="kodi-postar"
                    autoComplete="off"
                    placeholder="Shkruaj kodin postar"
                    type="number"
                    style={{ width: 300 }}
                    onChange={(e) => handleChange("kodiPostar", e.target.value)}
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 25 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ display: "block" }}>
              <div className="input" style={{ width: "100%" }}>
                <h3 className="data-label-email" style={{ width: 200 }}>
                  <span className="star-color">*&nbsp;</span>Email i kompanisë:
                </h3>
                <Form.Item
                  name="subjektEmail"
                  rules={[
                    {
                      // required: true,
                      message: "Shkruaj Adresën 2!",
                    },
                  ]}
                >
                  <Input
                    className="email-kompanis-input"
                    onChange={(e) =>
                      handleChange("subjektEmail", e.target.value)
                    }
                    placeholder="Emaili kompanisë"
                    style={{ width: 300 }}
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 25 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ display: "block" }}>
              <div className="input" style={{ width: "100%" }}>
                <h3 className="data-label" style={{ width: 200 }}>
                  <span className="star-color">*&nbsp;</span>Data e
                  regjistrimit:
                </h3>
                <Form.Item
                  name="dataRegjistrimit"
                  rules={[
                    {
                      required: true,
                      message: "Selekto nje opsion !",
                    },
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    placeholder="Data e regjistrimit"
                    className="responsive-date-picker"
                    style={{
                      width: 500,
                      background: "#F5F5F7",
                      borderRadius: 5,
                      border: 0,
                    }}
                    onChange={(val) => handleChange("dataRegjistrimit", val)}
                  />
                </Form.Item>
                <span style={{ width: 25 }} className="card-input-icon">
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div
                className="text-area"
                style={{ width: "100%", marginLeft: 0 }}
              >
                <h3>
                  <span className="star-color">*&nbsp;</span>Adresa:
                </h3>
                <Form.Item
                  name="adresa"
                  // style={{ width: "100%", paddingTop: 5 }}
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem Shkruaj Adresën !",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Shkruaj adresen "
                    onChange={(e) => handleChange("adresa", e.target.value)}
                    rows={3}
                    style={{ width: 330 }}
                  />
                </Form.Item>
                <span className="card-input-icon" style={{ width: 25 }}>
                  <IconI width={13} height={13} />
                </span>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default DetajetKompaniaCard;
