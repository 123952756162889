import { Card } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { TotalNumber } from "../../assets/icons";

const CardComponetDepartment = ({ data }) => {
  //* gets employeeList with redux from DB
  const { employees } = useSelector((state) => state.employeesList);
  return (
    <div className="departmentDragContainer">
      <div className="dragEmployeeView">
        <div className="title">
          <TotalNumber className="dragDepartmentIcon" />
          {!!employees && (
            <p className="dragEmployeeNumber">
              {
                //* filters employee to find employee with department name
                employees.filter((employee) => {
                  return (
                    employee.employeeDepartmentName ===
                    data?.data?.departmentName &&
                    employee?.employeeStatus !== "Shkarkuar"
                  );
                }).length
              }{" "}
              Punonjës
            </p>
          )}
          {data?.data?.departmentManagerName}
        </div>
      </div>
      <div className="dragDepartmentRoles">
        <p className="dragDepartmentRoleTitle">Rolet e departamentit:</p>
        {data?.data?.departmentRoles?.map((role) => {
          //* map departmentRoles and shows role name
          return (
            <p className="dragRoleNames">
              <span className="roleNumber">
                (
                {
                  employees.filter(
                    (employee) => employee.employeeRoleName === role.name
                  ).length
                }
                )
              </span>{" "}
              {role?.name}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default CardComponetDepartment;
