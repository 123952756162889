import React, { useState } from "react";
import { Drawer, Button, Table } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "./InterviewDrawer.scss";
import { ModalXIcon } from "../../../assets/icons";

function InterviewDrawer({ interviewActive, setInterviewActive }) {
  const { candidates } = useSelector((state) => state.candidates);
  const navigate = useNavigate();

  //Map the interview of the current week
  const weeksInterviews = candidates.filter(
    (items) => moment(items.applicantInterviewDate).week() === moment().week()
  );

  const showModal = () => {
    setInterviewActive(true);
  };

  const handleOk = () => {
    setInterviewActive(false);
  };

  //Find the unique teams
  const teamSet = [
    ...new Set(candidates?.map((items) => items?.applicantTeam)),
  ];

  const handleCancel = () => {
    setInterviewActive(false);
  };

  //function to navigate to /intervistat and pass the id of the interview as a state
  function handleRowClick(items) {
    navigate("/intervistat", {
      state: {
        activeTab: "2",
        kandidatiSelektuarId: items.applicantId,
      },
    });
    setInterviewActive(false);
  }

  const columns = [
    {
      title: "Emër",
      dataIndex: "applicantFirstName",
      key: "applicantFirstName",
    },
    {
      title: "Mbiemër",
      dataIndex: "applicantLastName",
      key: "applicantLastName",
    },
    {
      title: "Team",
      dataIndex: "applicantTeam",
      key: "applicantTeam",
      filters: teamSet?.map((item) => {
        return { text: item, value: item };
      }),
      onFilter: (value, record) => record.applicantTeam === value,
    },
    {
      title: "Pozicioni",
      dataIndex: "applicantDepartment",
      key: "applicantDepartment",
      width: 210,
    },
    {
      title: "Data e intervistës",
      dataIndex: "applicantInterviewDate",
      key: "applicantInterviewDate",
      render: (text) => moment(text).utc().format("DD/MM/YYYY HH:mm"),
      width: 160,
    },
  ];

  return (
    <>
      <Drawer
        title="Intervistat e Javës"
        className="interviewDrawer"
        width={700}
        height={100}
        // closable={false}
        onClose={handleCancel}
        open={interviewActive}
        destroyOnClose={true}
        closeIcon={<ModalXIcon />}
      >
        <Table
          rowKey={(record) => record.applicantId}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          columns={columns}
          dataSource={weeksInterviews}
          pagination={false}
          scroll={{
            x: 650,
          }}
          // onChange={onChange}
        />
      </Drawer>
    </>
  );
}

export default InterviewDrawer;
