/**
 * * Function to change WebSocket tunnel from production to dev
 * * route is the dashboard connection link ex: /dashboard/sockets
 * * returns websocket connection link
 */
export const getSocketUrl = (route) => {
	const socketProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
	const echoSocketUrlProd =
		socketProtocol + "//" + "leadmanager-express-backend.ue.r.appspot.com/hr" + route;
	const echoSocketUrlDev = socketProtocol + "//" + "localhost:1337/hr" + route;
	// return echoSocketUrlProd;
	return process.env.NODE_ENV === "production" ? echoSocketUrlProd : echoSocketUrlDev;
};
