import React from "react";
import { Tabs } from "antd";
import {
  DitlindjeIcon,
  IconNojftimet,
  PushimeIcon,
  LajmerimeIcon,
} from "../../../../assets/icons/index";
import "./njoftimet_card.scss";
import Lajmerime from "./components/Lajmerime/Lajmerime.jsx";
import Njoftime from "./components/NjoftimeTab/Njoftime.jsx";
import Ditlindje from "./components/Ditlindje/Ditlindje.jsx";
import Vacations from "./Vacation/Vacations";


function Njoftimet() {

  const tabs = [
    {
      key: "1",
      label: <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <LajmerimeIcon /> Lajmërimet
      </div>,
      children: <Lajmerime />,
    },
    {
      key: "2",
      label: <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <IconNojftimet width={20} height={18} />
        Njoftime
      </div>,
      children: <Njoftime />,
    },
    {
      key: "3",
      label: <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <DitlindjeIcon width={20} height={18} /> Ditëlindjet
      </div>,
      children: <Ditlindje />,
    },
    {
      key: "4",
      label: <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <PushimeIcon /> Pushimet
      </div>,
      children: <Vacations />,
    },
  ];
  return (
    <div className="njoftime">
      <div className="card-container">
        <Tabs type="card" items={tabs} />
      </div>
    </div>
  );
}

export default Njoftimet;
