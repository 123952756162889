import { Form } from "antd";
import RenderDynamicComponents from "../../../RenderDynamicComponents/RenderDynamicComponents";
import { EyeOutlined } from "@ant-design/icons";
import "./FormPreview.scss";

const HEADER_TITLE = "Kjo është pamja paraprake.";

const FormPreview = ({ fieldJSON = [] }) => {
  const [form] = Form.useForm();

  return (
    <div className="documentationFormPreview">
      <div className="previewHeader">
        <EyeOutlined className="headerTitleIcon" />
        <span>{HEADER_TITLE}</span>
      </div>
      <Form form={form}>
        <div className="fieldsContainer">
          {RenderDynamicComponents(fieldJSON, { form })}
        </div>
      </Form>
    </div>
  );
};

export default FormPreview;
