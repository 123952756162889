import { message } from "antd";
import types from "../types/types";

export const addClocking = (clockingObj) => {
	return (dispatch) => {
		dispatch({
			type: types.ADD_CLOCKING,
			payload: clockingObj,
		});
	};
};

export const editClocking = (clockingObj) => {
	return (dispatch) => {
		dispatch({
			type: types.EDIT_CLOCKING,
			payload: clockingObj,
		});
	};
};

// export default addC;
