import { API } from "aws-amplify";
import _ from "lodash";
import moment from "moment-timezone";
// import { groupByKey } from "../../../../utils/loadash/methods";
import { groupByKey } from "./methods";
export const checkHolidaysEarnings = (
  processedShifts,
  employee,
  momentInstance,
  employeeRequests
) => {
  const validHolidays = [];
  const holidays = {
    groupedHolidays: {},
    getHolidayNumbers: function () {
      if (Object.keys(this.groupedHolidays).length !== 0) {
        let holidaysList = {
          Pushime: { total: 0 },
          "Dite Personale": { total: 0 },
        };
        let allTotal = 0;
        Object.entries(this.groupedHolidays).forEach((entry) => {
          const temp = entry[1].filter((el) => {
            return (
              moment(el.date, "DD/MM/YYYY").day() !== 0 &&
              moment(el.date, "DD/MM/YYYY").day() !== 6
            );
          });
          const [key, value] = entry;
          holidaysList[key].total = temp.length;
          allTotal += temp.length;
        });

        return [holidaysList, allTotal];
      }
      return null;
    },
  };

  let userSub = employee?.userSub || null;
  // console.log("userSub", userSub);
  if (employeeRequests.length > 0)
    employeeRequests = employeeRequests.filter(
      (request) => request.userSub === userSub
    );
  const shifts = processedShifts.map((el) => ({
    ...el,
    date: moment(el?.clockInDate).format("DD/MM/YYYY"),
  }));
  employeeRequests = employeeRequests
    .map((request) => {
      const requestPeriod = request?.requestPeriod?.filter(
        (el) =>
          moment(el).valueOf() > moment(momentInstance.start).valueOf() &&
          moment(el).valueOf() < moment(momentInstance.end).valueOf()
      );
      if (requestPeriod.length > 0) {
        return { ...request, requestPeriod: requestPeriod };
      }
      return null;
    })
    .filter((val) => !!val);

  if (employeeRequests.length > 0) {
    employeeRequests.map((el) =>
      el.requestPeriod.map((rDate) => {
        const appliedShift = shifts?.filter(
          (el) => el.date === moment(rDate).format("DD/MM/YYYY")
        );
        if (appliedShift.length === 0) {
          validHolidays.push({
            ...el,
            requestPeriod: [],
            date: moment(rDate).format("DD/MM/YYYY"),
          });
        } else {
          validHolidays.push({
            ...el,
            requestPeriod: [],
            date: moment(rDate).format("DD/MM/YYYY"),
          });
        }
      })
    );
  }
  // console.log("Employee Requests", employeeRequests);
  // console.log("Valid Holidays", validHolidays);
  // console.log("Shifts", shifts);

  if (validHolidays.length > 0) {
    holidays.groupedHolidays = groupByKey(validHolidays, "requestType");
  }
  return holidays;
};
