import React from "react";
import { GeneralCard } from "../AdminCards/GeneralCard";
import "./Draggable.scss";

function DragCard({ content, data, onClick }) {
	const { icon, number, text } = data;
	return <GeneralCard {...{ icon, number, text }} onClick={onClick} />;
}

export default React.memo(DragCard);
