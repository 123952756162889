import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Form,
  message,
  Select,
  DatePicker,
  Input,
  Skeleton,
  TimePicker,
  Checkbox,
} from "antd";
import { CloseIcon, TickIcon } from "../../../../../assets/icons";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import { textInput, personalNumber } from "../../RegEx/RegExpressions";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import PunonjesitContext from "../../../store/PunonjesitContext";
import { editEmployee } from "../../../../../store/actions";

function PersonalInformation({
  handleHidePersonal,
  changes,
  setChanges,
  handleChange,
  socketHandler,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { employee, setEmployee, auth } = useContext(PunonjesitContext);
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const { programFields } = useSelector((state) => state.programFields);
  const [genderType, setGenderType] = useState();
  const [breakValue, setBreakValue] = useState([]);
  const [oldValueOfBreakTime, setOldValueOfBreakTime] = useState([]);
  const [isPaid, setIsPaid] = useState(employee?.isBreakTimePaid);

  useEffect(() => {
    const employeeGender = programFields.find(
      (res) => res.fieldName === "Gjinia"
    );
    setGenderType(employeeGender);
  }, [programFields]);

  const messageOnSuccess = () => {
    message.success("Të dhënat u ndryshuan!");
  };
  const handleSave = async (success) => {
    setLoading(true);
    let changedObj = breakValue?.length > 0 && [
      {
        activity: "Ndryshuar",
        author:
          auth?.user?.attributes?.given_name +
          " " +
          auth?.user?.attributes?.family_name,
        changeDate: moment().valueOf(),
        field: "Orari i pushimit",
        oldValue: `${oldValueOfBreakTime[0]?.oldBreakTime}`,
        newValue: !!breakValue ? `${breakValue[0]} - ${breakValue[1]}` : "",
      },
    ];
    if (!!employee?.keylog) {
      let toSend = {
        ...success,
        employeeDayOfBirth: moment(success?.employeeDayOfBirth).valueOf(),
        keylog:
          breakValue?.length > 0
            ? [...employee?.keylog, ...changes, ...changedObj]
            : [...employee?.keylog, ...changes],
        breakTime: breakValue,
        isBreakTimePaid: isPaid,
      };
      API.put("employees", `/employees/${employee?.employeeId}`, {
        body: {
          ...toSend,
        },
      })
        .then((r) => {
          // if (socketHandler.readyState === socketHandler.OPEN)
          //   socketHandler.send(
          //     JSON.stringify({
          //       request: "user_edit",
          //       body: r?.employee?.Item,
          //       id: auth?.employeeId,
          //     })
          //   );
          messageOnSuccess();
          handleHidePersonal(r);
          setLoading(false);
          setEmployee(r?.employee?.Item);
          dispatch(editEmployee(r?.employee?.Item));
        })
        .catch((err) => console.log(err));
    } else {
      let toSend = {
        ...success,
        employeeDayOfBirth: moment(success?.employeeDayOfBirth).valueOf(),
        isBreakTimePaid: isPaid,
        breakTime: breakValue,
        keylog:
          breakValue?.length > 0
            ? [...(employee?.keylog || []), ...changes, ...changedObj]
            : [...(employee?.keylog || []), ...changes],
      };
      API.put("employees", `/employees/${employee?.employeeId}`, {
        body: {
          ...toSend,
        },
      })
        .then((r) => {
          // if (socketHandler.readyState === socketHandler.OPEN)
          //   socketHandler.send(
          //     JSON.stringify({
          //       request: "user_edit",
          //       body: employee,
          //       id: auth?.employeeId,
          //     })
          //   );
          messageOnSuccess(r);
          handleHidePersonal(r);
          setLoading(false);
          setEmployee(r?.employee?.Item);
          dispatch(editEmployee(r?.employee?.Item));
        })
        .catch((err) => console.log(err));
    }
  };

  //! birthday validation it does not allow you to select birthdate over 67 years and under 18 years
  const disabledDate = (current) => {
    const youngerThan18 = dayjs().subtract(18, "years").valueOf();
    const olderThan67 = dayjs().subtract(67, "years").valueOf();

    return current > moment(youngerThan18) || current < moment(olderThan67);
  };

  const checkIsPaid = (e) => {
    setIsPaid(e.target.checked);
    handleChange(
      "isBreakTimePaid",
      !e.target.value ? "Paguar" : "I Pa paguar",
      "Pushimi i paguar"
    );
  };
  React.useEffect(() => {
    form.setFieldsValue({
      employeeFirstName: employee?.employeeFirstName,
      employeeLastName: employee?.employeeLastName,
      employeeSNN: employee?.employeeSNN,
      employeeFathersName: employee?.employeeFathersName,
      employeePersonalNumber: employee?.employeePersonalNumber,
      employeeGender: employee?.employeeGender,
      isBreakTimePaid: employee?.isBreakTimePaid,
      employeeDayOfBirth: !!employee?.employeeDayOfBirth
        ? moment(employee?.employeeDayOfBirth)
        : "",
      breakTime:
        !!employee?.breakTime?.length > 0
          ? [
              moment(employee?.breakTime[0], "HH:mm"),
              moment(employee?.breakTime[1], "HH:mm"),
            ]
          : [],
    });
  }, [employee, form]);

  useEffect(() => {
    setOldValueOfBreakTime([
      {
        oldBreakTime:
          !!employee?.breakTime?.length > 0
            ? `${moment(employee?.breakTime[0], "HH:mm").format(
                "HH:mm"
              )} - ${moment(employee?.breakTime[1], "HH:mm").format("HH:mm")}`
            : [],
      },
      setBreakValue(
        !!employee?.breakTime?.length > 0
          ? [
              moment(employee?.breakTime[0], "HH:mm").format("HH:mm"),
              moment(employee?.breakTime[1], "HH:mm").format("HH:mm"),
            ]
          : []
      ),
    ]);
    setChanges([]);
  }, [employee]);

  return (
    <div className="personal-info-edit" style={{ height: 470 }}>
      <Card
        className="personal-info-card"
        title="Informacione personale"
        extra={
          <div className="icon-button">
            <Form.Item>
              <button
                id="close"
                style={{ marginRight: 10 }}
                onClick={handleHidePersonal}
              >
                <CloseIcon className="action-icon" />
              </button>
              <button form="personal-info" id="tick" htmlType="submit">
                <TickIcon className="action-icon" />
              </button>
            </Form.Item>
          </div>
        }
        style={{ width: "100%", height: "100%" }}
      >
        {loading === false ? (
          <Form
            className="personal-info"
            form={form}
            name="personal-info"
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            colon={false}
            onFinish={handleSave}
            autoComplete="off"
            // autoFocus
          >
            <div className="personalInformation">
              <Form.Item
                label="Emri:"
                name="employeeFirstName"
                rules={[
                  {
                    required: true,
                    message: "Ju lutem vendosni emrin!",
                  },
                  {
                    validator: (rule, value) => {
                      if (
                        value &&
                        value?.split("")?.some?.((item) => {
                          if (isNaN(item)) {
                            return false;
                          } else {
                            return true;
                          }
                        })
                      ) {
                        return Promise.reject(
                          "Kjo fushë duhet të përmbajë vetëm shkronja"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  htmlFor="Emri"
                  className="my-form"
                  placeholder="Vendos emrin e punonjësit"
                  onChange={(e) =>
                    handleChange("employeeFirstName", e.target.value, "Emri")
                  }
                />
              </Form.Item>
              <Form.Item
                label="Atësia:"
                name="employeeFathersName"
                rules={[
                  {
                    message: "Kjo fushë duhet të përmbajë vetëm shkronja",
                  },
                ]}
              >
                <Input
                  className="my-form"
                  placeholder="Vendos atësinë e punonjës"
                  onChange={(e) =>
                    handleChange(
                      "employeeFathersName",
                      e.target.value,
                      "Atësia"
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                label="Mbiemri:"
                name="employeeLastName"
                rules={[
                  {
                    required: true,
                    message: "Ju lutem vendosni mbiemri!",
                  },
                  {
                    validator: (rule, value) => {
                      if (
                        value &&
                        value?.split("")?.some((item) => {
                          if (isNaN(item)) {
                            return false;
                          } else {
                            return true;
                          }
                        })
                      ) {
                        return Promise.reject(
                          "Kjo fushë duhet të përmbajë vetëm shkronja"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  className="my-form"
                  initialValue={employee.employeeNumber}
                  placeholder="Vendos mbiemrin e punonjësit"
                  onChange={(e) =>
                    handleChange(
                      "employeeLastName",
                      e.target.value,
                      "Mbiemri",
                      "Mbiemri"
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                label="Numri personal:"
                name="employeePersonalNumber"
                rules={[
                  {
                    required: true,
                    message: "Ju lutem vendosni numrin e punonjesit!",
                  },
                  {
                    pattern: personalNumber,
                    message: "Kjo fushe duhet te permbaje vetem numra",
                  },
                ]}
              >
                <Input
                  className="my-form"
                  onChange={(e) =>
                    handleChange(
                      "employeePersonalNumber",
                      e.target.value,
                      "Numri personal"
                    )
                  }
                  placeholder=" --- "
                />
              </Form.Item>
              <Form.Item label="Gjinia:" name="employeeGender">
                <Select
                  className="gender-select"
                  name="employeeGender"
                  onChange={(val) =>
                    handleChange("employeeGender", val, "Gjinia")
                  }
                  style={{
                    width: "100%",
                    background: "#F5F5F7",
                    borderRadius: 5,
                  }}
                  bordered={false}
                >
                  {genderType?.fieldOptions?.map((gender) => (
                    <Option value={gender}>{gender}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Ditëlindja:" name="employeeDayOfBirth">
                <DatePicker
                  format={"DD/MM/YYYY"}
                  placeholder="Ditëlindja e punonjësit"
                  className="my-picker"
                  disabledDate={disabledDate}
                  onChange={(val) =>
                    handleChange("employeeDayOfBirth", val, "Ditëlindja")
                  }
                />
              </Form.Item>
              <Form.Item label="Orari i pushimit:" name="breakTime">
                <TimePicker.RangePicker
                  format={"HH:mm"}
                  value={breakValue}
                  style={{
                    width: "100%",
                    background: "#F5F5F7",
                    borderRadius: 5,
                  }}
                  onChange={(val) => {
                    val === null
                      ? setBreakValue(null)
                      : setBreakValue([
                          val[0].format("HH:mm"),
                          val[1].format("HH:mm"),
                        ]);
                  }}
                />
              </Form.Item>
              <div style={{ width: 450 }}></div>
              <Form.Item label="Pushim i paguar:" name="isBreakTimePaid">
                <Checkbox onChange={(e) => checkIsPaid(e)} checked={isPaid}>
                  {!!isPaid ? "Paguar" : "I papaguar"}
                </Checkbox>
              </Form.Item>
            </div>
            {/* </div> */}
          </Form>
        ) : (
          <Skeleton active paragraph={{ rows: 8 }} />
        )}
      </Card>
    </div>
  );
}
export default PersonalInformation;
