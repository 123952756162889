import React, { useEffect } from "react";
import "./konfigurimet.scss";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

function Kofigurimet() {
  const navigate = useNavigate();
  const location = useLocation();

  //navigate to profili when you open setings
  useEffect(() => {
    if (location?.pathname === "/konfigurimet")
      navigate("/konfigurimet/kompania/profili");
  }, [location]);

  return (
    <div className="konfigurimet">
      <Outlet />
    </div>
  );
}

export default Kofigurimet;
