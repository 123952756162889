import {
  DatePicker,
  Input,
  Radio,
  Select,
  TimePicker,
  Checkbox,
  AutoComplete,
  Calendar,
  InputNumber,
} from "antd";
import MaskedInput from "antd-mask-input";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LabeledInput from "../Documentation/LabeledInput/LabeledInput";
import { compareIncluding } from "../Documentation/utils/compareIncluding";
import { AntdTransfer } from "./components";
import "./InputComponent.scss";
import QuillEditor from "../Intervistat/Kandidatet/ShtoKandidat/Components/QuillEditor";
import SimpleQuillEditor from "../Intervistat/Kandidatet/ShtoKandidat/Components/SimpleQuillEditor";
import dayjs from "dayjs";
import moment from "moment";

const InputComponent = ({
  label,
  type = "",
  formItemName,
  placeholder = "",
  initialValue,
  noFormItem = false,
  className = "",
  style = {},
  required = false,
  disabled = false,
  tooltip,
  rules = [],
  optionType = "default",
  dropdownRender,
  dropdownClassName = "",
  dropdownStyle = {},
  options = [],
  optionGroups = [],
  onChange,
  onSelect,
  optionFilterProp = "children",
  filterOption = compareIncluding(type, "select")
    ? (input, option) =>
        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
    : (inputValue, option) =>
        option?.title?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1,
  picker,
  showSearch,
  disabledDate,
  disabledHours,
  disabledMinutes,
  maxLength,
  buttonStyle,
  typeNumber,
  addonAfter,
  addonBefore,
  selectClassName,
  use12Hours,
  showNow = false,
  hideDisabledOptions = false,
  rows = 2,
  showTime = false,
  format = "",
  minuteStep = 1,
  suffixIcon,
  getPopupContainer,
  popoverVisible = false,
  popoverTitle = "",
  popoverContent,
  popoverPlacement,
  popoverClassName,
  popoverStyle,
  mode = "",
  mask = "",
  defaultChecked = false,
  defaultValue,
  onSearch,
  dataSource = [],
  typePassword = false,
  render = (item) => item?.title,
  targetKeys,
  setTargetKeys,
  allowClear = true,
  onPressEnter,
  prefix,
  onKeyDown,
  maxTagCount,
  customOptions,
  CustomField,
  fullScreen,
  headerRender = () => {},
  formatter,
  onOpenChange,
  onWheel,
  defaultPickerValue,
  readOnly,
  classNameStyle,
  placement,
  value,
  ...rest
}) => {
  const { TextArea } = Input;
  return (
    <LabeledInput
      {...{
        label: type?.toLowerCase() === "checkbox" ? "" : label,
        formItemName,
        initialValue,
        noFormItem,
        className,
        style:
          type?.toLowerCase() === "textarea"
            ? { ...style, width: "100%" }
            : style,
        required,
        tooltip,
        rules,
        popoverVisible,
        popoverTitle,
        popoverContent,
        popoverPlacement,
        popoverClassName,
        popoverStyle,
        valuePropName: type?.toLowerCase() === "checkbox" ? "checked" : "value",
        ...rest,
      }}
    >
      {type?.toLowerCase() === "autocomplete" ? (
        <AutoComplete
          placeholder={placeholder}
          options={customOptions}
          onSelect={onSelect}
          onChange={onChange}
          disabled={disabled}
        >
          {options}
          {optionGroups}
        </AutoComplete>
      ) : type?.toLowerCase() === "phone" ? (
        <PhoneInput
          disableCountryGuess
          {...{
            country: "al",
            onlyCountries: ["al", "xk"],
            placeholder,
            disabled,
            specialLabel: "",
            copyNumbersOnly: false,
            onKeyDown,
            onChange,
            inputProps: {
              maxLength: 16,
            },
          }}
        />
      ) : type?.toLowerCase() === "input" ? (
        <Input
          prefix={prefix}
          suffix={suffixIcon}
          onPressEnter={onPressEnter}
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          type={typeNumber ? "number" : typePassword ? "password" : "text"}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          disabled={disabled}
          {...(!noFormItem ? { defaultValue: initialValue } : {})}
        />
      ) : type?.toLowerCase() === "inputnumber" ? (
        <InputNumber
          controls={true}
          keyboard={true}
          suffixIcon={suffixIcon}
          {...(!noFormItem ? { defaultValue: initialValue } : {})}
        />
      ) : type?.toLowerCase() === "select" ? (
        <Select
          showSearch={showSearch}
          onSelect={onSelect}
          placeholder={placeholder}
          optionFilterProp={optionFilterProp}
          filterOption={filterOption}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          className={selectClassName}
          disabled={disabled}
          mode={mode}
          suffixIcon={suffixIcon}
          maxTagCount={maxTagCount}
          dropdownClassName={dropdownClassName}
          dropdownStyle={dropdownStyle}
          options={customOptions}
          {...(!!dropdownRender ? { dropdownRender } : {})}
        >
          {options}
          {optionGroups}
        </Select>
      ) : type?.toLowerCase() === "tagselect" ? (
        <Select
          showSearch={showSearch}
          onSelect={onSelect}
          placeholder={placeholder}
          optionFilterProp={optionFilterProp}
          filterOption={filterOption}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          className={`customTagsSelect ${selectClassName}`}
          disabled={disabled}
          mode="tags"
          suffixIcon={suffixIcon}
          maxTagCount={maxTagCount}
          dropdownClassName={dropdownClassName}
          dropdownStyle={dropdownStyle}
          options={customOptions}
          {...(!!dropdownRender ? { dropdownRender } : {})}
        >
          {options}
          {optionGroups}
        </Select>
      ) : type?.toLowerCase() === "textarea" ? (
        <TextArea
          rows={rows}
          placeholder={placeholder}
          disabled={disabled}
          type={typeNumber ? "number" : typePassword ? "password" : "text"}
        />
      ) : type?.toLowerCase() === "datepicker" ? (
        <DatePicker
          picker={picker}
          allowClear={allowClear}
          disabledDate={disabledDate}
          onChange={onChange}
          format={format || "DD/MM/YYYY"}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          disabled={disabled}
          showTime={showTime}
          defaultValue={defaultValue}
        />
      ) : type?.toLowerCase() === "radio" ? (
        <Radio.Group
          onChange={onChange}
          optionType={optionType}
          buttonStyle={buttonStyle}
          disabled={disabled}
        >
          {options}
        </Radio.Group>
      ) : type?.toLowerCase() === "timepicker" ? (
        <TimePicker
          format={format || "hh:mm a"}
          showNow={showNow}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          getPopupContainer={
            getPopupContainer
              ? () => document.body
              : (triggerNode) => triggerNode.parentElement
          }
          onChange={onChange}
          onOpenChange={onOpenChange}
          use12Hours={use12Hours}
          disabled={disabled}
          hideDisabledOptions={hideDisabledOptions}
          minuteStep={minuteStep}
        />
      ) : type?.toLowerCase() === "custom" ? (
        <MaskedInput
          mask={mask}
          placeholder={placeholder}
          autoComplete="off"
          onChange={onChange}
          maxLength={maxLength}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          disabled={disabled}
        />
      ) : type?.toLowerCase() === "checkbox" ? (
        <Checkbox
          onChange={onChange}
          disabled={disabled}
          defaultChecked={defaultChecked}
        >
          {label}
        </Checkbox>
      ) : type?.toLowerCase() === "transfer" ? (
        <AntdTransfer
          dataSource={dataSource}
          targetKeys={targetKeys}
          setTargetKeys={setTargetKeys}
          showSearch={showSearch}
          filterOption={filterOption}
          onSearch={onSearch}
          render={render}
        />
      ) : type?.toLowerCase() === "customquill" ? (
        <QuillEditor
          {...{
            onChange,
            placeholder,
            value: value || "",
            formItemName,
          }}
        />
      ) : type?.toLowerCase() === "quill" ? (
        <SimpleQuillEditor
          {...{
            onChange,
            placeholder,
            value: value || "",
          }}
        />
      ) : type?.toLowerCase() === "calendar" ? (
        <Calendar
          headerRender={headerRender}
          fullscreen={fullScreen}
          formItemName={formItemName}
          disabledDate={disabledDate}
          mode="date"
        />
      ) : (
        <Input
          prefix={prefix}
          suffixIcon={suffixIcon}
          onPressEnter={onPressEnter}
          autoComplete="off"
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onChange={onChange}
          maxLength={maxLength}
          type={typeNumber ? "number" : typePassword ? "password" : "text"}
          addonAfter={addonAfter}
          controls={false}
          addonBefore={addonBefore}
          disabled={disabled}
          {...onKeyDown}
          {...(!!noFormItem ? { defaultValue: initialValue } : {})}
        />
      )}
    </LabeledInput>
  );
};

export default InputComponent;
