import { add, divide, multiply, subtract } from "lodash";
import { currencyformatter } from "../../wages/utils/formatter";

export const prepareContributions = (finalCalc) => {
	// console.log("table 3", finalCalc);
	const contributionTable = {
		columnsDef: [
			"PAGA PER KONTRIBUTET",
			"SIG. SHENDETESORE",
			"SIG SHOQERORE",
			"SIGURIMET TOTAL",
			"TAX PROGRESIVE",
		],
		rowData: [],
		netoPay: getNetoPay(Math.round(finalCalc?.finalWage)),
	};

	const pagaNeto = getNetoPay(finalCalc?.finalWage, finalCalc?.info);
	contributionTable.netoPay = pagaNeto;
	const { contributions, healthIns, progressiveTax, socialIns, totalIns } = pagaNeto;
	const total = progressiveTax + totalIns;
	contributionTable.rowData.push([contributions, healthIns, socialIns, totalIns, progressiveTax]);
	contributionTable.rowData.push([
		"TOTALI KONTRIBUTET",
		"",
		"",
		"",
		currencyformatter.format(total),
	]);
	return contributionTable;
};

export const getNetoPay = (brutoval, emp) => {
	const netoObj = {
		progressiveTax: 0,
		contributions: 0,
		socialIns: 0,
		healthIns: 0,
		totalIns: 0,
		netoPay: 0,
		// for employee
		insuranceEmployeer: 0,
		healthInsEmployeer: 0,
		totalEmployeeerIns: 0,
	};
	let brutoAmount = brutoval || 0;
	// || 40000;
	if (!!brutoAmount && brutoAmount > 0) {
		// Taska Progresive

		if (brutoAmount <= 40000) {
			netoObj.progressiveTax = 0;
		} else if (brutoAmount >= 40001 && brutoAmount <= 50000) {
			netoObj.progressiveTax = (brutoAmount - 30000) * 0.13 * 0.5;
		} else if ((brutoAmount) => 50001 && brutoAmount <= 200000) {
			netoObj.progressiveTax = (brutoAmount - 30000) * 0.13;
		} else {
			netoObj.progressiveTax = (brutoAmount - 200000) * 0.23 + 22100;
		}

		// Kontributet
		if (brutoAmount > 141133) {
			netoObj.contributions = 141133;
		}
		// else if (brutoAmount < 40000) {
		// 	netoObj.contributions = 40000;
		// }
		else {
			netoObj.contributions = brutoAmount;
		}

		// Sigurimet
		netoObj.socialIns = multiply(netoObj.contributions, 0.095);
		netoObj.healthIns = multiply(brutoAmount, 0.017);
		netoObj.totalIns = add(netoObj.socialIns, netoObj.healthIns);
		netoObj.netoPay = subtract(subtract(brutoAmount, netoObj.totalIns), netoObj.progressiveTax);
		// brutoAmount > 40000
		// 	? subtract(subtract(brutoAmount, netoObj.totalIns), netoObj.progressiveTax)
		// 	: subtract(subtract(40000 - netoObj.totalIns), netoObj.progressiveTax);

		netoObj.insuranceEmployeer = multiply(netoObj?.contributions, 0.15);
		netoObj.healthInsEmployeer = multiply(brutoAmount, 0.017);
		netoObj.totalEmployeeerIns = add(netoObj?.insuranceEmployeer, netoObj?.healthInsEmployeer);
		console.log("netoObj", netoObj);
		return netoObj;
	} else return netoObj;
};

export const getBrutoFromNeto = (neto) => {
	let bruto = 0;
	if (neto > 0) {
		if (neto <= 35520) {
			bruto = divide(neto, 0.888);
		} else if (neto >= 35520 && neto <= 43100) {
			bruto = divide(subtract(neto, 1950), 0.823);
			// bruto = (neto - 1950) / 0.823;
		} else if (neto >= 43100 && neto <= 110882) {
			bruto = divide(subtract(neto, 3900), 0.758);
			// bruto = (neto - 3900) / 0.758;
		} else if (neto > 110879 && neto <= 161092) {
			bruto = divide(add(neto, 9507.635), 0.853);
			// bruto = (neto + 9507.635) / 0.853;
		} else if (neto > 161092) {
			bruto = divide(subtract(neto, 10492.365), 0.753);
			// bruto = (neto - 10492.365) / 0.753;
		}
		return bruto;
		// return Math.round(bruto);
	}
	return bruto;
	// return Math.round(bruto);
};
