import { useMemo, useRef } from "react";
// import loaddash isEqual
import isEqual from "lodash.isequal";

function useMemoCompare(nextDeps) {
	const previousDeps = useRef();
	console.log("previousDeps", { previousDeps, nextDeps });

	const areTheyEqual = isEqual(previousDeps.current, nextDeps);

	const memoizedValue = useMemo(
		() => {
			previousDeps.current = nextDeps;
			return nextDeps;
		},
		areTheyEqual ? previousDeps.current : nextDeps
	);

	return memoizedValue;
}

export default useMemoCompare;
