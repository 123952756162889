import { Form } from "antd";
import InputComponent from "../../../../../InputComponent/InputComponent";
import { Tick, Close } from "../../../../assets";
import "./OptionsFormList.scss";

const OptionsFormList = ({ form, formItemName = "fieldOptions" }) => (
  <Form {...{ form, rules: {} }}>
    <div className="docOptionsContainer coreInputsContainer">
      <Form.List
        name={formItemName}
        initialValue={[""]}
        rules={[
          {
            validator: (_, value = []) =>
              value.filter(Boolean).length === 0
                ? Promise.reject(new Error("Ju lutem jepni opsionet!"))
                : Promise.resolve(),
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields?.map((field, index) => (
              <div className="optionField">
                <InputComponent
                  {...{
                    ...field,
                    onPressEnter: () => add(),
                    label: `Opsioni ${index + 1}`,
                    placeholder: "Shkruani një këtu...",
                    formItemName: field.name,
                  }}
                />
                <span
                  onClick={() => {
                    if (fields.length === 1) {
                      remove(field.name);
                      add();
                    } else remove(field.name);
                  }}
                  className="fieldOptionButton added"
                >
                  <Close className="closeSvgIcon" />
                </span>
                {index === fields.length - 1 && (
                  <span
                    onClick={() => add()}
                    className="fieldOptionButton notAdded"
                  >
                    <Tick />
                  </span>
                )}
              </div>
            ))}
            <Form.ErrorList {...{ errors }} />
          </>
        )}
      </Form.List>
    </div>
  </Form>
);

export default OptionsFormList;
