import { Card, Col, Row } from "antd";
import { API } from "aws-amplify";
import React, { useState, useEffect, useMemo } from "react";
import { ComponentCard } from "../../../dashboard/AdminDashboard/Draggable/ComponentCard";
function WageInfo({ showPaymentInfo, employeesData }) {
	const [tableData, setTableData] = useState();
	const displayShifts = useMemo(() => {
		if (!!tableData && !!employeesData) {
			let temp = [];
			let shifts = employeesData?.employeeShiftId || [];
			if (shifts.length > 0) {
				shifts?.map((shift) => {
					let name = tableData.find((el) => el?.shiftId === shift);
					if (!!name) {
						temp.push(name);
					}
				});
				if (temp.length > 0) {
					return (
						<div className="style-width-input" style={{ width: "200%" }}>
							{" "}
							{temp?.map((t, i) => (
								<>
									{i === temp.length - 1 ? (
										<span>{t?.shiftName}</span>
									) : (
										<span>{t?.shiftName + ", "}</span>
									)}
								</>
							))}
						</div>
					);
				} else if (temp.length === 0) {
					return <strong style={{ color: "Red" }}>Nuk ka te dhena</strong>;
				}
			}
			return <strong style={{ color: "Red" }}>Nuk ka te dhena</strong>;
		}
	}, [tableData, employeesData]);
	const getShifts = async () => {
		await API.get("shifts", "/shifts").then((r) => setTableData(r));
	};
	useEffect(() => {
		getShifts();
	}, [employeesData, showPaymentInfo]);

	const Com = () => (
		<div>
			<Row style={{ width: "100%", paddingBottom: 15 }}>
				<Col span={11}>
					<div className="label-view-details">Paga neto:</div>
				</Col>
				<Col span={13}>
					<div className="label-show-details">{employeesData?.employeeMonthlyNetSalary}</div>
				</Col>
			</Row>
			<Row style={{ width: "100%", paddingBottom: 15 }}>
				<Col span={11}>
					<div className="label-view-details">Paga bruto:</div>
				</Col>
				<Col span={13}>
					<div className="label-show-details">{employeesData?.employeeGrossMonthlySalary}</div>
				</Col>
			</Row>
			<Row style={{ width: "100%", paddingBottom: 15 }}>
				<Col span={11}>
					<div className="label-view-details">Lloji i pagës:</div>
				</Col>
				<Col span={13}>
					<div className="label-show-details">{employeesData?.employeePayrollType}</div>
				</Col>
			</Row>
			<Row style={{ width: "100%", paddingBottom: 15 }}>
				<Col span={11}>
					<div className="label-view-details">Pagesa per ore:</div>
				</Col>
				<Col span={13}>
					<div className="label-show-details">
						{!!employeesData?.employeeSalaryPerHour
							? parseFloat(employeesData?.employeeSalaryPerHour || 0).toFixed(2)
							: ""}
					</div>
				</Col>
			</Row>
			<Row className="frekuenca-row" style={{ width: "100%", paddingBottom: 15 }}>
				<Col span={11}>
					<div className="label-view-details">Frekuenca e pagës:</div>
				</Col>
				<Col span={13}>
					<div className="label-show-details">{employeesData?.employeePayrollFrequency}</div>
				</Col>
			</Row>
			<Row className="row-mob-with-fix" style={{ width: "110%", paddingBottom: 15 }}>
				<Col span={12}>
					<div className="label-view-details">Orari automatik i paguar: </div>
					{displayShifts}
				</Col>
			</Row>
		</div>
	);
	return (
		<Row>
			{showPaymentInfo === true && (
				<Card
					className="pay-info-card"
					title="Informacioni i pagesës"
					style={{ height: 370 }}
					extra={
						<div className="icon-button">
							{/* <button id="edit" onClick={handleClick}>
              <EditIcon className="pen-icon" />
            </button> */}
						</div>
					}
				>
					<ComponentCard
						blur={true}
						width={"100%"}
						height={"110%"}
						content={<Com />}
						shadow={false}
					/>
					{/* <Com /> */}
				</Card>
			)}
		</Row>
	);
}

export default WageInfo;
