import { Tooltip } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { DeleteIcon, EditIcon } from '../../../assets/icons'
import './KonfigurimiKandidateve.scss'
import moment from 'moment'

export const columnDefs = ({ handleEdit, handleDelete }) => {


    // const ShowText = () => {
    //     return 12
    // }
    return [
        {
            headerName: "Label",
            field: "label",
            filter: "agTextColumnFilter",
            width: "400px",
            resizable: true,
            sortable: true,
        },
        {
            headerName: "Required",
            filter: "agTextColumnFilter",
            field: "required",
            resizable: true,
            sortable: true,
            valueGetter: (params) => {
                return params?.data?.required === true
                    ? "Yes"
                    : params?.data?.required === false
                        ? "No"
                        : ""
            }
        },
        {
            headerName: "Type",
            field: "type",
            filter: "agTextColumnFilter",
            width: "210px",
            resizable: true,
            sortable: true,
            valueGetter: (params) => {
                return params?.data?.type.charAt(0).toUpperCase() + params?.data?.type.slice(1)
            }
        },
        {
            headerName: "Created By",
            field: "createdBy",
        },
        {
            headerName: "Created At",
            field: "createdAt",
            valueGetter: ({ data }) => {
                if (data?.hasOwnProperty("createdAt")) {
                    return dayjs(data.createdAt).format("DD/MM/YYYY")
                } else {
                    return ""
                }
            }
        },
        {
            headerName: "Edit",
            resizable: true,
            cellStyle: () => {
                return {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }
            },

            cellRendererFramework: ({ data, rowIndex }) => (
                <div onClick={() => handleEdit(data)}
                    key={rowIndex}
                >
                    <div className='buttonStyle'>
                        <Tooltip title="Edit"><EditIcon fill='#fff' width={18} height={18} /></Tooltip>
                    </div>
                </div >
            )
        },
        {
            headerName: "Delete",
            cellStyle: () => {
                return {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }
            },
            resizable: true,
            cellRendererFramework: ({ data, rowIndex }) => (
                <div onClick={() => {
                    handleDelete(data)
                }}
                    key={rowIndex}
                >
                    <div className='buttonStyle'>
                        <Tooltip title="Delete"><DeleteIcon fill='#fff' width={18} height={18} /></Tooltip>
                    </div>
                </div>
                // <MemorizedEditCellRender data={data} rowIndex={rowIndex} handleDelete={handleDelete} />

            )
        }

    ]
}
