import React from "react";
import { HollowDotsSpinner } from "react-epic-spinners";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IconPunonjesit, TotalNumber, Punonjësit } from "../../../assets/icons";
import DragCard from "./Draggable/DragCard";
import { useNavigate } from "react-router-dom";
const TotalEmployeeNumber = () => {
  const navigate = useNavigate();
  // get active employes from redoux
  const { activeEmployees } = useSelector((state) => state.employeesList);
  //Eshte shtuar kushtu me poshte sepse kur nuk ka punonjes nuk del 0
  const data = {
    icon: <Punonjësit />,
    // icon: <IconPunonjesit />,
    text: "Totali i punonjësve",
    number: activeEmployees?.length ? activeEmployees?.length : 0,
  };

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {!!activeEmployees ? (
        <DragCard
          data={data}
          onClick={() => navigate("/punonjesit")}
        />
      ) : (
        <DragCard
          data={{
            icon: (
              <HollowDotsSpinner
                size={26}
                color="#1DA193"
                style={{ marginTop: "50%" }}
              ></HollowDotsSpinner>
            ),
          }}
        />
      )}
    </div>
  );
};
// 2h 15
// 5h 45

export default TotalEmployeeNumber;
