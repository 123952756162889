import { Dropdown, Menu } from "antd";

const DropdownWrapper = ({
  onClick,
  dropdownDisabled = false,
  dropdownOptions,
  children,
}) => {
  //* dropdown options, renders the options
  const menu = (
    <Menu onClick={onClick}>
      {dropdownOptions.map((item) => (
        <Menu.Item key={item}>{item}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown disabled={!!dropdownDisabled} overlay={menu}>
      {children}
    </Dropdown>
  );
};

export default DropdownWrapper;
