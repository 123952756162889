import moment from "moment-timezone";
export const weekFiltering = (momentInstance, filterType) => {
  if (momentInstance !== null) {
    let start = moment(momentInstance).startOf("month").valueOf();

    switch (filterType) {
      case "firstWeek":
        return [moment(start).weekday(0).valueOf(), moment(start).weekday(6).valueOf()];
      case "secondWeek":
        return [moment(start).weekday(7).valueOf(), moment(start).weekday(13).valueOf()];
      case "thirdWeek":
        return [moment(start).weekday(14).valueOf(), moment(start).weekday(20).valueOf()];
      case "fourthWeek":
        return [moment(start).weekday(21).valueOf(), moment(start).weekday(27).valueOf()];
      case "fifthWeek":
        return [moment(start).weekday(28).valueOf(), moment(start).endOf("month").valueOf()];
      default:
        return [moment(start).weekday(0).valueOf(), moment(start).weekday(6).valueOf()];
    }
  }
};
