import { Button, Select } from "antd";
import moment from "moment-timezone";
import React, { useMemo, useState } from "react";
import MobileHtmlTabel from "../../../utils/MobileComponents/MobileHtmlTabel";
import { ClearIcon, DropDownIcon } from "../../dashboard/assets";
import { FilterIconButton } from "../../../assets/icons";

function MobileApprovals({
  aggridData,
  setNewShiftVisib,
  activeEmployees,
  location,
}) {
  const [mobileFileterEmp, setMobileFilerEmo] = useState(null);
  const [mobileFileterStatus, setMobileFilerStatus] = useState(null);
  const { Option } = Select;

  const onChangePunojsiMobile = (e) => {
    setMobileFilerEmo(e);
  };

  const onChangeStatusMobile = (e) => {
    setMobileFilerStatus(e);
    // setMobileFilerEmo(null);
  };

  const clearMobileFilter = () => {
    setMobileFilerStatus(null);
    setMobileFilerEmo(null);
  };

  const MobileFilerEmoplo = useMemo(() => {
    if (mobileFileterStatus !== null && mobileFileterEmp !== null) {
      console.log("erdhi3");
      let datamob = aggridData?.filter?.(
        (el) =>
          el?.approved == mobileFileterStatus &&
          `${el?.employee_details?.employeeFirstName} ${el?.employee_details?.employeeLastName}` ==
            mobileFileterEmp
      );
      return datamob;
    }
    if (mobileFileterEmp !== null) {
      console.log("erdhi1");
      let datamob = aggridData?.filter?.(
        (el) =>
          `${el?.employee_details?.employeeFirstName} ${el?.employee_details?.employeeLastName}` ==
          mobileFileterEmp
      );
      return datamob;
    }
    if (mobileFileterStatus !== null) {
      console.log("erdhi2");
      let datamob = aggridData?.filter?.(
        (el) => el?.approved === mobileFileterStatus
      );
      return datamob;
    }
  }, [mobileFileterEmp, mobileFileterStatus]);

  return (
    <div className="base-mobile-with-header" style={{ margin: 0, padding: 10 }}>
      <div className="more-shift-filters2-mobile">
        <div className="employee-filter">
          <Select
            onChange={(e) => onChangePunojsiMobile(e)}
            clearIcon={<ClearIcon />}
            suffixIcon={<DropDownIcon />}
            optionFilterProp="children"
            defaultValue={
              !!location?.state?.paga?.employeeId
                ? location.state.paga.employeeId
                : null
            }
            value={mobileFileterEmp}
            showSearch
            allowClear
            // value={activeFiltering?.employee}
            placeholder="Zgjidh Punonjesin"
          >
            {activeEmployees?.map((el) => (
              <Option
                value={`${el?.employeeFirstName} ${el?.employeeLastName}`}
              >
                {el.employeeFirstName + " " + el.employeeLastName}
              </Option>
            ))}{" "}
          </Select>
        </div>
        <div className="employee-filter">
          <Select
            showSearch
            clearIcon={<ClearIcon />}
            suffixIcon={<DropDownIcon />}
            value={mobileFileterStatus}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => onChangeStatusMobile(e)}
            allowClear
            placeholder="Zgjidh statusin"
          >
            <Option value={true}>Aprovuar</Option>
            <Option value={false}>Pa Aprovuar</Option>
          </Select>
        </div>
        <div className="employee-filter">
          <div className="clear-filters-shift" onClick={clearMobileFilter}>
            <button>
              <FilterIconButton />
            </button>
          </div>
        </div>
      </div>
      <div
        className="header-div"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {" "}
        <Button className="btn-header" onClick={() => setNewShiftVisib(true)}>
          Krijo nje hyrje
        </Button>{" "}
        {/* <div
          style={{
            borderRadius: 5,
            padding: 7,
            // color: "#fff",
            marginRight: 20,
            background: "#f1f7fb",
          }}
        >
          {aggridData?.length} Rekorde
        </div> */}
      </div>
      <MobileHtmlTabel
        tableData={
          mobileFileterEmp !== null || mobileFileterStatus !== null
            ? MobileFilerEmoplo?.map(
                ({
                  approved,
                  employee_details,
                  data,
                  clockInDate,
                  clockOutDate,
                  oret_totale,
                }) => ({
                  approved: approved ? "Aprovuar" : "Pa Aprovuar",
                  emri: employee_details?.employeeFirstName,
                  mbiemri: employee_details?.employeeLastName,
                  statusHeaderKey: employee_details?.employeeDepartmentName,
                  data: moment(data).format("DD/MM/YYYY"),
                  clockInDate: moment(clockInDate).format("HH:mm"),
                  clockOutDate: moment(clockOutDate).format("HH:mm"),
                  oret_totale,
                })
              )
            : aggridData?.map(
                ({
                  approved,
                  employee_details,
                  data,
                  clockInDate,
                  clockOutDate,
                  oret_totale,
                }) => ({
                  approved: approved ? "Aprovuar" : "Pa Aprovuar",
                  emri: employee_details?.employeeFirstName,
                  mbiemri: employee_details?.employeeLastName,
                  statusHeaderKey: employee_details?.employeeDepartmentName,
                  data: moment(data).format("DD/MM/YYYY"),
                  clockInDate: moment(clockInDate).format("HH:mm"),
                  clockOutDate: moment(clockOutDate).format("HH:mm"),
                  oret_totale,
                })
              )
        }
        header={[
          "Statusi",
          "Emri",
          "Mbiemri",
          "Departamenti",
          "Data",
          "Fillimi i turnit",
          "Mbarimi i turnit",
          "Orët totale",
        ]}
        isHeader={true}
        isLink={false}
      />
    </div>
  );
}

export default MobileApprovals;
