import CardComponentInfoContatct from "../../../../Konfigurimet/KompaniaSettings/InformacioneRrethKompanis/CardComponentInfoContatct";
import CardComponentInfoKomp from "../../../../Konfigurimet/KompaniaSettings/InformacioneRrethKompanis/CardComponentInfoKomp";
import DetajetKompaniaCard from "../../../../Konfigurimet/KompaniaSettings/InformacioneRrethKompanis/DetajetKompaniaCard";
import EmployeOfTheMonthCard from "../../../../Konfigurimet/KompaniaSettings/InformacioneRrethKompanis/EmployeOfTheMonthCard";
import EmployeOfWeekCard from "../../../../Konfigurimet/KompaniaSettings/InformacioneRrethKompanis/EmployeOfWeekCard";
import InformacioneKontakti from "../../../../Konfigurimet/KompaniaSettings/InformacioneRrethKompanis/InformacioneKontakti";

const defaultData = {

  x: 0,
  y: 0,
  h: 1,
  w: 1,
  minW: 1,
  minH: 1,
  static: false

}

const proccessMap = (dynamicMap) => {
  return dynamicMap.map((el, index) => ({
    id: index,
    name: el.earningName,
    emri: el.earningName,
    src: CardComponentInfoKomp,
    parent: false,
    // data: cardData[0],
    data: el,
    hidden: false,
  }));
};


export const InfoRrethKompanisCards = [
  {
    i: "0",
    data: {
      name: "Detajet e kompanisë/subjektit",
      emri: "Detajet e kompanisë/subjektit",
      src: CardComponentInfoKomp,
      // content: ClockIn,
      parent: true,
      data: {},
      hidden: false,
    },
    ...defaultData,
    x: 0,
    y: 0


  },
  {
    i: "1",
    data: {

      name: "Informacione kontakti",
      emri: "Informacione kontakti",
      src: CardComponentInfoContatct,
      // content: WeeklyMonthly,
      parent: true,
      data: {},
      hidden: false,
    },
    ...defaultData,
    x: 1,
    y: 0
  },
  {
    i: "2",
    data: {
      name: "Employee of the month",
      emri: "Employee of the month",
      src: EmployeOfTheMonthCard,
      // content: WeeklyMonthly,
      parent: true,
      data: {},
      hidden: false,
    },
    ...defaultData,
    x: 0,
    y: 1
  },
  {
    i: "3",
    data: {
      name: "Employee of the week",
      emri: "Employee of the week",
      src: EmployeOfWeekCard,
      // content: WeeklyMonthly,
      parent: true,
      data: {},
      hidden: false,
    },
    ...defaultData,
    x: 1,
    y: 1
  },
];

export const getSettingsInfoRrethKompSaved = (dynamicMap) => {
  //   let test = proccessMap(dynamicMap);
  const ls = JSON.parse(
    localStorage.getItem("draggableSetingsInfoRrethKompanis")
  );
  if (ls !== null) {
    let temporary = [];
    ls.forEach((el, idx) => {
      let found = InfoRrethKompanisCards.findIndex((l, x) => l.i === el.i);
      if (found !== -1) {
        temporary.push({
          ...InfoRrethKompanisCards[found],
          data: {
            ...InfoRrethKompanisCards[found].data,
            hidden: el.data.hidden
          },
          x: ls[found].x,
          y: ls[found].y,
          h: ls[found].h,
          w: ls[found].w,
          minW: ls[found].minW,
          minH: ls[found].minH,
        });
      }
    });
    return temporary;
  } else if (ls === null) {
    localStorage.setItem("draggableSetingsInfoRrethKompanis", JSON.stringify(InfoRrethKompanisCards));
    return InfoRrethKompanisCards;
  }
};
