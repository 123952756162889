import React from "react";
import Cardkonfig from "../../dynamic components/Card";
import DetajetKompaniaCard from "./DetajetKompaniaCard";

function CardComponentInfoKomp({ handleChange }) {
  return (
    // <div className="Detajet-Kompania-Card-Div" style={{ width: 785 }}>
    <Cardkonfig
      contentChild={<DetajetKompaniaCard handleChange={handleChange} />}
      width="100%"
      height={"calc(100% - 35px)"}
    //   title="Detajet e kompanisë/subjektit"
    />
    // </div>
  );
}

export default CardComponentInfoKomp;
