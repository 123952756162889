import { groupSingleShift } from "./calculcate";
import { API } from "aws-amplify";
import { sliceShifts } from "./slicing";
import { groupShifts } from "../../../../components/Pagat/PaySub/employeeCalc/calculcate";
import { COEFFICENTS_WAGES } from "./wageHours";
import { add, multiply } from "lodash";
import moment from "moment-timezone";
const TOTALITIES_REF = {
	employeeRate: 0,
	late: {
		hours: 0,
		pay: 0,
	},
	normal: {
		hours: 0,
		pay: 0,
	},
	extra: {
		hours: 0,
		pay: 0,
	},
	totalPay: 0,
	totalHours: 0,
};

export async function dayCalculateFactory(finishedShift) {
	const { employeeId, clockingId } = finishedShift || null;
	// const { EXTRA_HOURS, LATE_HOURS, NORMAL_HOURS } = groupSingleShift(finishedShift) || null;
	let employeeSalaryPerHour = null;
	let breakTime = [];
	let breakTimeVal = 0;

	let clockInType = null;
	let UPPER_COEFFICENTS = null;
	console.log("employeeId", employeeId);
	console.log("clockingId", clockingId);
	if (!!employeeId) {
		await API.get("employees", `/employees/${employeeId}`).then((res) => {
			employeeSalaryPerHour = res.employeeSalaryPerHour;
			let clock_day = moment(finishedShift.clockInDate).day();
			/// If not Saturday or Sunday, then calculate break time
			if (clock_day !== 6 && clock_day !== 0) {
				breakTime = res.breakTime;
				console.log("new empl", res);
			}
		});
		await API.get("clocking", `/clocking/${clockingId}`).then((res) => (clockInType = res.dayType));
		await API.get("programFields", "/programFields").then(
			(res) =>
				(UPPER_COEFFICENTS = res?.find(
					({ fieldName }) => fieldName === "ClockIn Types"
				)?.fieldOptions)
		);
	}
	let isNotSubjetToOtherCoefficients = clockInType !== "Regular" ? true : false;
	const { EXTRA_HOURS, LATE_HOURS, NORMAL_HOURS } =
		groupSingleShift(finishedShift, isNotSubjetToOtherCoefficients) || null;

	if (breakTime?.length > 1) {
		let diff = moment(breakTime[1], "HH:mm").diff(moment(breakTime[0], "HH:mm"), "hours", true);
		breakTimeVal = diff * employeeSalaryPerHour;
	}

	const result = Object.assign({}, TOTALITIES_REF); // avoiding mutation
	result["breakTime"] = breakTime;
	result["breakTimeVal"] = breakTimeVal;

	if (!!employeeSalaryPerHour && !!EXTRA_HOURS && !!LATE_HOURS && !!NORMAL_HOURS) {
		console.log("upper", UPPER_COEFFICENTS);
		result["employeeRate"] = employeeSalaryPerHour;
		console.log("employeeRate", employeeSalaryPerHour);
		result["late"] = {
			hours: LATE_HOURS.amount,
			pay: getPay(employeeSalaryPerHour, LATE_HOURS.amount, LATE_HOURS.coefficent),
		};
		result["normal"] = {
			hours: NORMAL_HOURS.amount,
			pay: getPay(employeeSalaryPerHour, NORMAL_HOURS.amount, NORMAL_HOURS.coefficent),
		};
		result["extra"] = {
			hours: EXTRA_HOURS.amount,
			pay: getPay(employeeSalaryPerHour, EXTRA_HOURS.amount, EXTRA_HOURS.coefficent),
		};
		result["upperCoefficent"] =
			UPPER_COEFFICENTS?.find((coefficient) => coefficient?.name === clockInType)?.coefficent || 1;
		result["totalPay"] = multiply(
			add(add(result["late"].pay, result["normal"].pay), result["extra"].pay),
			result["upperCoefficent"]
		);
		result["totalHours"] = add(
			result["late"].hours,
			add(result["normal"].hours + result["extra"].hours)
		);
		console.log("result", result);
		return result;
	}
	console.log("result", result);

	return result;
}

const getPay = (rate, amount, coefficient) => {
	if (amount <= 0) {
		return 0;
	}
	return multiply(rate, multiply(amount, coefficient));
};

//
export const getClockInPrePay = (appliedClockings) => {
	let temp = {};

	if (Array.isArray(appliedClockings)) {
		appliedClockings.forEach((clockIn) => {
			if (!!clockIn?.totalities && clockIn?.approved === true) {
				let eRate = clockIn?.totalities?.employeeRate;
				if (!temp[eRate]) {
					temp[eRate] = {
						archieve: [clockIn],
						normal: { hours: 0, amount: 0 },
						extra: { hours: 0, amount: 0 },
						late: { hours: 0, amount: 0 },
						totalHours: 0,
						totalAmount: 0,
					};
				} else {
					temp[eRate].archieve.push(clockIn);
				}
			}
		});

		Object.entries(temp).forEach(([key, value]) => {
			value.archieve.forEach(({ totalities }) => {
				temp[key].normal.hours += totalities.normal.hours;
				temp[key].normal.amount += totalities.normal.pay;
				temp[key].extra.hours += totalities.extra.hours;
				temp[key].extra.amount += totalities.extra.pay;
				temp[key].late.hours += totalities.late.hours;
				temp[key].late.amount += totalities.late.pay;
				temp[key].totalHours += totalities.totalHours;
				temp[key].totalAmount += totalities.totalPay;
			});
		});
	}
	return temp;
};

export const getEarningsSaved = (result) => {
	let { paidClockIns } = result || null;
	const totalities = {
		normalHours: {
			normalEarnings: 0,
			coefficent: COEFFICENTS_WAGES?.NORMAL_HOURS,
			rate: 0,
			hours: 0,
		},
		lateHours: {
			lateEarnings: 0,
			coefficent: COEFFICENTS_WAGES?.LATE_HOURS,
			rate: 0,
			hours: 0,
		},
		extraHours: {
			extraEarnings: 0,
			coefficent: COEFFICENTS_WAGES?.EXTRA_HOURS,
			rate: 0,
			hours: 0,
		},
		totalWage: 0,
	};
	const toRet = [];
	if (!!paidClockIns) {
		Object.entries(paidClockIns).forEach(([key, value]) => {
			const temp = {
				normalHours: {
					normalEarnings: 0,
					coefficent: COEFFICENTS_WAGES?.NORMAL_HOURS,
					rate: 0,
					hours: 0,
				},
				lateHours: {
					lateEarnings: 0,
					coefficent: COEFFICENTS_WAGES?.LATE_HOURS,
					rate: 0,
					hours: 0,
				},
				extraHours: {
					extraEarnings: 0,
					coefficent: COEFFICENTS_WAGES?.EXTRA_HOURS,
					rate: 0,
					hours: 0,
				},
				totalWage: 0,
			};
			temp.normalHours.rate = multiply(key, COEFFICENTS_WAGES?.NORMAL_HOURS);
			temp.normalHours.hours = value.normal.hours;
			temp.lateHours.rate = multiply(key, COEFFICENTS_WAGES?.LATE_HOURS);
			temp.lateHours.hours = value.late.hours;
			temp.extraHours.rate = multiply(key, COEFFICENTS_WAGES?.EXTRA_HOURS);
			temp.extraHours.hours = value.extra.hours;
			temp.normalHours.normalEarnings += value.normal.amount;
			temp.lateHours.lateEarnings += value.late.amount;
			temp.extraHours.extraEarnings += value.extra.amount;
			temp.totalWage += value?.totalAmount || 0;
			temp.employeeRate = key;
			temp.totalHours = value.normal.hours + value.late.hours + value.extra.hours;

			//
			toRet.push(temp);
		});
	}
	return toRet;
};
