import { Button, Modal } from "antd";
import moment from "moment";
import React from "react";
import { useAuth } from "../../../../authentication/authHelpers";
import { albanianDay } from "./albanianDay";
import { albanianMonth } from "./albanianMonth";
import "./DetailsOvertimeModal.scss";
import { ModalXIcon } from "../../../../assets/icons";
const DetailsOvertimeModal = ({ open, setOpen, specificData, onApprove }) => {
  const { employee } = useAuth();
  const {
    requestDescription,
    requestPeriod,
    requestStatus,
    requestType,
    overtimeDay,
  } = specificData;

  let monthName = new Date(
    moment(overtimeDay).format("YYYY/MM/DD")
  ).toLocaleString("default", {
    month: "long",
  });
  let dayName = new Date(
    moment(overtimeDay).format("YYYY/MM/DD")
  ).toLocaleString("default", {
    weekday: "long",
  });

  return (
    <div>
      <Modal
        title={`Kërkesë për orë shtesë ${requestType?.toLowerCase()}`}
        centered
        className="detailsOvertimeModal"
        open={open}
        destroyOnClose={true}
        closeIcon={<ModalXIcon />}
        onCancel={() => setOpen(false)}
        footer={[
          <div>
            <div style={{ display: "flex" }}>
              <Button onClick={() => setOpen(false)} className="turnBackBtn">
                Kthehu pas
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: -35,
              }}
            >
              {requestStatus !== "Refuzuar" && (
                <Button
                  onClick={() => onApprove("Refuzuar")}
                  className="refuseBtn"
                >
                  Refuzo kërkesën
                </Button>
              )}
              {requestStatus !== "Aprovuar" && (
                <Button
                  onClick={() => onApprove("Aprovuar")}
                  className="approveBtn"
                  style={{
                    backgroundColor: "#1DA193",
                  }}
                >
                  Aprovo kërkesën
                </Button>
              )}
            </div>
          </div>,
        ]}
      >
        <div className="detailContainer">
          <div className="employeeName">{`${employee?.employeeFirstName} ${employee?.employeeLastName}`}</div>
          <div>{employee?.employeeRoleName}</div>
          {[undefined, null, []]?.includes(requestPeriod) ? (
            ""
          ) : (
            <div className="dateTimeDetails">
              {`${albanianDay(dayName)}, ${moment(overtimeDay).format("DD")}
              ${albanianMonth(monthName)} ${moment(overtimeDay).format(
                "YYYY"
              )} | ${requestPeriod[0]} - ${requestPeriod[1]}`}
            </div>
          )}
          <div className="descriptionDetail">
            <div className="shenim">Shenim:</div>
            <div className="requestDescription">
              {requestDescription?.replace("<p>", " ")?.replace("</p>", " ")}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailsOvertimeModal;
