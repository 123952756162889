import React, { useState } from "react";
import "./KalkulatoriPages.scss";
import { Button } from "antd";

const CalculatorButtons = ({ handleEqual, handleClick, handleRemove }) => {
  return (
    <>
      <div className="calculatorNumbers">
        <button name="7" onClick={handleClick} className="btn">
          7
        </button>
        <button name="8" onClick={handleClick} className="btn">
          8
        </button>
        <button name="9" onClick={handleClick} className="btn">
          9
        </button>
        <button name="4" onClick={handleClick} className="btn">
          4
        </button>
        <button name="5" onClick={handleClick} className="btn">
          5
        </button>
        <button name="6" onClick={handleClick} className="btn">
          6
        </button>
        <button name="1" onClick={handleClick} className="btn">
          1
        </button>
        <button name="2" onClick={handleClick} className="btn">
          2
        </button>
        <button name="3" onClick={handleClick} className="btn">
          3
        </button>
        <button name="0" onClick={handleClick} className="btn">
          0
        </button>
        <button onClick={handleRemove} className="btn">
          C
        </button>
        <button onClick={handleEqual} className="btn">
          =
        </button>
      </div>
    </>
  );
};

export default CalculatorButtons;
