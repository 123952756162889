import parse from "html-react-parser";

// function html formater
export const htmlParser = (html) => {
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  return parse(html || "", options);
};
