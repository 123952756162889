import Layout from "./authentication/Layout";
// import PageNotFound from "./components/commonComponents/PageNotFound";
import { NotifContextProvider } from "./components/dashboard/components/notifications/context/notifContext";
import DynamicPunojsitView from "./components/punonjesit/DynamicView/DynamicPunojsitView";
import ListPrezenca from "./components/ListPrezenca/ListPrezenca";
import Punonjesit from "./components/punonjesit/Punonjesit";
import Raportet from "./components/Raportet/Raportet";
import DocumentationConfiguration from "./components/DocumentationConfiguration/DocumentationConfiguration";
import {
  Kofigurimet,
  PerdoruesitTeDhenatAksesit,
  MenyratPageses,
  PolitikatFestatZyrtare,
  PolitikatDitetPushim,
  PolitikatOretJashtOrarit,
  SistemiStatustetPunojsve,
  SistemiLlojetKerkesave,
  SistemiDokumentacionet,
  SistemiPaisjetOrarit,
  InformacioneRrethKompanis,
  SistemiArsyetLargimeve,
  SiguriaFiltrartPerdoruesve,
  PagatFitimetSetigns,
  UserProfile,
  SistemiTurnet,
  SiguriaPerdoruesit,
  PagatZbritjetSetings,
  KonfigurimetPozicionet,
  Preferencat,
  KonfigurimiKandidateve,
  KompaniaRolet,
} from "./components/Konfigurimet/index";
import DashboardBase from "./components/dashboard/DashboardBase";
import Approvals from "./components/Pagat/approvals/Approvals";
import Wages from "./components/Pagat/wages/Wages";
import Departamentet from "./Departments/Department";
import DocumentationPage from "./components/Documentation/DocumentationPage";
import PagatEksportetListes from "./components/Konfigurimet/PagatSettings/PagatEksportetListes/PagatEksportetListes";
import PagatGrid from "./components/Pagat/PagatGrid";
import MainWage from "./components/Pagat/wages/MainWage";
import ReportsMain from "./components/Reports/ReportsMain";
import GeneratePage from "./components/Reports/generate/GeneratePage";
import Announcements from "./components/Announcement/Announcements";
import Intervistat from "./components/Intervistat/Intervistat";
import SistemiIP from "./components/Konfigurimet/SistemiSettings/SistemiIp/SistemiIP";
import ProblematikatPage from "./components/Problematikat/ProblematikatPage";
import EmailTemplate from "./components/Konfigurimet/EmailTemplate/EmailTemplate";

const NotFound = () => {
  return <h1>Not found</h1>;
};
export const AuthRouteData = [
  {
    path: "/",
    Component: DashboardBase,
    View: Layout,
    Context: NotifContextProvider,
  },
  {
    path: "/kryefaqja",
    Component: DashboardBase,
    View: Layout,
  },
  {
    path: "/punonjesit",
    Component: Punonjesit,
    View: Layout,
  },
  {
    path: "/punonjesit/specifikat/:employeeId",
    Component: DynamicPunojsitView,
    View: Layout,
  },
  {
    path: "/prezencat",
    Component: ListPrezenca,
    View: Layout,
  },
  {
    path: "/pagat",
    Component: MainWage,
    View: Layout,
  },
  {
    path: "/pagat/llogaritja",
    Component: Wages,
    View: Layout,
  },

  {
    path: "/aprovimet",
    Component: Approvals,
    View: Layout,
  },
  {
    path: "/dokumentacione",
    Component: DocumentationPage,
    View: Layout,
  },
  {
    path: "/raportet",
    Component: ReportsMain,
    View: Layout,
  },
  {
    path: "/raportet/gjenero",
    Component: GeneratePage,
    View: Layout,
  },
  {
    path: "/departamentet",
    Component: Departamentet,
    View: Layout,
  },
  {
    path: "/lajmerimet",
    Component: Announcements,
    View: Layout,
  },
  {
    path: "notfound",
    Component: NotFound,
    View: NotFound,
  },
  {
    path: "konfigurimet",
    Component: Kofigurimet,
    View: Layout,
    child: [
      {
        path: "kompania/profili",
        Component: UserProfile,
      },
      {
        path: "kompania/info/rreth/komapnise",
        Component: InformacioneRrethKompanis,
      },
      {
        path: "kompania/pozicionet",
        Component: KonfigurimetPozicionet,
      },
      {
        path: "kompania/rolet",
        Component: KompaniaRolet,
      },
      {
        path: "sistemi/pajisjet/orarit",
        Component: SistemiPaisjetOrarit,
      },
      {
        path: "sistemi/turnet",
        Component: SistemiTurnet,
      },
      // {
      // 	path: "sistemi/turnet",
      // 	Component: SistemiTurnet,
      // },
      {
        path: "sistemi/statuset/punojesve",
        Component: SistemiStatustetPunojsve,
      },
      {
        path: "sistemi/arsyet/largimit",
        Component: SistemiArsyetLargimeve,
      },
      {
        path: "sistemi/llojet/kerkesave",
        Component: SistemiLlojetKerkesave,
      },
      {
        path: "sistemi/dokumentacionet",
        Component: SistemiDokumentacionet,
      },
      { path: "sistemi/adresatIp", Component: SistemiIP },
      {
        path: "politikat/ditet/pushimit",
        Component: PolitikatDitetPushim,
      },
      {
        path: "politikat/festave/zyrtare",
        Component: PolitikatFestatZyrtare,
      },
      {
        path: "politikat/oreve/jashte/orarit",
        Component: PolitikatOretJashtOrarit,
      },
      {
        path: "pagat/fitimet",
        Component: PagatFitimetSetigns,
      },
      {
        path: "pagat/zbritje",
        Component: PagatZbritjetSetings,
      },
      {
        path: "pagat/pagesat",
        Component: MenyratPageses,
      },
      {
        path: "pagat/kalkulimi",
        Component: PagatEksportetListes,
      },
      {
        path: "siguria/perdoruesit",
        Component: SiguriaPerdoruesit,
      },
      {
        path: "konfigurimet/preferencat",
        Component: Preferencat,
      },
      {
        path: "konfigurimet/KonfigurimiKandidateve",
        Component: KonfigurimiKandidateve,
      },
      {
        path: "siguria/filtrat/perdorusve",
        Component: SiguriaFiltrartPerdoruesve,
      },
      { path: "preferencat", Component: Preferencat },
      {
        path: "KonfigurimiKandidateve",
        Component: KonfigurimiKandidateve,
      },
      {
        path: "siguria/rolet/siguris",
        Component: PerdoruesitTeDhenatAksesit,
      },
      {
        path: "dokumentacione",
        Component: DocumentationConfiguration,
      },
      { path: "preferencat", Component: SiguriaFiltrartPerdoruesve },
      {
        path: "emailTemplate",
        Component: EmailTemplate,
      },
    ],
  },
  {
    path: "/intervistat",
    Component: Intervistat,
    View: Layout,
  },
  {
    path: "/problematikat",
    Component: ProblematikatPage,
    View: Layout,
  },
];
