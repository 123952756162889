import moment from "moment-timezone";
import { weekFiltering } from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/filtering";
export const onPickMonth = (momentInstance, gridApi) => {
  let start = moment(momentInstance).startOf("month").valueOf();
  let end = moment(momentInstance).endOf("month").valueOf();
  const dateFilter = gridApi.getFilterInstance("fillim_turni");
  dateFilter.setModel({
    type: "inRange",
    filter: start,
    filterTo: end,
  });
  gridApi.onFilterChanged();
};

export const onWeekFilter = (filterType, gridApi) => {
  let filterArr = weekFiltering(moment(), filterType);

  let start = filterArr[0];
  let end = filterArr[1];
  // console.log("filterArr", filterArr, selected.muaji);
  const dateFilter = gridApi.getFilterInstance("fillim_turni");
  dateFilter.setModel({
    type: "inRange",
    filter: start,
    filterTo: end,
  });
  gridApi.onFilterChanged();
};

export const onStatusFilter = (statusInstance, gridApi) => {
  // console.log(statusInstance);
  if (statusInstance !== undefined) {
    const statusFilter = gridApi.getFilterInstance("approved");
    statusFilter.setModel({
      type: "equals",
      filter: `${statusInstance}`,
    });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter("approved");
  }
};

export const onDepartmentFilter = (
  departmentInstance,
  gridApi,
  filterInstance
) => {
  if (departmentInstance !== undefined) {
    const departmentFilter = gridApi.getFilterInstance(filterInstance);
    departmentFilter.setModel({
      type: "equals",
      filter: `${departmentInstance}`,
    });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter(filterInstance);
  }
};

export const onEmployeeFilter = (employeeId, gridApi, filterInstance) => {
  // console.log("Employee Id", employeeId);
  if (employeeId !== undefined) {
    const statusFilter = gridApi.getFilterInstance(filterInstance);
    statusFilter.setModel({
      type: "equals",
      filter: `${employeeId}`,
    });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter(filterInstance);
  }
};

export const onBranchFilter = (branchName, gridApi) => {
  if (branchName !== undefined) {
    console.log("Branch Name", branchName);

    const branchFilter = gridApi.getFilterInstance(
      "employee_details.employeeTeam"
    );
    console.log("Branch Filter", branchFilter);
    branchFilter.setModel({
      type: "equals",
      filter: `${branchName}`,
    });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter("employee_details.employeeTeam");
  }
};

export const onEmployeeWageTypeFilter = (wageType, gridApi, filterInstance) => {
  if (wageType !== undefined) {
    const wageFilter = gridApi.getFilterInstance(filterInstance);
    wageFilter.setModel({
      type: "equals",
      filter: `${wageType}`,
    });
    gridApi.onFilterChanged();
  } else {
    gridApi.destroyFilter(filterInstance);
  }
};

export const handleDateRange = (e, gridApi) => {
  if (e !== null) {
    let start = moment(e[0]).startOf("day").valueOf();
    let end = moment(e[1]).endOf("day").valueOf();
    const dateFilter = gridApi.getFilterInstance("fillim_turni");
    dateFilter.setModel({
      type: "inRange",
      filter: start,
      filterTo: end,
    });
    // console.log("Dater", "start0", start, "end", end);
    gridApi.onFilterChanged();
  } else if (e === null) {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setQuickFilter(null);
  }
};
