import moment from "moment-timezone";
import { htmlParser } from "../../../../utils";
import { groupSingleShift } from "../../../Pagat/PaySub/employeeCalc/calculcate";
import { currencyformatter } from "../../../Pagat/PaySub/employeeCalc/methods";
import { getTranslation } from "../../generate/translations";
import { message } from "antd";
import { API } from "aws-amplify";
// import redux store
import { store } from "../../../../store";
//group object by keys
function groupObjects(objects, key) {
	return objects.reduce((acc, obj) => {
		let keyValue = obj[key];
		if (!acc[keyValue]) {
			acc[keyValue] = [];
		}
		acc[keyValue].push(obj);
		return acc;
	}, {});
}

const getReportsClockings = async (timeInterval) => {
	return new Promise(async (resolve, reject) => {
		await API.get(`clocking`, `/clocking`, {
			queryStringParameters: {
				limiter: moment(timeInterval[0]).valueOf(),
				endLimiter: moment(timeInterval[1]).valueOf(),
				// limiter: moment("01/1/2022", "DD/MM/YYYY").valueOf(),
				// start: params,
				// end: moment().valueOf(),
			},
		})
			.then((r) => resolve(r))
			.catch((error) => console.error(error));
	});
};

export const approvals = (prevData, filter, columns, manualColumns, other) => {
	let result = {
		columnsDef: [],
		allColumns: [],
		rowData: [],
		selectedColumns: [],
		allColumns: [],
	};

	const data = store?.getState().customReducer?.reportClockings || [];
	console.log("reported", data);

	if (!!data && !!filter) {
		const missingShifts = [];
		other
			?.filter((d) => d?.employeeStatus === "Aktiv" && d?.employeePayrollType !== "Page Fikse")
			?.forEach((d) => {
				let validShifts = data?.filter((e) => e?.employeeId === d?.employeeId);
				let missingDates = getDates(
					filter?.timeInterval[0],
					filter?.timeInterval[1],
					validShifts,
					d
				);
				missingShifts.push(...missingDates);
				console.log("missingDates", missingDates);
			});
		console.log("missingShifts", missingShifts);
		result.selectedColumns = manualColumns || columns.columnDefs;
		result.allColumns = columns.allAppliedColumnDefs;
		const merged = data.concat(missingShifts)?.sort((a, b) => a.clockInDate - b.clockInDate);

		const preFilter = merged
			?.filter(
				(d) =>
					d.clockInDate >= moment(filter?.timeInterval[0]).startOf("day").valueOf() &&
					d.clockInDate <= moment(filter?.timeInterval[1]).endOf("day").valueOf()
			)
			.map((d) => {
				if (d?.dayType === "MUNGESE")
					return {
						...d,
						...d?.employeeDetails,
						employeeDayOfBirth: moment(d?.employeeDetails?.employeeDayOfBirth).format("DD/MM/YYYY"),
						employeeHireDate: moment(d?.employeeDetails?.employeeHireDate).format("DD/MM/YYYY"),
						clockInDate: moment(d.clockInDate).format("HH:mm"),
						clockInPayment: currencyformatter.format(d?.totalities?.totalPay || 0) || 0,
						clockInRate: currencyformatter.format(d?.totalities?.employeeRate || 0) || 0,
						employee: `${d?.employeeDetails?.employeeFirstName} ${d?.employeeDetails?.employeeLastName}`,
						approved: d?.approved ? "Aprovuar" : "Pa Aprovuar",
						clockDate: moment(d.clockInDate).format("DD/MM/YYYY"),
						clockOutDate: !!d.clockOutDate ? moment(d.clockOutDate).format("HH:mm") : "Not closed",
						clockInNote: !!d?.clockInNote ? htmlParser(d?.clockInNote) : "",
						duration: "0.00",
						before19: "0.00",
						between19and22: "0.00",
						after22: "0.00",
						dayType: getDayTypeTranslations(d?.dayType || ""),
						breakTime: "-",
						breakTimeVal: 0,
					};
				const { EXTRA_HOURS = 0, LATE_HOURS = 0, NORMAL_HOURS = 0 } = groupSingleShift(d) || null;
				// console.log("groupSingleShift", groupSingleShift(d));
				// console.log("NORMAL_HOURS", d);
				// console.log("d", d);
				return {
					...d,
					...d?.employeeDetails,
					employeeDayOfBirth: moment(d?.employeeDetails?.employeeDayOfBirth).format("DD/MM/YYYY"),
					employeeHireDate: moment(d?.employeeDetails?.employeeHireDate).format("DD/MM/YYYY"),
					clockInDate: moment(d.clockInDate).format("HH:mm"),
					clockInPayment: currencyformatter.format(d?.totalities?.totalPay || 0) || 0,
					clockInRate: currencyformatter.format(d?.totalities?.employeeRate || 0) || 0,
					employee: `${d?.employeeDetails?.employeeFirstName} ${d?.employeeDetails?.employeeLastName}`,
					approved: d?.approved ? "Aprovuar" : "Pa Aprovuar",
					clockDate: moment(d.clockInDate).format("DD/MM/YYYY"),
					clockOutDate: !!d.clockOutDate ? moment(d.clockOutDate).format("HH:mm") : "Not closed",
					clockInNote: !!d?.clockInNote ? htmlParser(d?.clockInNote) : "",
					duration: !!d?.clockOutDate
						? parseFloat(
								moment(d?.clockOutDate).diff(moment(d?.clockInDate), "hours", true).toFixed(2)
						  )
						: 0,
					before19: parseFloat(NORMAL_HOURS?.amount?.toFixed(2) || 0),
					between19and22: parseFloat(LATE_HOURS?.amount.toFixed(2) || 0),
					after22: parseFloat(EXTRA_HOURS?.amount?.toFixed(2) || 0),
					dayType: getDayTypeTranslations(d?.dayType || ""),
					breakTime:
						!!d?.totalities?.breakTime?.length > 0
							? `${d?.totalities?.breakTime?.[0] + " - " + d?.totalities?.breakTime?.[1]}`
							: "",
					breakTimeVal: d?.totalities?.breakTimeVal?.toFixed(2) || 0,
				};
			});

		if (!!manualColumns && Array.isArray(manualColumns)) {
			manualColumns.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			preFilter.forEach((emplRecord) => {
				let toAdd = [];
				manualColumns.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		} else {
			columns?.columnDefs.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			preFilter.forEach((emplRecord) => {
				// console.log("preFilter", preFilter);
				let toAdd = [];
				columns?.columnDefs?.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		}

		return result;
	}
	console.log("result", result);
	return result;
};

export const getDayTypeTranslations = (englishDayType) => {
	switch (englishDayType) {
		case "Regular":
			return "Normal";
		case "Sick":
			return "Sëmurë";
		case "HolidayRequest":
			return "Kërkesë Leje";
		case "NationalHoliday":
			return "Festë Zyrtare";
		case "NationalHolidayWorking":
			return "Festë Zyrtare Punë";
		case "Maternity":
			return "Leje Lindje";
		default:
			return englishDayType;
	}
};

export function getDates(startDate, endDate, validShifts, employeeDetails) {
	console.log("get dates", { startDate, endDate, validShifts, employeeDetails });
	var dates = [];
	var tempEnd = moment().endOf("day").valueOf();
	var currentDate = moment(startDate).valueOf();
	let employeeHireDate = !!employeeDetails?.employeeHireDate
		? moment(employeeDetails?.employeeHireDate).valueOf()
		: currentDate;
	console.log("employeeHireDate", employeeHireDate);
	while (currentDate <= tempEnd) {
		if (moment(currentDate).day() !== 0 && moment(currentDate).day() !== 6) {
			if (
				!validShifts?.some(
					(e) =>
						moment(e.clockInDate).format("DD/MM/YYYY") === moment(currentDate).format("DD/MM/YYYY")
				) &&
				currentDate >= employeeHireDate
			) {
				dates.push(moment(currentDate).format("DD/MM/YYYY"));
			}
		}
		currentDate = moment(currentDate).add(1, "days").valueOf();
	}
	const toReturn = dates?.map((el) =>
		clockInObj(
			moment(el, "DD/MM/YYYY 13:00"),
			moment(el, "DD/MM/YYYY 13:00"),
			"MUNGESE",
			"manualAdmin",
			true,
			employeeDetails
		)
	);
	console.log("toReturn", dates);

	return toReturn;
	console.log("toReturn", dates);
}

export const clockInObj = (
	clockInDate,
	clockOutDate,
	dayType = "MUNGESE",
	clockingCategory = "manualAdmin",
	approved = true,
	employeeDetails
) => {
	return {
		clockInDate,
		clockOutDate,
		dayType,
		clockingCategory,
		approved,
		employeeId: employeeDetails?.employeeId,
		employeeDetails,
		duration: "0.00",
		before19: "0.00",
		between19and22: "0.00",
		after22: "0.00",
		breakTime: "",
		breakTimeVal: 0,
	};
};
