import moment from "moment-timezone";
import { getTranslation } from "../../generate/translations";

export const requests = (data, filter, columns, manualColumns) => {
	let result = {
		columnsDef: [],
		allColumns: [],
		rowData: [],
		selectedColumns: [],
		allColumns: [],
	};
	if (!!data && !!filter) {
		result.selectedColumns = manualColumns || columns.columnDefs;
		result.allColumns = columns.allAppliedColumnDefs;

		// result.columnsDef.push("Emer", "Mbiemer", "Lloji", "Fillim", "Mbarim", "Departamenti");
		const preFilter = data
			.filter((f) => {
				let times = f?.requestPeriod || [];
				return times.some(
					(el) =>
						moment(el).isSameOrAfter(filter?.timeInterval[0]) &&
						moment(el).isSameOrBefore(filter?.timeInterval[1])
				);
			})
			.map((d) => ({
				...d,
				...d?.employeeDetails,
				requestStart: moment(d?.requestPeriod[0]).format("DD/MM/YYYY"),
				requestEnd:
					d?.requestPeriod.length > 1
						? moment(d?.requestPeriod[d?.requestPeriod?.length - 1]).format("DD/MM/YYYY")
						: moment(d?.requestPeriod[0]).format("DD/MM/YYYY"),
			}));

		if (!!manualColumns && Array.isArray(manualColumns)) {
			manualColumns.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			preFilter.forEach((emplRecord) => {
				let toAdd = [];
				manualColumns.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		} else {
			columns?.columnDefs.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			preFilter.forEach((emplRecord) => {
				console.log("preFilter", preFilter);
				let toAdd = [];
				columns?.columnDefs?.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		}

		return result;
	}
	return result;
};
