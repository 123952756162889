import React from "react";
import { Modal, Button } from "antd";
import { htmlParser } from "../../utils";

function DetailsModal({ setVisible, visible, specificData }) {
  return (
    <Modal
      title="Detajet e lejes"
      className="paisjet-per-orarin-modal"
      centered
      open={visible}
      destroyOnClose={true}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={"auto"}
      footer={[
        <Button onClick={() => setVisible(false)} className="Mbyll-btn">
          Mbyll
        </Button>,
      ]}
    >
      <>
        <h3>Pershkrimi i lejes</h3>
        <div>{htmlParser(specificData?.requestDescription)}</div>
      </>
    </Modal>
  );
}

export default DetailsModal;
