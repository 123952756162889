import React from "react";
import { FlexLogo } from "../../../assets/images";
import {
  BreedingRhombusSpinner,
  PixelSpinner,
  FlowerSpinner,
} from "react-epic-spinners";
import "./FlexLogoLoader.scss";

const Loader = () => {
  return (
    <div className="loader-wrapper-2-parent">
      <div className="loader-wrapper-2">
        <span className="flex-logo-spinner">
          {/* <FlexLogo /> */}
          <BreedingRhombusSpinner
            animationDelay={0}
            size={130}
            color={"#1da193"}
          />
        </span>
      </div>
    </div>
  );
};
export default Loader;
