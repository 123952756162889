import { FixedSalary, HourlyIcon } from "../../../../assets/icons";
import { currencyformatter } from "./formatter";
import "./../Wages.scss";
export const wagesColumnDefs = (onEditWage) => {
  return [
    {
      headerName: "Nr",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      // checkboxSelection: true,
      field: "nr",
      filter: "agNumberColumnFilter",
      width: 90,
      flex: 1,
      minWidth: 90,
      maxWidth: 90,
      filterParams: {
        readOnly: true,
      },
    },
    {
      headerName: "Punonjesi",
      valueGetter: ({ data }) =>
        data?.employeeFirstName + " " + data?.employeeLastName,
      filterParams: { readOnly: true },
      resizable: true,
      flex: 2.6,
    },
    {
      headerName: "Departamenti",
      field: "employeeDepartmentName",
      flex: 2.6,
      filterParams: {
        readOnly: true,
      },
      // valueGetter: (params) => {
      // 	// console.log("DADAFWF", params.data);
      // 	try {
      // 		return matchDepartamentWithEmployee(params.data.employeeDepartmentId, departments);
      // 	} catch (err) {
      // 		console.error("createdAt--err");
      // 	}
      // },
    },
    {
      headerName: "Lloji i pageses",
      flex: 2,
      field: "employeePayrollType",
      filterParams: {
        readOnly: true,
      },
      cellStyle: {
        color: "red",
        display: "flex",
        "justify-content": "center",
        "margin-top": "5px",
        fill: "red",
      },

      cellRendererFramework: ({ data }) => (
        <div>
          {/* <strong style={{ color: "#1DA193" }}>
            {data.employeePayrollType}
          </strong> */}
          <span className="paymentTypeIcons">
            {data.employeePayrollType === "Page Fikse" ? (
              <FixedSalary />
            ) : (
              <HourlyIcon />
            )}
          </span>
        </div>
      ),
    },

    {
      headerName: "Periudha",
      field: "periudha",
      filter: "agNumberColumnFilter",
      flex: 2,
      filterParams: {
        inRangeInclusive: true,
      },
    },

    // {
    // 	headerName: "Oret Totale",
    // 	field: "oret_totale",
    // 	filter: "agNumberColumnFilter",
    // 	filterParams: {
    // 		readOnly: true,
    // 	},
    // 	cellRendererFramework: ({ data }) => (
    // 		<div>
    // 			<strong
    // 				style={{ color: "green", cursor: "pointer" }}
    // 				onClick={() =>
    // 					navigate("/aprovimet", { state: { paga: data, data: { muaji: data.timeStance } } })
    // 				}
    // 			>
    // 				{isNaN(data.oret_totale) ? "0.00" : data.oret_totale.toFixed(2)}
    // 			</strong>
    // 		</div>
    // 	),
    // },
    {
      headerName: "Pagesa per ore",
      field: "employeeSalaryPerHour",
      filter: "agNumberColumnFilter",
      flex: 2.2,
      // editable: true,
      // cellEditorSelector: cellEditorSelector,
      filterParams: {
        readOnly: true,
      },

      cellRendererFramework: ({ data }) => (
        <div>
          <strong>
            {isNaN(data.employeeSalaryPerHour)
              ? "0.00"
              : Number(data.employeeSalaryPerHour).toFixed(2)}
          </strong>
        </div>
      ),
    },
    {
      headerName: "Statusi",
      field: "wageStatus",
      flex: 2,
      // filter: "agNumberColumnFilter",

      filterParams: {
        readOnly: true,
      },
      cellRendererFramework: ({ data }) => <div>{data?.wageStatus}</div>,
    },
    {
      headerName: "Paga e llogaritur",
      field: "calculatedWage",
      filter: "agNumberColumnFilter",
      flex: 2.2,

      filterParams: {
        readOnly: true,
      },
      cellRendererFramework: ({ data }) => (
        <div>
          <strong style={{ color: "#1DA193" }}>
            {isNaN(data.calculatedWage)
              ? "0.00"
              : `${currencyformatter.format(data.calculatedWage.toFixed(2))}`}
          </strong>
        </div>
      ),
    },
    {
      headerName: "employeeId",
      filter: "agTextColumnFilter",
      field: "employeeId",
      hide: true,
      filterParams: {
        readOnly: true,
      },
    },
    {
      headerName: "Ndrysho",
      field: null,
      flex: 2,
      filterParams: { readOnly: true },
      cellRendererFramework: ({ data }) => (
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
          }}
          cursor="pointer">
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100px",
              cursor: "pointer",
              height: "22px",
              marginTop: "8px",
              background: "#1DA193",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={() => onEditWage(data)}>
            Ndrysho
          </span>
        </div>
      ),
    },
  ];
};
