import moment from "moment-timezone";

export const expirationDateObject = {
	label: "Data e Skadencës",
	formItemName: "expirationDate",
	placeholder: "Data e Skadencës",
	type: "datepicker",
	// disabledDate: (curr) => curr < moment(),
	// required: true,
};
