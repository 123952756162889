import React from "react";
 
export default () => <svg width="60" height="45" viewBox="0 0 60 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3495 36.0482C16.7222 36.7513 14.8275 36.0144 14.1178 34.4021L13.5726 33.164C12.3042 30.2826 13.001 26.9139 15.3066 24.7812C17.0407 23.1769 17.4777 20.5972 16.3688 18.5078C15.1546 16.2198 12.3875 15.1556 9.93229 16.0321C7.33468 16.9578 5.88048 19.7362 6.61947 22.3545C6.68557 22.5885 6.76936 22.8214 6.8685 23.0468C7.5782 24.6589 6.8344 26.536 5.207 27.2391C3.57971 27.9422 1.685 27.2053 0.975304 25.5931C0.757684 25.0988 0.573463 24.5862 0.42768 24.0695C-1.21332 18.2546 2.00375 12.0913 7.75257 10.0398C13.2098 8.09155 19.3605 10.4576 22.0595 15.5437C24.5126 20.1663 23.5399 25.8792 19.6941 29.4367C19.376 29.7309 19.2842 30.2054 19.4656 30.6176L20.0108 31.8558C20.7207 33.468 19.9769 35.3451 18.3495 36.0482Z" fill="#EA3943"/>
<path d="M22.1753 44.7333C20.548 45.4364 18.6533 44.6996 17.9436 43.0873L17.9329 43.0632C17.2232 41.451 17.967 39.5739 19.5944 38.8708C21.2217 38.1677 23.1164 38.9046 23.8261 40.5169L23.8368 40.541C24.5465 42.1533 23.8025 44.0303 22.1753 44.7333Z" fill="#EA3943"/>
<path d="M3.03174 22.9448C3.17694 23.4616 3.36198 23.9735 3.5796 24.4677C4.08539 25.6167 5.19282 26.3218 6.37643 26.3762C6.06822 26.7384 5.67412 27.0371 5.20618 27.2394C3.57983 27.9421 1.68454 27.206 0.974608 25.5934C0.756988 25.0991 0.57347 24.5866 0.428156 24.0698C-1.21413 18.2542 2.00411 12.0911 7.75223 10.0396C7.95813 9.96623 8.1652 9.89935 8.37333 9.83887C3.89578 12.4608 1.59067 17.837 3.03174 22.9448Z" fill="#C03840"/>
<path d="M17.909 23.655C15.6036 25.7878 14.9061 29.1574 16.1746 32.0386L16.7195 33.2766C17.2253 34.4256 18.3342 35.13 19.5178 35.1843C19.2096 35.5465 18.8155 35.8453 18.3476 36.0475C16.7198 36.7508 14.8259 36.0142 14.116 34.4016L13.5711 33.1636C12.3028 30.2824 12.9987 26.9134 15.3055 24.78C17.0392 23.1753 17.4755 20.5971 16.3665 18.5078C15.3243 16.5424 13.1363 15.4798 10.9844 15.7762C11.4374 15.4087 11.9586 15.1123 12.5354 14.9065C14.9906 14.0296 17.7574 15.0937 18.9716 17.3822C20.0805 19.4715 19.6428 22.0504 17.909 23.655Z" fill="#C03840"/>
<path d="M23.3456 43.8703C23.0373 44.2325 22.6432 44.5313 22.1753 44.7335C20.5475 45.4368 18.6537 44.7002 17.9437 43.0876L17.9328 43.0627C17.2235 41.4515 17.9665 39.5739 19.5942 38.8705C20.0623 38.6683 20.5513 38.5854 21.029 38.6082C20.2645 39.5059 20.0312 40.7903 20.5363 41.9379L20.5472 41.9627C21.0531 43.1115 22.1621 43.816 23.3456 43.8703Z" fill="#C03840"/>
<path d="M43.9913 35.7367C42.2765 35.2815 41.2587 33.5351 41.7182 31.8363L42.0712 30.5314C42.8925 27.4951 45.5571 25.2913 48.7019 25.0478C51.0673 24.8644 53.0322 23.1172 53.4799 20.7986C53.9702 18.2597 52.4763 15.7187 50.005 14.8877C47.3915 14.0073 44.5062 15.2817 43.4363 17.7853C43.3407 18.0091 43.2596 18.243 43.1955 18.4804C42.736 20.1793 40.9733 21.1876 39.2584 20.7324C37.5436 20.2772 36.5258 18.5308 36.9853 16.8319C37.1262 16.3111 37.3047 15.7966 37.5158 15.3024C39.8917 9.74226 46.2854 6.90992 52.0714 8.85631C57.5644 10.7033 60.8847 16.352 59.7949 21.9956C58.8041 27.1251 54.4488 30.9914 49.2032 31.398C48.7694 31.4316 48.3989 31.7459 48.2815 32.1802L47.9285 33.485C47.4689 35.1837 45.7062 36.1919 43.9913 35.7367Z" fill="#EA3943"/>
<path d="M41.5163 44.8908C39.8014 44.4355 38.7836 42.6892 39.2431 40.9903L39.2501 40.9648C39.7095 39.2659 41.4723 38.2576 43.1871 38.7128C44.902 39.168 45.9197 40.9144 45.4602 42.6133L45.4533 42.6388C44.9938 44.3378 43.2312 45.346 41.5163 44.8908Z" fill="#EA3943"/>
<path d="M40.2604 16.0315C40.0487 16.5254 39.8713 17.0398 39.7305 17.5608C39.403 18.7716 39.8253 20.0067 40.7165 20.7803C40.2475 20.8732 39.7514 20.8634 39.2583 20.7325C37.5445 20.2775 36.5257 18.5315 36.9853 16.832C37.1262 16.3111 37.3051 15.797 37.5169 15.3032C39.8922 9.74165 46.2866 6.91024 52.0721 8.85617C52.2793 8.92594 52.4833 9.00152 52.684 9.08279C47.533 8.36762 42.3476 11.1477 40.2604 16.0315Z" fill="#C03840"/>
<path d="M51.4478 25.7765C48.3032 26.0204 45.6374 28.2244 44.8163 31.2605L44.4634 32.565C44.136 33.7758 44.5597 35.0114 45.451 35.7848C44.982 35.8777 44.4859 35.868 43.9928 35.737C42.2774 35.2817 41.2602 33.536 41.7198 31.8366L42.0726 30.5321C42.8939 27.4959 45.558 25.2915 48.7041 25.048C51.0695 24.864 53.033 23.1176 53.4805 20.7991C53.9024 18.6186 52.8601 16.4366 50.991 15.3393C51.5764 15.3318 52.1705 15.4219 52.751 15.6171C55.2226 16.4478 56.7163 18.9885 56.2257 21.5278C55.7781 23.8464 53.8131 25.5925 51.4478 25.7765Z" fill="#C03840"/>
<path d="M42.9744 44.9389C42.5054 45.0318 42.0094 45.022 41.5162 44.8911C39.8008 44.4358 38.7836 42.6901 39.2432 40.9908L39.2503 40.9646C39.7095 39.2668 41.4719 38.2575 43.1872 38.7128C43.6805 38.8438 44.115 39.0809 44.4742 39.3937C43.3116 39.6236 42.3208 40.4838 41.9938 41.693L41.9867 41.7192C41.6594 42.9298 42.0832 44.1655 42.9744 44.9389Z" fill="#C03840"/>
<path d="M31.4401 31.5058C29.4468 31.5058 27.8309 29.9049 27.8309 27.9302V26.4135C27.8309 22.8842 30.0742 19.7271 33.4132 18.5577C35.9247 17.6779 37.5432 15.2173 37.3485 12.5739C37.1355 9.67949 34.77 7.3538 31.8463 7.1641C28.7536 6.96174 25.9983 9.17434 25.5726 12.1975C25.5345 12.4677 25.5152 12.7447 25.5152 13.0207C25.5152 14.9954 23.8993 16.5963 21.9061 16.5963C19.9128 16.5963 18.2969 14.9954 18.2969 13.0207C18.2969 12.4153 18.3395 11.8058 18.4236 11.2092C19.3693 4.49522 25.4724 -0.417022 32.3179 0.0279838C38.8168 0.449654 44.0746 5.61964 44.548 12.0539C44.9782 17.9019 41.3891 23.3488 35.8195 25.2999C35.3589 25.4612 35.0494 25.9087 35.0494 26.4135V27.9302C35.0493 29.9049 33.4333 31.5058 31.4401 31.5058Z" fill="#EA3943"/>
<path d="M31.4412 42.1457C29.4479 42.1457 27.832 40.5448 27.832 38.5701V38.5404C27.832 36.5657 29.4479 34.9648 31.4412 34.9648C33.4345 34.9648 35.0504 36.5657 35.0504 38.5404V38.5701C35.0504 40.5448 33.4345 42.1457 31.4412 42.1457Z" fill="#EA3943"/>
<path d="M21.6139 11.21C21.5292 11.8065 21.4877 12.4156 21.4877 13.021C21.4877 14.4284 22.3079 15.6463 23.5014 16.2286C23.0201 16.4644 22.4792 16.5965 21.9059 16.5965C19.9138 16.5965 18.2969 14.9963 18.2969 13.021C18.2969 12.4156 18.3401 11.8065 18.4248 11.21C19.3695 4.49474 25.4736 -0.416814 32.3186 0.0279592C32.5638 0.0439808 32.8072 0.0672003 33.0487 0.0976181C27.2529 0.80489 22.445 5.31277 21.6139 11.21Z" fill="#C03840"/>
<path d="M36.6041 18.5569C33.2654 19.7267 31.021 22.8844 31.021 26.4134V27.9297C31.021 29.337 31.8431 30.5551 33.0365 31.1374C32.5552 31.3732 32.0144 31.5053 31.4411 31.5053C29.4472 31.5053 27.832 29.905 27.832 27.9297V26.4134C27.832 22.8843 30.0747 19.7267 33.4151 18.5569C35.9264 17.6764 37.5435 15.2171 37.3487 12.5739C37.1666 10.0878 35.3963 8.02144 33.0473 7.36955C33.6801 7.19273 34.3507 7.11947 35.0375 7.16417C37.9616 7.35353 40.3268 9.67886 40.5395 12.5739C40.7342 15.2171 39.1153 17.6763 36.6041 18.5569Z" fill="#C03840"/>
<path d="M33.0404 41.7773C32.5591 42.0131 32.0183 42.1453 31.445 42.1453C29.4512 42.1453 27.8359 40.5451 27.8359 38.5698V38.5394C27.8359 36.5658 29.4512 34.9639 31.445 34.9639C32.0183 34.9639 32.5591 35.096 33.0404 35.3318C31.847 35.9158 31.0249 37.1339 31.0249 38.5394V38.5698C31.0249 39.9771 31.847 41.1951 33.0404 41.7773Z" fill="#C03840"/>
</svg>
