import React, { useState, useEffect, useMemo, useContext } from "react";
import { API } from "aws-amplify";
import PersonalInformation from "./CardEdit/PersonalInformation";
import ContactInformation from "./CardEdit/ContactInformation";
import ActivityModal from "../components/ActivityModal/ActivityModal";
import "./personal.scss";
import { Row, Col } from "antd";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import NameCard from "../components/NameCard";
import ContactInfoCard from "./CardView/ContactInfoCard";
import User from "./User/User";
import UserActionCards from "./User/UserActionCards";
import Details from "./CardEdit/Details";
import DetailsView from "./CardView/DetailsView";
import DataCardInfoPersonal from "./CardView/DataCardInfoPersonal";
import moment from "moment-timezone";
import EmployeeWageCard from "./Wage/EmployeeWageCard";
import UserWageHistory from "../../../dashboard/UserDashboard/UserWageHistory";
import { useSelector } from "react-redux";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import { ComponentCard } from "../../../dashboard/AdminDashboard/Draggable/ComponentCard";
import { debounce } from "lodash";
import { DragIcon } from "../../../../assets/icons";
import PunonjesitContext from "../../store/PunonjesitContext";

// * @EneaXharau - Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

function EmployeesView() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showPersonal, setShowPersonal] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [details, setDetails] = useState(false);
  const [changes, setChanges] = useState([]);

  const { employee, auth } = useContext(PunonjesitContext);

  // will reuse this everywhere later
  const handleChange = (objField, e, label) => {
    let test = objField;
    let changedObj = {
      activity: "Ndryshuar",
      author:
        auth?.user?.attributes?.given_name +
        " " +
        auth?.user?.attributes?.family_name,
      changeDate: moment().valueOf(),
      field: label,
      oldValue:
        typeof employee[`${test}`] == "boolean"
          ? `${employee[`${test}`]}`
          : employee[`${test}`],
      newValue: typeof e == "boolean" ? `${e}` : e,
    };
    setChanges([
      ...changes?.filter((el) => el.field !== changedObj.field),
      changedObj,
    ]);
    // setFormData((prev) => ({ ...prev, [test]: e }));
  };

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // * @EneaXharau - useEffect to run when component re-renders
  // * @EneaXharau - Added socket emitter
  // useEffect(() => {
  //   socketHandler.onmessage = (msg) => {
  //     const { request, body, id } = JSON.parse(msg.data);
  //     switch (request) {
  //       case "user_edit_received":
  //         {
  //           auth?.employeeId !== id && setEmployeesData(body);
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   };
  // }, []);

  // * @EneaXharau - Dynamically change style={{height: 100%}} to help center loading component
  return (
    <div style={!!employee ? {} : { height: "100vh" }}>
      <LoadableComp loading={!!employee ? false : true}>
        <div>
          <Row
            className="employe-view-row"
            gutter={16}
            style={{ width: "100%", paddingRight: 20 }}
          >
            <Col span={6} style={{ paddingRight: 10, maxWidth: "100%" }}>
              <NameCard height={"300px"} />
            </Col>
            <Col span={6}>
              <div>
                {showPersonal === false ? (
                  <DataCardInfoPersonal
                    handleClick={() => setShowPersonal(true)}
                  />
                ) : (
                  <PersonalInformation
                    handleHidePersonal={() => setShowPersonal(false)}
                    handleChange={handleChange}
                    changes={changes}
                    setChanges={setChanges}
                    socketHandler={() => {}}
                    // socketHandler ={socketHandler}
                  />
                )}
              </div>
            </Col>
            <Col span={6}>
              <div>
                {showContact === false ? (
                  <ContactInfoCard handleClick={() => setShowContact(true)} />
                ) : (
                  <ContactInformation
                    handleHideContact={() => setShowContact(false)}
                    handleChange={handleChange}
                    changes={changes}
                    setChanges={setChanges}
                    // socketHandler={socketHandler}
                    socketHandler={() => {}}
                  />
                )}
              </div>
            </Col>
            <Col
              span={6}
              style={{ paddingRight: 20, width: "100%", height: "100%" }}
            >
              <div className="position-item">
                {details === false ? (
                  <DetailsView
                    title={"Detaje"}
                    nameOfClass={"info-card"}
                    handleClick={() => setDetails(true)}
                  />
                ) : (
                  <Details
                    handleDetailsClose={() => setDetails(false)}
                    handleChange={handleChange}
                    changes={changes}
                    setChanges={setChanges}
                    // socketHandler={socketHandler}
                    socketHandler={() => {}}
                  />
                )}
              </div>
            </Col>{" "}
          </Row>
        </div>
        <Row
          className="responsive-second-row-employe"
          style={{ marginBottom: 30, width: "100%", height: "100%" }}
        >
          <Col
            span={6}
            style={{ paddingRight: 20, width: "100%", height: "100%" }}
          >
            <div
              className="position-item"
              style={{
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              <UserActionCards
                headerName={"Statistika Pagat (deri sot)"}
                contentChild={
                  <ComponentCard
                    blur={true}
                    content={<EmployeeWageCard {...{ employee }} />}
                    shadow={false}
                  />
                }
              />
            </div>
          </Col>
          <Col
            span={6}
            style={{
              paddingRight: 10,
              marginLeft: -10,
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="position-item"
              style={{
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              <UserActionCards
                contentChild={
                  <ComponentCard
                    blur={true}
                    blurDuration={30}
                    content={
                      <UserWageHistory employee={employee?.employeeId} />
                    }
                    shadow={false}
                  />
                }
                headerName={"Historiku Pagat"}
              />
            </div>
          </Col>
          <Col style={{ marginLeft: 0, width: 770, height: "100%" }}>
            <UserActionCards
              className="wageHistory"
              headerName={"Llogaria e punonjesit"}
              contentChild={<User {...{ employee }} />}
            />{" "}
          </Col>
          <ActivityModal
            {...{
              isModalVisible,
              setIsModalVisible,
            }}
            // recordId={employee?.employeeId}
            // endpoint={"employees"}
            keylog={employee?.keylog}
            showModal={() => setIsModalVisible(true)}
          />
        </Row>
      </LoadableComp>
    </div>
  );
}

export default EmployeesView;
