import { Input, message, Select, Spin } from "antd";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { driveApi } from "../../DriveRequest";
import GoogleDriveUpload from "./GoogleDriveUpload";
import { CloseIcon } from "../icons";
import "./Profile.scss";

const Profile = ({ data }) => {
  const [userDBInfo, setUserDBInfo] = useState({});
  const [newPin, setNewPin] = useState(null);
  const [idle, setIdle] = useState(null);
  const [userPhoto, setUserPhoto] = useState("");
  const { Option } = Select;
  const [timeOut, setTimeOut] = useState(null);
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const [emplyeesObj, setEmployesObj] = useState(null);
  const { users } = useSelector((state) => state.usersList);
  const [fileId, setFileId] = useState("");
  const [spinning, setSpinning] = useState(true);

  const currentEmpl = () => {
    let empData = activeEmployees?.find?.(
      (el) => el?.userId === data?.data?.userId
    );
    setEmployesObj(empData);
  };

  const onPickerSuccess = async (params) => {
    const photoId = params?.uploadedFiles?.[0]?.id || "";
    if (!!photoId) {
      setFileId(photoId);
      await driveRequest
        .getImageSrc(photoId)
        .then((image) => setUserPhoto(image?.src));
    }
  };

  const onSelect = (e) => {
    setIdle(e);
    const [time, unit] = e.split(" ");
    if (unit.includes("minute")) {
      setTimeOut(parseInt(time) * 60000);
    } else if (unit.includes("hour")) {
      setTimeOut(parseInt(time) * 3600000);
    }
  };

  const validateNewPin = (newVal) => {
    let notSafe = [
      "123456",
      "password",
      "12345678",
      "qwerty",
      "123456789",
      "1234567890",
      "12345",
      "1234",
      "1111",
      "0000",
    ];
    if (notSafe.includes(newVal)) {
      message.error("This pin is not safe!");
      return false;
    } else {
      setNewPin(newVal);
    }
  };

  const onChangePin = async () => {
    if (!userPhoto) {
      Promise.allSettled([
        driveRequest.deleteDriveItem(emplyeesObj?.googleDriveFileId),
        API.put("employees", `/employees/${emplyeesObj?.employeeId}`, {
          body: {
            googleDriveFileId: !!userPhoto
              ? !!fileId
                ? fileId
                : users?.googleDriveFileId
              : "",
          },
        }),
      ]);
    }
    if (!!newPin || !!timeOut || !!userPhoto) {
      await API.put("users", `/users/${userDBInfo?.identityId}`, {
        body: {
          pinNumber: newPin,
          idleTimeout: !timeOut ? userDBInfo?.idleTimeout || 60000 : timeOut,
          googleDriveFileId: !!userPhoto
            ? !!fileId
              ? fileId
              : users?.googleDriveFileId
            : "",
        },
      })
        .then(() => {
          message.success("U ndryshua me sukses!");
        })
        .catch((err) => {
          message.error("Ndodhi nje gabim!");
        });
    }
  };

  const getImageFromDrive = (fileId) => {
    if (!!fileId) {
      driveRequest
        .getImageSrc(fileId)
        .then((image) => {
          setUserPhoto(image?.src);
        })
        .catch((err) => console.log(err))
        .finally(() => setSpinning(false));
    } else {
      setInterval(() => {
        setSpinning(false);
      }, 1000);
    }
  };

  useEffect(async () => {
    if (!!users) {
      setUserDBInfo(users);
      setNewPin(users?.pinNumber);
      setIdle(users?.idleTimeout / 60000 + " Minutes");
      getImageFromDrive(users?.googleDriveFileId);
    }
  }, [users]);

  useEffect(() => {
    if (!!data && !!activeEmployees) {
      currentEmpl();
    }
  }, [data, activeEmployees]);

  return (
    <>
      <div
        className="profili-konfigurimet"
        style={{ width: "100%", height: "calc(100% - 35px)" }}
      >
        <div className="userPersonalInfo">
          <Spin spinning={spinning}>
            {!!userPhoto && (
              <div className="profile-img-div">
                <img className="profile-img" src={userPhoto || ""} />
                <div
                  className="remove-profile-photo"
                  onClick={() => {
                    setUserPhoto("");
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
            )}
            {!spinning && !userPhoto && (
              <div className="userLettersName">
                <Avatar
                  name={data?.data?.given_name + " " + data?.data?.family_name}
                  size="140"
                  round={true}
                />
              </div>
            )}
          </Spin>
          <div className="userActions">
            <h2 style={{ paddingBottom: "7px" }}>
              {data?.data?.given_name + " "}
              {data?.data?.family_name}
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            ></div>
            <GoogleDriveUpload
              emplyeesObj={emplyeesObj}
              accessToken={accessToken}
              currentEmpl={currentEmpl}
              onPickerSuccess={onPickerSuccess}
              adminUpload={false}
              uploadImages={true}
            />
          </div>
          <div className="idle">
            <div>
              <span>Pin Number</span>
              <Input.Password
                type="password"
                className="pasw-prof-input"
                value={users?.pinNumber}
                onChange={(e) => validateNewPin(e.target.value)}
                style={{
                  borderRadius: "5px",
                  background: "#F5F5F7",
                  border: 0,
                }}
              />
            </div>
            <div>
              <span>Idle Time</span>{" "}
              <Select
                defaultValue={users?.idleTimeout / 60000 + " Minutes"}
                value={idle}
                onSelect={onSelect}
                style={{
                  width: "210px",
                }}
              >
                {idleOptions?.map(({ value, label }) => (
                  <Option value={value}>{label}</Option>
                ))}
              </Select>
            </div>
            <button className="user-profile-btn" onClick={onChangePin}>
              Ruaj Ndryshimet
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;

const idleOptions = [
  { value: "5 minutes", label: "5 minutes" },
  { value: "10 minutes", label: "10 minutes" },
  { value: "1 hour", label: "1 hour" },
  { value: "2 hours", label: "2 hours" },
  { value: "4 hours", label: "4 hours" },
];
