import moment from "moment-timezone";

export const getMonthStatistic = (wages, month, employeeId) => {
	let result = {
		neto: 0,
	};
	if (!!wages && !!month) {
		if (Array.isArray(wages)) {
			wages
				.filter(
					(wage) =>
						moment(wage.startPeriod).format("MMMM YYYY") === moment(month).format("MMMM YYYY") &&
						wage?.employeeId === employeeId
				)
				.map((w) => {
					result.neto += w?.taxes?.netoPay?.netoPay || 0;
				});
			return result;
		}
		return result;
	}
	return result;
};
