import React, { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Alert, Skeleton } from "antd";
import "./ClockInStats.scss";
import { groupBy } from "lodash";
import { HollowDotsSpinner } from "react-epic-spinners";
import { API } from "aws-amplify";
const LateClockIns = () => {
  const { eligibleEmployeesClockIn } = useSelector(
    (state) => state.employeesList
  );
  const { clockings } = useSelector((state) => state.clockings);
  const [lateClockIn, setLateClockIn] = useState(null); //Emrat e punonjesve qe kane bere clock in me vonese
  const [sortedClockings, setSortedClockings] = useState([]); //Clock in-et e para per cdo dite
  const [dataShifts, setDataShifts] = useState(null); //Shiftet e punonjesve

  const clockInColor = (sortedClocking) => {
    if (!!Object.keys(sortedClocking)?.length) {
      const { clockInDate = undefined, shiftStartApplicable = "13:30" } =
        sortedClocking;
      if (
        moment(clockInDate).format("HH:mm:ss") >=
        moment(shiftStartApplicable, "HH:mm:ss")
          .add(5, "minutes")
          .format("HH:mm:ss")
      ) {
        return "#EA3943";
      } else {
        return "#FCC94A";
      }
    }
  };

  const getDataShifts = async () => {
    await API.get("shifts", "/shifts").then(setDataShifts);
  };

  const lateClockInElements = useMemo(() => {
    if (!lateClockIn?.length) {
      return [];
    }

    return lateClockIn?.map((el) => {
      let index = sortedClockings.findIndex(
        (c) => c?.employeeId === el?.employeeId
      );
      const backgroundColor = clockInColor(sortedClockings?.[index] || {});
      const clockInFormattedTime = moment(
        sortedClockings?.[index]?.clockInDate
      ).format("HH:mm");

      return (
        <div className="late-clock-ins" key={el?.employeeId}>
          <div className="clockout-users-names">
            {el?.employeeFirstName + " " + el?.employeeLastName}
          </div>
          <span className="line-for-clockin-vones"></span>
          <div className="clockin-time" style={{ backgroundColor }}>
            {clockInFormattedTime}
          </div>
        </div>
      );
    });
  }, [lateClockIn, sortedClockings]);

  useEffect(() => {
    //get dataShifts only once, doesn't pass check even if []
    if (!dataShifts) {
      getDataShifts();
    }
    if (!!clockings) {
      //Merr clock in qe jane bere diten e sotme
      let todaysClk = clockings
        ?.filter(
          (el) =>
            moment(el?.clockInDate).format("DD/MM/YYYY") ===
            moment().format("DD/MM/YYYY")
        )
        ?.sort((a, b) => a?.clockInDate - b?.clockInDate);

      //Grupon clock in sipas id se punonjesit sepse mund te kete punonjes qe bejne clock in dhe clock out disa here ne dite
      let groupedClockings = groupBy(todaysClk, "employeeId");
      let appliedClockings = [];
      //Iteron array e kthyer nga groupBy per rastet kur nje punonjes ka bere clock in me shume se nje here ne nje dite,
      // i ben sort nga clock in me oren me te hershme deri te ora me e vone dhe shton vetem clock in e pare
      // ne array appliedClockings
      Object.entries(groupedClockings).map(([key, val]) => {
        if (val.length > 1) {
          let innerSort = val.sort((a, b) => a?.clockInDate - b?.clockInDate);
          appliedClockings.push(innerSort[0]);
        } else if (val.length === 1) {
          appliedClockings.push(val[0]);
        }
      });

      //Nga array me clock in-et e para per cdo punonjes, filtrohen ata qe kane bere clock in pas clockinTime te turnit perkates
      const result = appliedClockings
        ?.map((el) => {
          let tempApp =
            eligibleEmployeesClockIn?.find(
              (d) => d?.employeeId === el?.employeeId
            )?.employeeShiftId?.[0] || null;
          let tempShiftStartApplicable =
            dataShifts?.find((d) => d?.shiftId === tempApp)?.shiftStartTime ||
            null;
          return {
            ...el,
            shiftStartApplicable: !!tempShiftStartApplicable
              ? moment(tempShiftStartApplicable).format("HH:mm")
              : "13:00",
          };
        })
        ?.filter(
          (cl) =>
            moment(cl?.clockInDate).format("HH:mm:ss") >
            moment(cl?.shiftStartApplicable, "HH:mm")
              .add(1, "minute", true)
              .format("HH:mm:ss")
        );

      setSortedClockings(result);
      if (!!result) {
        //Merr nga employees emrat e punonjesve qe kane bere clock in me vonese
        let finalResult = eligibleEmployeesClockIn
          ?.filter((el) => {
            return result?.find((clk) => el?.employeeId === clk?.employeeId);
          })
          .sort(
            (b, a) =>
              result?.find((d) => d?.employeeId === a?.employeeId).clockInDate -
              result?.find((d) => d?.employeeId === b?.employeeId).clockInDate
          );
        setLateClockIn(finalResult);
      }
    }
  }, [clockings, dataShifts, eligibleEmployeesClockIn]);

  return (
    <>
      {!!eligibleEmployeesClockIn && !!clockings ? (
        <div className="clockin-container">
          {!!sortedClockings && !!lateClockIn ? (
            lateClockIn?.length >= 0 ? (
              lateClockInElements
            ) : (
              <Skeleton />
            )
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 60,
              }}
            >
              <Alert
                type="info"
                showIcon
                message="Nuk ka te dhena statistikore..."
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1DA193" size={24} />
        </div>
      )}
    </>
  );
};
export default LateClockIns;
