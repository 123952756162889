import { Badge, Popconfirm, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  HeaderUser,
  IconDetyrat,
  IconDil,
  IconNojftimet,
  LogoFlex,
  QotdIcon,
  DocumentationWarningIcon,
  IntervistatIcon,
  KonfigurimetIcon,
} from "../../../assets/icons";
import "../header.scss";
import { weatherIconPicker } from "./weatherIconPicker";
import Clock from "react-live-clock";
import { HollowDotsSpinner } from "react-epic-spinners";
import EditQOTD from "./EditQOTD";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";

function KryefaqjaHeader({
  qotd,
  location,
  dataWeather,
  fToCelTirana,
  setToggleTirana,
  toggleTirana,
  togglePrishtina,
  fToCelPristina,
  setTogglePrishtinas,
  fToCel,
  setToggle,
  toggle,
  userPhoto,
  auth,
  setRequestsActive,
  requestsActive,
  pendingRequest,
  setNotifsActive,
  notifsActive,
  handleLogOut,
  count,
  setQotd,
  setProblemsActive,
  problemsActive,
  problematikatCount,
  candidatesCount,
  interviewActive,
  setInterviewActive,
}) {
  const { employeeId } = useParams();
  const { pathname } = useLocation();
  const [employee, setEmployee] = useState(null);
  const isMobileView = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const getEmployee = async () => {
    const user = await API.get("employees", `/employees/${employeeId}`);
    setEmployee(user);
  };

  useEffect(() => {
    if (!!employeeId) {
      getEmployee();
    } else {
      setEmployee(null);
    }
  }, [employeeId, pathname]);

  return (
    <div
      className="header-row"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        paddingLeft: "10px",
      }}
    >
      <div
        className="col-header-name"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "100%",
          width: "60%",
          maxHeight: "70px",
        }}
      >
        {location.pathname.includes("/punonjesit/specifikat/") ? (
          !!employee ? (
            <span className="employee">{`${employee?.employeeFirstName} ${employee?.employeeLastName}`}</span>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="employee"
            >
              <HollowDotsSpinner color="#1DA193" size={20} />
            </div>
          )
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              fontSize: "14px",
              fontFamily: "Open Sans",
              fontWeight: "400",
              font: "normal normal normal 16px/22px Open Sans",
              lineHeight: "19px",
              height: "100%",
              fontStyle: "italic",
              alignItems: "center",
              color: "#1D3445",
              paddingRight: isMobileView ? "0px" : "20px",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "8px",
                height: "100%",
              }}
            >
              <QotdIcon />
              <EditQOTD {...{ qotd, setQotd }} />
            </span>
            <span
              style={{
                width: "100%",
                marginTop: "5px",
                height: "calc(100% - 10px)",
                marginBottom: "5px",
                overflowY: "scroll",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <>
                {`${qotd?.quote} ${!!qotd?.author ? `- ${qotd?.author}` : ""}`}
              </>
            </span>
          </div>
        )}
      </div>
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          height: "100%",
        }}
      >
        <div className="icons-div">
          {isMobileView && (
            <div
              className="times-div"
              style={{
                display: "flex",
                flexDirection: "row",
                width: 750,
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0px",
              }}
            >
              <div className="time-weather-div">
                Tiranë:&nbsp;
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"Europe/Tirane"}
                />
                {!!dataWeather && !!fToCelTirana ? (
                  <span
                    className="fahrenheit-span"
                    onClick={() => setToggleTirana(!toggleTirana)}
                  >
                    {toggleTirana ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {weatherIconPicker(
                          dataWeather?.tirana?.weather[0]?.main
                        )}
                        &nbsp;
                        {dataWeather?.tirana?.main?.temp}&nbsp;&#8457;
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {weatherIconPicker(
                          dataWeather?.tirana?.weather[0]?.main
                        )}
                        &nbsp;
                        {Math.round(fToCelTirana)} &#8451;
                      </div>
                    )}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HollowDotsSpinner color="#1DA193" size={20} />
                  </div>
                )}
              </div>
              <h1 style={{ paddingLeft: "10px", paddingRight: "10px" }}>|</h1>
              <div className="time-weather-div">
                Prishtinë:&nbsp;
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"Europe/Tirane"}
                />
                {!!dataWeather && !!fToCelPristina ? (
                  <span
                    className="fahrenheit-span"
                    onClick={() => setTogglePrishtinas(!togglePrishtina)}
                  >
                    {togglePrishtina ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {weatherIconPicker(
                          dataWeather?.pristina?.weather[0]?.main
                        )}
                        &nbsp;
                        {dataWeather?.pristina?.main?.temp}&nbsp;&#8457;
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {weatherIconPicker(
                          dataWeather?.pristina?.weather[0]?.main
                        )}
                        &nbsp;
                        {Math.round(fToCelPristina)} &#8451;
                      </div>
                    )}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HollowDotsSpinner color="#1DA193" size={20} />
                  </div>
                )}
              </div>{" "}
              <h1 style={{ paddingLeft: "10px", paddingRight: "10px" }}>|</h1>
              <div className="time-weather-div">
                New York:&nbsp;
                <Clock
                  format={"HH:mm "}
                  ticking={true}
                  timezone={"America/New_York"}
                />
                {!!dataWeather && !!fToCel ? (
                  <span
                    className="fahrenheit-span"
                    onClick={() => setToggle(!toggle)}
                  >
                    {toggle ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {weatherIconPicker(
                          dataWeather?.newyork?.weather[0]?.main
                        )}
                        &nbsp;
                        {Math.round(fToCel)} &#8451;
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {weatherIconPicker(
                          dataWeather?.newyork?.weather[0]?.main
                        )}
                        &nbsp;
                        {dataWeather?.newyork?.main?.temp}&nbsp;&#8457;
                      </div>
                    )}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HollowDotsSpinner color="#1DA193" size={20} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="icons">
            {userPhoto?.length > 0 && userPhoto !== null ? (
              <Tooltip
                placement="top"
                title={
                  <img
                    src={userPhoto}
                    alt="Flex"
                    width={150}
                    height={150}
                    style={{ borderRadius: 5 }}
                  />
                }
              >
                <Link to="/konfigurimet/kompania/profili">
                  <img
                    src={userPhoto}
                    alt="Flex"
                    width={32}
                    height={32}
                    style={{ borderRadius: 5 }}
                  />
                </Link>
              </Tooltip>
            ) : (
              <Tooltip
                placement="top"
                title={`${auth?.userAccess[0]?.given_name} ${auth?.userAccess[0]?.family_name}`}
              >
                <Link to="/konfigurimet/kompania/profili">
                  <HeaderUser />
                </Link>
              </Tooltip>
            )}
            {auth?.userAccess[0].userRole === "Admin" ||
            auth?.userAccess[0].userRole === "Super Admin" ? (
              <div onClick={() => setRequestsActive(!requestsActive)}>
                <Badge
                  className="notification-badge"
                  count={pendingRequest?.length}
                >
                  <Tooltip placement="bottom" title="Pushimet vjetore">
                    <IconDetyrat />
                  </Tooltip>
                </Badge>
              </div>
            ) : null}
            {auth?.userAccess[0].userRole === "Admin" ||
            auth?.userAccess[0].userRole === "Super Admin" ? (
              <div onClick={() => setInterviewActive(!interviewActive)}>
                <Badge className="notification-badge" count={candidatesCount}>
                  <Tooltip placement="bottom" title="Intervistat e kësaj jave">
                    <IntervistatIcon fill="white" />
                  </Tooltip>
                </Badge>
              </div>
            ) : null}
            {auth?.userAccess[0].userRole === "Admin" ||
            auth?.userAccess[0].userRole === "Super Admin" ? (
              <div onClick={() => setProblemsActive(!problemsActive)}>
                <Badge
                  className="notification-badge"
                  count={problematikatCount}
                >
                  <Tooltip placement="bottom" title="Problematikat">
                    <DocumentationWarningIcon
                      fill="white"
                      height={17}
                      width={18}
                    />
                  </Tooltip>
                </Badge>
              </div>
            ) : null}

            <div onClick={() => setNotifsActive(!notifsActive)}>
              <Badge className="notification-badge" count={count?.length}>
                <Tooltip placement="bottom" title="Njoftimet">
                  <IconNojftimet />
                </Tooltip>
              </Badge>
            </div>
            <Link to="/konfigurimet/kompania/profili">
              <Tooltip placement="bottom" title="Konfigurimet">
                <KonfigurimetIcon fill="white" />
              </Tooltip>
            </Link>

            <Popconfirm
              placement="left"
              title={"A jeni të sigurt që dëshironi të dilni nga programi?"}
              onConfirm={handleLogOut}
              okText="Po"
              cancelText="Jo"
              overlayClassName="popup-logout-modal"
              icon={<LogoFlex width={45} height={45} />}
            >
              <Tooltip placement="bottom" title="Dil">
                <IconDil />
              </Tooltip>
            </Popconfirm>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KryefaqjaHeader;
