import React, { useEffect, useLayoutEffect } from "react";
import "./Countdown.css";
import axios from "axios";
import { API } from "aws-amplify";
function Countdown() {
	const [ip, setIP] = React.useState("");

	const getData = async () => {
		const res = await axios.get("https://api.ipify.org/?format=json");
		console.log(res.data);
		setIP(res.data.ip);
		await API.get("getIp", "/getIp").then((res) => {
			setIP(res?.split(",")[0]);
			console.log("rest", res);
		});
	};

	// const onTest = async () => {
	// 	await API.put("programFields", "/programFields/4fgtf65fgf-mnjlsdfv-89000nmnbhujj", {
	// 		body: {
	// 			fieldOptions: {
	// 				whiteList: ["80.78.73.141", "92.119.237.30"],
	// 			},
	// 		},
	// 	});
	// };

	useLayoutEffect(() => {
		const d = document.getElementById("d");
		const h = document.getElementById("h");
		const m = document.getElementById("m");
		const s = document.getElementById("s");
		function getTrueNumber(num) {
			return num < 10 ? "0" + num : num;
		}

		function calculateRemainingTime() {
			const comingYear = new Date().getFullYear() + 1;
			const comingDate = new Date(`Jan 1, ${comingYear} 00:00:00`);

			const now = new Date();
			const remainingTime = comingDate.getTime() - now.getTime();
			const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
			const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const mins = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
			const secs = Math.floor((remainingTime % (1000 * 60)) / 1000);

			d.innerHTML = getTrueNumber(days);
			h.innerHTML = getTrueNumber(hours);
			m.innerHTML = getTrueNumber(mins);
			s.innerHTML = getTrueNumber(secs);

			return remainingTime;
		}

		function initCountdown() {
			const interval = setInterval(() => {
				const remainingTimeInMs = calculateRemainingTime();

				if (remainingTimeInMs <= 1000) {
					clearInterval(interval);
					initCountdown();
				}
			}, 1000);
		}
		initCountdown();
		getData();
	});

	return (
		<div>
			{" "}
			<div className="middle">
				{" "}
				<h1 className="label">New Year's Eve</h1>
				<div className="time">
					<span>
						<div id="d">00</div>
						Days
					</span>
					<span>
						<div id="h">00</div>
						Hours
					</span>
					<span>
						<div id="m">00</div>
						Minutes
					</span>
					<span>
						<div id="s">00</div>
						Seconds
					</span>
				</div>
			</div>
		</div>
	);
}

export default Countdown;
