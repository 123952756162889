import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./FormTextEditor.scss";

const FormTextEditor = ({
  label,
  onChange,
  className = "",
  style = {},
  value = "",
  disabled,
}) => {
  return (
    <div
      className={`labeledInputContainer formTextEditorContainer ${className}`.trim()}
      style={style}
    >
      <div className="labeledInputLabel">{label}</div>
      <Editor
        init={{
          selector: "textarea",
          plugins: "link image textpattern lists",
        }}
        value={value}
        onEditorChange={(val) => onChange(val)}
        disabled={disabled}
      />
    </div>
  );
};

export default FormTextEditor;
