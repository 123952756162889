import React from "react";
import moment from "moment-timezone";

function PunojsitPrint({ tableData, componentRef }) {
  const now = moment();
  return (
    <div className="Perdoruesit-print" ref={componentRef}>
      <table id="table-to-xls">
        <thead>
          <tr>
            <th>Emri</th>
            <th>Mbiemri</th>
            <th>Departmenti</th>
            <th>Roli</th>
            <th>Emaili punës</th>
            <th>Data Punësimit</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map?.((el) => (
            <tr>
              <td style={{ width: "70px" }}>{el?.employeeFirstName} </td>
              <td style={{ width: "80px" }}>{el?.employeeLastName}</td>
              <td>{el?.employeeDepartmentName} </td>
              <td>{el?.employeeRoleName} </td>
              <td>{el?.employeeEmailWork} </td>
              <td>{moment(el?.employeeHireDate).format("DD/MM/YYYY")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default PunojsitPrint;
