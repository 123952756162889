import React, { useState } from "react";
import { Drawer, Button, Select, Form, Input, Checkbox, message } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import "./paga_fitimet_setigns.scss";
import { API } from "aws-amplify";
import { useFitimetContext } from "./FitimetContext";

function KrijoNjeTeReModal({ setVisible, visible, socketHandler }) {
	const { Option } = Select;
	const { /*tableData*/ setTableData } = useFitimetContext();

	// objects in konfigration kards
	const [saveData, setSavedData] = useState({
		// ...tableData,
		rateFactor: "",
		earningName: "",
		// earningType: "",
		// earningFormula: "",
		// earningRate: "",
		excludeFromTotalHours: "",
		earningAmount: "",
	});

	// clear form inputs after submit
	const [form] = Form.useForm();

	// set the state value of the form
	const handleChange = (objField, e) => {
		let test = objField;
		setSavedData((prev) => ({ ...prev, [test]: e }));
	};

	// save the form
	const handleSave = async () => {
		// const { earningId, userId, ...rest } = saveData;
		await API.post("earnings", "/earnings", { body: { ...saveData } }).then((r) => {
			/**
			 * * @EneaXharau - Added socket emitter
			 * * .send() takes -> { request: String, body: any} -> stringified
			 */
			// if (socketHandler.readyState === socketHandler.OPEN) {
			// 	socketHandler.send(
			// 		JSON.stringify({
			// 			request: "profit_added",
			// 			body: "A profit added.",
			// 		})
			// 	);
			// }
			setTableData((prev) => [...prev, r]);
			success();
		});
		// console.log(saveData);
		form.resetFields();
		setVisible(false);
	};

	// display succes message after form is submited
	const success = () => {
		message.success("Të dhënat  u regjistruan me sukses !", 10);
		window.localStorage.removeItem("draggableSetingsFitimet");
	};

	return (
		<div className="krijo-modal">
			<Drawer
				title="Po krijon një kategori të re fitimi"
				className="krijo-modal"
				visible={visible}
				placement="right"
				onClose={() => setVisible(false)}
				width={500}
				footer={null}
			>
				<Form name="modal-fitemt" onFinish={handleSave} form={form}>
					<div className="krijo-modal-content">
						<div className="input-krijo" style={{ width: "100%" }}>
							<h3>Titulli:</h3>
							<Form.Item
								name="earningName"
								rules={[
									{
										required: true,
										message: "Ju lutem shkruaj Titullin !",
									},
								]}
							>
								<Input
									onChange={(e) => handleChange("earningName", e.target.value)}
									autoComplete="off"
									placeholder="Shkruaj Titullin"
									style={{ width: 222 }}
								/>
							</Form.Item>
						</div>
						{/* <div className="dropdown-krijo" style={{ width: "100%" }}>
              <h3 style={{ width: "100%" }}>Lloji i fitimit:</h3>
              <Form.Item
                name="earningType"
                rules={[
                  {
                    required: true,
                    message: "Selekte nje opsion !",
                  },
                ]}
              >
                <Select
                  placeholder="Selekto"
                  onChange={(val) => handleChange("earningType", val)}
                  style={{ width: 222 }}
                  bordered={false}
                  dropdownClassName="krijo-dropdown"
                  suffixIcon={<CaretDownOutlined />}
                >
                  <Option value="Software Developer">Software Developer</Option>
                  <Option value="React Developer">React Developer</Option>
                  <Option value="Node Developer">Node Developer</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="dropdown-krijo" style={{ width: "100%" }}>
              <h3 style={{ width: "100%" }}>Rregulli i llogaritjes:</h3>
              <Form.Item
                name="earningFormula"
                rules={[
                  {
                    required: true,
                    message: "Selekte nje opsion !",
                  },
                ]}
              >
                <Select
                  placeholder="Selekto"
                  onChange={(val) => handleChange("earningFormula", val)}
                  style={{ width: 222 }}
                  bordered={false}
                  dropdownClassName="krijo-dropdown"
                  suffixIcon={<CaretDownOutlined />}
                >
                  <Option value="Software Developer">Software Developer</Option>
                  <Option value="React Developer">React Developer</Option>
                  <Option value="Node Developer">Node Developer</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="input-krijo" style={{ width: "100%" }}>
              <h3>Raporti:</h3>
              <Form.Item
                name="earningRate"
                rules={[
                  {
                    required: true,
                    message: "Ju lutem shkruaj Raportin !",
                  },
                ]}
              >
                <Input
                  type="number"
                  onChange={(e) => handleChange("earningRate", e.target.value)}
                  autoComplete="off"
                  placeholder="0.00"
                  style={{ width: 222 }}
                />
              </Form.Item>
            </div> */}
						<div className="input-krijo" style={{ width: "100%" }}>
							<h3>Rregulli:</h3>
							<Form.Item
								name="rateFactor"
								rules={[
									{
										required: true,
										message: "Ju lutem shkruaj faktorin e raportit !",
									},
								]}
							>
								<Input
									type="number"
									onChange={(e) => handleChange("rateFactor", e.target.value)}
									autoComplete="off"
									placeholder="0.00"
									style={{ width: 222 }}
								/>
							</Form.Item>
						</div>
						<div className="input-krijo" style={{ width: "100%" }}>
							<h3>Vlera:</h3>
							<Form.Item
								name="earningAmount"
								rules={[
									{
										required: true,
										message: "Ju lutem shkruaj Vlerën !",
									},
								]}
							>
								<Input
									type="number"
									onChange={(e) => handleChange("earningAmount", e.target.value)}
									autoComplete="off"
									placeholder="0.00"
									style={{ width: 222 }}
								/>
							</Form.Item>
						</div>
						<Form.Item
							name="excludeFromTotalHours"
							valuePropName="checked"
							//   rules={[
							//     {
							//       validator: async (_, checked) => {
							//         if (!checked) {
							//           return Promise.reject(
							//             new Error("Ju lutem Zgjidh Opsionin !")
							//           );
							//         }
							//       },
							//     },
							//   ]}
						>
							<Checkbox
								onChange={(e) => handleChange("excludeFromTotalHours", e.target.checked)}
								className="krijo-checkbox"
								style={{ width: "100%" }}
							>
								<h3 style={{ width: 222 }}>Përjashtoje në shfaqjen e orëve totale</h3>
							</Checkbox>
						</Form.Item>
						<div className="modal-footer">
							<Form.Item>
								<Button className="anullo-modal-btn" onClick={() => setVisible(false)}>
									Anulo
								</Button>
								<Button htmlType="submit" className="ruaj-modal-btn">
									Ruaj ndryshimet
								</Button>
							</Form.Item>
						</div>
					</div>
				</Form>
			</Drawer>
		</div>
	);
}
export default KrijoNjeTeReModal;
