import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
// import { useReactToPrint } from "react-to-print";
import {
  getMonthsOfYear,
  // getNextMonthsOfYear,
} from "../../../punonjesit/EmployeeView/Payment/utils/calculations";
import { calcMonthInd } from "../../PaySub/employeeCalc/calculateMonthlyInd";
import moment from "moment-timezone";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
// import ShiftModal from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
// import PayStub from "../../PaySub/PaySub";
import EmployeePaySub from "./EmployeePaySub";
// import NewPayStub from "../../PaySub/previewPayStub/NewPayStub";
// import ExportToPdf from "../../../Konfigurimet/dynamic components/ExportToPdf";
// import { VisibleIcon } from "../../../../assets/icons";
import { useMediaQuery } from "react-responsive";
import MobileWageGrid from "./MobileWageGrid";

function WageGrid({ employee }) {
  // const { Option } = Select;
  const [rowData, setRowData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const location = useLocation();
  const [selectedData, setSelectedData] = useState(null);
  const { wages } = useSelector((state) => state.wages);
  const columnDefs = [
    {
      headerName: "Nr",
      field: "nr",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: "Muaji",
      field: "muaji",
      cellRendererFramework: ({ data }) => (
        <div style={{ cursor: "pointer" }}>
          <div variant="outlined">
            <span
              style={{ color: "#1DA193", fontWeight: 600 }}
              onClick={() => handleMonthModal(data)}
            >
              {moment(data.muaji).format("MMMM YYYY")}
            </span>
          </div>
        </div>
      ),
    },
    {
      headerName: "Shuma Neto",
      field: "neto",
      cellRendererFramework: ({ data }) => {
        return <span>{data?.neto !== "Pa caktuar" ? "E gjeneruar" : data?.neto}</span>;
      },
    },
    {
      headerName: "Shuma Bruto",
      field: "bruto",
      cellRendererFramework: ({ data }) => {
        return <span>{data?.bruto !== "Pa caktuar" ? "E gjeneruar" : data?.bruto}</span>;
      },
    },
  ];
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  console.log("wages", wages);

  const handleMonthModal = (data) => {
    if (data.neto !== "Pa caktuar") {
      setSelectedData(data);
      setIsModalVisible(true);
    } else {
      message.info("Nuk ka te dhena!");
    }
  };

  const handleClose = () => {
    setSelectedData(null);
    setIsModalVisible(false);
  };

  const initializeMonths = () => {
    let temp = getMonthsOfYear()?.map((el) => ({
      ...el,
      neto: calcMonthInd("neto", wages, el.muaji, { employeeId: employee }),
      bruto: calcMonthInd("bruto", wages, el.muaji, { employeeId: employee }),
    }));
    // console.log("Temp", temp);

    setRowData(temp);
  };

  useEffect(() => {
    if (!!wages) {
      initializeMonths();
    }
  }, [wages]);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  return (
    <>
      {isMobileView ? (
        <div className="payment-grid">
          <div className="punonjesit">
            <div className="ag-grid-header">
              {
                // <div className="header-icons">
                // 	<IconFilter />
                // 	<Tooltip
                // 		placement="top"
                // 		overlayClassName="global-icon-tooltip"
                // 		title={"Importo ne eksel"}
                // 	>
                // 		<IconExcel onClick={() => onBtExport()} />
                // 	</Tooltip>
                // 	<Tooltip
                // 		placement="top"
                // 		overlayClassName="global-icon-tooltip"
                // 		title={"Fshi një rekord"}
                // 	>
                // 		<DeleteIcon />
                // 	</Tooltip>
                // </div>
              }
              {/* 
					<div className="header-icons">
          <ExportToPdf id={"#Pagat-home-page"} tabelName={"pagat"} />
        </div> */}
            </div>
            <div className="ag-grid-punojsit">
              <div className="ag-theme-alpine" style={{ height: 620, width: "100%" }}>
                <AgGridComponent
                  rowData={rowData}
                  rowSelection="single"
                  onGridReady={onGridReady}
                  paginationPageSize={12}
                  columnDefs={columnDefs}
                  suppressRowClickSelection={true}
                />
              </div>
            </div>
          </div>
          <EmployeePaySub {...{ isModalVisible, selectedData, handleClose }} />
        </div>
      ) : (
        <MobileWageGrid rowData={rowData} />
      )}
    </>
  );
}

export default WageGrid;
