import React from "react";
import { Button, Checkbox, Tooltip } from "antd";
import { ExcelIcon, IconPdf } from "../../../../assets/icons";
import "./paga_fitimet_setigns.scss";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

function FitimetViewCard({ handleShowEditView, Data, id }) {
  const exportPdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#pagat-fitimet" });
    doc.save(`${Data.earningName}.pdf`);
  };

  return (
    <div className="Fitimet-view-card">
      {/* <div style={{ width: "100%", height: 55 }}>
        <h3 style={{ width: "100%" }}>Lloji i fitimit:</h3>
        <div className="krijo-data">{Data.earningType}</div>
      </div> */}

      {/* <div style={{ width: "100%", height: 55 }}>
        <h3 style={{ width: "100%" }}>Rregulli i llogaritjes:</h3>
        <div className="krijo-data">{Data.earningFormula}</div>
      </div> */}
      {/* <div style={{ width: "100%", height: 55 }}>
        <h3>Raporti:</h3>
        <div className="krijo-data">{Data.earningRate}</div>
      </div> */}
      <div style={{ width: "100%", height: 55 }}>
        <h3>Rregulli:</h3>
        <div className="krijo-data">{Data.rateFactor}</div>
      </div>
      <div style={{ width: "100%", height: 55 }}>
        <h3>Vlera:</h3>
        <div className="krijo-data">{Data.earningAmount}</div>
      </div>

      <Checkbox
        defaultChecked={Data.excludeFromTotalHours}
        className="festat-zyrtare-checkbox"
        style={{ width: "100%" }}
      >
        <h3 style={{ width: 222 }}>Përjashtoje në shfaqjen e orëve totale</h3>
      </Checkbox>

      <div className="festimer-view-card-footer">
        {/* <div className="icon-foter"> */}
        <ReactHtmlTableToExcel
          id="fitimet-table-xls-button"
          className="fitimet-table-xls-button"
          table="pagat-fitimet"
          filename={Data.earningName}
          sheet={Data.earningName}
          buttonText={
            <Tooltip
              placement="top"
              overlayClassName="global-icon-tooltip"
              title={"Eksporto në eksel"}
            >
              <ExcelIcon />
            </Tooltip>
          }
        />
        <div className="pagat-fitimet-excel-container">
          <table id="pagat-fitimet">
            <thead>
              <tr>
                <th>Titulli i fitimit</th>
                <th>Rregulli</th>
                <th>Vlera</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{Data.earningName}</td>
                <td>{Data.rateFactor}</td>
                <td>{Data.earningAmount}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* </div> */}
        {/* <ExcelIcon width={18} height={18} /> */}
        <Button className="ndrysho-card-btn" onClick={() => handleShowEditView(id)}>
          Ndrysho
        </Button>
        <div className="icon-foter">
          <IconPdf onClick={() => exportPdf()} width={18} height={18} />
        </div>
      </div>
    </div>
  );
}

export default FitimetViewCard;
