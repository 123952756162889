import React, { useEffect, useMemo, useState } from "react";
import { groupBy, wrap } from "lodash";
import "./Wages.scss";
import { currencyformatter } from "./utils/formatter";
import { Tooltip } from "antd";

function PagatGridHeader({ onFilter, statuses, rowData }) {
  const [headerData, setHeaderData] = useState(null);

  useEffect(() => {
    if (!!rowData && Array.isArray(rowData)) {
      if (rowData.length > 0) {
        let temp = rowData.map((el) => ({
          neto: getNumber(el?.neto),
          bruto: getNumber(el?.bruto),
          taxes: getNumber(el?.taxes),
          totalCost: getNumber(el?.totalCost),
        }));
        // console.log("tmep", temp);
        const totalities = {
          totalNeto: {
            value: 0,
            color: "#1DA193",
            name: "Shuma Neto (vjetore)",
          },
          totalBruto: {
            value: 0,
            color: "#FCC94A",
            name: "Shuma Bruto (vjetore)",
          },
          totalTaxes: { value: 0, color: "gray", name: "Taksat (vjetore)" },
          totalCost: { value: 0, color: "#EA3943", name: "Kosto (vjetore)" },
        };
        temp?.map((record) => {
          totalities.totalNeto.value += record?.neto || 0;
          totalities.totalBruto.value += record?.bruto || 0;
          totalities.totalTaxes.value += record?.taxes || 0;
          totalities.totalCost.value += record?.totalCost || 0;
        });
        setHeaderData(totalities);
        // console.log("totalities", totalities);
      }
    }
  }, [rowData]);

  return (
    <div className="wages-header" style={{ width: "100%" }}>
      <div className="header-wrapper">
        {!!headerData &&
          Object.entries(headerData).map(([key, value]) => (
            <FilterCard
              value={value?.value}
              name={value?.name}
              background={value?.color}
              onFilter={onFilter}
            />
          ))}
      </div>
    </div>
  );
}

export default PagatGridHeader;

const FilterCard = ({ value, name, background, onFilter }) => {
  return (
    <div
      className="wage-header__card"
      style={{ background: background, cursor: "default" }}
      onClick={() => onFilter(name)}>
      {" "}
      <span
        className="statusValue"
        style={{
          fontSize: 30,
          // width: "100%",
          overflowX: "scroll",
          overflowY: "hidden",
        }}>
        {" "}
        {currencyformatter.format(value).replace("ALL", "")}
      </span>{" "}
      <span className="statusName" style={{ fontSize: 20 }}>
        {" "}
        {name}{" "}
      </span>
    </div>
  );
};

const getNumber = (val) => {
  return Number(val.replace(/[^0-9.-]+/g, ""));
};
