import { Badge, Popconfirm } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import {
  DocumentationWarningIcon,
  GoBack,
  HeaderUser,
  IconDetyrat,
  IconDil,
  Iconkonfigurimet,
  IconNojftimet,
  LogoFlex,
  IntervistatIcon,
  KonfigurimetIcon,
} from "../../../assets/icons";
import HamburgerMenu from "../../../utils/MobileComponents/HamburgerMenu";
import DynamicHeaderTitle from "../DynamicHeaderTitle";

function MobileHeader({
  location,
  data,
  handleLogOut,
  auth,
  setRequestsActive,
  requestsActive,
  pendingRequest,
  setNotifsActive,
  notifsActive,
  count,
  userPhoto,
  problemsActive,
  setProblemsActive,
  problematikatCount,
  setInterviewActive,
  interviewActive,
  candidatesCount,
}) {
  return (
    <div className="header-for-mobile">
      <div className="header-app" style={{ width: "100%" }}>
        {location.pathname.startsWith("/konfigurimet") === true ? (
          <div className="header-konfig-mobile">
            <HamburgerMenu data={data} />
            <DynamicHeaderTitle />
            <div className="icons-div" style={{ margin: 0, padding: 0 }}>
              <div
                className="icons-kryefaqja-responsive"
                style={{ margin: 0, padding: 0 }}
              >
                <Link to="/kryefaqja" style={{ margin: 0, padding: 0 }}>
                  <GoBack />
                </Link>
              </div>
              <div className="icons-kryefaqja">
                <Popconfirm
                  placement="left"
                  title={"A jeni të sigurt që dëshironi të dilni nga programi?"}
                  onConfirm={handleLogOut}
                  okText="Po"
                  cancelText="Jo"
                  overlayClassName="popup-logout-modal"
                  icon={<LogoFlex width={45} height={45} />}
                >
                  <div className="icons-kryefaqja">
                    <IconDil />
                  </div>
                </Popconfirm>
              </div>
            </div>
          </div>
        ) : (
          <div className="header-dashbord-mobile">
            <HamburgerMenu data={data} />
            <div className="icons-div">
              {userPhoto?.length > 0 && userPhoto !== null ? (
                // <Tooltip
                //   placement="top"
                //   title={
                //     <img
                //       className="empView-img"
                //       src={userPhoto}
                //       alt="Flex"
                //       width={150}
                //       height={150}
                //       style={{ borderRadius: 5 }}
                //     />
                //   }
                // >
                // </Tooltip>
                <Link to="/konfigurimet/kompania/profili">
                  <div className="background-img-header">
                    <img
                      className="empView-img"
                      src={userPhoto}
                      alt="Flex"
                      width={32}
                      height={32}
                      style={{ borderRadius: 5 }}
                    />
                  </div>
                </Link>
              ) : (
                <div className="icons">
                  <Link to="/konfigurimet/kompania/profili">
                    <HeaderUser />
                  </Link>{" "}
                </div>
              )}
              {auth?.userAccess[0]?.userRole === "Admin" ||
              auth?.userAccess[0]?.userRole === "Super Admin" ? (
                <div
                  className="icons"
                  onClick={() => setRequestsActive(!requestsActive)}
                >
                  <Badge
                    className="notification-badge"
                    count={pendingRequest?.length}
                  >
                    <IconDetyrat />
                  </Badge>
                </div>
              ) : null}{" "}
              {auth?.userAccess[0]?.userRole === "Admin" ||
              auth?.userAccess[0]?.userRole === "Super Admin" ? (
                <div
                  className="icons"
                  onClick={() => setInterviewActive(!interviewActive)}
                >
                  <Badge className="notification-badge" count={candidatesCount}>
                    <IntervistatIcon fill="white" />
                  </Badge>
                </div>
              ) : null}
              {auth?.userAccess[0]?.userRole === "Admin" ||
              auth?.userAccess[0]?.userRole === "Super Admin" ? (
                <div
                  className="icons"
                  onClick={() => setProblemsActive(!problemsActive)}
                >
                  <Badge
                    className="notification-badge"
                    count={problematikatCount}
                  >
                    <DocumentationWarningIcon fill={"white"} />
                  </Badge>
                </div>
              ) : null}
              <div
                className="icons"
                onClick={() => setNotifsActive(!notifsActive)}
              >
                <Badge className="notification-badge" count={count?.length}>
                  <IconNojftimet />
                </Badge>
              </div>
              <div className="icons">
                <Link to="/konfigurimet/kompania/profili">
                  <KonfigurimetIcon fill="white" />
                </Link>
              </div>
              <div className="icons">
                <Popconfirm
                  placement="left"
                  title={"A jeni të sigurt që dëshironi të dilni nga programi?"}
                  onConfirm={handleLogOut}
                  okText="Po"
                  cancelText="Jo"
                  overlayClassName="popup-logout-modal"
                  icon={<LogoFlex width={45} height={45} />}
                >
                  <div className="icons">
                    <IconDil />
                  </div>
                </Popconfirm>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MobileHeader;
