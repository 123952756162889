import moment from "moment-timezone";
import { prepareRequests } from "./preparePaySub";
export const previewPrevious = (prev, employees) => {
	console.log("PREV", prev);
	const temp = {
		allhours: {},
		bruto: {},
		calculations: [],
		earnings: {},
		employeeInfo: {},
		holidays: {
			groupedHolidays: {},
			getHolidayNumbers: function () {
				if (Object.keys(this.groupedHolidays).length !== 0) {
					let holidaysList = { Pushime: { total: 0 }, "Dite Personale": { total: 0 } };
					let allTotal = 0;
					Object.entries(this.groupedHolidays).forEach((entry) => {
						const [key, value] = entry;
						holidaysList[key].total = value.length;
						allTotal += value.length;
					});

					return [holidaysList, allTotal];
				}
				return null;
			},
		},
		allShiftsUser: [],
		momentInstance: {},
		prepayments: {},
		requests: {},
		taxes: {},
		wageStatus: prev?.wageStatus || null,
		note: prev.wageNote || null,
		// For updating
		wageId: prev?.wageId,
	};
	temp.allhours = prev?.hoursCalculations?.allhours || {};
	temp.employeeInfo = employees.find((el) => el?.employeeId === prev?.employeeId);
	temp.bruto = { totalBruto: 0 };
	temp.calculations = prev?.hoursCalculations?.calculations || [];
	temp.allShiftsUser = prev?.hoursCalculations?.allShiftsUser || [];
	temp.earnings = prev?.earningsCalculations || {};
	temp.holidays.groupedHolidays = prev?.holidays?.groupedHolidays || [];
	temp.momentInstance = {
		start: moment(prev.startPeriod).valueOf(),
		end: moment(prev.endPeriod).valueOf(),
	};
	temp.prepayments = prev?.prepayments || {};
	// temp.requests = prepareRequests(temp);
	temp.requests = prev?.requests || {};
	temp.bruto = prev?.bruto;
	temp.taxes = prev?.taxes || {};
	return temp;
};
