import { Form, Modal } from "antd";
import RenderDynamicComponents from "../../../RenderDynamicComponents/RenderDynamicComponents";
import {
  inputFields,
  inputTypes,
  typesWithOptions,
} from "./customFieldModalData";
import { useState } from "react";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import OptionsFormList from "./components/OptionsFormList/OptionsFormList";
import { validateForm } from "../../../Documentation/utils/validateForm";
import { v4 as uuidv4 } from "uuid";
import { compareIncluding } from "../../../Documentation/utils/compareIncluding";
import { useDocumentationContext } from "../../context";
import {
  createCustomField,
  updateCustomField,
} from "../../utils/dispatchFunctions";
import { formatCategoryName } from "../../View/utils/formatCategoryName";
import "./NewCustomFieldModal.scss";
import TransferLabel from "../NewTemplateModal/components/TransferLabel/TransferLabel";
import { useEffect } from "react";

const antdModalProps = {
  closable: true,
  destroyOnClose: true,
  wrapClassName: "cardLayoutModal",
  footer: null,
  maskClosable: false,
  centered: true,
};

const NewCustomFieldModal = ({ visible, setVisible, editingField }) => {
  const { selectedCategory, curryDispatch } = useDocumentationContext();

  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState();

  const { getFieldsValue, getFieldValue, resetFields } = form;

  const { categoryName } = selectedCategory;

  const onTypeSelect = (type) => {
    setSelectedType(inputTypes[type]);
  };

  const isServiceDropDown = () =>
    compareIncluding(getFieldValue("customFieldsType"), "Service Dropdown");

  const populatedFields = inputFields({ onTypeSelect, selectedType, form });

  const onDiscard = () => {
    resetFields();
    setSelectedType("");
    setVisible(false);
  };

  const saveField = async () => {
    const {
      customFieldRequired,
      customFieldTypeNr,
      customFieldsLabel,
      customFieldsType,
      customFieldsRegEx,
      fieldOptions = [],
      customFieldMultiple,
    } = getFieldsValue();

    const fieldObj = {
      label: customFieldsLabel,
      placeholder: customFieldsLabel,
      formItemName: !!editingField ? editingField.formItemName : uuidv4(),
      type: selectedType,
      required: customFieldRequired,
      showSearch: true,
      options: isServiceDropDown()
        ? [].map(({ serviceName }) => serviceName)
        : fieldOptions.filter(Boolean),
      typeNumber:
        customFieldTypeNr || compareIncluding(customFieldsType, "Amount"),
      prefix: compareIncluding(customFieldsType, "Amount") ? "$" : undefined,
      rules: !!customFieldsRegEx ? [{ pattern: customFieldsRegEx }] : [],
      mode: !!customFieldMultiple ? "multiple" : undefined,
    };

    const dispatchArgs = [selectedCategory, fieldObj];

    await curryDispatch(
      !editingField
        ? createCustomField(...dispatchArgs)
        : updateCustomField(...dispatchArgs)
    ).then(onDiscard);
  };

  const onSubmit = () => {
    validateForm(form, saveField);
  };

  useEffect(() => {
    if (!editingField) return;
    const { label, type, required, options, typeNumber, prefix, rules, mode } =
      editingField;

    const reversedType = Object.entries(inputTypes).reduce(
      (acc, [key, value]) => ({ ...acc, [value]: key }),
      {}
    );

    form.setFieldsValue({
      customFieldsLabel: label,
      customFieldTypeNr: typeNumber,
      customFieldRequired: required,
      customFieldsRegEx: rules?.[0]?.pattern,
      fieldOptions: options,
      customFieldsType: !!prefix ? "Amount" : reversedType?.[type],
      customFieldMultiple: !!mode,
    });
    setSelectedType(type);
  }, [editingField]);

  return (
    <Modal
      destroyOnClose
      {...{
        ...antdModalProps,
        title: formatCategoryName(categoryName),
        visible,
        onCancel: onDiscard,
        wrapClassName: "cardLayoutModal newCustomFieldModal",
      }}
    >
      <Form form={form}>
        <div className="modalBodyContainer">
          <div className="coreInputsContainer">
            {RenderDynamicComponents(populatedFields.inputs)}
          </div>
          {typesWithOptions.includes(selectedType) && !isServiceDropDown() && (
            <OptionsFormList {...{ form }} />
          )}
          <div className="checkboxesContainer">
            {populatedFields.checkboxes.map(({ formItemName, label }) => (
              <Form.Item
                name={formItemName}
                key={formItemName}
                valuePropName="checked"
              >
                <TransferLabel title={label} />
              </Form.Item>
            ))}
          </div>

          <FormActionButtons
            {...{ className: "buttonsContainer", onSubmit, onDiscard }}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default NewCustomFieldModal;
