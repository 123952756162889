import { useState, useEffect, useCallback } from "react";
import "./ClockIn.scss";
import moment from "moment-timezone";
import {
  ClockInIcon,
  ClockOutIcon,
  CalendarIcon,
  TimeLogIcon,
  LiveIcon,
} from "../../assets/index";
import YesterdayInfo from "./YesterdayInfo";
import { Alert, Button, Input, Skeleton, Typography } from "antd";
import swal from "sweetalert";
import ClockShiftLog from "./clockShiftLog/ClockShiftLog";
import {
  endDoubleShift,
  endShift,
  getLastOnGoingShift,
  getShifts,
  getValidShifts,
  startClockIn,
} from "../../helpers/apiCall";
import { TimePicker, Popconfirm } from "antd";
import {
  getDisabledAfterHours,
  getDisabledAfterMinutes,
} from "../../utils/disabledTimeFilters";
import { message } from "antd";
import { useAuth } from "../../../../authentication/authHelpers";
import Forgot from "./forgot/Forgot";
import NewUserShift from "./NewUserShift";
import {
  notificationUserToAdmin,
  notificationUserToAdminNoDbPost,
} from "../../../../utils/sendNotification";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import ClockInActive from "./ClockInActive";
import ClockOutActive from "./ClockOutActive";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import { HollowDotsSpinner } from "react-epic-spinners";
import { API } from "aws-amplify";
import { ChristmasLights, WitchIcon } from "../../../../assets/holidays/icons";
import SuccessComponent from "../../../commonComponents/SuccessComponent/SuccessComponent";

// * @EneaXharau -  Websocket handler and listener
const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));
// ----------------------
const { TextArea } = Input;
const { Text } = Typography;

const ClockIn = () => {
  const userData = useAuth();
  const [showYesterday, setShowYesterday] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [newShift, setNewShift] = useState(true);
  const [loading, setLoading] = useState(true);
  const [shiftLog, setShiftLog] = useState(false);
  const [time, setTime] = useState(moment());
  const [customTime, setCustomTime] = useState(false);
  const [editedTime, setEditedTime] = useState(null);
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [notes, setNotes] = useState({ clockInNote: "", clockOutNote: "" });
  const [shift, setShift] = useState({
    userId: "374d3f3rfsdfsff4r834239421rfsdfdsr33",
    employeeId: null,
    clockInDate: "",
    clockOutDate: "",
    createdAt: moment().valueOf(),
  });
  const [loadingColor, setLoadingColor] = useState("#1DA193");
  const { users } = useSelector((state) => state.usersList);
  const { clockings } = useSelector((state) => state.clockings);
  const [shiftConfiguration, setShiftConfiguration] = useState({
    applicableShifts: [],
    appliedShifts: {},
  });
  const [autoSuggestion, setAutoSuggestion] = useState(null);

  const toggleShiftLog = (val) => {
    setShiftLog(val);
  };
  const resetValues = () => {
    setEditedTime(null);
    setNotes({ clockInNote: "", clockOutNote: "" });
  };
  const onCustomStartTime = (val) => {
    if (moment(val).format("HH:mm") !== moment(time).format("HH:mm")) {
      if (!!val && moment().unix() > moment(val).unix()) {
        setEditedTime(val);
      } else {
        message.error("Ti nuk mund të zgjedhësh një orë në të ardhmen.");
      }
    } else if (moment(val).format("HH:mm") === moment(time).format("HH:mm")) {
      setEditedTime(null);
      setNotes({ clockInNote: "", clockOutNote: "" });
    }
  };
  const onCustomEndTime = (val) => {
    const prevClockIn = apiData[apiData.length - 1]?.clockInDate;
    if (prevClockIn !== null) {
      if (moment(val).format("HH:mm") !== moment(time).format("HH:mm")) {
        if (
          !!val &&
          moment().unix() > moment(val).unix() &&
          moment(val).unix() > moment(prevClockIn).unix()
        ) {
          setEditedTime(val);
        } else {
          message.error(
            `Ti nuk mund të zgjedhësh kete orar! ${moment(val).format(
              "HH:mm"
            )}. Orari i përfundimit duhet të jetë mbas fillimit të turnit!`
          );
        }
      } else if (moment(val).format("HH:mm") === moment(time).format("HH:mm")) {
        setEditedTime(null);
        setNotes({ clockInNote: "", clockOutNote: "" });
      }
    } else {
      message.error("Ndodhi një gabim!");
    }
  };
  // Get all shifts from the database and sort according to clock in date! (ascending)
  const loadShiftData = useCallback(
    async (emplId) => {
      // await getShifts(emplId).then((result) => {
      // 	// console.log("result", result);
      // 	if (result) {
      // 		setApiData(result?.sort((a, b) => a?.clockInDate - b?.clockInDate) || []);
      // 	}
      // });
      setApiData(
        clockings
          ?.filter((el) => el?.employeeId === emplId)
          ?.sort((a, b) => a?.clockInDate - b?.clockInDate) || []
      );
      await API.get("shifts", "/shifts").then((res) => {
        if (Array.isArray(res)) {
          let shiftsAppl = userData?.employee?.employeeShiftId || [];
          let allShifts = [];

          if (shiftsAppl.length > 0) {
            shiftsAppl?.forEach((el) => {
              allShifts.push(res?.find((e) => e?.shiftId === el));
            });
          }
          setShiftConfiguration({
            applicableShifts: [...res],
            appliedShifts: allShifts,
          });
        }
      });
      if (loading) {
        setLoading(false);
      }
    },
    [clockings]
  );

  const onRefresh = useCallback(async () => {
    try {
      setLoading(true);
      setTimeout(async () => {
        // await loadShiftData(shift.employeeId);

        resetValues();
      }, 1500);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setSuccessVisibility(true);
    }
  }, [clockings]);

  // Clock out logic and external functions
  const handleClockOut = async (shift, val, type) => {
    // let lastShift = await getLastShift();http://localhost:3000/punonjesit
    if (!!shift?.employeeId) {
      let lastShift = getLastOnGoingShift(apiData);
      if (!!lastShift.clockingId) {
        // * @EneaXharau - Set loading color to red
        setLoadingColor("#EA3943");
        setLoading(true);
        if (type === "single") {
          await endShift(lastShift, shift, val);
          await loadShiftData(shift.employeeId);
          setNewShift(false);
          resetValues();
          setLoading(false);
          swal({
            title: `Turni u përfundua me sukses në orën ${moment(val).format(
              "HH:mm"
            )}!`,
            icon: "success",
            className: "custum-swal",
            // confirmButtonText
          });
        } else {
          await endDoubleShift(lastShift, shift, val);
          await loadShiftData(shift.employeeId);
          setNewShift(false);
          resetValues();
          setLoading(false);
          swal({
            title: `Turni u përfundua me sukses në orën ${moment(val).format(
              "HH:mm"
            )}!`,
            icon: "success",
            className: "custum-swal",
          });
        }

        notificationUserToAdminNoDbPost(
          {
            notificationTitle: "Mbaroi Turni",
            createdAt: moment().format("DD/MM/YYYY"),
            notificationCategory: "Info",
            notificationPriority: false,
            notificationBody: `${userData?.userAccess[0]?.given_name} ${
              userData?.userAccess[0]?.family_name
            } mbaroi turnin ${moment(val).format("HH:mm")} `,
            isAnnouncement: false,
            notificationUrl: "/aprovimet",
            notificationFirstName: userData?.userAccess[0]?.given_name,
            notificationLastName: userData?.userAccess[0]?.family_name,
          },
          users?.allUsers.Items
        );
      }
      setLoading(false);
    }
  };
  const onShiftNote = (e, key) => {
    setNotes((prev) => ({ ...prev, [key]: e.target.value }));
  };

  // Decides whether to clock out a single shift
  // In the case where the time is past midnight, it slices the shift into two! (before 8 am)

  const onClockOut = async () => {
    if (
      moment(apiData[apiData.length - 1]?.clockInDate).format("DD/MM/YYYY") ===
      moment().format("DD/MM/YYYY")
    ) {
      let clockOut;
      if (editedTime !== null) {
        if (notes?.clockOutNote !== "") {
          clockOut = {
            category: "manual",
            employeeId: shift.employeeId,
            clockOutNote: notes?.clockOutNote,
            editedClockOut: true,
            finishedAt: moment().valueOf(),
            approved: false,
          };
          await handleClockOut(clockOut, editedTime, "single");
        } else {
          message.error("Ju duhet të vendosni një shënim.");
        }
      } else if (editedTime === null) {
        clockOut = {
          category: "automatic",
          editedClockOut: false,
          employeeId: shift.employeeId,
          clockOutNote: notes?.clockOutNote,
          // finishedAt:  moment().valueOf(),
          finishedAt: moment(autoSuggestion).valueOf(),
          approved: false,
        };
        await handleClockOut(clockOut, autoSuggestion, "single");
      }
      // console.log("Clock out!", clockOut);
    } else if (
      moment(apiData[apiData.length - 1]?.clockOutDate).format("DD/MM/YYYY") !==
      moment().format("DD/MM/YYYY")
    ) {
      let today = moment();
      today.set({
        hour: "08",
        minute: "00",
        second: "00",
      });
      if (moment().valueOf() < moment(today).valueOf()) {
        let clockOut;
        if (editedTime !== null) {
          if (notes?.clockOutNote !== "") {
            clockOut = {
              category: "manual",
              employeeId: shift.employeeId,
              clockOutNote: notes?.clockOutNote,
              editedClockOut: true,
              finishedAt: moment().valueOf(),
              approved: false,
            };
            await handleClockOut(clockOut, editedTime, "double");
          } else {
            message.error("Ju duhet të vendosni një shënim.");
          }
        } else if (editedTime === null) {
          clockOut = {
            category: "automatic",
            editedClockOut: false,
            employeeId: shift.employeeId,
            clockOutNote: notes?.clockOutNote,
            finishedAt: moment().valueOf(),
            approved: false,
          };
          await handleClockOut(clockOut, time, "double");
        }
      } else {
        setShowForgot(true);
      }
    }
  };

  // When the last shift is not closed and current time is after 8am
  const onClockForgot = async (obj) => {
    const { isAfterMidnight } = obj;
    // console.log("Obj", obj);
    let clockForgot = {
      employeeId: shift.employeeId,
      editedClockOut: true,
      forgotClockOut: true,
      clockOutNote: obj.shenimi,
      finishedAt: moment().valueOf(),
      approved: false,
    };

    if (
      moment(obj.fillimi).format("DD/MM/YYYY") !==
      moment(obj.mbarimi).format("DD/MM/YYYY")
    ) {
      // let diff = moment.duration(obj.mbarimi.diff(obj.fillimi)).asDays();
      const date = moment(obj.fillimi);
      const time = moment(obj.mbarimi);
      date.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: "00",
      });
      // console.log("Diferenca", diff);
      // let editedClockOut = moment(obj.mbarimi).subtract(diff, "days").valueOf();
      if (isAfterMidnight) {
        await handleClockOut(clockForgot, time, "double");
      } else {
        await handleClockOut(clockForgot, date, "single");
      }
    }
  };

  // Function that determines the auto suggestion for clock out
  const getTime = (time) => {
    // let time = moment(time);
    let l_moment = moment();
    l_moment.set({
      hour: "21",
      minute: "15",
      second: "00",
    });
    let h_moment = moment();
    h_moment.set({
      hour: "21",
      minute: "31",
      second: "00",
    });
    let autoSuggestion = moment();
    autoSuggestion.set({
      hour: "21",
      minute: "30",
      second: "00",
    });

    let lower_bound = moment(l_moment).valueOf();
    let upper_bound = moment(h_moment).valueOf();
    if (
      moment(time).valueOf() > lower_bound &&
      moment(time).valueOf() < upper_bound
    ) {
      setShift((prev) => ({ ...prev, clockOutDate: autoSuggestion }));
      return autoSuggestion;
    } else {
      return moment();
      // return autoSuggestion;
    }
  };

  // Retrieving data from the API
  // * @EneaXharau - Added clockings dependency to update between
  // * tabs of the same user
  useEffect(() => {
    if (!!userData.employeeId && !!clockings) {
      setShift((prev) => ({ ...prev, employeeId: userData.employeeId }));
      loadShiftData(userData.employeeId);
    }
  }, [userData, clockings]);

  // Used for updating time indicator
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  // Used to suggest clock out time
  useEffect(() => {
    if (!!autoSuggestion) {
      setTimeout(() => {
        setAutoSuggestion(getTime(autoSuggestion));
      }, 60000);
    } else {
      setAutoSuggestion(getTime(moment()));
    }
  }, [autoSuggestion]);

  return (
    <div
      className="clockinBackground"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      {/* <Button onClick={onTest}>TEST</Button> */}
      {loading === true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color={loadingColor} size={24} />
        </div>
      ) : (
        <>
          <div
            className={`clock-in ${
              apiData[apiData.length - 1]?.clockInDate !== null &&
              apiData[apiData.length - 1]?.clockOutDate !== null
                ? "clockinBackgroundBlue"
                : "clockinBackgroundRed"
            }`}
          >
            {apiData[apiData.length - 1]?.clockInDate !== null &&
            apiData[apiData.length - 1]?.clockOutDate !== null &&
            newShift === true ? (
              <ClockInActive
                {...{
                  time,
                  editedTime,
                  customTime,
                  onCustomStartTime,
                  setCustomTime,
                  setShowConfirm,
                  setShowYesterday,
                  notes,
                  onShiftNote,
                  shiftConfiguration,
                }}
              />
            ) : (
              <ClockOutActive
                {...{
                  time,
                  editedTime,
                  apiData,
                  loading,
                  customTime,
                  autoSuggestion,
                  onCustomEndTime,
                  setCustomTime,
                  notes,
                  onClockOut,
                  onShiftNote,
                  setLoading,
                  setLoadingColor,
                  loadShiftData,
                  resetValues,
                  onClockForgot,
                  setNewShift,
                  shiftConfiguration,
                }}
              />
            )}
          </div>
        </>
      )}
      {showYesterday && (
        <YesterdayInfo
          handleYesterday={(val) => setShowYesterday(val)}
          showYesterday={showYesterday}
          shift={shift}
          userData={userData}
        />
      )}
      {/* <Confirmation
        clockInTime={moment()}
        showConfirm={showConfirm}
        handleShowConfirm={(val) => setShowConfirm(val)}
        confirmModal={handleClockIn}
        {...{ editedTime }}
      /> */}
      {showConfirm && (
        <NewUserShift
          selfType={true}
          selectedEmployee={null}
          employeeId={shift?.employeeId}
          employeeTeam={userData?.employee?.employeeTeam}
          userSub={userData}
          newShiftVisib={showConfirm}
          setNewShiftVisib={setShowConfirm}
          userData={userData}
          setPrevShift={() => setNewShift}
          liveTime={time}
          onRefresh={onRefresh}
          shiftConfiguration={shiftConfiguration}
        />
      )}
      {/* {shiftLog && (
        <ClockShiftLog
          shiftLog={shiftLog}
          toggleShift={() => toggleShiftLog()}
        />
      )} */}
      {showForgot && (
        <Forgot
          handleForgot={(val) => setShowForgot(val)}
          showForgot={showForgot}
          {...{ userData, shift, apiData, onClockForgot }}
        />
      )}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "10px",
          backgroundColor:
            apiData[apiData.length - 1]?.clockInDate !== null &&
            apiData[apiData.length - 1]?.clockOutDate !== null
              ? "#1DA193"
              : "#EA3943",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      />
      {successVisibility && (
        <SuccessComponent
          visibility={successVisibility}
          setSuccessVisibility={setSuccessVisibility}
          onOk={() => setSuccessVisibility(false)}
          text={`Turni u fillua me sukses në orën ${moment(
            newShift.clockInDate
          ).format("HH:mm")} `}
        />
      )}
    </div>
  );
};
export default ClockIn;
