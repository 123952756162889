import { Form, Button, Modal, Select, DatePicker } from "antd";
import moment from "moment-timezone";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RequestChair } from "../../../../dashboard/assets";
import CancelAddEmployeeModalPopover from "../../../AddEmployeeModal/Popover";
import "./EmployeeReasonModal.scss";

const EmployeeReasonModal = ({
  form,
  employeesData,
  startDate,
  changeEmployeeStatus,
  modalVisible,
  setModalVisible,
  termination,
}) => {
  const { programFields } = useSelector((state) => state.programFields);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [employeeReasonForLeaving, setEmployeeReasonForLeaving] = useState();
  const { Option } = Select;

  //* gets Arsyeja e perfundimit from programFields
  useEffect(() => {
    const employeeReason = programFields?.find(
      (res) => res.fieldName === "Arsyeja e perfundimit"
    );
    setEmployeeReasonForLeaving(
      employeeReason?.fieldOptions?.filter((item) => item?.name !== "Logs")
    );
  }, [programFields]);

  return (
    <>
      <Modal
        title={`${
          employeesData?.employeeFirstName +
          " " +
          employeesData?.employeeLastName
        } / ${!!termination ? "Shkarko" : "Largo"} punonjësin`}
        className="reasonModal"
        open={modalVisible}
        onCancel={() => {
          setPopoverVisible(true);
        }}
        maskClosable={false}
        footer={[
          <div className="reasonModalButtons">
            <Button
              className="reasonAnuloButton"
              onClick={() => setPopoverVisible(true)}
            >
              Anulo
            </Button>
            <Button
              className="confirmReason"
              type="primary"
              htmlType="submit"
              onClick={() => {
                changeEmployeeStatus(
                  `${!!termination ? "Shkarkuar" : "Larguar"}`
                );
                setModalVisible(false);
                form.resetFields();
              }}
            >
              Konfirmo
            </Button>
          </div>,
        ]}
      >
        <Form form={form} onFinish={changeEmployeeStatus}>
          <CancelAddEmployeeModalPopover
            title={`Ti nuk ke përfunduar, a je i sigurt që dëshiron të largohesh nga ${
              !!termination ? "shkarkimi" : "largimi"
            } i punonjësit?`}
            form={form}
            overlayClassName="reasonPopover"
            visible={popoverVisible}
            setVisible={setPopoverVisible}
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
            onClose={() => setModalVisible(false)}
          />
          <div className="reasonModalContainer">
            <div className="reasonModalComponents">
              <Form.Item
                className="terminationLabel"
                label="Arsyeja"
                name={`${
                  !!termination
                    ? "employeeReasonforTermination"
                    : "employeeReasonforSuspension"
                }`}
                rules={[
                  {
                    required: true,
                    message: `${
                      !!termination
                        ? "Përshkrimi i shkarkimit të punonjësit është i detyrueshëm"
                        : "Arsyeja e largimit të punonjësit është e detyrueshme!"
                    }`,
                  },
                ]}
              >
                <Select
                  className="inputReason"
                  placeholder={`Selekto arsyen e ${
                    !!termination ? "shkarkimit" : "largimit"
                  }`}
                >
                  {employeeReasonForLeaving?.map(({ name }) => (
                    <Option value={name}>{name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="terminationLabel"
                label="Data e përfundimit"
                name="employeeContractEndDate"
                rules={[
                  {
                    required: true,
                    message: `Data e ${
                      !!termination ? "shkarkimit" : "largimit"
                    } të punonjësit është e detyrushme!`,
                  },
                ]}
              >
                <DatePicker
                  allowClear
                  format={"DD/MM/YYYY"}
                  defaultValue={moment()}
                  className="reasonDate"
                  placeholder="Selekto datën"
                />
              </Form.Item>
            </div>
            <RequestChair className="suspensionChair" />
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EmployeeReasonModal;
