import React, { useCallback, useEffect, useMemo, useState } from "react";
import LoadableComp from "../../commonComponents/LoadableComp/LoadableComp";
import "./KonfigurimiKandidateve.scss";
import { NewFieldModal } from "./NewFieldModal";
import { useDispatch, useSelector } from "react-redux";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import { TickIcon, XIcon, IconLocked } from "../../../assets/icons";
import ConfirmModal from "./ConfirmModal";
import { PlusOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "./HeaderNames";
import { useAuth } from "../../../../src/authentication/authHelpers.js";
import PermissionsModal from "../../DocumentationConfiguration/View/DocumentationPreview/PermissionsModal/PermissionsModal.jsx";
import { API } from "aws-amplify";
import { message } from "antd";
import { programFields as programFieldsReducer } from "../../../store/actions/";

const INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID =
  "345dgdf-456457fgjvbmn-96789khjghj";

const KonfigurimiKandidateve = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { programFields } = useSelector((state) => state.programFields);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [changes, setChanges] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [deletedFields, setDeletedFields] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState();
  const [confirmChangesType, setConfirmChangesType] = useState("");
  const [showClearConfirmModal, setShowClearConfirmModal] = useState(false);
  const [permissionsModalVisible, setPermissionsModalVisible] = useState(false);

  const fieldCreatedBy =
    auth.employee.employeeFirstName + " " + auth.employee.employeeLastName;
  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const getRowId = useCallback((param) => {
    // console.log("param: ", param);
    return param?.fieldId;
  }, []);

  const handleDragEnd = () => {
    if (!changes) {
      setChanges(true);
    }
  };
  const handleDeleteWithGrid = (e) => {
    const transaction = {
      remove: [e],
    };
    gridApi.applyTransaction(transaction);

    const modifiedObject = {
      ...e,
      deletedField: true,
    };
    setDeletedFields([...deletedFields, modifiedObject]);
    setChanges(true);
  };

  const handleEdit = (e) => {
    setShowCreateModal(e);
    // setModalType("Edit")
    // console.log(e)
    // setSelectedRow(e)
  };
  const handleConfirmButtons = (e) => {
    setConfirmChangesType(e);
    setShowClearConfirmModal(!showClearConfirmModal);
  };
  //region currentPermissions
  const currentPermissions = useMemo(() => {
    if (!!programFields?.length) {
      const index = programFields?.findIndex(
        ({ fieldId }) => fieldId === INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID
      );
      if (index > -1) {
        return programFields[index]?.fieldOptions;
      }
    } else return [];
  }, [programFields]);

  //region HANDLE PERMISSIONS
  const handlePermissions = async (list) => {
    await API.put(
      "programFields",
      `/programFields/${INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID}`,
      {
        body: {
          fieldOptions: list,
        },
      }
    )
      .then((res) => {
        if (!!res.status) {
          const newProgramFields = [...programFields];
          const index = newProgramFields.findIndex(
            ({ fieldId }) => fieldId === INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID
          );
          if (index > -1) {
            newProgramFields[index] = {
              ...newProgramFields[index],
              fieldOptions: list,
            };
            dispatch(programFieldsReducer(newProgramFields));
          }
          setPermissionsModalVisible(false);
          message.success("Aksesi u ndryshua me sukses!");
        }
      })
      .catch((err) => console.log("Error: ", err));
  };

  useEffect(() => {
    try {
      if (programFields) {
        const TableData = programFields?.find(
          (el) => el?.fieldName === "Konfigurimi i Kandidateve"
        );
        if (TableData) {
          const availableTableData = TableData?.fieldOptions?.filter(
            (item) => !item.deletedField
          );
          const deletedTableData = TableData?.fieldOptions?.filter(
            (item) => item.deletedField
          );
          setRowData(availableTableData);
          setDeletedFields(deletedTableData);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [programFields]);

  //region RETURN
  return (
    <LoadableComp loading={!!rowData ? false : true}>
      <div className="konfigurimi-question-container ">
        <div className="konfigurimi-body ">
          <div className="table-header">
            <p>Konfigurimi Kandidateve</p>
            <div style={{ display: "flex", gap: 20 }}>
              <MondayButton
                {...{
                  onClick: () => setPermissionsModalVisible(true),
                  Icon: <IconLocked width={20} height={20} />,
                }}
              >
                Aksesi në Google Drive
              </MondayButton>
              <MondayButton
                {...{
                  onClick: () => {
                    setShowCreateModal([]);
                    // setModalType("Create")
                  },
                  Icon: <PlusOutlined />,
                  className: "mondayButtonGreen",
                }}
              >
                Add new field
              </MondayButton>
            </div>
          </div>
          <div
            className="ag-theme-alpine "
            style={{ height: "100%", width: "fill" }}
          >
            <AgGridReact
              gridApi={gridApi}
              columnDefs={columnDefs({
                handleEdit,
                handleDelete: handleDeleteWithGrid,
              })}
              rowDragEntireRow={true}
              rowData={rowData}
              rowSelection="multiple"
              onGridReady={onGridReady}
              rowHeight={45}
              animateRows={true}
              pagination={true}
              paginationPageSize={Math.floor((window.innerHeight - 425) / 30)}
              getRowNodeId={getRowId}
              rowDragManaged={true}
              onRowDragEnd={handleDragEnd}
              // onRowDragEnd={handleRowDragEnd}
            />
          </div>
        </div>
        <div className="konfigurimi-footer">
          <MondayButton
            {...{
              className: `whiteIconBackground ${
                changes ? "mondayButtonRed " : "mondayButtonGray"
              }`,
              Icon: <XIcon height={10} width={10} />,
              style: { display: "inline" },
              disabled: !changes,
              onClick: () => handleConfirmButtons("Cancel"),
            }}
          >
            Cancel Changes
          </MondayButton>
          <MondayButton
            {...{
              className: `${
                changes ? "mondayButtonGreen " : "mondayButtonGray"
              }`,
              Icon: <TickIcon height={17} width={17} />,
              style: { display: "inline" },
              disabled: !changes,
              onClick: () => handleConfirmButtons("Confirm"),
            }}
          >
            Save Changes
          </MondayButton>
        </div>
      </div>
      {showCreateModal && (
        <NewFieldModal
          {...{
            showCreateModal,
            setShowCreateModal,
            rowData,
            setChanges,
            gridApi,
            selectedRow,
            setSelectedRow,
            setRowData,
            fieldCreatedBy,
          }}
        />
      )}
      {showClearConfirmModal && (
        <ConfirmModal
          {...{
            showClearConfirmModal,
            confirmChangesType,
            setShowClearConfirmModal,
            setRowData,
            programFields,
            setChanges,
            gridApi,
            deletedFields,
          }}
        />
      )}
      {!!permissionsModalVisible && (
        <PermissionsModal
          {...{
            currentPermissions,
            onSave: handlePermissions,
            visible: permissionsModalVisible,
            setVisible: setPermissionsModalVisible,
          }}
        />
      )}
    </LoadableComp>
  );
};

export default KonfigurimiKandidateve;
