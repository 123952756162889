import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import "./colorcard.scss";
import {
  Form,
  Input,
  Button,
  Card,
  message,
  Divider,
  Skeleton,
  Tooltip,
} from "antd";
import { CloseIcon, DeleteIcon, TickIcon } from "../../../assets/icons";
import { CopyOutlined, InfoCircleFilled } from "@ant-design/icons";
import { PhotoshopPicker } from "react-color";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { CopyToClipboard } from "react-copy-to-clipboard";
import GlobalPopupKonfirm from "../../commonComponents/GlobalPopupKonfirm";
import { getSocketUrl } from "../../../utils/websocketConfig";
import { useAuth } from "../../../authentication/authHelpers";
import { LogsIcon } from "../../Documentation/View/assets";
import StatusetPunojsveLogs from "../SistemiSettings/SistemiStatustetPunojsve/StatusetPunojsveLogs";

// * @EneaXharau - Added Websocket connection
// const socketHandler = new WebSocket(getSocketUrl("/settings/system/reasons/sockets"));

function ColorCard({ id, styleCard, cardTitle }) {
  const [programFields, setProgramFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [color, setColor] = useState("#00FFE5");
  const [loading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const { userAccess } = useAuth();

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastname: userAccess[0].family_name,
  };

  const handleSave = () => {
    //Nqs nuk zgjidhet nje ngjyre atehere statusi nuk mund te shtohet
    if (color != "#00FFE5") {
      //Merr emrin e statusit nga form dhe e shton
      //ne nje objekt bashke me id dhe ngjyren e zgjedhur
      let result = {
        id: uuidv4(),
        name: form.getFieldsValue().item,
        color: color,
      };

      let logs = programFields.fieldOptions.find(
        (item) => item.name === "Logs"
      );
      let newLogsValue = {
        id: uuidv4(),
        activity: "U shtua",
        author: username,
        changeDate: Date.now(),
        currentData: result,
      };
      if (logs) {
        logs?.value?.push(newLogsValue);
      } else {
        logs = {
          id: uuidv4(),
          name: "Logs",
          value: [newLogsValue],
        };
      }
      setLoading(true);
      API.put("programFields", `/programFields/${id}`, {
        body: {
          fieldOptions: [...fields, result, logs],
        },
      }).then(() => {
        /**
         * * @EneaXharau - Added socket emitter
         * * .send() takes -> { request: String, body: any} -> stringified
         */
        // if (socketHandler.readyState === socketHandler.OPEN) {
        // 	socketHandler.send(
        // 		JSON.stringify({
        // 			request: "reason_added",
        // 			message: "A reason was added",
        // 		})
        // 	);
        // }
        showMessage();
        setLoading(false);
        fetchData();
      });
    }
    form.resetFields();
  };

  const removeItem = (index) => {
    let item;
    if (index > -1) {
      item = fields[index];
      fields.splice(index, 1);
    }
    let logs = programFields.fieldOptions.find((item) => item.name === "Logs");
    let newLogsValue = {
      id: uuidv4(),
      activity: "U Hoq",
      author: username,
      changeDate: Date.now(),
      currentData: item,
    };
    if (logs) {
      logs?.value?.push(newLogsValue);
    } else {
      logs = {
        id: uuidv4(),
        name: "Logs",
        value: [newLogsValue],
      };
    }
    setFields(fields);
    API.put("programFields", `/programFields/${id}`, {
      body: {
        fieldOptions: [...fields, logs],
      },
    }).then((r) => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN) {
      // 	socketHandler.send(
      // 		JSON.stringify({
      // 			request: "reason_removed",
      // 			message: "A reason was removed",
      // 		})
      // 	);
      // }
      fetchData();
      showDeleteMessage(r);
    });
  };
  const showMessage = () => {
    message.success("Rekordi i ri u shtua!");
  };
  const showCopyMessage = () => {
    message.success("Ngjyra u kopjua!");
  };
  const showDeleteMessage = () => {
    message.success("Rekordi u fshi!");
  };

  //Funksion i dnd
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  }
  //Klikimi ne input e form shfaq pjesen e color picker
  const handleInputClick = () => {
    setShowPicker(true);
    setShowCloseButton(true);
  };
  //Klikimi ne input e form fsheh color picker dhe butonin vet
  const handleCloseClick = () => {
    setShowPicker(false);
    setShowCloseButton(false);
  };

  //Merr nga db statuset e punonjesve
  const fetchData = async () => {
    const fieldsData = await API.get("programFields", `/programFields/${id}`);
    setProgramFields(fieldsData);
    setFields(
      fieldsData?.fieldOptions?.filter((items) => items.name !== "Logs")
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  // 	socketHandler.onmessage = (msg) => {
  // 		const { request, body } = JSON.parse(msg.data);
  // 		switch (request) {
  // 			case "reason_added_received": {
  // 				fetchData();
  // 			}
  // 			case "reason_removed_received": {
  // 				fetchData();
  // 			}
  // 			default:
  // 				break;
  // 		}
  // 	};
  // 	return () => socketHandler.close();
  // }, []);
  const validateInput = (_, value) => {
    if (value === "Logs") {
      return Promise.reject(new Error('Rekordi nuk mund te kete emrin"Logs"'));
    }
    return Promise.resolve();
  };

  return (
    <div className="status-container">
      <Card
        className="employee-status-card"
        style={styleCard}
        title={cardTitle}
        extra={
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Tooltip title={"Shfaq historikun"}>
              <LogsIcon onClick={() => setIsVisible(true)} />
            </Tooltip>
          </div>
        }
      >
        {fields.length > 0 && !loading ? (
          <div className="alldivs" style={{ display: "flex" }}>
            <div
              className="left-side"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {/* Form e shtimi te statuseve te reja */}
              <Form
                onFinish={() => handleSave()}
                autoComplete="off"
                form={form}
                layout="inline"
              >
                <Form.Item
                  className="emp-status"
                  name="item"
                  rules={[
                    { required: true, message: "Plotësoni fushën!" },
                    { validator: validateInput },
                  ]}
                >
                  <Input
                    placeholder="Shto rekord"
                    onClick={() => handleInputClick()}
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                  }}
                >
                  <Button
                    className="add-status-button"
                    htmlType="submit"
                    disabled={disabledButton}
                    icon={
                      <TickIcon
                        width={20}
                        height={20}
                        style={{ marginTop: 4 }}
                      />
                    }
                  ></Button>
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                  }}
                >
                  {showCloseButton ? (
                    <Button
                      className="close-status-button"
                      icon={
                        <CloseIcon
                          width={20}
                          height={20}
                          style={{ marginTop: 4 }}
                        />
                      }
                      onClick={() => handleCloseClick()}
                    />
                  ) : null}
                </Form.Item>
              </Form>

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="notelist">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="holder"
                    >
                      {fields?.map((stat, i) => {
                        return (
                          <Draggable
                            key={stat.id}
                            draggableId={stat.id}
                            index={i}
                          >
                            {(provided) => (
                              <div
                                className="name-btn-div"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    className="status-name"
                                    style={{ backgroundColor: stat.color }}
                                  >
                                    {stat.name}
                                  </div>
                                  <GlobalPopupKonfirm
                                    Button={
                                      <Button
                                        className="bin-button"
                                        // onClick={() => removeItem(i)}
                                        style={{ marginLeft: 10 }}
                                        icon={
                                          <DeleteIcon
                                            width={18}
                                            height={18}
                                            style={{ marginTop: 4 }}
                                          />
                                        }
                                      />
                                    }
                                    title={
                                      "Jeni të sigurt që do të fshini këtë rekord?"
                                    }
                                    onConfirm={() => removeItem(i)}
                                    okText={"Po"}
                                    cancelText={"Jo"}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            {showPicker ? (
              <div className="right-side">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Divider type="vertical" />
                  <div>
                    <div>
                      <InfoCircleFilled
                        style={{
                          background: "#fff",
                          color: "#1264A3",
                          marginRight: 5,
                          marginLeft: 10,
                          fontSize: 16,
                          // marginTop: 4,
                        }}
                      />
                      Duhet të zgjidhni ngjyrën për të shtuar një rekord.
                    </div>
                    <div>
                      <PhotoshopPicker
                        color={color}
                        header=""
                        onChangeComplete={(c) => setColor(c.hex)}
                        onChange={() => setDisabledButton(false)}
                      />

                      <input className="hex-code" value={color} disabled />
                      <CopyToClipboard text={color}>
                        <button
                          className="hex-code-button"
                          onClick={showCopyMessage}
                        >
                          <CopyOutlined />
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <Skeleton paragraph={{ rows: 8 }} />
        )}
      </Card>
      {isVisible && (
        <StatusetPunojsveLogs
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          data={programFields}
          cardTitle={cardTitle}
        />
      )}
    </div>
  );
}

export default ColorCard;
