import { Button } from "antd";
import moment from "moment-timezone";
import React from "react";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";

function MobileSiguriaPerdoruesit({ tableData, setVisible }) {
	return (
		<div className="base-mobile-with-header">
			<div className="header-div">
				{" "}
				<Button onClick={() => setVisible(true)} className="btn-header">
					Krijo përdorues
				</Button>{" "}
			</div>
			<MobileHtmlTabel
				header={[
					"Emri",
					"Mbiemri",
					"Gjinia",
					"Email",
					"Lloj i User",
					"Departamenti",
					"Pozicioni",
					"Nr.Tel",
					"Krijuar",
				]}
				tableData={tableData?.map?.(
					({
						given_name,
						family_name,
						gender,
						email,
						userRole,
						department,
						departmentRole,
						phone_number,
						createdAt,
						identityId,
						accessConfig,
					}) => ({
						given_name,
						family_name,
						gender,
						email,
						userRole,
						statusHeaderKey: department,
						departmentRole,
						phone_number,
						createdAt: moment(createdAt).format("DD/MM/YYYY"),
						identityId,
						accessConfig,
					})
				)}
				isHeader={true}
				isLink={true}
			/>
		</div>
	);
}

export default MobileSiguriaPerdoruesit;
