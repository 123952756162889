import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { Button, Input, Tooltip } from "antd";
import { ExcelIcon, IconKerko } from "../../assets/icons";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../utils/MobileComponents/MobileHtmlTabel";
import moment from "moment-timezone";
import { gridSidebar } from "../Documentation/utils/gridSidebar";
import StatusesPanel from "../Documentation/StatusesPanel/StatusesPanel";
import useObserver from "../Documentation/hooks/useObserver";
import { clearFilter, onFilter } from "../Documentation/utils/onFilter";
import FormCard from "../Documentation/FormCard/FormCard";
import "./ProblematikatPage.scss";
import { problematikatPageData } from "./problematikatPageData";
import ProblemsModal from "../dashboard/AdminDashboard/AdminCards/Problems/PromblemsModal/ProblemsModal";
import { useAuth } from "../../authentication/authHelpers";
import MondayButton from "../commonComponents/MondayButton/MondayButton";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { htmlParser } from "../../utils";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { PdfWhiteIcon } from "../Documentation/View/assets";

const ProblematikatPage = () => {
  const { problematikat } = useSelector((state) => state.problematikat);
  const { userAccess } = useAuth();
  const [gridApi, setGridApi] = useState(null);
  const [activeFilter, setActiveFilter] = useState();
  const [gridData, setGridData] = useState([]);
  const [searchInput, setSearchInput] = useState(null);
  const [editData, setEditData] = useState({});
  const gridContainerRef = useRef();

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastname: userAccess[0].family_name,
  };

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  useObserver({
    element: gridContainerRef,
    callback: () => {
      !!gridApi && gridApi.sizeColumnsToFit();
    },
  });

  //* maps all documents uploaded with all their info and shows them at ProblematikatPage
  const statuses = [
    { statusName: "Në pritje", ticketStatus: "Open", statusColor: "#FCC94A" },
    {
      statusName: "Zgjidhur",
      ticketStatus: "Completed",
      statusColor: "#1DA193",
    },
  ];

  //* gets statuses from docConfiguration and shows them at ProblematikatPage
  const populatedStatuses = statuses.map((el) => ({
    ...el,
    value: problematikat.filter(
      ({ ticketStatus }) => ticketStatus === el?.ticketStatus
    ).length,
  }));

  const populatedColDefs = problematikatPageData();

  //! DONE: function to populate the state needed for rowData in AgGrid
  const populateRowData = () => {
    return problematikat;
  };

  const onStatusCardClick = (ticketStatus) => {
    const test = statuses.filter((items) => items.statusName === ticketStatus);
    setActiveFilter((currFilter) =>
      currFilter === test[0].ticketStatus ? "" : test[0].ticketStatus
    );
  };

  //logic for exporting in PDF the table

  const exportPdf = async () => {
    const doc = new jsPDF({ orientation: "landscape" });

    let tableData = [];
    let headers = [];
    gridApi.getColumnDefs().forEach((item) => {
      headers.push(item.headerName);
    });

    gridApi.forEachNode((rowNode, index) => {
      const parseRes = htmlParser(
        removeImgTags(rowNode?.data?.ticketDescription)
      );
      let text = "";

      if (Array.isArray(parseRes)) {
        for (const res of parseRes) {
          let r = getAllTextFromTags(res).join(", ");

          text += r;
        }
      } else {
        text = getAllTextFromTags(parseRes).toString();
      }

      let data = [
        {
          content: index + 1,
          styles: {
            minCellWidth: 10,
          },
        },
        {
          content: `${rowNode?.data?.createdBy?.employeeFirstName} ${rowNode?.data?.createdBy?.employeeLastname}`,

          styles: {
            minCellWidth: 40,
          },
        },
        {
          content: text,
        },
        {
          content: `${rowNode?.data?.ticketCategory}`,
          styles: {
            minCellWidth: 40,
          },
        },
        {
          content: rowNode?.data?.ticketStatus,
          styles: {
            minCellWidth: 22,
            halign: "center",
          },
        },
        {
          content: moment(rowNode?.data?.createdAt).format("DD/MM/YYYY"),
          styles: {
            minCellWidth: 30,
            halign: "center",
          },
        },
        {
          content: !!rowNode?.data?.resolvedTicket
            ? rowNode?.data?.resolvedTicket
            : "",
          styles: {
            minCellWidth: 32,
            halign: "center",
          },
        },
      ];
      tableData.push(data);
    });
    doc.autoTable({
      head: [headers],
      body: tableData,
    });
    doc.save("Problematikat_Table.pdf");
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
    setSearchInput(e.target.value);
  };

  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setQuickFilter(null);
    setSearchInput(null);
  };

  useEffect(() => {
    !!gridApi &&
      (!!activeFilter
        ? onFilter({ gridApi, column: "ticketStatus", current: activeFilter })
        : clearFilter({ gridApi, column: "ticketStatus" }));
  }, [activeFilter]);

  useEffect(() => {
    if (!!gridApi) {
      gridApi.redrawRows();
      gridApi.sizeColumnsToFit();
    }
  }, [gridData]);

  useEffect(() => {
    setGridData(
      populateRowData()?.map((el) => {
        return el;
      })
    );
  }, [problematikat]);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  function handleRowClicked(data) {
    setEditData(data.data);
    setIsAddModalVisible(true);
  }
  const onClick = () => {
    setIsAddModalVisible(true);
  };

  //Function to get the text from a html tag

  function getAllTextFromTags(html, result = []) {
    let htmlProps = html?.props?.children;
    if (!!htmlProps) {
      if (Array.isArray(htmlProps)) {
        htmlProps?.forEach((prop) => getAllTextFromTags(prop, result));
      } else if (typeof htmlProps === "string") {
        result.push(htmlProps);
      } else {
        getAllTextFromTags(htmlProps, result);
      }
    } else {
      if (typeof html === "string") {
        result.push(html);
      }
    }

    return result;
  }

  //funtion to remove the img tag when we have a quill
  function removeImgTags(htmlContent) {
    var imgRegex = /<img[^>]+>/g;
    var cleanedHtmlContent = htmlContent.replace(imgRegex, "");
    return cleanedHtmlContent;
  }

  return (
    <>
      {isMobileView ? (
        <div className="problematikatPageWrapper">
          <StatusesPanel
            {...{
              data: populatedStatuses,
              onClick: onStatusCardClick,
            }}
          />
          <FormCard
            {...{
              className: "docGridCard",
            }}
          >
            <div className="headerSearchContainer">
              <div className="headerSearch">
                <div className="iconSearch">
                  <IconKerko width={15} height={15} />
                </div>
                <Input
                  className="agGridSearchInput"
                  placeholder="Kërko problematikë"
                  onChange={onFilterTextChange}
                  value={searchInput}
                  style={{ width: 200 }}
                />
                <Button className="clearButton" onClick={clearFilters}>
                  Pastro
                </Button>
              </div>
              <div className="headerButtonContainer">
                {problematikat?.length !== 0 && (
                  <ReactHtmlTableToExcel
                    id="problematika-table-xls-button"
                    className="problematikat-table-xls-button"
                    table="tableProblematikat"
                    filename={"Problematikt"}
                    sheet={"Problematikt"}
                    buttonText={
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={"Eksporto në eksel"}
                      >
                        <ExcelIcon />
                      </Tooltip>
                    }
                  />
                )}
                {problematikat?.length !== 0 && (
                  <table style={{ display: "none" }} id="tableProblematikat">
                    <tr>
                      {populatedColDefs.map((item) => {
                        return <th>{item?.headerName}</th>;
                      })}
                    </tr>
                    {problematikat.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {item.createdBy?.hasOwnProperty(
                              "employeeFirstName"
                            ) ||
                            item.createdBy?.hasOwnProperty("employeeLastname")
                              ? `${item.createdBy.employeeFirstName} ${item.createdBy.employeeLastname}`
                              : item.createdBy}
                          </td>
                          <td>
                            {htmlParser(removeImgTags(item?.ticketDescription))}
                          </td>
                          <td>{item?.ticketCategory}</td>
                          <td>{item?.ticketStatus}</td>
                          <td>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td>{item?.resolvedTicket}</td>
                        </tr>
                      );
                    })}
                  </table>
                )}
                {problematikat?.length !== 0 && (
                  <Tooltip
                    placement="top"
                    overlayClassName="global-icon-tooltip"
                    title={"Eksporto në pdf"}
                  >
                    <Button
                      className="pdfButton"
                      icon={<PdfWhiteIcon height={20} width={18} />}
                      onClick={exportPdf}
                      style={{ alignSelf: "flex-end", padding: "none" }}
                    />
                  </Tooltip>
                )}

                <MondayButton
                  onClick={onClick}
                  className="mondayButtonCreate"
                  hasIcon={false}
                  style={{ alignSelf: "flex-end" }}
                >
                  Krijoni
                </MondayButton>
              </div>
            </div>

            <div
              className="light-ag-theme documentationsTable"
              ref={gridContainerRef}
            >
              <AgGridReact
                {...{
                  rowStyle: { cursor: "pointer" },
                  columnDefs: populatedColDefs,
                  rowData: gridData,
                  onRowClicked: handleRowClicked,
                  defaultColDef: {
                    enableColResize: true,
                    editable: false,
                    sortable: true,
                    flex: 1,
                    minWidth: 100,
                    filter: true,
                  },
                  rowHeight: 45,
                  headerHeight: 45,
                  rowGroupPanelShow: "always",
                  pagination: true,
                  animateRows: true,
                  sideBar: gridSidebar,
                  onGridReady,
                  allowExcelExport: true,
                }}
              />
            </div>
          </FormCard>
          {!!isAddModalVisible && (
            <ProblemsModal
              isAddModalVisible={isAddModalVisible}
              setIsAddModalVisible={setIsAddModalVisible}
              username={username}
              editData={editData}
              setEditData={setEditData}
            />
          )}
        </div>
      ) : (
        <div className="base-mobile-div">
          <MobileHtmlTabel
            header={["Emri", "Kategoria", "Statusi", "Krijuar", "Zgjidhur"]}
            tableData={gridData?.map?.(
              ({
                createdBy,
                ticketCategory,
                ticketStatus,
                createdAt,
                resolvedTicket,
              }) => ({
                createdBy: `${createdBy.employeeFirstName} ${createdBy.employeeLastname}`,
                statusHeaderKey: ticketCategory,
                ticketStatus,
                createdAt: moment(createdAt).format("DD/MM/YYYY"),
                expirationDate: resolvedTicket,
              })
            )}
            isHeader={true}
            isLink={false}
          />
        </div>
      )}
    </>
  );
};
export default ProblematikatPage;
