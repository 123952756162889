import { message } from "antd";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjs from "dayjs";

export const postAnnouncement = async (data) => {
	console.log("data to post", data);
	if (!data) return;
	return await API.post("announcements", "/announcements", {
		body: data,
	});
};

export const editAnnouncement = async (id, data) => {
	console.log("data to edit", data);
	if (!data) return;
	return await API.put("announcements", `/announcements/${id}`, {
		body: data,
	});
};

export const deleteAnnouncement = async (id) => {
	return await API.del("announcements", `/announcements/${id}`);
};

export const getAnnouncements = async () => {
	return await API.get("announcements", "/announcements");
};

export const postResponse = async (id, response, user) => {
	console.log("topost", id, response, user);
	return await API.get("announcements", `/announcements/${id}`).then(async (res) => {
		console.log("response", response);
		console.log("res", res);
		let usersResponses = {
			...(res?.usersResponses || {}),
		};
		usersResponses[user] = { response: response, updatedAt: moment().valueOf() };

		return await API.put("announcements", `/announcements/${id}`, {
			body: { usersResponses },
		})
			.then((res) => {
				console.log("res", res);
				// message.success("U shtua përgjigja me sukses");
				return res;
			})
			.catch((err) => {
				console.log("err", err);
				message.error("Ka ndodhur një gabim në dërgimin e të dhënave");
			});
	});
};

export const onResend = async (user, id) => {
	console.log("onResend", id, user);
	return await API.get("announcements", `/announcements/${id}`).then(async (res) => {
		console.log("res", res);
		let usersResponses = {
			...(res?.usersResponses || {}),
		};
		delete usersResponses[user];

		return await API.put("announcements", `/announcements/${id}`, {
			body: { usersResponses },
		})
			.then((res) => {
				console.log("res", res);
				message.success("U ridërgua përgjigja me sukses");
				return res;
			})
			.catch((err) => {
				console.log("err", err);
				message.error("Ka ndodhur një gabim në dërgimin e të dhënave");
			});
	});
};

export const sortDates = (date1, date2, format = null) => {
  if (!format) {
    if (!date1 || !dayjs(date1).isValid()) return -1;
    if (!date2 || !dayjs(date2).isValid()) return 1;
    if (date1 === date2) return 0;
    return dayjs(date1).isBefore(dayjs(date2)) ? -1 : 1;
  } else {
    if (!date1 || !dayjs(date1, format).isValid()) return -1;
    if (!date2 || !dayjs(date2, format).isValid()) return 1;
    if (date1 === date2) return 0;
    return dayjs(date1, format).isBefore(dayjs(date2, format)) ? -1 : 1;
  }
}
