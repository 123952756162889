import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/index";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const middleware = [thunk];

// Creating the Redux Store
export const store = createStore(
	reducers,
	{ devTools: false },
	composeWithDevTools(applyMiddleware(...middleware))
);
