import "./ShowContactDetails.scss";
import React, { useContext, useEffect, useState } from "react";
import {
  CloseIcon,
  InfoIcon,
  CalendarIcon,
  TimeIcon,
  UserIcon,
} from "../Image";
import { Typography, Button, Modal, Space, message } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import QuillRenderer from "../Components/QuillRenderer";
import QuestionMark from "../Image/QuestionMark";
import moment from "moment";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import ShowContactDetailsSecondModal from "../ShowContactDetailsSecondModal/ShowContactDetailsSecondModal";
import { ModalXIcon, NewUploadIcon } from "../../../../../assets/icons";
import AddContactDocUploader from "../AddContactDocUploader/AddContactDocUploader";
import { sortBy } from "lodash";
import { fileIcons } from "../../../../Documentation/View/documentationViewData";
import { openInNewTab } from "../../../../Documentation/utils/openInNewTab";
import { useAuth } from "../../../../../authentication/authHelpers";
import { RedCloseIcon } from "../../../../Documentation/View/assets";
import MultipleFilePrevieModal from "../../../../dashboard/AdminDashboard/AdminCards/Problems/MultipleFilePreviewModal/MultipleFilePreviewModal";
import { InterviewsContext } from "../../../data";
import { htmlParser, toSentenceCase } from "../../../../../utils";
import { candidates as candidatesReducer } from "../../../../../store/actions";
// import { cancelScheduledEvent } from "../../../../../utils/calendlyEvents";

const { Text, Link } = Typography;

const ShowContactDetails = () => {
  const { candidates } = useSelector((state) => state.candidates);
  const { programFields } = useSelector((state) => state.programFields);
  const [showSecondLayoutStatus, setShowSecondLayoutStatus] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState();
  const [currentFile, setCurrentFile] = useState({});
  const dispatch = useDispatch();
  const auth = useAuth();
  const CANDIDATE_CONFIGURATION_FIELD_ID = "543gerger-dfgsdg-645645hfghfdghd";
  const INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID =
    "345dgdf-456457fgjvbmn-96789khjghj";

  const {
    onDelete,
    isChanged,
    isEditable,
    intervistat,
    driveRequest,
    setIsChanged,
    setIsEditable,
    uploadedFiles,
    temporaryArray,
    setIntervistat,
    parentFolderId,
    onPickerSuccess,
    filesToBeDeleted,
    setCreateUserPage,
    setTemporaryArray,
    tempUploadedFiles,
    kandidatiSelektuar,
    formattedDriveFiles,
    googleDriveFolderId,
    setTempUploadedFiles,
    resetGoogleDriveProps,
    defaultSwalProperties,
    setKandidatiSelektuar,
    setGoogleDriveFolderId,
    showUserInformationPage,
    setShowUserInformationPage,
  } = useContext(InterviewsContext);

  //region BUILD KEYLOGS
  const updatedFields = (originalObject, changedObject) => {
    const excludedFields = ["applicantEventURI", "googleDriveFolderId"];
    const { fieldOptions = undefined } = programFields?.find(
      ({ fieldId }) => fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
    );
    let changes = [];
    let constants = {
      activity: "Ndryshuar",
      author:
        auth.userAccess[0]?.given_name + " " + auth.userAccess[0]?.family_name,
      changeDate: moment().valueOf(),
    };
    for (const key in changedObject) {
      if (changedObject[key] !== null && !excludedFields.includes(key)) {
        if (Array.isArray(changedObject[key])) {
          if (key === "googleDriveFiles") {
            for (let i = 0; i < changedObject[key].length; i++) {
              if (changedObject[key][i] !== originalObject[key][i]) {
                changes.push({
                  ...constants,
                  field: key,
                  oldValue: originalObject[key][i],
                  newValue: changedObject[key][i],
                });
              }
            }
          } else {
            if (
              JSON.stringify(changedObject[key]) !==
              JSON.stringify(originalObject[key])
            ) {
              changes.push({
                ...constants,
                field: key,
                newValue: changedObject[key]?.join(", ") || changedObject[key],
                oldValue:
                  originalObject[key]?.join(", ") || originalObject[key],
              });
            }
          }
        } else if (moment(changedObject[key], moment.ISO_8601).isValid()) {
          if (
            new Date(changedObject[key]).toDateString() !==
            new Date(originalObject[key]).toDateString()
          ) {
            // no need since now we using Calendly only
            // if (key === "applicantInterviewDate") {
            //   const interviewDateValues = applicantInterviewDateKeylog({
            //     originalObject,
            //     changedObject,
            //     key,
            //   });
            //   for (let i = 0; i < interviewDateValues.length; i++) {
            //     const { key, value, format } = interviewDateValues[i];
            //     changes.push({
            //       ...constants,
            //       field: key,
            //       oldValue: moment(originalObject[key]).format(format),
            //       newValue: moment(value).format(format),
            //     });
            //   }
            // } else {
            changes.push({
              ...constants,
              field: key,
              oldValue: originalObject[key],
              newValue: changedObject[key],
            });
            // }
          }
        } else {
          if (changedObject[key] !== originalObject[key]) {
            changes.push({
              ...constants,
              field: key,
              oldValue: originalObject[key],
              newValue: changedObject[key],
            });
          }
        }
      } else if (
        originalObject[key] !== null &&
        !excludedFields.includes(key)
      ) {
        changes.push({
          ...constants,
          field: key,
          oldValue: originalObject[key],
          newValue: null,
        });
      }
    }

    return translateApplicantFields({ fieldOptions, changes });
  };

  //region COMPARE OBJS
  //function to check if we have any changes between 2 object
  const compareObject = (originalObject, changedObject) => {
    for (const key in changedObject) {
      if (Array.isArray(changedObject[key])) {
        if (originalObject[key].length === changedObject[key].length) {
          for (let i = 0; i < changedObject[key].length; i++) {
            if (typeof changedObject[key][i] === "object") {
              if (
                !compareObject(originalObject[key][i], changedObject[key][i])
              ) {
                return false;
              }
            } else if (originalObject[key][i] !== changedObject[key][i]) {
              {
                return false;
              }
            }
          }
        } else {
          return true;
        }
      } else if (
        Object.getPrototypeOf(changedObject[key]).constructor.name === "Object"
        // typeof changedObject[key] === "object" &&
        // !(changedObject[key] instanceof Date)
      ) {
        if (!compareObject(originalObject[key], changedObject[key])) {
          return false;
        }
      } else if (changedObject[key] instanceof Date) {
        if (changedObject[key].getTime() !== originalObject[key].getTime()) {
          return false;
        }
      } else {
        if (changedObject[key] !== originalObject[key]) {
          return false;
        }
      }
    }
    return true;
  };

  //region CONFIRM CANCEL
  const showCancelConfirm = () => {
    //check if we have any changes to the form
    if (!!isChanged) {
      Modal.confirm({
        title: "A jeni të sigurt?",
        icon: <QuestionMark />,
        centered: true,
        content: (
          <Text>
            Ju po anuloni shtimin e një kandidati në listë.
            <Space className="space-gap">
              Nëse jeni të sigurt për këtë veprim, klikoni
              <span className="text-bold">"Po, anuloje"</span>
            </Space>
          </Text>
        ),
        okText: "Po, anuloje",
        cancelText: "Jo, mos e anulo",
        width: "660px",
        className: "cancelModal",
        cancelButtonProps: {
          type: "primary",
          style: {
            background: "#EA3943",
            borderColor: "#EA3943",
            borderRadius: "5px",
          },
        },
        okButtonProps: {
          className: "ant-btn-confirm",
        },
        onOk() {
          if (!isEditable) {
            try {
              driveRequest.deleteDriveItem(googleDriveFolderId);
            } catch (err) {
              console.log(err);
            }
          } else {
            tempUploadedFiles.map((id) => {
              try {
                driveRequest.deleteDriveItem(id);
              } catch (err) {
                console.log(err);
              }
            });
          }
          setIsEditable(false);
          setShowUserInformationPage(false);
          setTemporaryArray(null);
          resetGoogleDriveProps();
          // if (temporaryArray?.applicantEventURI) {
          //   cancelScheduledEvent(
          //     temporaryArray?.applicantEventURI,
          //     REACT_APP_CALENDLY_TOKEN
          //   );
          // }
        },
      });
    } else {
      if (!isEditable) {
        try {
          driveRequest.deleteDriveItem(googleDriveFolderId);
        } catch (err) {
          console.log(err);
        }
      } else {
        tempUploadedFiles.map((id) => {
          try {
            driveRequest.deleteDriveItem(id);
          } catch (err) {
            console.log(err);
          }
        });
      }
      setIsEditable(false);
      setShowUserInformationPage(false);
      setTemporaryArray(null);
      resetGoogleDriveProps();
    }
  };

  //region ON SUBMIT
  const handleOnSubmit = async () => {
    if (isEditable) {
      let tmpCandidad = Object.assign(kandidatiSelektuar, temporaryArray);

      const { applicantId, userId, ...rest } = tmpCandidad;

      tmpCandidad = { ...tmpCandidad, googleDriveFiles: formattedDriveFiles };

      Swal.fire({
        title: "Ju lutem prisni...",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await API.put(
        "interviews",
        `/interviews/${kandidatiSelektuar?.applicantId}`,
        {
          body: { ...rest, googleDriveFiles: formattedDriveFiles },
        }
      )
        .then(() => {
          dispatch(
            candidatesReducer(updateCandidatet(candidates, tmpCandidad))
          );
          setIntervistat(updateCandidatet(intervistat, tmpCandidad));
          Swal.mixin({
            ...defaultSwalProperties,
          }).fire({
            icon: "success",
            title: "Kandidati u ndryshua!",
          });
          success(tmpCandidad);
        })
        .catch((err) => {
          console.log("err", { err });
          message.error("Ndodhi nje gabim");
        });
      setIsEditable(false);
      setShowUserInformationPage(false);
      try {
        filesToBeDeleted.forEach((item) => driveRequest.deleteDriveItem(item));
      } catch (err) {
        console.log("Deleting Files Error:", err);
      }
      resetGoogleDriveProps();
    } else {
      let tmpCandidad = {
        ...temporaryArray,
        googleDriveFiles: formattedDriveFiles,
      };

      const { applicantId, userId, ...rest } = tmpCandidad;

      Swal.fire({
        title: "Ju lutem prisni...",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await API.post("interviews", "/interviews", {
        body: rest,
      })
        .then((res) => {
          setShowUserInformationPage(false);
          setTemporaryArray(null);
          dispatch(candidatesReducer(res));
          setKandidatiSelektuar({ ...res });

          Swal.mixin({
            ...defaultSwalProperties,
          }).fire({
            icon: "success",
            title: "Kandidati u krijua!",
          });
          success(res);
        })
        .catch((err) => {
          message.error("err");
          console.error(err);
        });
      resetGoogleDriveProps();
    }
  };

  //region ON SUCCESS
  function updateCandidatet(data, newCandidad) {
    return data?.map((el) =>
      el?.applicantId === kandidatiSelektuar?.applicantId
        ? {
            ...newCandidad,
            applicantId: kandidatiSelektuar?.applicantId,
          }
        : el
    );
  }

  //region >success()
  const success = (tmpCandidad) => {
    Modal.info({
      icon: <UserIcon />,
      title: isEditable ? "Kandidati u Ndryshua" : "Kandidati u Shtua",
      centered: true,
      closable: false,
      content: (
        <div>
          <p>
            Intervista me kandidatin{" "}
            <span>
              {temporaryArray.applicantFirstName}{" "}
              {temporaryArray.applicantLastName}
            </span>{" "}
            sapo u {isEditable ? "ndryshua" : "Krijua"} me sukses. Për të bërë
            ndryshime
            {isEditable ? " të tjera " : " "}ju duhet të gjeni intervistën në
            listën e intervistave dhe të klikoni mbi të.
          </p>
        </div>
      ),
      okText: "Kliko për të parë",
      className: "successModal",

      okType: "link",
      onCancel() {
        if (!isEditable) {
          driveRequest.deleteDriveItem(googleDriveFolderId);
        }
        setIsEditable(false);
        setTemporaryArray(null);
      },
      onOk() {
        setIsEditable(false);
        setTemporaryArray(null);
        setKandidatiSelektuar({ ...tmpCandidad });
      },
    });
  };

  //region DRIVE FOLDER
  const getDriveFolder = async () => {
    let folderIdOnCreate = "";
    const folderName = `${kandidatiSelektuar?.applicantFirstName} ${
      kandidatiSelektuar?.applicantLastName
    }-${Date.now()}`;

    const { fieldOptions } = programFields?.find(
      ({ fieldId }) => fieldId === INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID
    );

    folderIdOnCreate = await createDriveFolder({
      driveRequest,
      parentId: parentFolderId,
      folderName,
      defaultPermissions: fieldOptions || [],
    });
    setGoogleDriveFolderId(folderIdOnCreate);
  };

  useEffect(() => {
    // on manual create mode we generate a folder Id
    if (!googleDriveFolderId) {
      getDriveFolder();
    }
  }, []);

  useEffect(() => {
    const fields = !!isEditable
      ? updatedFields(kandidatiSelektuar, temporaryArray)
      : [];
    setTemporaryArray((prev) => ({
      ...prev,
      googleDriveFolderId,
      keylog: [...(kandidatiSelektuar?.keylog || []), ...(fields || [])],
    }));
  }, [kandidatiSelektuar, googleDriveFolderId]);

  //constants
  let applicantFullName =
    temporaryArray.applicantFirstName + " " + temporaryArray.applicantLastName;

  let textFormater =
    applicantFullName.length > 20
      ? applicantFullName.slice(0, 20) + "..."
      : applicantFullName;

  //region RETURN
  return (
    <>
      <Modal
        open={showUserInformationPage}
        title={`${
          !!Object.keys(kandidatiSelektuar).length ? "Ndryshoni " : "Shtoni "
        } një kandidat`}
        closeIcon={<ModalXIcon />}
        // onCancel={showCancelConfirm}
        closable={true}
        destroyOnClose={true}
        onCancel={showCancelConfirm}
        className={`kantidatInfoModal ${
          showSecondLayoutStatus && "kantidatInfoModalSideContainer"
        }`}
        centered={true}
        footer={[
          <div key="footer-buttons" className="flex-between">
            {/* Footer Buttons */}
            <Button type="primary" danger onClick={showCancelConfirm}>
              {!!Object.keys(kandidatiSelektuar).length
                ? "Anuloni ndryshimet"
                : "Anuloni Krijimin"}
            </Button>
            <div className="footer-buttons">
              <Button
                type="primary"
                className="ant-btn-back"
                shape="rounded"
                onClick={() => {
                  setShowUserInformationPage(false);
                  setCreateUserPage(true);
                  setTemporaryArray(temporaryArray);
                }}
              >
                Kthehuni pas
              </Button>

              <Button
                onClick={handleOnSubmit}
                className="ant-btn-confirm"
                htmlType="submit"
                type="primary"
                shape="rounded"
              >
                {!!Object.keys(kandidatiSelektuar).length
                  ? "Konfirmoni ndryshimet"
                  : "Konfirmoni krijimin"}
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className="container">
          <div className="container-header">
            <img src={InfoIcon} alt="Info Icon" />
            <div className="header-text">
              <p>
                Kjo është pamja paraprake për kandidatin{" "}
                <span>{textFormater}</span>, lexoni edhe një herë të gjitha të
                dhënat para së të konfirmoni.
              </p>
            </div>
          </div>
          <div className="container-content">
            <div className="left-side-content">
              <div className="first-content-container">
                <div className="left-side-container">
                  <div className="first-row-first-container">
                    <p>{textFormater}</p>
                  </div>
                  <p>
                    <span>Pozicioni:</span>{" "}
                    {temporaryArray?.applicantDepartment}
                  </p>
                  <p>
                    <span>Rolet:</span>{" "}
                    {Array.isArray(temporaryArray?.applicantRoles)
                      ? temporaryArray?.applicantRoles?.join(", ")
                      : temporaryArray?.applicantRoles}
                  </p>
                  <p>
                    <span>Datëlindja:</span>
                    {temporaryArray?.applicantBirthday &&
                      moment(temporaryArray?.applicantBirthday)?.format(
                        "D/M/YYYY"
                      )}{" "}
                  </p>
                  <p>
                    <span>Nr. Personal</span>{" "}
                    {temporaryArray.applicantPersonalNumber}
                  </p>
                  <p>
                    <span>Nr. Celulari</span>{" "}
                    {temporaryArray.applicantPhoneNumber}
                  </p>
                </div>
                <div className="right-side-container">
                  <div
                    className="custom-status-style"
                    style={{
                      backgroundColor:
                        temporaryArray.applicantStatus === "Skualifikuar"
                          ? "#EA3943"
                          : temporaryArray.applicantStatus ===
                            "Listuar për intervistë"
                          ? "#FCC94A"
                          : temporaryArray.applicantStatus === "Në proçes"
                          ? "#FCC94A"
                          : "#20AC9D",
                    }}
                  >
                    {temporaryArray.applicantStatus}
                  </div>
                  <div className="input-row-style">
                    <img src={CalendarIcon} alt="Calendar Icon" />
                    <p>
                      <span>Data e intervistës: </span>
                      {moment(temporaryArray?.applicantInterviewDate)?.format(
                        "D/M/YYYY"
                      )}
                    </p>
                  </div>
                  <div className="input-row-style">
                    <img src={TimeIcon} alt="Time Icon" />
                    <p>
                      <span>Ora e intervistës: </span>
                      {moment(temporaryArray?.applicantInterviewDate)?.format(
                        "HH:mm",
                        {
                          trim: false,
                        }
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="second-content-container">
                <p>
                  <span>Adresa e banimit: </span>
                  {temporaryArray.applicantAddress}
                </p>
                <p>
                  <span>Data e mundshme e fillimit: </span>
                  {!!temporaryArray.applicantStartDate
                    ? moment(temporaryArray.applicantStartDate).format(
                        "DD/MM/YYYY"
                      )
                    : "S'ka datë"}
                </p>
                <div className="input-password-type">
                  <p>
                    <span>Norma e pagës: </span>
                    {temporaryArray.applicantPayment}
                  </p>
                </div>
                <Link
                  onClick={() => {
                    setShowSecondLayoutStatus(!showSecondLayoutStatus);
                  }}
                >
                  Kliko këtu për të shfaqur avantazhet dhe disavantazhet e
                  kandidatit
                </Link>
                <div className="diviner-line"></div>
                <p>
                  <span>Niveli i diplomës: </span>
                  {temporaryArray.applicantDiplomaLevel}
                </p>
                <p>
                  <span>Universiteti: </span>
                  {temporaryArray.applicantUniversity}
                </p>
                <p>
                  <span>Dega: </span>
                  {temporaryArray.applicantUniversityField}
                </p>
                <p>
                  <span>Platforma: </span>
                  {temporaryArray.applicantPlatform}
                </p>
                <div className="quill-content-container">
                  <div className="quill-container">
                    <span>Trajnime dhe kualifikime të tjera: </span>

                    {htmlParser(temporaryArray.applicantTraining)}

                    {/* <QuillRenderer value={temporaryArray.applicantTraining} /> */}
                  </div>
                  <div className="quill-container">
                    <span>Referenca të mëparshme: </span>
                    {htmlParser(temporaryArray.applicantReference)}

                    {/* <QuillRenderer value={temporaryArray.applicantReference} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-content">
              <div className="upload-kandidati-button">
                <NewUploadIcon width={90} height={90} />
                <div className="upload-kandidati-button-text-container">
                  <span className="upload-kandidati-button-text">
                    Hidhni dokumentat këtu ose klikoni butonin e mëposhtëm
                  </span>

                  <AddContactDocUploader
                    {...{
                      onPickerSuccess(param) {
                        onPickerSuccess(param);
                        setTempUploadedFiles([
                          ...tempUploadedFiles,
                          ...param.uploadedFiles.map(({ id }) => id),
                        ]);
                        setIsChanged(true);
                      },
                      uploadedFiles,
                      parentFolderId: googleDriveFolderId,
                    }}
                  />
                </div>
              </div>

              <div className="uploadedFilesSection">
                {sortBy(uploadedFiles, "lastEditedUtc")
                  .reverse()
                  ?.map(({ url, name = "", id, type, mimeType }) => {
                    const fileExtension = name.split(".").pop();

                    return (
                      <div className="fileCard" key={id}>
                        <div className="uploadFileName">
                          <span className="uploadedFileIcon">
                            {fileIcons({ type: fileExtension }) ||
                              fileIcons({ type: "image" })}
                          </span>
                          <span
                            className="fileName"
                            onClick={() => {
                              if (
                                type !== "photo" &&
                                mimeType !== "application/pdf" &&
                                mimeType !== "image/png"
                              )
                                openInNewTab(url);
                              else {
                                setPreviewModalVisible(true);
                                driveRequest.getImageSrc(id).then((file) => {
                                  setCurrentFile({
                                    ...file,
                                    url,
                                    mimeType,
                                    id,
                                  });
                                });
                              }
                            }}
                          >
                            {name}
                          </span>
                        </div>

                        <RedCloseIcon
                          height={18}
                          width={14}
                          className="closeIcon"
                          onClick={() => {
                            onDelete(id);
                            setIsChanged(true);
                          }}
                        />
                      </div>
                    );
                  })}
                <MultipleFilePrevieModal
                  {...{
                    visible: previewModalVisible,
                    setVisible: () => {
                      setCurrentFile({});
                      setPreviewModalVisible(false);
                    },
                    setCurrentFile,
                    file: currentFile,
                    uploadedFiles,
                    driveRequest,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ShowContactDetailsSecondModal
        showSecondLayoutStatus={showSecondLayoutStatus}
        setShowSecondLayoutStatus={setShowSecondLayoutStatus}
        temporaryArray={temporaryArray}
      />
    </>
  );
};

export default ShowContactDetails;
// export const fields = {
//   applicantAddress: "Adresa e banimit",
//   applicantAdvantages: "Avantazhet",
//   applicantBirthday: "Datëlindja",
//   applicantDiplomaLevel: "Niveli i diplomës",
//   applicantDisadvantages: "Disavantazhet",
//   applicantEmail: "Email",
//   applicantFirstName: "Emër",
//   applicantInterviewDate: "Data e intervistës",
//   applicantLastName: "Mbiemër",
//   applicantPayment: "Norma e pagës",
//   applicantPersonalNumber: "Nr.Personal",
//   applicantPhoneNumber: "Nr.Celulari",
//   applicantPlatform: "Platforma",
//   applicantReference: "Referenca",
//   applicantStartDate: "Data e mundshme e fillimit",
//   applicantTeam: "Skuadra",
//   applicantStatus: "Statusi",
//   applicantTraining: "Trajnime dhe kualifikime të tjera",
//   applicantUniversity: "Universiteti",
//   googleDriveFiles: "Dokumentet",
//   applicantRoles: "Rolet",
//   applicantDepartment: "Departamenti",
// };
export const translateApplicantFields = ({ fieldOptions, changes }) => {
  const fieldLabels = new Map(
    fieldOptions.map(({ formItemName, label }) => [formItemName, label])
  );
  const rest = changes
    .filter((el) => !["keylog"].includes(el?.field))
    .map((log) => {
      const translatedField = fieldLabels.get(log?.field) || log?.field;
      return {
        ...log,
        field: translatedField,
      };
    });
  return rest;
};
export const applicantInterviewDateKeylog = ({
  originalObject,
  changedObject,
  key,
}) => {
  const dataFormats = ["DD/MM/YYYY", "HH:mm"];
  let values = [];
  for (let i = 0; i < dataFormats.length; i++) {
    const format = dataFormats[i];
    if (
      moment(originalObject?.[key]).format(format) !==
      moment(changedObject?.[key]).format(format)
    ) {
      const formattedValue = moment(changedObject[key]).format(format);
      values.push({ key, value: formattedValue, format });
    }
  }
  return values;
};
export async function createDriveFolder({
  driveRequest,
  parentId,
  folderName,
  resetValues,
  defaultPermissions = [],
}) {
  let result = "";
  await driveRequest
    .getFolderIdOrCreate({
      name: folderName,
      parent: parentId,
      defaultPermissions,
    })
    .then((res) => (result = res))
    .catch((err) => {
      message.error({
        key: 0,
        content: "Nuk u lidh dot me Google Drive",
        duration: 2,
      });
      console.log("Error: ", err);
      setTimeout(() => {
        if (resetValues && typeof resetValues === "function") {
          resetValues();
        }
      }, 600);
    });
  return result;
}
