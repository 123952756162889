import { message } from "antd";

const DEFAULT_KEY = "windowMessages";
const DEFAULT_DURATION = 3;
const DEFAULT_SUCCESS_MSG = "U ruajt!";
const DEFAULT_LOADING_MSG = "Duke ruajtur...";
const DEFAULT_ERROR_MSG = "Diçka shkoj gabim!";

export const showLoadingMsg = (params) => {
    const { content = DEFAULT_LOADING_MSG, key = DEFAULT_KEY } = params || {}
    message.loading({ content, key })
}

export const showSuccessMsg = (params) => {
    const {
        content = DEFAULT_SUCCESS_MSG,
        key = DEFAULT_KEY,
        duration = DEFAULT_DURATION,
        className = "",
    } = params || {}
    message.success({ content, key, duration, className })
}

export const showErrorMsg = (params) => {
    const {
        content = DEFAULT_ERROR_MSG,
        key = DEFAULT_KEY,
        duration = DEFAULT_DURATION,
        className = "",
    } = params || {}
    message.error({ content, key, duration, className })
}

export const showWarningMsg = (params) => {
    const {
        content = DEFAULT_ERROR_MSG,
        key = DEFAULT_KEY,
        duration = DEFAULT_DURATION,
        className = "",
    } = params || {}

    message.warning({ content, key, duration, className })
}