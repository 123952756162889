import moment from "moment-timezone";
import React from "react";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";

function MobilePaymentGrid({ rowData }) {
	return (
		<MobileHtmlTabel
			tableData={rowData?.map?.(({ muaji, neto, bruto }) => ({
				muaji: moment(muaji).format("MMMM - YYYY"),
				neto,
				bruto,
			}))}
			header={["Muaji", "Shuma neto", "Shuma bruto"]}
			isHeader={false}
			isLink={false}
		/>
	);
}

export default MobilePaymentGrid;
