import { Skeleton } from "antd";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CepiDjathtas, CepiMajtas, MedaljaEmp } from "../../../../assets/icons";
import ReturnImg from "../../../../utils/ReturnImg";
import "./EmployesOfMonth.scss";
import Avatar from "react-avatar";
import _ from "lodash";
import { useSelector } from "react-redux";

function EmployeOfMonth() {
  //state with data return object
  const [emplWeekMonth, setEmplWeekMonth] = useState(null);
  const [loading, setLoading] = useState(true);
  const { programFields } = useSelector((state) => state.programFields);
  // get data from programFields and store in state
  const getProgramFields = () => {
    setEmplWeekMonth(
      programFields.find?.(
        (data) => data?.fieldName === "Employees of the Week & Month"
      )?.fieldOptions
    );
  };

  // run when page is render
  useEffect(() => {
    if (!!programFields) {
      getProgramFields();
      setLoading(false);
    }
  }, [programFields]);

  return (
    <>
      {!loading ? (
        <div className="emp-Month-card">
          <div className="cepi-majtas">
            <CepiMajtas style={{ borderBottomLeftRadius: 10 }} />
          </div>
          {emplWeekMonth?.month?.name ? (
            <div className="content-emp-month">
              <div className="content-emp">
                <div className="muaj">
                  Punonjësi i Muajit -{" "}
                  {moment(emplWeekMonth?.month?.start).format("MMMM YYYY")}
                </div>
                <div className="emri">{emplWeekMonth?.month?.name}</div>
                <div className="message">
                  {emplWeekMonth?.month?.mesazhPublikMonth || ""}
                </div>
              </div>
              <div
                className="medalje-name-container"
                style={{ display: "flex" }}
              >
                <div className="medalja">
                  <MedaljaEmp />
                </div>
                <div className="foto">
                  {
                    <ReturnImg
                      width={180}
                      height={180}
                      borderRadius={5}
                      googleDriveFileId={
                        emplWeekMonth?.month?.googleDriveFileId
                      }
                      defaultImg={
                        <div style={{ width: 180, height: 180 }}>
                          <Avatar
                            name={emplWeekMonth?.month?.name || ""}
                            size="180"
                            round={true}
                          />
                        </div>
                      }
                    />
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className="content-emp-month-noData">
              Punonjësi i Muajit nuk është përzgjedhur!{" "}
            </div>
          )}
          <div className="cepi-djathtas">
            <CepiDjathtas />
          </div>
        </div>
      ) : (
        <div>
          <Skeleton />
        </div>
      )}
    </>
  );
}

export default EmployeOfMonth;
