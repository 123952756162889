import Modal from "antd/lib/modal/Modal";
import "./IpLogs.scss";
import { Button } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { htmlParser } from "../../../../utils";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { ModalXIcon } from "../../../../assets/icons";

const IpLogs = ({ data, isVisible, setIsVisible }) => {
  const [tableData, setTableData] = useState([]);
  const authorFormatter = (params) => {
    if (params.value) {
      const author = `${params.value.employeeFirstName} ${params.value.employeeLastname}`;
      return author;
    }
    return "";
  };
  const dateFormatter = (params) => {
    if (params.value) {
      const formattedDate = moment(params.value).format("DD/MM/YYYY HH:mm ");
      return formattedDate;
    }
    return "";
  };

  const columnDefs = [
    {
      headerName: "Autori",
      field: "author",
      valueFormatter: authorFormatter,
    },

    {
      headerName: "Koha e ndryshimit",
      field: "changeDate",
      valueFormatter: dateFormatter,
    },
    {
      headerName: "Activity",
      field: "activity",
    },
    {
      headerName: "Ip",
      field: "currentData",
    },
    // {
    //   headerName: "Përshkrimi i kërkesës",
    //   field: "statusDescription",
    //   cellRendererFramework: ({ value }) => htmlParser(value),
    // },
  ];

  const onFirstDataRendered = useCallback((param) => {
    param.columnApi.autoSizeAllColumns(false);
  }, []);

  useEffect(() => {
    let newData = data?.fieldOptions?.logs;

    setTableData(newData);
  }, [isVisible]);

  return (
    <Modal
      className="ipLogs"
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      destroyOnClose
      title="Paisjet Logs"
      closeIcon={<ModalXIcon />}
      width={650}
      footer={[
        <Button className="decline" onClick={() => setIsVisible(false)}>
          Mbyllni faqen
        </Button>,
      ]}
    >
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", height: "100%", borderRadius: "5px" }}
      >
        <AgGridComponent
          columnDefs={columnDefs}
          customDefaultColDef={{ flex: undefined }}
          rowData={tableData}
          overlayLoadingTemplate="Historiku është bosh!"
          autoSizeStrategy={{ type: "fitCellContents" }}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </Modal>
  );
};

export default IpLogs;
