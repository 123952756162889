import { API } from "aws-amplify";
import { validateCrashingShifts } from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
import swal from "sweetalert";
import moment from "moment-timezone";
import { message } from "antd";

export const matchDepartamentWithEmployee = (departmentId, departments) => {
  let res = departments.filter((el) => el.departmentId === departmentId);
  if (res.length > 0) {
    return res[0].departmentName;
  } else {
    return null;
  }
};

export const createNewShiftByAdmin = async (
  shiftData,
  employeeId,
  employeeRequests
) => {
  let valid = await validateCrashingShifts(shiftData, employeeId, null);
  // let req = await validateRequests(shiftData, employeeId, employeeRequests);
  // let afterMidnight = await afterMidnight(shiftData, employeeId);
  // console.log("request", req);
  // if (req === false) {
  // 	message.error("Ju nuk mund te shtoni pasi ka kerkese leje!");
  // 	return Promise.reject(["", ""]);
  // }

  console.log("valid", valid);

  const lower_limit = moment(shiftData.clockInDate);
  lower_limit.set({
    hour: "00",
    minute: "00",
    second: "00",
  });
  const higher_limit = moment(shiftData.clockInDate);
  higher_limit.set({
    hour: "07",
    minute: "30",
    second: "00",
  });

  shiftData.clockingCategory =
    moment(shiftData?.clockInDate).valueOf() <= moment(higher_limit).valueOf()
      ? "midnightReset"
      : shiftData?.clockingCategory;

  if (valid.value === true) {
    return API.post("clocking", `/clocking`, {
      body: {
        clockingCategory: "midnightReset",
        ...shiftData,
      },
    }).then(
      (res) => res
      // API.post("clockingLogs", "/clockingLogs", {
      //   body: {
      //     clockingId: clockingId,
      //     createdAt: moment().valueOf(),
      //     username: adminLog.username,
      //     note: "Shtim Turni Manual",
      //     newShift: [{ clockInDate: shiftData.clockInDate, clockOutDate: shiftData.clockOutDate }],
      //   },
      // })
    );
  } else {
    // swal({
    //   title: `Ju nuk mund te shtoni kete turn ne kete orar sepse ka te pakten nje turn qe ka perplasje:
    //   ${moment(valid.crashShift.clockInDate).format("HH:mm")}
    //    - ${moment(valid.crashShift.clockOutDate).format("HH:mm")}
    //   `,
    //   icon: "error",
    //   className: "custum-swal",
    // });
    return Promise.reject([valid.crashShift, shiftData]);
  }
};

const validateRequests = async (shiftData, employeeId, employeeRequests) => {
  let userSub;
  await API.get("employees", `/employees/${employeeId}`).then(
    (res) => (userSub = res)
  );
  let appliedRequest = employeeRequests.filter(
    (el) =>
      el.userSub === userSub.userSub &&
      el?.requestStatus === "APPROVED" &&
      el.requestPeriod.some(
        (period) =>
          moment(period).format("DD/MM/YYYY") ===
          moment(shiftData.clockInDate).format("DD/MM/YYYY")
      )
  );
  if (appliedRequest.length > 0) {
    return false;
  }
  return true;
};
