import { CloseOutlined } from "@ant-design/icons";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import NormalSizedModal from "../../../../commonComponents/NormalSizedModal/NormalSizedModal";
import "./FilePreviewModal.scss";
import { GDriveIcon } from "../../assets/index";
import { openInNewTab } from "../../../utils/openInNewTab";
import LoadableComp from "../../../../commonComponents/LoadableComp/LoadableComp";

const FilePreviewModal = ({ visible, setVisible, file = {} }) => {
  const { src, mimeType, url } = file;

  return (
    <NormalSizedModal
      {...{
        visible,
        setVisible,
        className: "filePreviewModal",
        destroyOnClose: true,
        centered: true,
        maskClosable: true,
      }}
    >
      <div className="bodyContent">
        <CloseOutlined
          className="closeIcon"
          onClick={() => setVisible(false)}
        />
        <LoadableComp loading={!src}>
          {mimeType === "application/pdf" ? (
            <iframe
              title="pdfPreview"
              id="previewIFrame"
              className="displayedFileFrame"
              {...{ src }}
            />
          ) : (
            <img alt="..." className="displayedImage" {...{ src }} />
          )}
          {!!url && (
            <MondayButton
              {...{
                className: "gDriveButton",
                Icon: <GDriveIcon />,
                onClick: () => openInNewTab(url),
              }}
            >
              Hapni në Drive
            </MondayButton>
          )}
        </LoadableComp>
      </div>
    </NormalSizedModal>
  );
};

export default FilePreviewModal;
