import FieldOption from "../FieldOption/FieldOption";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import "./CustomFields.scss";

const TITLE = "Fushat e Personalizuara";

const CustomFields = ({
  customsFieldsAvailable = [],
  className = "",
  docObject = [],
  expirationDate = false,
  selectedDocumentation = {},
  setEditedCategory,
}) => {
  const docObjectFormItemNames = docObject?.map(
    ({ formItemName }) => formItemName
  );

  const totalFields = uniqWith(
    [...customsFieldsAvailable, ...docObject],
    isEqual
  );

  const onFieldOptionClick = (formItemName, added) => {
    setEditedCategory((prev) => ({
      ...prev,
      documentationsAvailable: prev?.documentationsAvailable?.map((item) =>
        item?.docName !== selectedDocumentation?.docName
          ? item
          : {
              ...item,
              docObject: !added
                ? [
                    ...item?.docObject,
                    customsFieldsAvailable.find(
                      ({ formItemName: arrFormItemName }) =>
                        arrFormItemName === formItemName
                    ),
                  ]
                : item?.docObject.filter(
                    ({ formItemName: arrFormItemName }) =>
                      arrFormItemName !== formItemName
                  ),
            }
      ),
    }));
  };

  const onExpirationDateClick = (added) => {
    setEditedCategory((prev) => ({
      ...prev,
      documentationsAvailable: prev?.documentationsAvailable.map((item) =>
        item?.docName !== selectedDocumentation?.docName
          ? item
          : { ...item, expirationDate: !added }
      ),
    }));
  };

  return (
    <div className={`docCustomFields ${className}`}>
      <div className="compTitle">{TITLE}</div>
      <div className="fieldOptionsContainer">
        {totalFields.map(({ label, formItemName }) => (
          <FieldOption
            className="docFieldOption"
            added={docObjectFormItemNames.includes(formItemName)}
            onClick={() =>
              onFieldOptionClick(
                formItemName,
                docObjectFormItemNames.includes(formItemName)
              )
            }
          >
            {label}
          </FieldOption>
        ))}
      </div>

      <FieldOption
        className="docFieldOption expirationDateField"
        added={expirationDate}
        onClick={() => onExpirationDateClick(expirationDate)}
      >
        Data e Skadencës
      </FieldOption>
    </div>
  );
};

export default CustomFields;
