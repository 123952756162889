import React, { useContext } from "react";
import { Card, Row, Col, Checkbox } from "antd";
import moment from "moment-timezone";
import { EditIcon } from "../../../../../assets/icons";
import "../../components/DataCard.scss";
import PunonjesitContext from "../../../store/PunonjesitContext";

function DetailsView({ handleClick }) {
  const { employee } = useContext(PunonjesitContext);

  return (
    <div className="details-view-employes" style={{ width: "100%" }}>
      <Card
        className="info-card"
        title="Detaje"
        extra={
          <div className="icon-button">
            <button id="edit" onClick={handleClick}>
              <EditIcon className="pen-icon" />
            </button>
          </div>
        }
      >
        <Row style={{ width: "100%", paddingBottom: 15 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Team:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">{employee?.employeeTeam}</div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 15 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Departamenti:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeDepartmentName}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 15 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Pozicioni:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeRoleName}
              </div>
            </Col>
          </div>
        </Row>
        {employee?.employeeOtherPosition?.length > 0 ? (
          <Row style={{ width: "100%", paddingBottom: 15 }}>
            <div className="infoData">
              <Col span={11}>
                <div className="label-view-details">Pozicion tjetër:</div>
              </Col>
              <Col span={13}>
                <div className="label-show-details">
                  {employee?.employeeOtherPosition?.join(", ") ||
                    employee?.employeeOtherPosition}
                </div>
              </Col>
            </div>
          </Row>
        ) : null}
        <Row style={{ width: "100%", paddingBottom: 15 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Statusi i punonjësit:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeStatus}
              </div>
            </Col>
          </div>
        </Row>
        <Row
          style={{
            width: "100%",
            paddingBottom: 15,
            display: employee?.employeeStatus !== "Aktiv" ? "" : "none",
          }}
        >
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Data e përfundimit:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {moment(employee?.employeeContractEndDate).format("DD/MM/YYYY")}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 15 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Data e punësimit:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeHireDate === null
                  ? moment(employee?.employeeHireDate).format("DD/MM/YYYY")
                  : moment(employee?.employeeHireDate).format("DD/MM/YYYY")}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 15 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Lloji i punësimit:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeTypeOfEmployment}
              </div>
            </Col>
          </div>
        </Row>
        <Row style={{ width: "100%", paddingBottom: 15 }}>
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Periudha e kontrates:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeContractDuration}
              </div>
            </Col>
          </div>
        </Row>
        <div style={{ width: "100%", marginBottom: 15 }}>
          <Checkbox
            className="status"
            disabled
            checked={employee?.employeelsSupervisor}
            style={{
              display: employee?.employeelsSupervisor ? "" : "none",
            }}
          >
            Është supervizor
          </Checkbox>
        </div>
        <Row
          style={{
            width: "100%",
            paddingBottom: 10,
            display: employee?.employeelsSupervisor ? "none" : "",
          }}
        >
          <div className="infoData">
            <Col span={11}>
              <div className="label-view-details">Supervizor:</div>
            </Col>
            <Col span={13}>
              <div className="label-show-details">
                {employee?.employeeSupervisor}
              </div>
            </Col>
          </div>
        </Row>
        <Row
          style={{
            width: "100%",
            paddingBottom: 10,
            display: employee?.employeeDateOfReEmployment,
          }}
        >
          {!!employee?.employeeDateOfReEmployment &&
          employee?.employeeStatus === "Aktiv" ? (
            <div className="infoData">
              <Col span={11}>
                <div className="label-view-details">Data e ripunësimit:</div>
              </Col>
              <Col span={13}>
                <div className="label-show-details">
                  {moment(employee?.employeeDateOfReEmployment).format(
                    "DD/MM/YYYY"
                  )}
                </div>
              </Col>
            </div>
          ) : null}
        </Row>
      </Card>
    </div>
  );
}

export default DetailsView;
