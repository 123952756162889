import React from "react";
import { categories } from "./Announcements";

function LajmerimetPrint({ tableData, componentRef }) {
  //getting nested usersResponses
  const usersResponses = tableData.flatMap((el) => {
    const tmp = [];

    for (const id in el?.usersResponses || {}) {
      tmp.push({
        id,
        response: el.usersResponses[id]["response"],
        announcementId: el?.announcementId,
      });
    }
    return tmp;
  });
  const extractPermbajtja = (string) => {
    var temp = document.createElement("div");
    temp.innerHTML = string;
    return temp.textContent || temp.innerHTML;
  };
  const displayCategory = (obj, data) => {
    for (const key in obj) {
      if (data === key) {
        return obj[key];
      }
    }
    return null;
  };
  return (
    <div
      className="Lajmerimet-print"
      style={{ visibility: "collapse" }}
      ref={componentRef}
    >
      <table id="table-to-xls">
        <thead>
          <tr>
            <th>Titulli</th>
            <th>Kategoria</th>
            <th>Përmbajtja</th>
            <th>Url</th>
            <th>Krijuar nga</th>
            <th>Përgjigjet</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map?.((el) => (
            <tr>
              <td style={{ width: "70px" }}>{el?.announcementTitle} </td>
              <td style={{ width: "80px" }}>
                {displayCategory(categories, el?.announcementCategory)}
              </td>
              <td>{extractPermbajtja(el?.announcementBody)} </td>
              <td>
                <a href={`${el.announcementUrl}`} target={"_blank"}>
                  {el?.announcementUrl}
                </a>
              </td>
              <td>{el?.createdBy} </td>
              <td style={{ display: "flex", flexDirection: "column" }}>
                {usersResponses.map((res) => {
                  if (res.announcementId === el?.announcementId) {
                    return (
                      <span>
                        {res.response}
                        {", "}
                      </span>
                    );
                  }
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default LajmerimetPrint;
