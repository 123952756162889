import { Form } from "antd";
import TransferLabel from "../TransferLabel/TransferLabel";
import "./DocumentationsCard.scss";

const COMP_TITLE =
  "Zgjidhni të gjitha dokumentet që do të përfshihen në këtë model";

const DocumentationsCard = ({ documentationsAvailable = [] }) => {
  return (
    <div className="documentationsCheckboxesContainer">
      <div className="checkboxesContainerTitle">{COMP_TITLE}</div>
      <div className="checkBoxes">
        {documentationsAvailable.map(({ docName }) => (
          //! maps all documentations that are created
          <Form.Item
            key={docName}
            name={docName}
            valuePropName="checked"
            noStyle
          >
            <TransferLabel title={docName} />
          </Form.Item>
        ))}
      </div>
    </div>
  );
};

export default DocumentationsCard;
