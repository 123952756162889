import types from "../types/types";
const initialState = {
	earnings: undefined,
};

const earningsReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.SET_EARNINGS:
			return {
				...state,
				earnings: payload,
			};
		default:
			return state;
	}
};
export default earningsReducer;
