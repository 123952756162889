import { calculateEmployeeHoursGeneral } from "./calculcate";
import { store } from "../../../../store/store";
import { sliceEmployeeShifts } from "./slicing";

// var { clockings, employeesList, departments, usersList } = store.getState();
export function paySubFactory(paySub) {
	switch (paySub.type) {
		case "normal":
			return calculateEmployeeHoursGeneral(paySub);
		case "slicing":
			return sliceEmployeeShifts(paySub, false);
		// Returns the object needed for total amounts
		case "allEarnings":
			return sliceEmployeeShifts(paySub, true);
	}
}
