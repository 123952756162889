import React from "react";
import "../SistemiStatustetPunojsve/systemEmployeeStatus.scss";
import ColorCard from "../../dynamic components/ColorCard";

function SistemiArsyetLargimeve() {
  const id = "fa2594ff-e2dc-4f71-a669-80275a8ce983";
  const cardTitle = "Arsyet e përfundimit";
  const styleCard = {
    height: 555,
    width: "100%",
  };

  return (
    <>
      <ColorCard id={id} cardTitle={cardTitle} styleCard={styleCard} />
    </>
  );
}

export default SistemiArsyetLargimeve;
