import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Spin } from "antd";
import ActivityModal from "./ActivityModal/ActivityModal";
import "../Personal/employee.scss";
import "./NameCard.scss";
import { Row } from "antd";
import moment from "moment-timezone";
import driveApi from "../../../DriveRequest/driveApi";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { message } from "antd";
import {
  showLoadingMsg,
  showSuccessMsg,
} from "../../../Documentation/utils/Messages";
import EmployeeReasonModal from "./EmployeeReasonModal/EmployeeReasonModal";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import GoogleDriveUpload from "../../../Konfigurimet/Profile/GoogleDriveUpload";
import PunonjesitContext from "../../store/PunonjesitContext";

// * @EneaXharau - Added Websocket connection
// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

function NameCard() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { users } = useSelector((state) => state.usersList);
  const [modalVisible, setModalVisible] = useState(false);
  const [terminationModalVisible, setTerminationModalVisible] = useState(null);
  const [form] = Form.useForm();
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const [currentFile, setCurrentFile] = useState("");
  const [spinning, setSpinning] = useState(true);

  const { employee, setEmployee, auth } = useContext(PunonjesitContext);

  let changedObjforDateOfReEmployment = {
    activity: "Ndryshuar",
    author:
      auth?.user?.attributes?.given_name +
      " " +
      auth?.user?.attributes?.family_name,
    changeDate: moment().valueOf(),
    field: "Data e ripunësimit",
    oldValue: employee?.employeeDateOfReEmployment || "",
    newValue: moment(
      form.getFieldValue("employeeDateOfReEmployment")
    ).valueOf(),
  };

  const changeEmployeeStatus = async (employeeStatus) => {
    let changedObj = {
      activity: "Ndryshuar",
      author:
        auth?.user?.attributes?.given_name +
        " " +
        auth?.user?.attributes?.family_name,
      changeDate: moment().valueOf(),
      field: "Statusi i punonjësit",
      oldValue: employee?.employeeStatus,
      newValue: employeeStatus,
    };
    await API.put("employees", `/employees/${employee.employeeId}`, {
      body: {
        employeeReasonforSuspension:
          form.getFieldsValue().employeeReasonforSuspension ||
          "Nuk ka arsye largimi!",
        employeeReasonforTermination:
          form.getFieldsValue().employeeReasonforTermination ||
          "Nuk ka arsye shkarkimi!",
        employeeStatus: employeeStatus,
        employeeContractEndDate:
          employeeStatus === "Larguar" || employeeStatus === "Shkarkuar"
            ? moment(form.getFieldValue("employeeContractEndDate")).valueOf()
            : undefined,
        employeeDateOfReEmployment:
          employeeStatus === "Aktiv" ? moment().valueOf() : undefined,
        keylog: [
          employee.keylog ? { ...employee?.keylog } : {},
          changedObj,
          employeeStatus === "Aktiv" ? changedObjforDateOfReEmployment : null,
        ],
      },
    }).then(async (res) => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      console.log(
        "🚀 ~ file: NameCard.js ~ line 59 ~ changeEmployeeStatus ~ res",
        res?.employee?.Item
      );
      // if (socketHandler.readyState === socketHandler.OPEN) {
      //   socketHandler.send(
      //     JSON.stringify({
      //       request: "user_edit",
      //       body: res?.employee?.Item,
      //       id: auth?.employeeId,
      //     })
      //   );
      // }
      showLoadingMsg({ content: "Duke ruajtur..." });
      showSuccessMsg({ content: "Të dhënat u ruajtën!" });
      setEmployee(res?.employee?.Item);
    });
  };

  function disableStatusButtons() {
    if (employee?.employeeStatus === "Larguar") {
      return true;
    } else if (employee?.employeeStatus === "Shkarkuar") {
      return true;
    }
  }
  const onPickerSuccess = async (params) => {
    setSpinning(true);
    const fileId = params?.uploadedFiles?.[0]?.id || "";
    const userIdentityId = users?.allUsers?.Items?.find(
      (el) => el?.userSub === employee?.userSub
    )?.identityId;
    if (!!fileId) {
      await API.put("users", `/users/${userIdentityId}`, {
        body: {
          googleDriveFileId: fileId,
        },
      })
        .then((r) => {
          setSpinning(false);
          setEmployee((prev) => ({ ...prev, googleDriveFileId: fileId }));
          message.success("U ndryshua me sukses!");
        })
        .catch((err) => {
          message.error("Ndodhi nje gabim!");
        });
    }
  };

  useEffect(async () => {
    if (!!employee?.googleDriveFileId) {
      driveRequest.getImageSrc(employee?.googleDriveFileId).then((image) => {
        setCurrentFile(image?.src);
        setSpinning(false);
      });
    } else {
      setTimeout(() => {
        setSpinning(false);
      }, 1000);
    }
  }, [employee?.googleDriveFileId]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        {/* Card e emrit dhe aktivitetit */}
        <Card
          className="name-card"
          style={{ width: "100%", justifyContent: "center", height: "470px" }}
          title={
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {employee?.employeeFirstName} {employee?.employeeLastName}
            </span>
          }
        >
          <Row
            style={{
              width: "100%",
              justifyContent: "center",
              height: "100%",
              marginTop: "-16px",
            }}
          >
            <div className="act">Aktiv qe prej:</div>
            <div id="active">
              {moment(employee?.employeeHireDate).format("DD/MM/YYYY")}
            </div>
            <div className="nameCard-buttons">
              <button
                className="changes"
                onClick={() => setIsModalVisible(true)}
              >
                Shiko ndryshimet
              </button>
              {employee?.employeeStatus === "Aktiv" ? (
                <Button
                  className="cardDeleteButton"
                  onClick={() => {
                    setModalVisible(true);
                    setTerminationModalVisible(false);
                  }}
                  disabled={
                    employee?.employeeStatus === "Larguar" ? true : false
                  }
                >
                  Largo
                </Button>
              ) : (
                <Button
                  onClick={() => changeEmployeeStatus("Aktiv")}
                  className={
                    employee?.employeeStatus === "Shkarkuar"
                      ? "disableActiveButton"
                      : "cardActivateButton"
                  }
                  disabled={
                    employee?.employeeStatus === "Shkarkuar" ? true : false
                  }
                >
                  Aktivizo
                </Button>
              )}
              {terminationModalVisible === false ? (
                <EmployeeReasonModal
                  {...{
                    form,
                    modalVisible,
                    setModalVisible,
                    employee,
                    changeEmployeeStatus,
                    termination: false,
                  }}
                />
              ) : null}
              <Button
                className={
                  employee?.employeeStatus === "Shkarkuar"
                    ? "disableTerminateButton"
                    : "terminateEmployeeButton"
                }
                onClick={() => {
                  setModalVisible(true);
                  setTerminationModalVisible(true);
                }}
                disabled={disableStatusButtons()}
              >
                Shkarko
              </Button>
              {terminationModalVisible === true ? (
                <EmployeeReasonModal
                  {...{
                    form,
                    modalVisible,
                    setModalVisible,
                    employee,
                    changeEmployeeStatus,
                    termination: true,
                  }}
                />
              ) : null}
            </div>
          </Row>

          {/* to be made reusable */}
          {employee?.employeeStatus === "Larguar" ? (
            <div className="showReason">
              <span className="reasonLabel">Arsyeja largimit:</span>
              <div className="reason">
                {employee?.employeeReasonforSuspension}
              </div>
            </div>
          ) : (
            ""
          )}
          {employee?.employeeStatus === "Shkarkuar" ? (
            <div className="showReason">
              <span className="reasonLabel">Arsyeja e shkarkimit:</span>
              <div className="reason">
                {employee?.employeeReasonforTermination}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="image-empView-div" style={{ height: 250 }}>
            <Spin spinning={spinning}>
              {!!currentFile && (
                <img className="empView-img" src={currentFile} alt="Flex" />
              )}
              {!spinning && !currentFile && <div>No photo uploaded</div>}
            </Spin>
          </div>
          {users?.userRole === "Admin" &&
            employee?.employeeStatus === "Aktiv" && (
              <GoogleDriveUpload
                emplyeesObj={employee}
                accessToken={accessToken}
                onPickerSuccess={onPickerSuccess}
                setCurrentFile={setCurrentFile}
                adminUpload={true}
                uploadImages={true}
              />
            )}
        </Card>
      </div>
      <ActivityModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showModal={() => setIsModalVisible(true)}
        keylog={employee?.keylog}
      />
    </div>
  );
}
export default NameCard;
