import React, { useState } from "react";
import { FlexLogo } from "../../assets/icons";
import "./auth-scss/signUp.scss";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from "antd";
import { Color } from "ag-grid-community";

function SignUp() {
  const { Option } = Select;

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 80,
          background: "#00000029",
        }}
      >
        <Option value="+355">+355</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  return (
    <div className="sign-up">
      <Row>
        <Col span={24}>
          <div className="signUp-header">
            <img src={FlexLogo} alt="Flex" width={160} height={104} />
          </div>
        </Col>
      </Row>
      <div className="background">
        <div className="signUpForm">
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{ prefix: "+355" }}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Ju lutem shkruan emailin tuaj !",
                },
                {
                  required: true,
                  message: "Ju lutem shkruan emailin tuaj !",
                },
              ]}
            >
              <Input
                placeholder="Emaili"
                style={{ width: "300px", background: "#F5F5F7" }}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Ju lutem shkruan fjalkalimin tuaj !",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Fjalkalimi"
                style={{ width: "300px", background: "#F5F5F7" }}
              />
            </Form.Item>

            <Form.Item
              name="phone"
              type="number"
              rules={[
                {
                  required: true,
                  message: "Ju lutem shkruani numrin tuaj !",
                },
              ]}
            >
              <Input
                placeholder="Numri Telefonit"
                addonBefore={prefixSelector}
                type="number"
                style={{ width: "300px" }}
              />
            </Form.Item>

            <Form.Item
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Ju lutem zgjidhni gjinin tuaj !",
                },
              ]}
            >
              <Select
                placeholder="Zgjidhni gjinin tuaj"
                style={{ background: "#B9BFC2", width: "300px" }}
              >
                <Option
                  style={{ background: "#B9BFC2", color: "#fff" }}
                  value="mashkull"
                >
                  Mashkull
                </Option>
                <Option
                  style={{ background: "#B9BFC2", color: "#fff" }}
                  value="femër"
                >
                  Femër
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Ju duhet të pranoni kushtet")
                        ),
                },
              ]}
            >
              <Checkbox>I pranoj kushtet tuaja</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Regjistrohu
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
