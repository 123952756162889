import React from "react";
import "./AddLeaveStatus.scss";
import { Button } from "antd";

function AddLeaveStatus({ status, onClick }) {
  return (
    <Button
      onClick={onClick}
      className="leaveStatus"
      disabled={status === "Përfunduar"}
      size="small"
      style={
        status === "Refuzuar"
          ? { backgroundColor: "#EA3943", color: "#FBFBFB" }
          : status === "Pending"
          ? { backgroundColor: "#FCC94A", color: "#1D3445" }
          : status === "Aprovuar"
          ? { backgroundColor: "#1DA193", color: "#FBFBFB" }
          : { backgroundColor: "#787677", color: "#FBFBFB" }
      }
    >
      {status}
    </Button>
  );
}

export default AddLeaveStatus;
