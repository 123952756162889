import React, { useContext } from "react";
import "./MainTabs.scss";
import { Badge, Button, Input } from "antd";
import Swal from "sweetalert2";
import { FilterIconButton, SearchIconInput } from "../../../assets/icons";
import { InterviewsContext } from "../data";
const FilterComponent = () => {
  const {
    onChange,
    activeFilters,
    setOpenFilters,
    setActiveFilters,
    defaultSwalProperties,
  } = useContext(InterviewsContext);

  return (
    <div className="kanbanFilters">
      <Input
        placeholder="Kerko kandidatin..."
        className="inputSearchFilter"
        value={activeFilters?.kandidati}
        onChange={(event) => onChange(event.target.value, "kandidati")}
        prefix={<SearchIconInput />}
      />
      <Badge
        size="medium"
        color="#20AC9D"
        count={
          !Object.keys(activeFilters)?.length
            ? null
            : Object.keys(activeFilters)?.length
        }
      >
        <Button
          onClick={() => setOpenFilters(true)}
          className="filterBtnKanban"
        >
          Filtroni
        </Button>
      </Badge>
      {Object.keys(activeFilters)?.length > 0 && (
        <Button
          className="clearFilterBtn"
          onClick={() => {
            setActiveFilters({});
            Swal.mixin({
              ...defaultSwalProperties,
            }).fire({
              icon: "info",
              title: "Te gjitha filtrat u pastruan!",
            });
          }}
        >
          <div className="centerIcon">{<FilterIconButton />}</div>
        </Button>
      )}
    </div>
  );
};

export default FilterComponent;
