import types from "../types/types";

const prepayments = (prepaymentsList) => {
	return (dispatch) => {
		dispatch({
			type: types.SET_PREPAYMENTS,
			payload: prepaymentsList,
		});
	};
};
export default prepayments;
