import React, { useState, useEffect } from "react";
import { Row } from "antd";
import { API } from "aws-amplify";
import "./kompania_pozicionet.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import { uniqBy } from "lodash";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import DragContainer from "../../../dashboard/AdminDashboard/Draggable/DragContainer";
// import CardComponent from "./CardComponent";
// import { MenagementIcon, EngenieringIcon } from "../../../../assets/icons";

/**
 * * @EneaXharau - Websocket connection
 */
// const socketHandler = new WebSocket(getSocketUrl("/departments/sockets"));

function KompaniaPozicionet() {
	const [department, setDepartment] = useState(null);
	const navigate = useNavigate();
	//get employee table from Redoux
	const { employees } = useSelector((state) => state.employeesList);
	const [FilterDepartment, setFilterDepartment] = useState();

	// console.log(employees);

	// get the departments table
	const getUsers = async () => {
		await API.get("departments", "/departments").then((departments) => {
			// matches the departments names with the employes that have that department
			const filter = uniqBy(
				departments?.map(
					({ departmentName, departmentRoles, departmentManagerName, departmentId }) => ({
						departmentName,
						departmentRoles,
						departmentId,
						departmentManagerName,
						lengthForEachDepartment: employees?.filter(
							({ employeeDepartmentName, employeeStatus }) =>
								employeeDepartmentName === departmentName && employeeStatus === "Aktiv"
						),
					})
				),
				"departmentName",
				"departmentRoles"
			);
			setFilterDepartment(filter);
			setDepartment(departments);
		});
	};

	// executes the fuction getUsers when the pages is renderd
	useEffect(() => {
		if(!!employees?.length){
			getUsers();
		}
	}, [employees]);

	/**
	 * * @EneaXharau - useEffect to listen to socket emits from backend
	 * * and run a function with a setTimeout to cancel the delay of emitting
	 * * according to its emit request.
	 * * .onmessage is a variable that needs to be a function (arrow preferably)
	 * * .onmessage takes a parameter from backend which is is a stringified object
	 */
	// useEffect(() => {
	//   socketHandler.onmessage = (msg) => {
	//     const { request, body } = JSON.parse(msg.data);
	//     switch (request) {
	//       case "department_added_received":
	//         {
	//           getUsers();
	//         }
	//         break;
	//       case "department_deleted_received":
	//         {
	//           getUsers();
	//         }
	//         break;
	//       case "department_change_received":
	//         {
	//           getUsers();
	//         }
	//         break;
	//       default:
	//         break;
	//     }
	//   };
	//   return () => socketHandler.close();
	// }, []);

	return (
		<LoadableComp loading={!department}>
			<div className="kompania-pozicionet">
				<Row
					className="kompania-pozicionet-row"
					style={{
						width: "100%",
						// paddingLeft: 20,
						marginRight: 0,
					}}
				>
					{department?.length > 0 ? (
						<DragContainer view={"settingsPozicionet"} dynamicMap={FilterDepartment} />
					) : (
						<div className="nuk-ka-departament">Nuk ka asnjë departament</div>
					)}

					{/* <CardComponent data={Data} /> */}
				</Row>
			</div>
		</LoadableComp>
	);
}

export default KompaniaPozicionet;
